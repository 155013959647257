<template>
  <div>
    <v-row class="d-flex align-center pt-4 pt-sm-10">
      <v-col cols="12" sm="6" md="6">
        <p class="text-h4 primary--text mb-0 font-weight-black hidden-sm-and-down">Oportunidades</p>
      </v-col>
      <v-col cols="12" sm="6" md="6" class="d-flex flex-wrap justify-md-end justify-center">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn icon large class="mr-auto hidden-md-and-up" @click="showFilters = !showFilters" v-on="on">
              <v-icon size="16">fas fa-filter</v-icon>
            </v-btn>
          </template>
          <span>{{ showFilters ? "Ocultar" : "Mostrar" }} filtros</span>
        </v-tooltip>
        <div class="pr-4 md-d-flex hidden-md-and-down">
          <v-tooltip bottom>
            <template v-slot:activator="{ on1 }">
              <v-btn
                class="pa-2"
                rounded
                large
                icon
                :to="{ name: 'tickets' }"
                exact-active-class="v-btn--text"
                exact
                v-on="on1"
              >
                <v-icon size="16">fas fa-list</v-icon>
              </v-btn>
            </template>
            <span>Vista en tabla</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                class="pa-2"
                rounded
                large
                icon
                :to="{ name: 'ticket_dashboard' }"
                exact-active-class="v-btn--text"
                exact
                v-on="on"
              >
                <v-icon size="16">fas fa-th</v-icon>
              </v-btn>
            </template>
            <span>Vista por estados</span>
          </v-tooltip>
        </div>
        <div>
          <v-btn :to="{ name: 'ticket_downloads_register' }" outlined color="primary" dark normal class="mr-4 my-auto">
            Descargar
            <v-icon right size="16">fa-file-download</v-icon>
          </v-btn>
        </div>
        <v-menu offset-y bottom left>
          <template v-slot:activator="{ on }">
            <v-btn outlined icon v-on="on">
              <v-icon size="16">fa fa-ellipsis-v</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item :to="{ name: 'ticket_downloads' }">
              <v-list-item-title>Descargables</v-list-item-title>
            </v-list-item>
            <v-list-item :to="{ name: 'importer_tickets' }">
              <v-list-item-title>Importar oportunidades</v-list-item-title>
            </v-list-item>
            <v-list-item :to="{ name: 'importer_ticket_activities' }">
              <v-list-item-title>Importar actividades</v-list-item-title>
            </v-list-item>
            <v-list-item :to="{ name: 'report_activities' }">
              <v-list-item-title>Reporte de actividades</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
    </v-row>
    <v-row class="d-flex align-center py-2 py-sm-4">
      <template v-for="(f, i) in customFilters">
        <v-col
          v-if="showFilters"
          :key="i"
          :cols="f.flex_xs || '12'"
          :sm="f.flex_md || '6'"
          :md="f.flex_md || '3'"
          class="py-0 mb-1 mb-sm-0"
        >
          <v-card class="py-2 px-4 elevation-3 rounded-lg">
            <v-autocomplete
              :value="f.value"
              :items="f.items"
              :label="f.name"
              :readonly="f.readonly_advisor && getAgent.role === $roles.ADVISOR"
              :item-text="f.item_text || 'name'"
              :item-value="f.item_value || 'id'"
              class="px-1"
              @change="applyFilters(f.id, $event)"
            ></v-autocomplete>
          </v-card>
        </v-col>
      </template>
      <v-spacer></v-spacer>
      <v-col cols="12" sm="6" md="3" class="py-0">
        <v-card class="px-4 elevation-3 rounded-lg py-2">
          <v-text-field
            label="Buscar"
            dense
            solo
            single-line
            hide-details
            flat
            class="d-flex align-center imput-icon"
            append-icon="fa fa-search"
            @input="applySearch"
          ></v-text-field>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "TicketHeader",
  props: {
    query: {
      type: String,
      default: "",
    },
    showFilters: {
      type: Boolean,
      default: false,
    },
    customFilters: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return { timerToSearch: null };
  },
  computed: {
    ...mapGetters(["getAgent"]),
  },
  methods: {
    applySearch(value) {
      clearTimeout(this.timerToSearch);

      this.timerToSearch = setTimeout(async () => {
        this.$emit("update:query", value);
        this.$emit("loadTickets");
      }, 250);
    },
    applyFilters(id, event) {
      this.$emit("applyFilter", id, event);
    },
  },
};
</script>
