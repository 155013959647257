var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    [
      _vm.getSegmentsAutomations && _vm.getAgent
        ? _c(
            "v-container",
            { staticClass: "mb-10 pb-10 pt-4 pt-sm-10" },
            [
              _c(
                "v-row",
                { staticClass: "d-flex" },
                [
                  _c("v-col", { attrs: { cols: "12", sm: "6", md: "9" } }, [
                    _c(
                      "h2",
                      {
                        staticClass:
                          "text-h4 primary--text pb-4 font-weight-black pt-0"
                      },
                      [
                        _vm._v(
                          "\n          Automatizaciones de segmentos\n        "
                        )
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    {
                      staticClass: "text-sm-right",
                      attrs: { cols: "12", sm: "6", md: "3" }
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "mr-4 my-auto",
                          attrs: {
                            to: { name: "segments" },
                            outlined: "",
                            color: "primary",
                            dark: "",
                            normal: "",
                            "active-class": "no-active"
                          }
                        },
                        [_vm._v("\n          Ver segmentos\n        ")]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c(
                        "v-card",
                        { staticClass: "pa-4 rounded-lg fill-height" },
                        [
                          _c(
                            "v-row",
                            { staticClass: "justify-center" },
                            [
                              _vm.getSegmentsAutomations.length
                                ? _c(
                                    "v-col",
                                    {
                                      staticClass: "py-0",
                                      attrs: { cols: "12" }
                                    },
                                    [
                                      _c("v-data-table", {
                                        staticClass: "text-headers-colors",
                                        attrs: {
                                          headers: _vm.headers,
                                          items: _vm.getSegmentsAutomations,
                                          "items-per-page": 50,
                                          dense: "",
                                          "sort-by": "",
                                          "mobile-breakpoint": 0,
                                          "hide-default-footer": "",
                                          "show-expand": ""
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "item.created_at",
                                              fn: function(ref) {
                                                var item = ref.item
                                                return [
                                                  _vm._v(
                                                    "\n                  " +
                                                      _vm._s(
                                                        _vm.$formatTimestamp(
                                                          item.created_at
                                                        )
                                                      ) +
                                                      "\n                "
                                                  )
                                                ]
                                              }
                                            },
                                            {
                                              key: "item.module",
                                              fn: function(ref) {
                                                var item = ref.item
                                                return [
                                                  _vm._v(
                                                    "\n                  " +
                                                      _vm._s(
                                                        _vm.modulesName[
                                                          item.segment.module
                                                        ]
                                                      ) +
                                                      "\n                "
                                                  )
                                                ]
                                              }
                                            },
                                            {
                                              key: "item.state",
                                              fn: function(ref) {
                                                var item = ref.item
                                                return [
                                                  [
                                                    "iniciando",
                                                    "enviando"
                                                  ].includes(item.state)
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "text-center"
                                                        },
                                                        [
                                                          _c(
                                                            "v-progress-circular",
                                                            {
                                                              attrs: {
                                                                size: 20,
                                                                color:
                                                                  "primary",
                                                                indeterminate:
                                                                  ""
                                                              }
                                                            }
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  _vm._v(
                                                    "\n                  " +
                                                      _vm._s(item.state) +
                                                      "\n                "
                                                  )
                                                ]
                                              }
                                            },
                                            {
                                              key: "expanded-item",
                                              fn: function(ref) {
                                                var item = ref.item
                                                return [
                                                  _c(
                                                    "td",
                                                    {
                                                      staticClass: "pa-8",
                                                      attrs: {
                                                        colspan:
                                                          _vm.headers.length
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "v-row",
                                                        [
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "12",
                                                                sm: "8"
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "SegmentDetail",
                                                                {
                                                                  attrs: {
                                                                    segment:
                                                                      item.segment
                                                                  }
                                                                }
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "12",
                                                                sm: "4"
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "v-row",
                                                                [
                                                                  _c(
                                                                    "v-col",
                                                                    {
                                                                      attrs: {
                                                                        cols:
                                                                          "12"
                                                                      }
                                                                    },
                                                                    [
                                                                      _c("h4", [
                                                                        _vm._v(
                                                                          "Nº de registros"
                                                                        )
                                                                      ]),
                                                                      _vm._v(
                                                                        "\n                            " +
                                                                          _vm._s(
                                                                            item.counter_messages ||
                                                                              0
                                                                          ) +
                                                                          "\n                          "
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "v-col",
                                                                    {
                                                                      attrs: {
                                                                        cols:
                                                                          "12"
                                                                      }
                                                                    },
                                                                    [
                                                                      _c("h4", [
                                                                        _vm._v(
                                                                          "Nº de mensajes enviados"
                                                                        )
                                                                      ]),
                                                                      _vm._v(
                                                                        "\n                            " +
                                                                          _vm._s(
                                                                            item.counter_messages_successfull ||
                                                                              0
                                                                          ) +
                                                                          "\n                          "
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "v-col",
                                                                    {
                                                                      attrs: {
                                                                        cols:
                                                                          "12"
                                                                      }
                                                                    },
                                                                    [
                                                                      _c("h4", [
                                                                        _vm._v(
                                                                          "Nº de mensajes no enviados"
                                                                        )
                                                                      ]),
                                                                      _vm._v(
                                                                        "\n                            " +
                                                                          _vm._s(
                                                                            item.counter_messages_failed ||
                                                                              0
                                                                          ) +
                                                                          "\n                          "
                                                                      )
                                                                    ]
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          false,
                                          2756857532
                                        )
                                      })
                                    ],
                                    1
                                  )
                                : _c(
                                    "v-col",
                                    {
                                      staticClass:
                                        "text-center text--secondary pt-10",
                                      attrs: { cols: "12" }
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "fas fa-file-download"
                                      }),
                                      _vm._v(" "),
                                      _c("h6", { staticClass: "caption" }, [
                                        _vm._v(
                                          "Aún no hay descargas disponibles"
                                        )
                                      ])
                                    ]
                                  )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _c("v-container", [_c("ShowLoading")], 1)
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }