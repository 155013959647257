const SET_TICKET_STATES = "SET_TICKET_STATES";
const ADD_TICKET_STATE = "ADD_TICKET_STATE";
const UPDATE_TICKET_STATE = "UPDATE_TICKET_STATE";
const REMOVE_TICKET_STATE = "REMOVE_TICKET_STATE";
const SET_ORDER_STATES = "SET_ORDER_STATES";
const SET_ORDER_STATES_MAP = "SET_ORDER_STATES_MAP";

export {
  SET_TICKET_STATES,
  ADD_TICKET_STATE,
  UPDATE_TICKET_STATE,
  REMOVE_TICKET_STATE,
  SET_ORDER_STATES,
  SET_ORDER_STATES_MAP,
};
