/* eslint-disable */
import Vue from "vue";
import VueRouter from "vue-router";
import Menu from "@/views/menu/Menu";
import Login from "@/views/auth/Login";
import Orders from "@/views/orders/Orders";
import OrderDetail from "@/views/orders/options/Detail";
import Contacts from "@/views/contacts/Contacts";
import ImporterContacts from "@/views/contacts/options/Importer";
import ImporterContactActivities from "@/views/contacts/options/ImporterActivities";
import ContactDetail from "@/views/contacts/options/Detail";
import ContactRegister from "@/views/contacts/options/Register";
import Tickets from "@/views/tickets/Tickets";
import TicketDetail from "@/views/tickets/options/Detail";
import TicketRegister from "@/views/tickets/options/Register";
import TicketDashboard from "@/views/tickets/options/Dashboard";
import TicketDownloads from "@/views/tickets/options/Downloads";
import TicketDownloadsRegister from "@/views/tickets/options/downloads/Register";
import ImporterTickets from "@/views/tickets/options/Importer";
import ImporterTicketActivities from "@/views/tickets/options/ImporterActivities";
import ReporterActivities from "@/views/tickets/options/ReportActivities";
import Agents from "@/views/agents/Agents";
import AgentDetail from "@/views/agents/options/Detail";
import AgentRegister from "@/views/agents/options/Register";
import Events from "@/views/events/Events";
import Campaigns from "@/views/campaigns/Campaigns";
import CampaignRegisterBudget from "@/views/campaigns/options/RegisterBudget";
import Statistics from "@/views/statistics/Statistics";
import Segments from "@/views/segments/Segments";
import SegmentRegister from "@/views/segments/options/Register";
import SegmentDownloads from "@/views/segments/options/Downloads";
import SegmentAutomations from "@/views/segments/options/Automations";
import Settings from "@/views/settings/Settings";

import { contactsCollection, ticketsCollection, ordersCollection } from "@/config/firebase";

import getCurrentUser from "@/helpers/get_current_user";
import { roles } from "@/helpers/get_constants";

Vue.use(VueRouter);

const allRoles = [roles.ADVISOR, roles.SUPERVISOR, roles.ADMIN, roles.SUPER_ADMIN, roles.ENTREPRENEUR];

const router = new VueRouter({
  mode: "history",
  routes: [
    {
      path: "/",
      redirect: "/opportunities",
    },
    {
      path: "/login",
      name: "login",
      component: Login,
      meta: {
        authorizedRoles: ["anonymous"],
      },
    },
    {
      path: "/contacts",
      name: "contacts",
      component: Contacts,
      props: (route) => ({ state: route.query.state, country: route.query.country, agent: route.query.agent }),
      meta: {
        title: "Contactos",
        authorizedRoles: allRoles,
      },
    },
    {
      path: "/contacts/importer",
      name: "importer_contacts",
      component: ImporterContacts,
      meta: {
        title: "Contactos",
        authorizedRoles: allRoles,
      },
    },
    {
      path: "/contacts/importer-activities",
      name: "importer_contact_activities",
      component: ImporterContactActivities,
      meta: {
        title: "Contactos",
        authorizedRoles: allRoles,
      },
    },
    {
      path: "/contacts/register",
      name: "contact_register",
      component: ContactRegister,
      meta: {
        title: "Contactos",
        authorizedRoles: allRoles,
      },
    },
    {
      path: "/contacts/:id",
      name: "contact_detail",
      component: ContactDetail,
      meta: {
        title: "Contactos",
        authorizedRoles: allRoles,
      },
      async beforeEnter(to, from, next) {
        let response = await contactsCollection.doc(to.params.id).get();
        if (response.exists) {
          if (
            [roles.SUPER_ADMIN, roles.ADMIN].includes(to.meta.user.role) ||
            ([roles.SUPERVISOR, roles.ENTREPRENEUR].includes(to.meta.user.role) &&
              response.data().team_id === to.meta.user.team_id) ||
            to.meta.user.uid === response.data().agent_id
          ) {
            next();
          } else {
            next("/contacts");
          }
        } else {
          next("/contacts");
        }
      },
    },
    {
      path: "/orders",
      name: "orders",
      component: Orders,
      meta: {
        title: "Órdenes",
        authorizedRoles: [roles.SUPER_ADMIN, roles.ADMIN, roles.ENTREPRENEUR],
      },
    },
    {
      path: "/orders/:id",
      name: "order_detail",
      component: OrderDetail,
      meta: {
        title: "Órdenes",
        authorizedRoles: [roles.SUPER_ADMIN, roles.ADMIN, roles.ENTREPRENEUR],
      },
      async beforeEnter(to, from, next) {
        let response = await ordersCollection.doc(to.params.id).get();
        if (response.exists) {
          if (
            [roles.SUPER_ADMIN, roles.ADMIN, roles.SUPERVISOR].includes(to.meta.user.role) ||
            (roles.ENTREPRENEUR === to.meta.user.role && response.data().team_id === to.meta.user.team_id)
          ) {
            next();
          } else {
            next("/orders");
          }
        } else {
          next("/orders");
        }
      },
    },
    {
      path: "/opportunities",
      name: "tickets",
      component: Tickets,
      meta: {
        title: "Oportunidades",
        authorizedRoles: allRoles,
      },
    },
    {
      path: "/opportunities/dashboard",
      name: "ticket_dashboard",
      component: TicketDashboard,
      meta: {
        title: "Dashboard Oportunidades",
        authorizedRoles: allRoles,
      },
    },
    {
      path: "/opportunities/register",
      name: "ticket_register",
      component: TicketRegister,
      meta: {
        title: "Oportunidad",
        authorizedRoles: allRoles,
      },
    },
    {
      path: "/opportunities/importer",
      name: "importer_tickets",
      component: ImporterTickets,
      meta: {
        title: "Tickets",
        authorizedRoles: allRoles,
      },
    },
    {
      path: "/opportunities/importer-activities",
      name: "importer_ticket_activities",
      component: ImporterTicketActivities,
      meta: {
        title: "Tickets",
        authorizedRoles: allRoles,
      },
    },
    {
      path: "/opportunities/report-activities",
      name: "report_activities",
      component: ReporterActivities,
      meta: {
        title: "Tickets",
        authorizedRoles: allRoles,
      },
    },
    {
      path: "/opportunities/downloads",
      name: "ticket_downloads",
      component: TicketDownloads,
      meta: {
        title: "Tickets",
        authorizedRoles: allRoles,
      },
    },
    {
      path: "/opportunities/downloads/register",
      name: "ticket_downloads_register",
      component: TicketDownloadsRegister,
      meta: {
        title: "Tickets",
        authorizedRoles: allRoles,
      },
    },
    {
      path: "/opportunities/:id",
      name: "ticket_detail",
      component: TicketDetail,
      meta: {
        title: "Oportunidades",
        authorizedRoles: allRoles,
      },
      async beforeEnter(to, from, next) {
        let response = await ticketsCollection.doc(to.params.id).get();
        if (response.exists) {
          if (
            [roles.SUPER_ADMIN, roles.ADMIN].includes(to.meta.user.role) ||
            ([roles.SUPERVISOR, roles.ENTREPRENEUR].includes(to.meta.user.role) &&
              response.data().team_id === to.meta.user.team_id) ||
            to.meta.user.uid === response.data().agent_id
          ) {
            next();
          } else {
            next("/opportunities");
          }
        } else {
          next("/opportunities");
        }
      },
    },
    {
      path: "/agents",
      name: "agents",
      component: Agents,
      meta: {
        title: "Trabajadores",
        authorizedRoles: [roles.ADMIN, roles.SUPER_ADMIN],
      },
    },
    {
      path: "/agents/register",
      name: "agent_register",
      component: AgentRegister,
      meta: {
        title: "Trabajadores",
        authorizedRoles: [roles.SUPER_ADMIN],
      },
    },
    {
      path: "/agent/:id",
      name: "agent_detail",
      component: AgentDetail,
      meta: {
        title: "Trabajadores",
        authorizedRoles: [roles.ADMIN, roles.SUPER_ADMIN],
      },
    },
    {
      path: "/campaigns",
      name: "campaigns",
      component: Campaigns,
      meta: {
        title: "Campañas",
        authorizedRoles: allRoles,
      },
    },
    {
      path: "/campaigns/register-budget",
      name: "campaigns_register_budget",
      component: CampaignRegisterBudget,
      meta: {
        title: "Nuevo presupuesto de campaña",
        authorizedRoles: allRoles,
      },
    },
    {
      path: "/events",
      name: "events",
      component: Events,
      meta: {
        title: "Eventos",
        authorizedRoles: allRoles,
      },
    },
    {
      path: "/statistics",
      name: "statistics",
      component: Statistics,
      meta: {
        title: "Estadísticas",
        authorizedRoles: [roles.ADMIN, roles.SUPER_ADMIN, roles.ENTREPRENEUR],
      },
    },
    {
      path: "/segments",
      name: "segments",
      component: Segments,
      meta: {
        title: "Segmentos",
        authorizedRoles: [roles.ADMIN, roles.SUPER_ADMIN, roles.ENTREPRENEUR],
      },
    },
    {
      path: "/segments/register",
      name: "segment_register",
      component: SegmentRegister,
      meta: {
        title: "Segmentos",
        authorizedRoles: [roles.ADMIN, roles.SUPER_ADMIN, roles.ENTREPRENEUR],
      },
    },
    {
      path: "/segments/downloads",
      name: "segment_downloads",
      component: SegmentDownloads,
      meta: {
        title: "Segmentos",
        authorizedRoles: [roles.ADMIN, roles.SUPER_ADMIN, roles.ENTREPRENEUR],
      },
    },
    {
      path: "/segments/automations",
      name: "segment_automations",
      component: SegmentAutomations,
      meta: {
        title: "Segmentos",
        authorizedRoles: [roles.ADMIN, roles.SUPER_ADMIN, roles.ENTREPRENEUR],
      },
    },
    {
      path: "/settings",
      name: "settings",
      component: Settings,
      meta: {
        title: "Configuraciones",
        authorizedRoles: allRoles,
      },
    },
    {
      path: "/menu",
      name: "menu",
      component: Menu,
      meta: {
        title: "Menu",
        authorizedRoles: allRoles,
      },
    },
    {
      path: "*",
      redirect: "/login",
    },
  ],
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

router.beforeEach(async (to, from, next) => {
  let authorizedRoles = to.meta.authorizedRoles || [];
  let user = await getCurrentUser();

  if (!authorizedRoles.includes(user.role)) {
    if (user.role === "anonymous") {
      next("/login");
    } else {
      next("/");
    }
  } else {
    to.meta.user = user;
    next();
  }
});

export default router;
