<template>
  <div>
    <v-timeline v-if="getTimeline(ticketId) && getTimeline(ticketId).length > 0" dense>
      <v-slide-x-transition group>
        <v-timeline-item
          v-for="(c, index) in sortTimeLine"
          :key="index"
          fill-dot
          :color="getCommentTypes[c.type].color"
          small
        >
          <template v-slot:icon>
            <v-avatar v-if="getAllAgentsMap[c.uid] && getAllAgentsMap[c.uid].photo" size="24">
              <img :src="getAllAgentsMap[c.uid].photo" :alt="getAllAgentsMap[c.uid].name" />
            </v-avatar>
          </template>
          <v-card class="pl-6 py-2 pr-2">
            <v-row>
              <strong v-if="getAllAgentsMap[c.uid]" class="pr-1" v-text="getAllAgentsMap[c.uid].full_name"></strong>
              <strong v-if="getAllAgentsMap[c.uid]">·</strong>
              <strong v-if="c.created_at" class="ml-1" v-text="$formatTimestamp(c.created_at, 'short')"></strong>
            </v-row>
            <div class="caption pb-2" v-html="c.text_formatted || c.text"></div>
            <div v-if="c.order_id" class="mb-1">
              <v-col v-if="c.products" cols="12" class="pr-5">
                <h6 class="caption mb-2">Productos relacionados:</h6>
                <v-simple-table class="mb-4" style="overflow-x: auto; border: 1px solid lightgrey;">
                  <thead class="grey lighten-4">
                    <tr>
                      <th class="text-center">Nombre</th>
                      <th class="text-center">Cantidad</th>
                      <th class="text-center">Subtotal</th>
                    </tr>
                  </thead>
                  <tbody class="text-center">
                    <tr v-for="(product, i) in c.products" :key="i">
                      <td>{{ product.name || product.id }}</td>
                      <td>{{ product.quantity }}</td>
                      <td>{{ $formatCurrency(product.price * product.quantity) }}</td>
                    </tr>
                    <tr>
                      <td colspan=" 2" class="text-right subtitle">Total</td>
                      <td class="grey lighten-5">
                        <b class="primary--text subtitle">{{ $formatCurrency(c.total_paid) }}</b>
                      </td>
                    </tr>
                  </tbody>
                </v-simple-table>
              </v-col>
              <v-btn
                v-if="![$roles.ADVISOR, $roles.SUPERVISOR].includes(getAgent.role)"
                text
                small
                :to="{ name: 'order_detail', params: { id: c.order_id } }"
                target="_blank"
                color="primary"
              >
                <v-icon x-small class="pr-2">fas fa-external-link-alt</v-icon> Ver detalle de la orden
              </v-btn>
            </div>
            <v-chip :color="getCommentTypes[c.type].color" rounded outlined small class="my-1 mr-1">
              <v-icon left size="12">{{ getCommentTypes[c.type].icon }}</v-icon>
              <span v-if="c.type == $activityTypes.CALL">Llamada</span>
              <span v-else-if="c.type == $activityTypes.MESSAGE">Correo</span>
              <span v-else-if="c.type == $activityTypes.EVENT">
                {{ `${c.event_date} ${c.event_time} ${c.event_time_end ? ` - ${c.event_time_end}` : ""}` }}
              </span>
              <span v-else>Comentario</span>
            </v-chip>
            <v-chip v-if="c.remote_jid" :color="getCommentTypes[5].color" rounded outlined small>
              <v-icon left size="12">{{ getCommentTypes[5].icon }}</v-icon>
              <span>WhatsApp</span>
            </v-chip>
            <div v-if="c.type === $activityTypes.EVENT && c.uid === getAgent.uid" class="text-right pa-2">
              <BtnCalendar
                :event-id.sync="c.id"
                :date.sync="c.event_date"
                :hour-start.sync="c.event_time"
                :hour-end.sync="c.event_time_end"
                :title.sync="c.event_name"
                :detail.sync="c.detail"
              />
            </div>
          </v-card>
        </v-timeline-item>
      </v-slide-x-transition>
    </v-timeline>
    <div v-else class="text-center py-4">Aún no hay comentarios</div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import BtnCalendar from "@/components/buttons/BtnCalendar";

export default {
  components: {
    BtnCalendar,
  },
  props: {
    ticketId: {
      type: String,
      default: "",
    },
  },
  computed: {
    ...mapGetters(["getAgent", "getAllAgentsMap", "getTimeline", "getSelectedTicketId", "getCommentTypes"]),
    sortTimeLine() {
      return this.getTimeline(this.ticketId);
    },
  },
  async beforeMount() {
    if (this.ticketId) await this.getTicketTimeline({ ticket_id: this.ticketId });
  },
  methods: {
    ...mapActions(["getTicketTimeline"]),
  },
};
</script>
