<template>
  <v-app id="contact_detail">
    <v-row v-if="getAgent && getContact && getAllAgentsArray">
      <!-- Contact information -->
      <v-col cols="12" md="5">
        <v-row class="sticky-75">
          <v-col cols="12">
            <v-expansion-panels v-model="tabs" multiple>
              <!-- Información -->
              <v-expansion-panel>
                <v-expansion-panel-header class="text--darken-2 font-weight-bold">
                  INFORMACIÓN
                  <div v-if="!editInformation" class="text-right">
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-btn size="16" icon small class="mr-2" v-on="on">
                          <v-icon size="16" @click.stop.prevent="editInformation = true">fas fa-pencil-alt</v-icon>
                        </v-btn>
                      </template>
                      <span>Editar información</span>
                    </v-tooltip>
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-row class="fill-height text-center px-3">
                    <v-col v-if="editInformation" cols="12">
                      <h4>Editar información del Contacto</h4>
                    </v-col>
                    <v-col v-if="editInformation" cols="12">
                      <ContactForm action="update" @cancelInformation="cancelInformation" />
                    </v-col>
                    <v-col v-else cols="12">
                      <v-row class="fill-height text-center">
                        <template v-for="(contactField, i) in contactFields">
                          <v-col :key="i" cols="12">
                            <v-text-field
                              :value="getContact[contactField.field]"
                              :label="contactField.label"
                              :readonly="!editInformation"
                            ></v-text-field>
                          </v-col>
                        </template>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <!-- Etiquetas -->
              <v-expansion-panel>
                <v-expansion-panel-header class="text--darken-2 font-weight-bold">
                  ETIQUETAS
                  <v-icon
                    v-if="!editTags"
                    size="16"
                    class="pa-2 mr-4"
                    style="font-size: 16px; position: absolute; z-index: 10000; right: 35px;"
                    @click.stop.prevent="editTags = true"
                    >fas fa-pencil-alt</v-icon
                  >
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-form lazy-validation>
                    <v-row class="text-center px-3 pt-2">
                      <v-col v-if="editTags" cols="12">
                        <h4>Editar etiquetas del Contacto</h4>
                      </v-col>
                      <v-col cols="12">
                        <template>
                          <v-combobox
                            v-model="tags"
                            :items="getActiveTags"
                            :disabled="!editTags"
                            chips
                            label="Etiquetas"
                            multiple
                            class="h-auto"
                            item-id="name"
                            item-text="name"
                            return-object
                            @change="changeTags"
                          >
                            <template v-slot:selection="{ attrs, item, select, selected }">
                              <v-chip
                                v-bind="attrs"
                                :input-value="selected"
                                :close="editTags"
                                :color="item.color"
                                @click="select"
                                @click:close="removeTag(item)"
                              >
                                <strong>{{ item.name }}</strong
                                >&nbsp;
                                <span></span>
                              </v-chip>
                            </template>
                            <template v-slot:no-data>
                              <v-list-item>
                                <v-list-item-content>
                                  <v-list-item-title>
                                    El tag no existe. Presiona <kbd>enter</kbd> para crearlo.
                                  </v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                            </template>
                          </v-combobox>
                        </template>
                      </v-col>
                      <TagModal />
                      <v-col v-if="editTags" cols="12">
                        <v-btn small rounded color="accent" :loading="loadSaveTags" @click="saveTags">Guardar</v-btn>
                        <v-btn small rounded @click="cancelTags()">Cancelar</v-btn>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <!-- Ventas -->
              <v-expansion-panel>
                <v-expansion-panel-header class="text--darken-2 font-weight-bold">
                  VENTAS
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-row class="fill-height text-center px-3 pt-2">
                    <v-col cols="7" class="pt-1 text-right">
                      <h4 class="font-weight-medium">
                        Total de órdenes generadas:
                      </h4>
                    </v-col>
                    <v-col cols="5" class="pt-1">
                      <h4 class="font-weight-medium">
                        <strong>{{ getContact.total_orders || 0 }}</strong>
                      </h4>
                    </v-col>
                    <v-col cols="7" class="pt-1 text-right">
                      <h4 class="font-weight-medium">
                        Total de órdenes completadas:
                      </h4>
                    </v-col>
                    <v-col cols="5" class="pt-1">
                      <h4 class="font-weight-medium">
                        <strong>{{ getContact.total_sales || 0 }}</strong>
                      </h4>
                    </v-col>
                    <v-col cols="7" class="text-right">
                      <h4 class="font-weight-medium">
                        Total de dinero comprado:
                      </h4>
                    </v-col>
                    <v-col cols="5">
                      <h4 class="font-weight-medium">
                        <strong>{{ $formatCurrency(getContact.total_value || 0) }}</strong>
                      </h4>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <!--Asistencia -->
              <v-expansion-panel>
                <v-expansion-panel-header class="text--darken-2 font-weight-bold">
                  ASISTENCIA
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-row class="fill-height text-center px-3 pt-2">
                    <v-col cols="12" class="d-flex">
                      <div v-if="getContact.agent_id">
                        <v-avatar size="50px" color="black">
                          <img
                            v-if="getAllAgentsMap[getContact.agent_id].photo"
                            :src="getAllAgentsMap[getContact.agent_id].photo"
                            alt="Avatar"
                          />
                          <span v-else class="white--text headline">
                            {{ getAllAgentsMap[getContact.agent_id].full_name.substring(0, 1) }}
                          </span>
                        </v-avatar>
                      </div>
                      <div v-else>
                        <v-avatar size="50px" color="black">
                          <span class="white--text headline">-</span>
                        </v-avatar>
                      </div>
                      <v-text-field
                        v-if="getAgent.role === $roles.ADVISOR"
                        v-model="getAgent.full_name"
                        label="Encargado"
                        class="pl-2"
                        readonly
                      >
                      </v-text-field>
                      <v-autocomplete
                        v-else
                        v-model="agentContact"
                        :items="getAgentsToArray"
                        item-text="full_name"
                        item-value="id"
                        :value="getContact.agent_id"
                        label="Encargado"
                        placeholder="Sin asignar"
                        class="pl-2 w95"
                      ></v-autocomplete>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <!--Fuente -->
              <v-expansion-panel>
                <v-expansion-panel-header class="text--darken-2 font-weight-bold">
                  FUENTE
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-row class="fill-height text-center px-3 pt-2">
                    <v-col class="size" cols="12">
                      <div>
                        Obtenido desde <strong>{{ getContact.origin }}</strong>
                      </div>
                    </v-col>
                    <v-col v-if="getContact.created_uid" class="d-flex" cols="12">
                      <div>
                        <v-avatar size="50px" color="black">
                          <img
                            v-if="getAllAgentsMap[getContact.created_uid].photo"
                            :src="getAllAgentsMap[getContact.created_uid].photo"
                            alt="Avatar"
                          />
                          <span v-else class="white--text headline">
                            {{ getAllAgentsMap[getContact.created_uid].full_name.substring(0, 1) }}
                          </span>
                        </v-avatar>
                      </div>
                      <v-text-field
                        :value="getAllAgentsMap[getContact.created_uid].full_name"
                        label="Registrado por:"
                        class="pl-2 w95"
                        readonly
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <!-- Privacidad de datos -->
              <v-expansion-panel>
                <v-expansion-panel-header class="text--darken-2 font-weight-bold">
                  PRIVACIDAD DE DATOS
                  <div v-if="!editPrivacy" class="text-right">
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-btn size="16" icon small class="mr-2" v-on="on">
                          <v-icon size="16" @click.stop.prevent="editPrivacy = true">fas fa-pencil-alt</v-icon>
                        </v-btn>
                      </template>
                      <span>Editar privacidad de datos</span>
                    </v-tooltip>
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-row class="px-3 pt-2">
                    <v-col cols="12">
                      <v-checkbox v-model="terms" :readonly="!editPrivacy">
                        <template v-slot:label>
                          <div>
                            Autorización para utilizar sus datos en cualquier campaña/actividad.
                          </div>
                        </template>
                      </v-checkbox>
                    </v-col>
                    <v-col v-if="editPrivacy" cols="12" class="text-center">
                      <v-btn small rounded color="accent" :loading="loadSavePrivacy" @click="savePrivacy"
                        >Guardar</v-btn
                      >
                      <v-btn small rounded @click="cancelPrivacy">Cancelar</v-btn>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-row>
      </v-col>
      <!-- Timeline -->
      <v-col cols="12" md="7">
        <v-row>
          <!-- Box -->
          <v-col cols="12" class="mt-0">
            <v-card>
              <v-col class="grey--text text--darken-2 font-weight-bold pa-4" cols="12">HISTORIAL DE SEGUIMIENTO</v-col>
              <v-col cols="12 pa-4">
                <v-form ref="formActivity" lazy-validation>
                  <v-row>
                    <v-col cols="12 pa-4">
                      <v-textarea
                        v-model="comment.text"
                        name="input"
                        label="Escribe tu comentario"
                        auto-grow
                        rows="1"
                        placeholder=" "
                        :rules="rules.required"
                        class="expand"
                      ></v-textarea>
                    </v-col>
                    <v-col v-if="comment.type === 4" cols="12" class="py-0 px-4">
                      <v-col cols="12" class="px-0">
                        <v-text-field
                          v-model="comment.event_name"
                          label="Nombre del evento"
                          placeholder=" "
                          :rules="rules.required"
                        ></v-text-field>
                      </v-col>
                      <v-row class="px-3">
                        <v-col cols="12" md="4" lg="4" class="pa-0">
                          <v-row>
                            <v-col cols="3" class="d-flex">
                              <p class="my-auto body-2">Fecha:</p>
                            </v-col>
                            <v-col cols="9" sm="6">
                              <v-menu
                                v-model="menuDateStart"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="300px"
                              >
                                <template v-slot:activator="{ on }">
                                  <v-text-field
                                    v-model="comment.date_start"
                                    class="mb-4"
                                    :placeholder="today"
                                    readonly
                                    :rules="rules.required"
                                    v-on="on"
                                  ></v-text-field>
                                </template>
                                <v-date-picker
                                  v-model="comment.date_start"
                                  @input="menuDateStart = false"
                                ></v-date-picker>
                              </v-menu>
                            </v-col>
                          </v-row>
                        </v-col>
                        <v-spacer></v-spacer>
                        <v-col cols="12" md="8" lg="6" class="pa-0">
                          <v-row>
                            <v-col cols="3" class="d-flex">
                              <p class="my-auto body-2">Hora:</p>
                            </v-col>
                            <v-col cols="4" class="pr-0">
                              <v-dialog
                                ref="dialogTimeStart"
                                v-model="dialogTimeStart"
                                :return-value.sync="comment.time_start"
                                persistent
                                width="290px"
                              >
                                <template v-slot:activator="{ on }">
                                  <v-text-field
                                    v-model="comment.time_start"
                                    label=""
                                    placeholder="00:00"
                                    class="mb-4"
                                    readonly
                                    :rules="rules.required"
                                    v-on="on"
                                  ></v-text-field>
                                </template>
                                <v-time-picker v-if="dialogTimeStart" v-model="comment.time_start" full-width>
                                  <v-spacer></v-spacer>
                                  <v-btn text color="primary" @click="dialogTimeStart = false">Cancelar</v-btn>
                                  <v-btn text color="primary" @click="$refs.dialogTimeStart.save(comment.time_start)"
                                    >Aceptar</v-btn
                                  >
                                </v-time-picker>
                              </v-dialog>
                            </v-col>
                            <v-col cols="1" class="d-flex">
                              <p class="my-auto body-2">-</p>
                            </v-col>
                            <v-col cols="4">
                              <v-dialog
                                ref="dialogTimeEnd"
                                v-model="dialogTimeEnd"
                                :return-value.sync="comment.time_end"
                                persistent
                                width="290px"
                              >
                                <template v-slot:activator="{ on }">
                                  <v-text-field
                                    v-model="comment.time_end"
                                    label=""
                                    placeholder="01:00"
                                    class="mb-4"
                                    readonly
                                    :rules="[...rules.required, (v) => v > comment.time_start || 'Hora inválida']"
                                    v-on="on"
                                  ></v-text-field>
                                </template>
                                <v-time-picker
                                  v-if="dialogTimeEnd"
                                  v-model="comment.time_end"
                                  :min="comment.time_start"
                                  full-width
                                >
                                  <v-spacer></v-spacer>
                                  <v-btn text color="primary" @click="dialogTimeEnd = false">Cancelar</v-btn>
                                  <v-btn text color="primary" @click="$refs.dialogTimeEnd.save(comment.time_end)"
                                    >Aceptar</v-btn
                                  >
                                </v-time-picker>
                              </v-dialog>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-radio-group v-model="comment.type" row style="height: 30px;">
                        <v-icon class="pr-1" size="18">fas fa-comments</v-icon>
                        <v-radio color="grey darken-1" :value="0" selected></v-radio>
                        <v-icon class="pr-1" size="18">fas fa-phone</v-icon>
                        <v-radio color="grey darken-1" :value="1"></v-radio>
                        <v-icon class="pr-1" size="18">fas fa-envelope</v-icon>
                        <v-radio color="grey darken-1" :value="2"></v-radio>
                        <v-icon class="pr-1" size="18">far fa-calendar-alt</v-icon>
                        <v-radio color="grey darken-1" :value="4"></v-radio>
                      </v-radio-group>
                    </v-col>
                    <v-col class="text-right" cols="12" md="6">
                      <v-btn color="primary" class="mt-0" rounded @click="commentTimeline">Comentar</v-btn>
                    </v-col>
                  </v-row>
                </v-form>
              </v-col>
            </v-card>
          </v-col>
          <!-- Comments -->
          <v-col cols="12">
            <template>
              <v-card class="mx-auto">
                <v-card-text class="py-0">
                  <v-col cols="12" sm="10" offset-sm="1">
                    <v-timeline
                      v-if="getContactTimeline(contact.id) && getContactTimeline(contact.id).length > 0"
                      align-top
                      dense
                    >
                      <v-slide-x-reverse-transition group hide-on-leave>
                        <template v-for="(c, index) in getContactTimeline(contact.id)">
                          <v-timeline-item :key="index" :icon="iconTypeComment[c.type]" color="primary" large>
                            <v-card class="px-2">
                              <v-row class="pa-2">
                                <v-col v-if="c.created_at" class="grey--text text--darken-1" cols="12">
                                  {{ $formatTimestamp(c.created_at, true) }}
                                </v-col>
                                <v-col cols="12" sm="8" offset-sm="2" class="font-italic pb-2">
                                  "<span v-html="c.text_formatted || c.text"></span>"
                                </v-col>
                                <v-col v-if="c.order_id" cols="12" class="pb-2">
                                  <v-btn
                                    :to="{ name: 'order_detail', params: { id: c.order_id } }"
                                    small
                                    color="accent"
                                    target="_blank"
                                  >
                                    <v-icon dark small class="pr-2">fas fa-external-link-alt</v-icon>
                                    Detalle de compra
                                  </v-btn>
                                </v-col>
                                <v-col v-if="c.ticket_id" cols="12" class="pb-2">
                                  <v-btn
                                    :to="{ name: 'ticket_detail', params: { id: c.ticket_id } }"
                                    small
                                    color="accent"
                                    target="_blank"
                                  >
                                    <v-icon dark small class="pr-2">fas fa-external-link-alt</v-icon>
                                    Detalle de la oportunidad
                                  </v-btn>
                                </v-col>
                                <div v-if="getAllAgentsMap[c.uid]" class="d-flex pa-1">
                                  <div>
                                    <v-avatar size="25px" color="black">
                                      <img
                                        v-if="getAllAgentsMap[c.uid].photo"
                                        :src="getAllAgentsMap[c.uid].photo"
                                        alt="Avatar"
                                      />
                                      <span v-else class="white--text headline">
                                        {{ getAllAgentsMap[c.uid].full_name.substring(0, 1) }}
                                      </span>
                                    </v-avatar>
                                  </div>
                                  <h6 class="pt-1 pl-2 caption grey--text text--darken-2">
                                    <span>{{ getAllAgentsMap[c.uid].name }}</span>
                                    <span v-if="c.type == 1">realizó la llamada.</span>
                                    <span v-else-if="c.type == 2">envió el correo.</span>
                                    <span v-else-if="c.type == 4">
                                      agendó un evento para el
                                      {{
                                        `${c.event_date} ${c.event_time} ${
                                          c.event_time_end ? ` - ${c.event_time_end}` : ""
                                        }`
                                      }}
                                    </span>
                                    <span v-else>agregó la nota.</span>
                                  </h6>
                                </div>
                                <div v-if="c.type == 4 && c.uid === getAgent.uid" class="text-right pa-2 w100">
                                  <BtnCalendar
                                    :event-id.sync="c.id"
                                    :date.sync="c.event_date"
                                    :hour-start.sync="c.event_time"
                                    :hour-end.sync="c.event_time_end"
                                    :title.sync="c.event_name"
                                    :detail.sync="c.detail"
                                  />
                                </div>
                              </v-row>
                            </v-card>
                          </v-timeline-item>
                        </template>
                      </v-slide-x-reverse-transition>
                    </v-timeline>
                    <div v-else class="text-center py-4">Aún no hay comentarios</div>
                  </v-col>
                </v-card-text>
              </v-card>
            </template>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-container v-else>
      <ShowLoading />
    </v-container>
  </v-app>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { contactFormSchema } from "Config/config";
import ContactForm from "@/components/forms/ContactForm";
import TagModal from "@/components/modals/TagModal";
import BtnCalendar from "@/components/buttons/BtnCalendar";
import getRandomColor from "@/helpers/get_randomcolor";

export default {
  name: "ContactDetail",
  components: {
    ContactForm,
    TagModal,
    BtnCalendar,
  },
  data() {
    return {
      contact: {
        id: this.$route.params.id,
      },
      comment: { type: 0 },
      iconTypeComment: [
        "fas fa-comment",
        "fas fa-phone-square",
        "fas fa-envelope",
        "fas fa-user",
        "far fa-calendar-alt",
      ],
      tabs: [0, 1, 2, 3],
      reasons: [],
      tags: [],
      terms: false,
      editTags: false,
      editNewsletter: false,
      editInformation: false,
      editPrivacy: false,
      loadSaveInformation: false,
      loadSaveTags: false,
      loadSavePrivacy: false,
      rules: {
        required: [(v) => !!v || "Campo requerido"],
      },
      contactFields: [],
      today: this.$moment().format("YYYY-MM-DD"),
      menuDateStart: false,
      dialogTimeStart: false,
      dialogTimeEnd: false,
    };
  },
  computed: {
    ...mapGetters([
      "getAgent",
      "getAllAgentsMap",
      "getAllAgentsArray",
      "getContact",
      "getContactTimeline",
      "getAgentsToArray",
      "getTags",
      "getActiveTags",
      "getSchemaContactForm",
    ]),
    agentContact: {
      get() {
        return this.getContact ? this.getContact.agent_id : 0;
      },
      set(value) {
        let agent = this.getAgentsToArray.find((seller) => {
          return seller.uid == value;
        });
        this.setContactAgent({
          contact_id: this.getContact.id,
          agent: agent,
        });
      },
    },
  },
  watch: {
    getContact(value) {
      this.terms = value.terms && value.terms.accept;
    },
    getTags() {
      if (!this.loadSaveTags) this.tags = this.setTags(this.getContact.tags);
    },
    "comment.time_start"(value) {
      if (value && (!this.comment.time_end || this.comment.time_end < value)) {
        let date = this.$moment(`${this.comment.date_start ?? this.today} ${value}`).add(1, "h");
        this.comment.time_end = date.format("hh:mm");
      }
    },
  },
  async mounted() {
    await this.getContactById({ contact_id: this.contact.id });
    await this.fetchContactTimeline({ contact_id: this.contact.id });
    await this.getAllTags();
    await this.initSchema();
    this.getAllAgents(false);
  },
  methods: {
    ...mapActions([
      "getContactById",
      "addActivity",
      "updateContact",
      "fetchContactTimeline",
      "setContactAgent",
      "getAllAgents",
      "setContactTerms",
      "getAllTags",
      "createTag",
      "getTeamSchema",
    ]),
    async initSchema() {
      let formSchema = contactFormSchema;

      if (this.getAgent.role === this.$roles.ENTREPRENEUR) {
        await this.getTeamSchema({ id: this.getAgent.team_id, schemaIds: ["contact_form"] });

        formSchema = this.getSchemaContactForm;
      }

      this.contactFields = this.loadSchemaFields(formSchema);
    },
    loadSchemaFields(schema) {
      let fields = [];
      for (let field in schema) {
        if (schema[field].show_on_detail) {
          fields.push({ field: field, ...schema[field] });
        }
      }
      return fields;
    },
    async commentTimeline() {
      if (this.$refs.formActivity.validate()) {
        let data = {
          contact_id: this.contact.id,
          comment: this.comment,
        };
        this.addActivity(data);
        this.resetComment();
      }
    },
    resetComment() {
      this.comment = { type: 0 };
      this.$refs.formActivity.resetValidation();
    },
    cancelInformation() {
      this.editInformation = false;
    },
    setTags(tags) {
      let formattedTags = [];

      if (this.getTags && tags) {
        for (let i = 0; i < tags.length; i++) {
          let ind = this.getTags.findIndex((item) => item.id == tags[i]);
          formattedTags.push(this.getTags[ind]);
        }
      }

      return formattedTags;
    },
    changeTags(e) {
      if (e.length > 0) {
        let last = e[e.length - 1];
        if (!last.color) this.tags[e.length - 1] = { id: last, name: last, color: getRandomColor(), active: true };
      }
    },
    async saveTags() {
      try {
        this.loadSaveTags = true;

        let tagsToSave = [],
          auxTag,
          isRepeated,
          promises = [];

        for (let i = 0; i < this.tags.length; i++) {
          auxTag = Object.assign({}, this.tags[i]);
          isRepeated = this.getTags.find((item) => {
            return item.name === auxTag.name && item.active;
          });

          if (isRepeated) tagsToSave.push(auxTag.id);
          else promises.push(this.createTag({ tag: auxTag }));
        }

        let createdTag = await Promise.all(await promises);
        tagsToSave.push(...createdTag);

        const response = await this.updateContact({ contact_id: this.getContact.id, data: { tags: tagsToSave } });
        if (response) this.cancelTags(tagsToSave);
      } catch (error) {
        console.error("contact/saveTags: ", error);
      } finally {
        this.loadSaveTags = false;
      }
    },
    cancelTags(tags) {
      this.editTags = false;
      this.tags = this.setTags(tags || this.getContact.tags);
    },
    removeTag(item) {
      this.tags.splice(this.tags.indexOf(item), 1);
      this.tags = [...this.tags];
    },
    async savePrivacy() {
      try {
        this.loadSavePrivacy = true;

        const response = await this.setContactTerms({
          contact_id: this.getContact.id,
          terms: this.terms,
          agent_id: this.getContact.agent_id,
        });

        if (response) this.cancelPrivacy();
      } catch (error) {
        console.error("contact/savePrivacy: ", error);
      } finally {
        this.loadSavePrivacy = false;
      }
    },
    cancelPrivacy() {
      this.editPrivacy = false;
      this.terms = this.getContact.terms && this.getContact.terms.accept;
    },
  },
};
</script>
<style>
.v-card__title .v-toolbar__content {
  padding: 0;
}
.v-text-field.v-text-field--solo .v-input__control {
  min-height: 28px;
}
.sticky-75 {
  position: sticky;
  top: 75px;
  z-index: 1;
}
.v-input--selection-controls {
  margin-top: 0;
  padding-bottom: 0;
}
.v-messages.theme--light {
  display: none;
}
.w95 {
  width: 95%;
}
.w100 {
  width: 100%;
}
.h-auto {
  height: auto !important;
}
.v-toolbar-info .v-toolbar__content {
  padding: 0 !important;
  height: 58px !important;
}
</style>
