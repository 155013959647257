export default {
  getContact(state) {
    return state.contact;
  },
  getContacts(state) {
    return state.contacts;
  },
  getNewContacts(state) {
    return state.newContacts;
  },
  getRoles(state) {
    return state.roles;
  },
};
