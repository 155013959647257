var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    { attrs: { id: "tickets-downloads-register" } },
    [
      _vm.getAgent && _vm.getAgentsToArray
        ? _c(
            "v-container",
            { staticClass: "mb-10 pb-10" },
            [
              _c(
                "v-row",
                { staticClass: "pt-4" },
                [
                  _c(
                    "v-col",
                    {
                      attrs: {
                        cols: "12",
                        sm: "10",
                        md: "8",
                        "offset-sm": "1",
                        "offset-md": "2"
                      }
                    },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { staticClass: "py-0", attrs: { cols: "12" } },
                            [
                              _c(
                                "h2",
                                {
                                  staticClass:
                                    "text-h4 primary--text pb-4 font-weight-black pt-8 pt-md-0 pt-lg-0"
                                },
                                [
                                  _vm._v(
                                    "\n              Iniciar descarga de Oportunidades\n            "
                                  )
                                ]
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c(
                                "v-card",
                                { staticClass: "pa-4 rounded-lg" },
                                [
                                  _c(
                                    "v-form",
                                    {
                                      ref: "formDownload",
                                      attrs: { "lazy-validation": "" }
                                    },
                                    [
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "pt-0",
                                              attrs: { cols: "12", sm: "7" }
                                            },
                                            [
                                              _c(
                                                "h5",
                                                {
                                                  staticClass:
                                                    "font-weight-regular mb-2"
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                      Al iniciar y completar una nueva descarga, el archivo estará disponible por 30 días.\n                    "
                                                  )
                                                ]
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "text-sm-right pt-0",
                                              attrs: { cols: "12", sm: "5" }
                                            },
                                            [
                                              _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    to: {
                                                      name: "ticket_downloads"
                                                    },
                                                    color: "primary",
                                                    "active-class": "no-active",
                                                    outlined: ""
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                      Ver descargables disponibles\n                    "
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "pb-2",
                                              attrs: { cols: "12" }
                                            },
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  rules: [
                                                    _vm.$rules.REQUIRED,
                                                    function(v) {
                                                      return (
                                                        (v.length > 0 &&
                                                          v.length < 40) ||
                                                        "Debe ser menor a 40 carácteres alfanúmericos"
                                                      )
                                                    }
                                                  ],
                                                  label: "Nombre",
                                                  placeholder:
                                                    "Nombre de la descarga"
                                                },
                                                model: {
                                                  value: _vm.name,
                                                  callback: function($$v) {
                                                    _vm.name = $$v
                                                  },
                                                  expression: "name"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "pb-2",
                                              attrs: { cols: "12", sm: "6" }
                                            },
                                            [
                                              _c(
                                                "v-menu",
                                                {
                                                  attrs: {
                                                    "close-on-content-click": false,
                                                    "nudge-right": 40,
                                                    transition:
                                                      "scale-transition",
                                                    "offset-y": "",
                                                    "max-width": "290"
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "activator",
                                                        fn: function(ref) {
                                                          var on = ref.on
                                                          return [
                                                            _c(
                                                              "v-text-field",
                                                              _vm._g(
                                                                {
                                                                  attrs: {
                                                                    rules: [
                                                                      _vm.$rules
                                                                        .REQUIRED
                                                                    ],
                                                                    label:
                                                                      "Fecha inicial",
                                                                    readonly: ""
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm.dateStart,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.dateStart = $$v
                                                                    },
                                                                    expression:
                                                                      "dateStart"
                                                                  }
                                                                },
                                                                on
                                                              )
                                                            )
                                                          ]
                                                        }
                                                      }
                                                    ],
                                                    null,
                                                    false,
                                                    3647583566
                                                  ),
                                                  model: {
                                                    value: _vm.menuDateStart,
                                                    callback: function($$v) {
                                                      _vm.menuDateStart = $$v
                                                    },
                                                    expression: "menuDateStart"
                                                  }
                                                },
                                                [
                                                  _vm._v(" "),
                                                  _c("v-date-picker", {
                                                    on: {
                                                      input: function($event) {
                                                        _vm.menuDateStart = false
                                                      }
                                                    },
                                                    model: {
                                                      value: _vm.dateStart,
                                                      callback: function($$v) {
                                                        _vm.dateStart = $$v
                                                      },
                                                      expression: "dateStart"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "pb-2",
                                              attrs: { cols: "12", sm: "6" }
                                            },
                                            [
                                              _c(
                                                "v-menu",
                                                {
                                                  attrs: {
                                                    "close-on-content-click": false,
                                                    "nudge-right": 40,
                                                    transition:
                                                      "scale-transition",
                                                    "offset-y": "",
                                                    "max-width": "290"
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "activator",
                                                        fn: function(ref) {
                                                          var on = ref.on
                                                          return [
                                                            _c(
                                                              "v-text-field",
                                                              _vm._g(
                                                                {
                                                                  attrs: {
                                                                    rules: [
                                                                      _vm.$rules
                                                                        .REQUIRED,
                                                                      function(
                                                                        v
                                                                      ) {
                                                                        return (
                                                                          v >=
                                                                            _vm.dateStart ||
                                                                          "La fecha debe ser mayor a la inicial"
                                                                        )
                                                                      }
                                                                    ],
                                                                    label:
                                                                      "Fecha final",
                                                                    readonly: ""
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm.dateEnd,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.dateEnd = $$v
                                                                    },
                                                                    expression:
                                                                      "dateEnd"
                                                                  }
                                                                },
                                                                on
                                                              )
                                                            )
                                                          ]
                                                        }
                                                      }
                                                    ],
                                                    null,
                                                    false,
                                                    139688118
                                                  ),
                                                  model: {
                                                    value: _vm.menuDateEnd,
                                                    callback: function($$v) {
                                                      _vm.menuDateEnd = $$v
                                                    },
                                                    expression: "menuDateEnd"
                                                  }
                                                },
                                                [
                                                  _vm._v(" "),
                                                  _c("v-date-picker", {
                                                    on: {
                                                      input: function($event) {
                                                        _vm.menuDateEnd = false
                                                      }
                                                    },
                                                    model: {
                                                      value: _vm.dateEnd,
                                                      callback: function($$v) {
                                                        _vm.dateEnd = $$v
                                                      },
                                                      expression: "dateEnd"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _vm.getAgent.role !==
                                          _vm.$roles.ADVISOR
                                            ? _c(
                                                "v-col",
                                                {
                                                  staticClass: "pb-3",
                                                  attrs: { cols: "12", sm: "6" }
                                                },
                                                [
                                                  _c("v-autocomplete", {
                                                    attrs: {
                                                      items: _vm.getAgents,
                                                      label: "Encargado",
                                                      "item-value": "id",
                                                      "item-text": "full_name",
                                                      placeholder:
                                                        "Seleccione encargado"
                                                    },
                                                    model: {
                                                      value: _vm.agent,
                                                      callback: function($$v) {
                                                        _vm.agent = $$v
                                                      },
                                                      expression: "agent"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12" } },
                                            [
                                              _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    loading: _vm.loading,
                                                    rounded: "",
                                                    color: "primary"
                                                  },
                                                  on: { click: _vm.save }
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                      Iniciar descarga\n                    "
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _c("v-container", [_c("ShowLoading")], 1)
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }