var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { scrollable: "", persistent: "", "max-width": "800" },
      model: {
        value: _vm.dialog,
        callback: function($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _c(
        "v-card",
        { staticClass: "py-3" },
        [
          _c(
            "v-card-title",
            { staticClass: "headline" },
            [
              _c(
                "div",
                [
                  _c("v-img", {
                    staticClass: "mr-2",
                    attrs: {
                      src: require("@/assets/social_icons/" + _vm.id + ".png"),
                      width: "20px"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c("span", { staticClass: "ml-0" }, [_vm._v("SendGrid")]),
              _vm._v(" "),
              _c("v-spacer"),
              _vm._v(" "),
              _c(
                "v-btn",
                { attrs: { icon: "" }, on: { click: _vm.closeDialog } },
                [
                  _c("v-icon", { attrs: { small: "" } }, [
                    _vm._v("fas fa-times")
                  ])
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card-text",
            [
              !_vm.getIntegrationSendGrid
                ? _c(
                    "v-row",
                    [
                      _c("v-col", { attrs: { cols: "12" } }, [
                        _c("h4", { staticClass: "font-weight-thin" }, [
                          _vm._v(
                            "\n            La integración con SendGrid le permitirá realizar algunas acciones como el enviar correo a los agentes que\n            son asignados a una oportunidad, también puede hacer que un mensaje de correo se convierta en una\n            oportunidad dentro de la plataforma.\n          "
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { staticClass: "pt-2", attrs: { cols: "12" } },
                        [
                          _c(
                            "v-alert",
                            {
                              staticClass: "caption",
                              attrs: { dense: "", text: "", type: "info" }
                            },
                            [
                              _vm._v(
                                "\n            Antes de empezar, le dejamos una guía para configuraciones previas a la\n            "
                              ),
                              _c(
                                "a",
                                {
                                  attrs: {
                                    href:
                                      "https://docs.google.com/document/d/1C1yTnbAgr8Mf9jD4B-TLGcLjViT1zt_9TxfnxfKBktg/edit?usp=sharing",
                                    target: "_blank"
                                  }
                                },
                                [_vm._v("integración de SendGrid en Apolo CRM")]
                              ),
                              _vm._v(
                                ". Recuerde que el nombre de la clave API ha crear será: "
                              ),
                              _c("strong", [_vm._v("Apolo CRM")]),
                              _vm._v(".\n          ")
                            ]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { staticClass: "d-flex", attrs: { cols: "12" } },
                        [
                          _c(
                            "span",
                            { staticClass: "circle primary white--text mr-2" },
                            [_vm._v("1")]
                          ),
                          _vm._v(" "),
                          _c(
                            "v-row",
                            { staticClass: "pr-5" },
                            [
                              _c(
                                "v-col",
                                { staticClass: "pt-0", attrs: { cols: "12" } },
                                [
                                  _c(
                                    "h4",
                                    { staticClass: "font-weight-thin" },
                                    [
                                      _vm._v(
                                        "\n                Deberá ingresar el "
                                      ),
                                      _c(
                                        "span",
                                        { staticClass: "font-weight-bold" },
                                        [_vm._v("token")]
                                      ),
                                      _vm._v(
                                        " de la clave de API\n                "
                                      ),
                                      _c(
                                        "span",
                                        { staticClass: "font-weight-bold" },
                                        [_vm._v("Apolo CRM")]
                                      ),
                                      _vm._v(
                                        " creada en SendGrid.\n              "
                                      )
                                    ]
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                { staticClass: "py-0", attrs: { cols: "12" } },
                                [
                                  _c(
                                    "v-form",
                                    {
                                      ref: "form",
                                      attrs: { "lazy-validation": "" }
                                    },
                                    [
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12" } },
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  label: "Token",
                                                  placeholder: " ",
                                                  type: "password",
                                                  rules: _vm.rules.required
                                                },
                                                model: {
                                                  value: _vm.access_token,
                                                  callback: function($$v) {
                                                    _vm.access_token = $$v
                                                  },
                                                  expression: "access_token"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _vm.config_apikey_error
                                ? _c(
                                    "v-col",
                                    { attrs: { cols: "12" } },
                                    [
                                      _c(
                                        "v-alert",
                                        { attrs: { text: "", type: "error" } },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(_vm.config_apikey_error) +
                                              "\n              "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                {
                                  staticClass: "text-right",
                                  attrs: { cols: "12" }
                                },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { rounded: "", text: "" },
                                      on: { click: _vm.closeDialog }
                                    },
                                    [_vm._v("Cancelar")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        color: "primary",
                                        rounded: "",
                                        loading: _vm.load
                                      },
                                      on: { click: _vm.save }
                                    },
                                    [
                                      _vm._v(
                                        "\n                Verificar y guardar\n              "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { staticClass: "d-flex", attrs: { cols: "12" } },
                        [
                          _c(
                            "span",
                            { staticClass: "circle primary white--text mr-2" },
                            [_vm._v("2")]
                          ),
                          _vm._v(" "),
                          _c(
                            "v-row",
                            { staticClass: "pr-5" },
                            [
                              _c(
                                "v-col",
                                { staticClass: "pt-0", attrs: { cols: "12" } },
                                [
                                  _c(
                                    "h4",
                                    { staticClass: "font-weight-thin" },
                                    [
                                      _vm._v(
                                        "\n                Puedes configurar y activar las siguientes acciones para tu Apolo CRM.\n              "
                                      )
                                    ]
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                { attrs: { cols: "12" } },
                                [
                                  _c(
                                    "v-expansion-panels",
                                    { attrs: { dense: "" } },
                                    [
                                      _c(
                                        "v-expansion-panel",
                                        [
                                          _c("v-expansion-panel-header", [
                                            _c(
                                              "span",
                                              { staticClass: "w100" },
                                              [
                                                _vm._v(
                                                  "Enviar correo a los asesores cuando son asignados a alguna oportunidad"
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "text-center px-5 caption"
                                              },
                                              [
                                                _vm.getIntegrationSendGrid
                                                  .op_send_mail
                                                  ? _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "success--text"
                                                      },
                                                      [_vm._v("Activada")]
                                                    )
                                                  : _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "accent--text"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "Configuración necesaria"
                                                        )
                                                      ]
                                                    )
                                              ]
                                            )
                                          ]),
                                          _vm._v(" "),
                                          _c(
                                            "v-expansion-panel-content",
                                            [
                                              _vm.getIntegrationSendGrid
                                                .op_send_mail
                                                ? _c(
                                                    "v-row",
                                                    [
                                                      _vm.send_mail_error
                                                        ? _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "12"
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "v-alert",
                                                                {
                                                                  attrs: {
                                                                    text: "",
                                                                    type:
                                                                      "error"
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                          " +
                                                                      _vm._s(
                                                                        _vm.send_mail_error
                                                                      ) +
                                                                      "\n                        "
                                                                  )
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      _c(
                                                        "v-col",
                                                        {
                                                          attrs: { cols: "12" }
                                                        },
                                                        [
                                                          _c(
                                                            "v-btn",
                                                            {
                                                              attrs: {
                                                                float: "",
                                                                outlined: "",
                                                                rounded: "",
                                                                small: "",
                                                                color: "red",
                                                                loading:
                                                                  _vm.load
                                                              },
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.removeConfig(
                                                                    "send_mail"
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "Eliminar configuración"
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                : _c(
                                                    "v-row",
                                                    [
                                                      _c(
                                                        "v-col",
                                                        {
                                                          attrs: { cols: "12" }
                                                        },
                                                        [
                                                          _c(
                                                            "h3",
                                                            {
                                                              staticClass:
                                                                "mb-3"
                                                            },
                                                            [
                                                              _vm._v(
                                                                "Configuración necesaria"
                                                              )
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "h4",
                                                            {
                                                              staticClass:
                                                                "font-weight-thin"
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                          Está integración hará que se envíe correos tanto al asignado de la oportunidad, como a los\n                          supervisores del equipo al que pertenezca si así desea.\n                        "
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "v-col",
                                                        {
                                                          staticClass: "pt-2",
                                                          attrs: { cols: "12" }
                                                        },
                                                        [
                                                          _c(
                                                            "v-alert",
                                                            {
                                                              staticClass:
                                                                "caption",
                                                              attrs: {
                                                                dense: "",
                                                                text: "",
                                                                type: "info"
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                          Antes de empezar, deberá otorgar el acceso de "
                                                              ),
                                                              _c("strong", [
                                                                _vm._v(
                                                                  "enviar correo"
                                                                )
                                                              ]),
                                                              _vm._v(
                                                                " a la clave API\n                          Apolo CRM creada y también "
                                                              ),
                                                              _c("strong", [
                                                                _vm._v(
                                                                  "autorizar el dominio " +
                                                                    _vm._s(
                                                                      _vm.domain_authorized
                                                                    )
                                                                )
                                                              ]),
                                                              _vm._v(
                                                                ". Aquí encontrará una\n                          "
                                                              ),
                                                              _c(
                                                                "a",
                                                                {
                                                                  attrs: {
                                                                    href:
                                                                      "https://docs.google.com/document/d/1eWyN1mcYL4EbUUuouRw6h7WFl6y326VJbAwYuDY6maE/edit?usp=sharing",
                                                                    target:
                                                                      "_blank"
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "guía para configuraciones para el envío de corrreos con SendGrid"
                                                                  )
                                                                ]
                                                              ),
                                                              _vm._v(
                                                                ".\n                        "
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "v-col",
                                                        {
                                                          staticClass: "d-flex",
                                                          attrs: { cols: "12" }
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "circle primary white--text mr-2"
                                                            },
                                                            [_vm._v("1")]
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "v-row",
                                                            {
                                                              staticClass:
                                                                "pr-5"
                                                            },
                                                            [
                                                              _c(
                                                                "v-col",
                                                                {
                                                                  staticClass:
                                                                    "pt-0",
                                                                  attrs: {
                                                                    cols: "12"
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "h4",
                                                                    {
                                                                      staticClass:
                                                                        "font-weight-thin"
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                              Deberá verficar si el "
                                                                      ),
                                                                      _c(
                                                                        "span",
                                                                        {
                                                                          staticClass:
                                                                            "font-weight-bold"
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "correo electrónico"
                                                                          )
                                                                        ]
                                                                      ),
                                                                      _vm._v(
                                                                        " ya se\n                              encuentra autorizado en la cuenta de SendGrid, para que pueda enviar los correos.\n                            "
                                                                      )
                                                                    ]
                                                                  )
                                                                ]
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "v-col",
                                                                {
                                                                  staticClass:
                                                                    "py-0",
                                                                  attrs: {
                                                                    cols: "12"
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-form",
                                                                    {
                                                                      ref:
                                                                        "formVerifySendMail",
                                                                      attrs: {
                                                                        "lazy-validation":
                                                                          ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-row",
                                                                        [
                                                                          _c(
                                                                            "v-col",
                                                                            {
                                                                              attrs: {
                                                                                cols:
                                                                                  "12"
                                                                              }
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "v-text-field",
                                                                                {
                                                                                  attrs: {
                                                                                    label:
                                                                                      "Correo electrónico",
                                                                                    placeholder:
                                                                                      " ",
                                                                                    rules:
                                                                                      _vm
                                                                                        .rules
                                                                                        .email,
                                                                                    readonly:
                                                                                      ""
                                                                                  },
                                                                                  model: {
                                                                                    value:
                                                                                      _vm.email,
                                                                                    callback: function(
                                                                                      $$v
                                                                                    ) {
                                                                                      _vm.email = $$v
                                                                                    },
                                                                                    expression:
                                                                                      "email"
                                                                                  }
                                                                                }
                                                                              )
                                                                            ],
                                                                            1
                                                                          ),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "v-col",
                                                                            {
                                                                              staticClass:
                                                                                "text-right",
                                                                              attrs: {
                                                                                cols:
                                                                                  "12"
                                                                              }
                                                                            },
                                                                            [
                                                                              _vm.account_verification_error
                                                                                ? _c(
                                                                                    "span",
                                                                                    {
                                                                                      staticClass:
                                                                                        "error--text mr-4"
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        _vm._s(
                                                                                          _vm.account_verification_error
                                                                                        )
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                : _vm._e(),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _vm.account_verified
                                                                                ? _c(
                                                                                    "span",
                                                                                    {
                                                                                      staticClass:
                                                                                        "green--text mr-4 font-weight-bold"
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "i",
                                                                                        {
                                                                                          staticClass:
                                                                                            "fas fa-check"
                                                                                        }
                                                                                      ),
                                                                                      _vm._v(
                                                                                        " Verificado"
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                : _vm._e(),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "v-btn",
                                                                                {
                                                                                  attrs: {
                                                                                    rounded:
                                                                                      "",
                                                                                    color:
                                                                                      "accent",
                                                                                    outlined:
                                                                                      "",
                                                                                    disabled:
                                                                                      _vm.account_verified,
                                                                                    loading:
                                                                                      _vm.load_verify
                                                                                  },
                                                                                  on: {
                                                                                    click:
                                                                                      _vm.verifySendMail
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "Verificar"
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ],
                                                                            1
                                                                          )
                                                                        ],
                                                                        1
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "v-col",
                                                        {
                                                          staticClass: "d-flex",
                                                          attrs: { cols: "12" }
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "circle primary white--text mr-2"
                                                            },
                                                            [_vm._v("2")]
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "v-row",
                                                            {
                                                              staticClass:
                                                                "pr-5"
                                                            },
                                                            [
                                                              _c(
                                                                "v-col",
                                                                {
                                                                  staticClass:
                                                                    "pt-0",
                                                                  attrs: {
                                                                    cols: "12"
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "h4",
                                                                    {
                                                                      staticClass:
                                                                        "font-weight-thin"
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                              Seleccione las opciones que desea para tener en cuenta en el envío automático de\n                              correos. Recuerde que el envío al\n                              "
                                                                      ),
                                                                      _c(
                                                                        "span",
                                                                        {
                                                                          staticClass:
                                                                            "font-weight-bold"
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "agente asignado"
                                                                          )
                                                                        ]
                                                                      ),
                                                                      _vm._v(
                                                                        " es obligatorio.\n                            "
                                                                      )
                                                                    ]
                                                                  )
                                                                ]
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "v-col",
                                                                {
                                                                  staticClass:
                                                                    "pt-0",
                                                                  attrs: {
                                                                    cols: "12"
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "d-flex"
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-checkbox",
                                                                        {
                                                                          attrs: {
                                                                            value: true,
                                                                            readonly:
                                                                              "",
                                                                            color:
                                                                              "accent",
                                                                            "hide-details":
                                                                              ""
                                                                          },
                                                                          model: {
                                                                            value:
                                                                              _vm.send_agent_assigned,
                                                                            callback: function(
                                                                              $$v
                                                                            ) {
                                                                              _vm.send_agent_assigned = $$v
                                                                            },
                                                                            expression:
                                                                              "send_agent_assigned"
                                                                          }
                                                                        }
                                                                      ),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c(
                                                                        "span",
                                                                        {
                                                                          staticClass:
                                                                            "mt-2"
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "Envío al agente asignado"
                                                                          )
                                                                        ]
                                                                      )
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "d-flex"
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-checkbox",
                                                                        {
                                                                          attrs: {
                                                                            value: true,
                                                                            color:
                                                                              "accent",
                                                                            "hide-details":
                                                                              ""
                                                                          },
                                                                          model: {
                                                                            value:
                                                                              _vm.send_supervisors,
                                                                            callback: function(
                                                                              $$v
                                                                            ) {
                                                                              _vm.send_supervisors = $$v
                                                                            },
                                                                            expression:
                                                                              "send_supervisors"
                                                                          }
                                                                        }
                                                                      ),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c(
                                                                        "span",
                                                                        {
                                                                          staticClass:
                                                                            "mt-2"
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "Envío a supervisores del equipo del agente asignado"
                                                                          )
                                                                        ]
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      ),
                                                      _vm._v(" "),
                                                      _vm.send_mail_error
                                                        ? _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "12"
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "v-alert",
                                                                {
                                                                  attrs: {
                                                                    text: "",
                                                                    type:
                                                                      "error"
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                          " +
                                                                      _vm._s(
                                                                        _vm.send_mail_error
                                                                      ) +
                                                                      "\n                        "
                                                                  )
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      _c(
                                                        "v-col",
                                                        {
                                                          staticClass:
                                                            "text-right",
                                                          attrs: { cols: "12" }
                                                        },
                                                        [
                                                          _c(
                                                            "v-btn",
                                                            {
                                                              attrs: {
                                                                color:
                                                                  "primary",
                                                                rounded: "",
                                                                disabled: !_vm.account_verified,
                                                                loading:
                                                                  _vm.load
                                                              },
                                                              on: {
                                                                click:
                                                                  _vm.saveConfigSendMail
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                          Guardar\n                        "
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-expansion-panel",
                                        [
                                          _c("v-expansion-panel-header", [
                                            _c(
                                              "span",
                                              { staticClass: "w100" },
                                              [
                                                _vm._v(
                                                  "Recibir correos y convertirlos a oportunidades"
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "text-center px-5 caption"
                                              },
                                              [
                                                _vm.getIntegrationSendGrid
                                                  .op_receive_mail
                                                  ? _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "success--text"
                                                      },
                                                      [_vm._v("Activada")]
                                                    )
                                                  : _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "caption accent--text"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "Configuración necesaria"
                                                        )
                                                      ]
                                                    )
                                              ]
                                            )
                                          ]),
                                          _vm._v(" "),
                                          _c(
                                            "v-expansion-panel-content",
                                            [
                                              _vm.getIntegrationSendGrid
                                                .op_receive_mail
                                                ? _c(
                                                    "v-row",
                                                    { staticClass: "px-3" },
                                                    [
                                                      _vm.receive_mail_error
                                                        ? _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "12"
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "v-alert",
                                                                {
                                                                  attrs: {
                                                                    text: "",
                                                                    type:
                                                                      "error"
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                          " +
                                                                      _vm._s(
                                                                        _vm.receive_mail_error
                                                                      ) +
                                                                      "\n                        "
                                                                  )
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      _c(
                                                        "v-col",
                                                        {
                                                          attrs: { cols: "12" }
                                                        },
                                                        [
                                                          _c(
                                                            "v-btn",
                                                            {
                                                              attrs: {
                                                                float: "",
                                                                outlined: "",
                                                                rounded: "",
                                                                small: "",
                                                                color: "red",
                                                                loading:
                                                                  _vm.load
                                                              },
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.removeConfig(
                                                                    "receive_mail"
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "Eliminar configuración"
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                : _c(
                                                    "v-row",
                                                    [
                                                      _c(
                                                        "v-col",
                                                        {
                                                          attrs: { cols: "12" }
                                                        },
                                                        [
                                                          _c(
                                                            "h3",
                                                            {
                                                              staticClass:
                                                                "mb-3"
                                                            },
                                                            [
                                                              _vm._v(
                                                                "Configuración necesaria"
                                                              )
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "h4",
                                                            {
                                                              staticClass:
                                                                "font-weight-thin"
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                          Está integración hará que cada vez que le envíen un correo a su bandeja de entrada de un\n                          correo con dominio autorizado, esto convertirá en una nueva oportunidad en Apolo CRM.\n                        "
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "v-col",
                                                        {
                                                          staticClass: "pt-2",
                                                          attrs: { cols: "12" }
                                                        },
                                                        [
                                                          _c(
                                                            "v-alert",
                                                            {
                                                              staticClass:
                                                                "caption",
                                                              attrs: {
                                                                dense: "",
                                                                text: "",
                                                                type: "info"
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                          Antes de empezar, deberá otorgar el acceso de "
                                                              ),
                                                              _c("strong", [
                                                                _vm._v(
                                                                  "análisis entrante"
                                                                )
                                                              ]),
                                                              _vm._v(
                                                                " y\n                          "
                                                              ),
                                                              _c("strong", [
                                                                _vm._v(
                                                                  "autenticación del remitente"
                                                                )
                                                              ]),
                                                              _vm._v(
                                                                " la clave API Apolo CRM creada, así como\n                          configurar un "
                                                              ),
                                                              _c("strong", [
                                                                _vm._v(
                                                                  "dominio autorizado"
                                                                )
                                                              ]),
                                                              _vm._v(
                                                                ". Aquí encontrará una\n                          "
                                                              ),
                                                              _c(
                                                                "a",
                                                                {
                                                                  attrs: {
                                                                    href:
                                                                      "https://docs.google.com/document/d/1JpNmp6XnZENX1YUI64B8hOkXESCAzp6Hj2c7T8zW0Wk/edit?usp=sharing",
                                                                    target:
                                                                      "_blank"
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "guía para recepción de correos entrantes con SendGrid"
                                                                  )
                                                                ]
                                                              ),
                                                              _vm._v(
                                                                ".\n                        "
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "v-col",
                                                        {
                                                          staticClass: "d-flex",
                                                          attrs: { cols: "12" }
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "circle primary white--text mr-2"
                                                            },
                                                            [_vm._v("1")]
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "v-row",
                                                            {
                                                              staticClass:
                                                                "pr-5"
                                                            },
                                                            [
                                                              _c(
                                                                "v-col",
                                                                {
                                                                  staticClass:
                                                                    "pt-0",
                                                                  attrs: {
                                                                    cols: "12"
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "h4",
                                                                    {
                                                                      staticClass:
                                                                        "font-weight-thin"
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                              Deberá ingresar el "
                                                                      ),
                                                                      _c(
                                                                        "span",
                                                                        {
                                                                          staticClass:
                                                                            "font-weight-bold"
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "dominio autorizado"
                                                                          )
                                                                        ]
                                                                      ),
                                                                      _vm._v(
                                                                        " que\n                              configuró en SendGrid.\n                            "
                                                                      )
                                                                    ]
                                                                  )
                                                                ]
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "v-col",
                                                                {
                                                                  staticClass:
                                                                    "py-0",
                                                                  attrs: {
                                                                    cols: "12"
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-form",
                                                                    {
                                                                      ref:
                                                                        "formVerifyDomainAuthorized",
                                                                      attrs: {
                                                                        "lazy-validation":
                                                                          ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-row",
                                                                        [
                                                                          _c(
                                                                            "v-col",
                                                                            {
                                                                              attrs: {
                                                                                cols:
                                                                                  "12"
                                                                              }
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "v-text-field",
                                                                                {
                                                                                  attrs: {
                                                                                    label:
                                                                                      "Dominio autorizado",
                                                                                    placeholder:
                                                                                      " ",
                                                                                    rules:
                                                                                      _vm
                                                                                        .rules
                                                                                        .required
                                                                                  },
                                                                                  model: {
                                                                                    value:
                                                                                      _vm.domain_authorized,
                                                                                    callback: function(
                                                                                      $$v
                                                                                    ) {
                                                                                      _vm.domain_authorized = $$v
                                                                                    },
                                                                                    expression:
                                                                                      "domain_authorized"
                                                                                  }
                                                                                }
                                                                              )
                                                                            ],
                                                                            1
                                                                          )
                                                                        ],
                                                                        1
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      ),
                                                      _vm._v(" "),
                                                      _vm.receive_mail_error
                                                        ? _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "12"
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "v-alert",
                                                                {
                                                                  attrs: {
                                                                    text: "",
                                                                    type:
                                                                      "error"
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                          " +
                                                                      _vm._s(
                                                                        _vm.receive_mail_error
                                                                      ) +
                                                                      "\n                        "
                                                                  )
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      _c(
                                                        "v-col",
                                                        {
                                                          staticClass:
                                                            "text-right",
                                                          attrs: { cols: "12" }
                                                        },
                                                        [
                                                          _c(
                                                            "v-btn",
                                                            {
                                                              attrs: {
                                                                color:
                                                                  "primary",
                                                                rounded: "",
                                                                loading:
                                                                  _vm.load
                                                              },
                                                              on: {
                                                                click:
                                                                  _vm.saveConfigReceiveMail
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                          Guardar\n                        "
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }