var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    { staticClass: "py-8 pt-md-0 pt-lg-0", attrs: { id: "order_detail" } },
    [
      _vm.getOrder && _vm.getAgents
        ? _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12", md: "5" } },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { staticClass: "mt-0", attrs: { cols: "12" } },
                        [
                          _c(
                            "v-card",
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass:
                                    "grey--text text--darken-3 font-weight-bold pa-4 pb-0",
                                  attrs: { cols: "12" }
                                },
                                [
                                  _vm._v(
                                    "\n              INFORMACIÓN\n            "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                { attrs: { cols: "12" } },
                                [
                                  _c(
                                    "v-row",
                                    { staticClass: "px-4" },
                                    _vm._l(_vm.schemaSale, function(schema, i) {
                                      return _c(
                                        "v-col",
                                        { key: i, attrs: { cols: "12" } },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              label: schema.header,
                                              value:
                                                schema.type === "date"
                                                  ? _vm.$formatTimestamp(
                                                      _vm.getOrder[schema.key],
                                                      "date_z"
                                                    )
                                                  : _vm.getOrder[schema.key],
                                              readonly: "",
                                              "hide-details": ""
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    }),
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { staticClass: "py-2", attrs: { cols: "12" } },
                        [_c("CardState")],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { staticClass: "py-2", attrs: { cols: "12" } },
                        [_c("CardPayment")],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { staticClass: "py-2", attrs: { cols: "12" } },
                        [
                          _c(
                            "v-card",
                            { staticClass: "pa-4 pb-0" },
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass:
                                    "grey--text text--darken-3 font-weight-bold pa-0",
                                  attrs: { cols: "12" }
                                },
                                [_vm._v("\n              FUENTE\n            ")]
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                { attrs: { cols: "12" } },
                                [
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "size",
                                          attrs: { cols: "12" }
                                        },
                                        [
                                          _c("div", [
                                            _vm._v(
                                              "\n                    Obtenido desde "
                                            ),
                                            _c("strong", [
                                              _vm._v(
                                                _vm._s(_vm.getOrder.origin)
                                              )
                                            ])
                                          ])
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _vm.getOrder.ticket_created_uid
                                        ? _c(
                                            "v-col",
                                            {
                                              staticClass: "d-flex",
                                              attrs: { cols: "12" }
                                            },
                                            [
                                              _c(
                                                "div",
                                                [
                                                  _c(
                                                    "v-avatar",
                                                    {
                                                      attrs: {
                                                        size: "50px",
                                                        color: "accent"
                                                      }
                                                    },
                                                    [
                                                      _vm.getAllAgentsMap[
                                                        _vm.getOrder
                                                          .ticket_created_uid
                                                      ].photo
                                                        ? _c("img", {
                                                            attrs: {
                                                              src:
                                                                _vm
                                                                  .getAllAgentsMap[
                                                                  _vm.getOrder
                                                                    .ticket_created_uid
                                                                ].photo,
                                                              alt: "Avatar"
                                                            }
                                                          })
                                                        : _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "white--text headline"
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                        " +
                                                                  _vm._s(
                                                                    _vm.getAllAgentsMap[
                                                                      _vm
                                                                        .getOrder
                                                                        .ticket_created_uid
                                                                    ].full_name.substring(
                                                                      0,
                                                                      1
                                                                    )
                                                                  ) +
                                                                  "\n                      "
                                                              )
                                                            ]
                                                          )
                                                    ]
                                                  )
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c("v-text-field", {
                                                staticClass: "pl-2 w95",
                                                attrs: {
                                                  value:
                                                    _vm.getAllAgentsMap[
                                                      _vm.getOrder
                                                        .ticket_created_uid
                                                    ].full_name,
                                                  label:
                                                    "Encargado que registró la oportunidad",
                                                  readonly: ""
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "d-flex",
                                          attrs: { cols: "12" }
                                        },
                                        [
                                          _c(
                                            "div",
                                            [
                                              _c(
                                                "v-avatar",
                                                {
                                                  attrs: {
                                                    size: "50px",
                                                    color: "accent"
                                                  }
                                                },
                                                [
                                                  _vm.getAllAgentsMap[
                                                    _vm.getOrder.created_uid
                                                  ].photo
                                                    ? _c("img", {
                                                        attrs: {
                                                          src:
                                                            _vm.getAllAgentsMap[
                                                              _vm.getOrder
                                                                .created_uid
                                                            ].photo,
                                                          alt: "Avatar"
                                                        }
                                                      })
                                                    : _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "white--text headline"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                        " +
                                                              _vm._s(
                                                                _vm.getAllAgentsMap[
                                                                  _vm.getOrder
                                                                    .created_uid
                                                                ].full_name.substring(
                                                                  0,
                                                                  1
                                                                )
                                                              ) +
                                                              "\n                      "
                                                          )
                                                        ]
                                                      )
                                                ]
                                              )
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c("v-text-field", {
                                            staticClass: "pl-2 w95",
                                            attrs: {
                                              label:
                                                "Encargado que generó la orden",
                                              value:
                                                _vm.getAllAgentsMap[
                                                  _vm.getOrder.created_uid
                                                ].full_name,
                                              readonly: ""
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _vm.getOrder.ticket_id
                                        ? _c(
                                            "v-col",
                                            {
                                              staticClass: "d-flex",
                                              attrs: { cols: "12" }
                                            },
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  label: "Oportunidad",
                                                  value:
                                                    _vm.getOrder.ticket_name,
                                                  readonly: ""
                                                }
                                              }),
                                              _vm._v(" "),
                                              _c(
                                                "v-tooltip",
                                                {
                                                  attrs: { bottom: "" },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "activator",
                                                        fn: function(ref) {
                                                          var on = ref.on
                                                          return [
                                                            _c(
                                                              "v-btn",
                                                              _vm._g(
                                                                {
                                                                  staticClass:
                                                                    "my-auto ml-3",
                                                                  attrs: {
                                                                    icon: "",
                                                                    to: {
                                                                      name:
                                                                        "ticket_detail",
                                                                      params: {
                                                                        id:
                                                                          _vm
                                                                            .getOrder
                                                                            .ticket_id
                                                                      }
                                                                    },
                                                                    target:
                                                                      "_blank"
                                                                  }
                                                                },
                                                                on
                                                              ),
                                                              [
                                                                _c(
                                                                  "v-icon",
                                                                  {
                                                                    attrs: {
                                                                      size: "16"
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "fas fa-external-link-alt"
                                                                    )
                                                                  ]
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ]
                                                        }
                                                      }
                                                    ],
                                                    null,
                                                    false,
                                                    1376113218
                                                  )
                                                },
                                                [
                                                  _vm._v(" "),
                                                  _c("span", [
                                                    _vm._v("Ver oportunidad")
                                                  ])
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.getOrder.contact_id
                                        ? _c(
                                            "v-col",
                                            {
                                              staticClass: "d-flex",
                                              attrs: { cols: "12" }
                                            },
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  label: "Contacto",
                                                  value: _vm.getOrder.full_name,
                                                  readonly: ""
                                                }
                                              }),
                                              _vm._v(" "),
                                              _c(
                                                "v-tooltip",
                                                {
                                                  attrs: { bottom: "" },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "activator",
                                                        fn: function(ref) {
                                                          var on = ref.on
                                                          return [
                                                            _c(
                                                              "v-btn",
                                                              _vm._g(
                                                                {
                                                                  staticClass:
                                                                    "my-auto ml-3",
                                                                  attrs: {
                                                                    icon: "",
                                                                    to: {
                                                                      name:
                                                                        "contact_detail",
                                                                      params: {
                                                                        id:
                                                                          _vm
                                                                            .getOrder
                                                                            .contact_id
                                                                      }
                                                                    },
                                                                    target:
                                                                      "_blank"
                                                                  }
                                                                },
                                                                on
                                                              ),
                                                              [
                                                                _c(
                                                                  "v-icon",
                                                                  {
                                                                    attrs: {
                                                                      size: "16"
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "fas fa-external-link-alt"
                                                                    )
                                                                  ]
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ]
                                                        }
                                                      }
                                                    ],
                                                    null,
                                                    false,
                                                    2829220002
                                                  )
                                                },
                                                [
                                                  _vm._v(" "),
                                                  _c("span", [
                                                    _vm._v("Ver contacto")
                                                  ])
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                { attrs: { cols: "12", md: "7" } },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { staticClass: "mt-0", attrs: { cols: "12" } },
                        [_c("CardProducts")],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { staticClass: "mt-0", attrs: { cols: "12" } },
                        [_c("CardTimeline")],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _c("v-container", [_c("ShowLoading")], 1)
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }