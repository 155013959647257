<template>
  <div>
    <v-list-item key="notifications" @click.stop="toggle = !toggle">
      <v-list-item-avatar>
        <v-icon :class="allowBrowserNotifications ? 'far fa-bell' : 'far fa-bell-slash'"></v-icon>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title>Notificaciones</v-list-item-title>
        <v-list-item-subtitle>
          <span v-if="message" class="red--text">{{ message }}</span>
          <span v-else>
            Permitir notificaciones en este dispositivo para los siguientes casos
          </span>
        </v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action>
        <v-icon>
          {{ !toggle ? "fas fa-angle-down" : "fas fa-angle-up" }}
        </v-icon>
      </v-list-item-action>
    </v-list-item>
    <v-container v-if="toggle">
      <v-col cols="12" class="d-flex pb-0">
        <v-switch
          v-model="allowNotification[notificationTypes.ASSIGN_TICKETS]"
          :loading="load[notificationTypes.ASSIGN_TICKETS]"
          :disabled="!allowBrowserNotifications"
          @click="handleNotification(notificationTypes.ASSIGN_TICKETS)"
        ></v-switch>
        <span class="pa-1">Cuando sea asignado a una oportunidad</span>
      </v-col>
      <v-col cols="12" class="d-flex py-0">
        <v-switch
          v-model="allowNotification[notificationTypes.START_EVENTS]"
          :loading="load[notificationTypes.START_EVENTS]"
          :disabled="!allowBrowserNotifications"
          @click="handleNotification(notificationTypes.START_EVENTS)"
        ></v-switch>
        <span class="pa-1">Cuando inicia un evento agendado</span>
      </v-col>
    </v-container>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { messaging } from "@/config/firebase";
import { notificationTypes } from "@/helpers/get_constants";

export default {
  name: "Notifications",
  data() {
    return {
      toggle: false,
      message: "",
      token: null,
      notificationTypes: notificationTypes,
      allowBrowserNotifications: false,
      allowNotification: {
        assign_tickets: false,
        start_events: false,
      },
      load: {
        assign_tickets: false,
        start_events: false,
      },
    };
  },
  computed: {
    ...mapGetters(["getAgent", "getDevice"]),
  },
  watch: {
    getDevice(value) {
      if (value && value.notification_types) {
        this.allowNotification[notificationTypes.ASSIGN_TICKETS] = value.notification_types.includes(
          notificationTypes.ASSIGN_TICKETS,
        );
        this.allowNotification[notificationTypes.START_EVENTS] = value.notification_types.includes(
          notificationTypes.START_EVENTS,
        );
      }
    },
  },
  created() {
    if (process.env.NODE_ENV === "production") this.verifyAllowNotifications();
  },
  methods: {
    ...mapActions(["handleDeviceNotifications", "getCurrentDevice"]),
    async verifyAllowNotifications() {
      if (!("Notification" in window)) {
        this.message = "Este navegador no es compatible con las notificaciones de escritorio";
      } else {
        if (Notification.permission === "denied")
          this.message = "Debe permitir las notificaciones de este sitio en las configuraciones de su navegador";

        if (Notification.permission === "granted" && messaging) {
          this.allowBrowserNotifications = true;

          const deviceToken = await messaging.getToken();
          if (deviceToken) await this.getCurrentDevice({ agent_id: this.getAgent.uid, token: deviceToken });
        }
      }
    },
    async handleNotification(type) {
      try {
        this.load[type] = true;

        await this.handleDeviceNotifications({
          notification_types: [type],
          allow: this.allowNotification[type],
          token: this.getDevice.token,
        });
      } catch (error) {
        console.error("notifications/handleNotification: ", error);
        this.allowNotification[type] = !this.allowNotification[type];
      } finally {
        this.load[type] = false;
      }
    },
  },
};
</script>
