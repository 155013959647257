export default {
  getTeam(state) {
    return state.team;
  },
  getTeams(state) {
    return state.teams;
  },
  getSchemaTicketForm(state) {
    return state.schemaTicketForm;
  },
  getSchemaContactForm(state) {
    return state.schemaContactForm;
  },
  getSchemaTicketExport(state) {
    return state.schemaTicketExport;
  },
  getSchemaContactExport(state) {
    return state.schemaContactExport;
  },
};
