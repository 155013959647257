import Vue from "vue";
import * as MutationsType from "./mutationsType";

export default {
  [MutationsType.SET_EVENTS](state, payload) {
    state.events = payload;
  },
  [MutationsType.UPDATE_EVENT](state, payload) {
    let index = (state.events || []).findIndex((e) => payload.id === e.id);
    if (index >= 0) Vue.set(state.events, index, payload);
  },
};
