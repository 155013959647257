<template>
  <div id="root">
    <v-app>
      <DrawerApp v-if="user" :user="getAgent" :drawer.sync="drawer" class="hidden-sm-and-down" />
      <Navbar
        v-if="user"
        :user="getAgent"
        :clipped="clipped"
        :title="title"
        :drawer.sync="drawer"
        class="hidden-lg-and-up hidden-md-and-up"
      />
      <v-main>
        <v-container>
          <v-fade-transition mode="out-in">
            <router-view></router-view>
          </v-fade-transition>
        </v-container>
      </v-main>
      <TicketDetailDrawer v-if="getSelectedTicketId" />
      <AgentDetailDrawer />
      <BottomNavigationApp v-if="user" class="hidden-lg-and-up hidden-md-and-up" />
      <v-snackbar v-if="notification" v-model="showNotification" bottom right multi-line :timeout="6000" elevation="20">
        {{ notification.text }}
        <v-spacer></v-spacer>
        <v-btn v-if="notification.link" primary icon :to="notification.link" target="_blank">
          <v-icon dark small>fas fa-external-link-alt</v-icon>
        </v-btn>
        <v-btn primary icon class="ml-0" @click="showNotification = false">
          <v-icon dark small>fas fa-times</v-icon>
        </v-btn>
      </v-snackbar>
    </v-app>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { auth, messaging } from "@/config/firebase";
import { notificationTypes } from "@/helpers/get_constants";
import formatNotification from "@/helpers/format_notification";
import Navbar from "@/components/inc/Navbar";
import DrawerApp from "@/components/inc/DrawerApp";
import BottomNavigationApp from "@/components/inc/BottomNavigationApp";
import TicketDetailDrawer from "@/views/tickets/TicketDetailDrawer";
import AgentDetailDrawer from "@/views/agents/DetailDrawer";
import EventBus from "./index";

export default {
  name: "App",
  components: {
    Navbar,
    DrawerApp,
    BottomNavigationApp,
    TicketDetailDrawer,
    AgentDetailDrawer,
  },
  data() {
    return {
      user: {},
      clipped: false,
      title: "",
      drawer: true,
      showNotification: false,
      notification: null,
    };
  },
  computed: {
    ...mapGetters(["getAgent", "getSelectedTicketId"]),
  },
  watch: {
    $route(to, from) {
      if (to !== from) {
        this.title = this.getTitle();
      }
    },
    user(value) {
      if (value && this.$router.currentRoute.name === "login") this.$router.replace("/");
    },
  },
  mounted() {
    const theme = localStorage.getItem("dark_theme");
    if (theme) this.$vuetify.theme.dark = theme === "true";
  },
  async created() {
    this.title = this.getTitle();
    this.user = await auth.currentUser;

    if (this.user) {
      await this.getAgentById({ agent: { uid: this.user.uid } });
      if (process.env.NODE_ENV === "production" && messaging) this.verifyAllowNotifications();
    }

    EventBus.$on(
      "setNotification",
      function (payload) {
        this.notification = formatNotification({ route: this.$route, ...payload });
        this.showNotification = true;
      }.bind(this),
    );
  },
  methods: {
    ...mapActions(["getAgentById", "handleDeviceNotifications"]),
    getTitle() {
      return this.$route.meta.title || "";
    },
    async verifyAllowNotifications() {
      if ("Notification" in window) {
        const grant = await Notification.requestPermission();
        if (grant === "granted" && !localStorage.getItem("sync_notifications")) this.sendTokenToServer();
      }
    },
    async sendTokenToServer() {
      try {
        let token = await messaging.getToken();
        await this.handleDeviceNotifications({
          notification_types: [notificationTypes.ASSIGN_TICKETS, notificationTypes.START_EVENTS],
          allow: true,
          token: token,
        });
        localStorage.setItem("sync_notifications", true);
      } catch (error) {
        console.error("notifications/sendTokenToServer: ", error);
      }
    },
  },
};
</script>
<style>
.v-application {
  font-family: "Baloo Da 2";
}
/* border drawer */
/* .v-navigation-drawer .v-navigation-drawer__border {
  background-color: transparent !important;
} */
/* select filters imputs */
.select-filters .v-text-field__details {
  display: none !important;
}
/* shadows */
.v-sheet.v-card:not(.v-sheet--outlined) {
  box-shadow: 0 13px 15px 0px rgb(195 194 196 / 12%), 0 10px 18px 0 rgb(224 220 254 / 15%),
    0 9px 18px 0 rgb(109 101 120 / 6%);
}
.v-sheet.theme--dark.v-card:not(.v-sheet--outlined) {
  box-shadow: none !important;
}
.v-application .elevation-3 {
  box-shadow: 0 3px 9px -2px rgb(195 194 196 / 12%), 0 3px 4px 0 rgb(224 220 254 / 15%),
    0 1px 8px 0 rgb(109 101 120 / 6%) !important;
}
.v-expansion-panel:before {
  box-shadow: 0 13px 15px 0px rgb(195 194 196 / 12%), 0 10px 18px 0 rgb(224 220 254 / 15%),
    0 9px 18px 0 rgb(109 101 120 / 6%);
}
aside.v-navigation-drawer {
  width: 0px !important;
}
.v-input.expand,
.v-select--is-multi {
  height: auto !important;
}
.circle {
  align-items: center;
  border-radius: 50%;
  display: inline-flex;
  font-size: 0.75rem;
  justify-content: center;
  height: 24px;
  min-width: 24px;
  width: 24px;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.v-data-table__expand-icon {
  font-size: 18px !important;
}

.v-btn--active.no-active:not(:focus):not(:hover)::before {
  opacity: 0 !important;
}

@media (max-width: 958px) {
  .v-main {
    padding: 0px !important;
  }
}
</style>
