var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    [
      _c(
        "v-col",
        {
          staticClass:
            "grey--text text--darken-3 font-weight-bold pa-4 pb-0 d-flex",
          attrs: { cols: "12" }
        },
        [
          _c("span", { staticClass: "w100" }, [
            _vm.editProducts ? _c("span", [_vm._v("EDITAR")]) : _vm._e(),
            _vm._v("\n      PRODUCTOS RELACIONADOS\n    ")
          ]),
          _vm._v(" "),
          _vm.allowUpdateItemsProducts
            ? _c(
                "div",
                { staticClass: "text-right" },
                [
                  _c(
                    "v-tooltip",
                    {
                      attrs: { bottom: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    {
                                      attrs: { icon: "", small: "" },
                                      on: { click: _vm.addItemProduct }
                                    },
                                    on
                                  ),
                                  [
                                    _c("v-icon", { attrs: { small: "" } }, [
                                      _vm._v("fas fa-plus")
                                    ])
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        2430396974
                      )
                    },
                    [_vm._v(" "), _c("span", [_vm._v("Agregar producto")])]
                  )
                ],
                1
              )
            : _vm._e()
        ]
      ),
      _vm._v(" "),
      _c(
        "v-col",
        { attrs: { cols: "12" } },
        [
          _c(
            "v-form",
            { ref: "formProducts", attrs: { "lazy-validation": "" } },
            [
              _c("ItemsProducts", {
                attrs: {
                  products: _vm.products,
                  action: _vm.actionItemsProducts,
                  "format-one-row": true
                },
                on: {
                  "update:products": function($event) {
                    _vm.products = $event
                  },
                  "update:action": function($event) {
                    _vm.actionItemsProducts = $event
                  }
                }
              }),
              _vm._v(" "),
              _vm.editProducts
                ? _c(
                    "v-col",
                    { staticClass: "text-center", attrs: { cols: "12" } },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            loading: _vm.loadingSave,
                            small: "",
                            rounded: "",
                            color: "primary"
                          },
                          on: { click: _vm.saveOrderProducts }
                        },
                        [_vm._v("Guardar")]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          attrs: { small: "", rounded: "" },
                          on: { click: _vm.cancelEdit }
                        },
                        [_vm._v("Cancelar")]
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }