import Vue from "vue";
import * as MutationsType from "./mutationsType";

export default {
  [MutationsType.SET_CAMPAIGNS_BUDGETS](state, payload) {
    state.campaignsBudgets = payload;
  },
  [MutationsType.ADD_CAMPAIGN_BUDGET](state, payload) {
    state.campaignsBudgets.push(payload);
  },
  [MutationsType.UPDATE_CAMPAIGN_BUDGET](state, payload) {
    let ind = state.campaignsBudgets.findIndex((campaignBudget) => campaignBudget.id === payload.id);
    if (ind >= 0) Vue.set(state.campaignsBudgets, ind, { ...state.campaignsBudgets[ind], ...payload });
  },
  [MutationsType.REMOVE_CAMPAIGN_BUDGET](state, payload) {
    let ind = state.campaignsBudgets.findIndex((t) => t.id === payload);
    Vue.delete(state.campaignsBudgets, ind);
  },
};
