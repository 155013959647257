export default {
  getOrder(state) {
    return state.order;
  },
  getOrders(state) {
    return state.orders;
  },
  getOrderPaymentMethods(state) {
    return state.orderPaymentMethods;
  },
};
