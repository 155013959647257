<template>
  <v-app id="calendar">
    <v-container v-if="getAgent && getAgents">
      <v-row class="d-flex align-center pt-4">
        <v-col cols="12" sm="6" md="9">
          <p class="text-h4 primary--text mb-0 font-weight-black hidden-sm-and-down">Calendario de eventos</p>
        </v-col>
      </v-row>
      <v-row class="d-flex align-center">
        <v-col cols="12" sm="6" md="9" class="py-0">
          <span
            class="circle"
            :style="{ 'background-color': getAgent.color, height: '12px', 'min-width': '12px', width: '12px' }"
          ></span>
          <span>Tus eventos</span>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="pt-0">
          <v-sheet height="64">
            <v-toolbar flat color="white">
              <v-btn outlined class="mr-4" color="primary" @click="setToday"> Hoy </v-btn>
              <v-btn fab text small @click="prev">
                <v-icon small>fas fa-chevron-left</v-icon>
              </v-btn>
              <v-btn fab text small @click="next">
                <v-icon small>fas fa-chevron-right</v-icon>
              </v-btn>
              <v-toolbar-title>{{ title }}</v-toolbar-title>
              <v-spacer></v-spacer>
              <span class="pr-2">Ver: </span>
              <v-menu bottom right>
                <template v-slot:activator="{ on }">
                  <v-btn outlined color="primary" v-on="on">
                    <span>{{ typeToLabel[type] }}</span>
                    <v-icon right small>fas fa-chevron-down</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item @click="type = 'day'">
                    <v-list-item-title>Día</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="type = 'week'">
                    <v-list-item-title>Semana</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="type = 'month'">
                    <v-list-item-title>Mes</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-toolbar>
          </v-sheet>
          <v-sheet v-if="getEvents" height="600">
            <v-calendar
              ref="calendar"
              v-model="focus"
              color="primary"
              :events="getEvents"
              :event-color="getEventColor"
              :event-margin-bottom="3"
              :now="today"
              :type="type"
              class="elevation-3"
              event-overlap-mode="column"
              event-overlap-threshold="30"
              locale="es"
              @click:event="showEvent"
              @click:more="viewDay"
              @click:date="viewDay"
              @change="updateRange"
            ></v-calendar>
            <v-menu
              v-model="selectedOpen"
              :close-on-content-click="false"
              :activator="selectedElement"
              offset-x
              offset-y
              max-width="300px"
            >
              <v-card color="grey lighten-4" flat>
                <v-toolbar :color="getEventColor(selectedEvent)" dark>
                  <v-toolbar-title>{{ selectedEvent.name }}</v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-btn v-if="!isUpdate && selectedEvent.agent_id === getAgent.uid" icon @click="isUpdate = true">
                    <v-icon small> fas fa-pen </v-icon>
                  </v-btn>
                  <v-btn icon @click="selectedOpen = false">
                    <v-icon small>fas fa-times</v-icon>
                  </v-btn>
                </v-toolbar>
                <v-card-text>
                  <v-row v-if="isUpdate">
                    <v-form ref="formEventSchedule" lazy-validation>
                      <v-col cols="12">
                        <v-row no-gutters>
                          <v-col cols="3" class="d-flex">
                            <p class="my-auto text-body-2">Fecha:</p>
                          </v-col>
                          <v-col cols="9">
                            <v-menu
                              v-model="menuDateStart"
                              :close-on-content-click="false"
                              :nudge-right="40"
                              transition="scale-transition"
                              offset-y
                              min-width="300px"
                            >
                              <template #activator="{ on }">
                                <v-text-field
                                  v-model="selectedEvent.date"
                                  class="mb-4"
                                  readonly
                                  :rules="[$rules.REQUIRED]"
                                  v-on="on"
                                />
                              </template>
                              <v-date-picker v-model="selectedEvent.date" @input="menuDateStart = false" />
                            </v-menu>
                          </v-col>
                        </v-row>
                        <v-row no-gutters>
                          <v-col cols="3" class="d-flex">
                            <p class="my-auto text-body-2">Hora:</p>
                          </v-col>
                          <v-col cols="4" class="pr-0">
                            <v-dialog
                              ref="dialogTimeStart"
                              v-model="dialogTimeStart"
                              :return-value.sync="selectedEvent.time"
                              persistent
                              width="290px"
                            >
                              <template #activator="{ on }">
                                <v-text-field
                                  v-model="selectedEvent.time"
                                  label=""
                                  placeholder="00:00"
                                  class="mb-4"
                                  readonly
                                  :rules="[$rules.REQUIRED]"
                                  v-on="on"
                                />
                              </template>
                              <v-time-picker v-if="dialogTimeStart" v-model="selectedEvent.time" full-width>
                                <v-spacer />
                                <v-btn text color="primary" @click="dialogTimeStart = false"> Cancelar </v-btn>
                                <v-btn text color="primary" @click="$refs.dialogTimeStart.save(selectedEvent.time)">
                                  Aceptar
                                </v-btn>
                              </v-time-picker>
                            </v-dialog>
                          </v-col>
                          <v-col cols="1" class="d-flex">
                            <p class="my-auto text-body-2">-</p>
                          </v-col>
                          <v-col cols="4">
                            <v-dialog
                              ref="dialogTimeEnd"
                              v-model="dialogTimeEnd"
                              :return-value.sync="selectedEvent.time_end"
                              persistent
                              width="290px"
                            >
                              <template #activator="{ on }">
                                <v-text-field
                                  v-model="selectedEvent.time_end"
                                  label=""
                                  placeholder="01:00"
                                  class="mb-4"
                                  readonly
                                  :rules="[$rules.REQUIRED, (v) => v > selectedEvent.time_start || 'Hora inválida']"
                                  v-on="on"
                                />
                              </template>
                              <v-time-picker
                                v-if="dialogTimeEnd"
                                v-model="selectedEvent.time_end"
                                :min="selectedEvent.time_start"
                                full-width
                              >
                                <v-spacer />
                                <v-btn text color="primary" @click="dialogTimeEnd = false"> Cancelar </v-btn>
                                <v-btn text color="primary" @click="$refs.dialogTimeEnd.save(selectedEvent.time_end)">
                                  Aceptar
                                </v-btn>
                              </v-time-picker>
                            </v-dialog>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="12" class="text-right">
                        <v-btn text rounded small @click="isUpdate = false">Cancelar</v-btn>
                        <v-btn :loading="loadingUpdate" color="primary" rounded small @click="onUpdateEvent">
                          Actualizar
                        </v-btn>
                      </v-col>
                    </v-form>
                  </v-row>
                  <v-row v-else>
                    <v-col cols="12" class="py-3">
                      <p class="subtitle-2 mb-0">{{ selectedEvent.details }}</p>
                    </v-col>
                    <v-col v-if="selectedEvent.ticket_id" cols="12" class="text-left pb-4">
                      <v-btn
                        text
                        small
                        :to="{ name: 'ticket_detail', params: { id: selectedEvent.ticket_id } }"
                        target="_blank"
                        color="primary"
                      >
                        <v-icon x-small class="pr-2">fas fa-external-link-alt</v-icon> Ver detalle de la oportunidad
                      </v-btn>
                      <v-btn
                        text
                        small
                        :to="{ name: 'contact_detail', params: { id: selectedEvent.contact_id } }"
                        target="_blank"
                        color="primary"
                      >
                        <v-icon x-small class="pr-2">fas fa-external-link-alt</v-icon> Ver contacto
                      </v-btn>
                    </v-col>
                    <v-col v-if="getAllAgentsMap[selectedEvent.agent_id]" cols="12" class="d-flex py-0 my-2">
                      <v-avatar size="40px" color="black">
                        <img
                          v-if="getAllAgentsMap[selectedEvent.agent_id].photo"
                          :src="getAllAgentsMap[selectedEvent.agent_id].photo"
                          alt="Avatar"
                        />
                        <span v-else class="white--text headline">
                          {{ getAllAgentsMap[selectedEvent.agent_id].full_name.substring(0, 1) }}
                        </span>
                      </v-avatar>
                      <div class="pl-2">
                        <h6 class="caption font-weight-bold">Encargado</h6>
                        <h5 class="body-2">{{ getAllAgentsMap[selectedEvent.agent_id].full_name }}</h5>
                      </div>
                    </v-col>
                    <v-col cols="12" class="text-right">
                      <div v-if="selectedEvent.agent_id === getAgent.uid" class="text-right">
                        <BtnCalendar
                          :event-id.sync="selectedEvent.id"
                          :date.sync="selectedEvent.date"
                          :hour-start.sync="selectedEvent.time"
                          :hour-end.sync="selectedEvent.time_end"
                          :title.sync="selectedEvent.name"
                          :detail.sync="selectedEvent.details"
                        />
                      </div>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-menu>
          </v-sheet>
        </v-col>
      </v-row>
    </v-container>
    <v-container v-else>
      <ShowLoading />
    </v-container>
  </v-app>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import BtnCalendar from "@/components/buttons/BtnCalendar";

export default {
  name: "Calendar",
  components: {
    BtnCalendar,
  },
  data() {
    return {
      today: this.$moment().format("YYYY-MM-DD"),
      focus: this.$moment().format("YYYY-MM-DD"),
      type: "month",
      typeToLabel: {
        month: "Mes",
        week: "Semana",
        day: "Día",
      },
      start: null,
      end: null,
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
      rules: {
        required: [(v) => !!v || "Campo requerido"],
      },
      isUpdate: false,
      loadingUpdate: false,
      menuDateStart: false,
      dialogTimeStart: false,
      dialogTimeEnd: false,
    };
  },
  computed: {
    ...mapGetters(["getEvents", "getAgent", "getAgents", "getAllAgentsMap"]),
    title() {
      const { start, end } = this;
      if (!start || !end) {
        return "";
      }

      const startMonth = this.monthFormatter(start);
      const endMonth = this.monthFormatter(end);
      const suffixMonth = startMonth === endMonth ? "" : endMonth;

      switch (this.type) {
        case "month":
          return `${startMonth} ${start.year}`;
        case "week":
        case "4day":
          return `${start.day} al ${suffixMonth} ${end.day} de ${startMonth} ${"del " + start.year}`;
        case "day":
          return `${start.day} de ${startMonth} del ${start.year}`;
      }
      return "";
    },
    monthFormatter() {
      return this.$refs.calendar.getFormatter({
        timeZone: "UTC",
        month: "long",
      });
    },
  },
  watch: {
    getAgent(value) {
      if (value) this.init();
    },
  },
  async created() {
    if (this.getAgent) this.init();
  },
  methods: {
    ...mapActions(["getAllEvents", "getAllAgents", "updateEvent"]),
    async init() {
      await this.getAllEvents();
      await this.getAllAgents();

      if (this.$refs.calendar) this.$refs.calendar.checkChange();
    },
    viewDay({ date }) {
      this.focus = date;
      this.type = "day";
    },
    getEventColor(event) {
      let color_default = "secondary";
      if (this.getAllAgentsMap[event.agent_id] && this.getAllAgentsMap[event.agent_id].color)
        color_default = this.getAllAgentsMap[event.agent_id].color;
      return color_default;
    },
    setToday() {
      this.focus = this.today;
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        setTimeout(() => (this.selectedOpen = true), 10);
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        setTimeout(open, 10);
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    },
    updateRange({ start, end }) {
      this.start = start;
      this.end = end;
    },
    async onUpdateEvent() {
      try {
        this.loadingUpdate = true;

        if (!this.$refs.formEventSchedule.validate()) return;

        await this.updateEvent({
          eventId: this.selectedEvent.id,
          data: {
            date: this.selectedEvent.date,
            time: this.selectedEvent.time,
            time_end: this.selectedEvent.time_end,
          },
        });

        this.selectedOpen = false;
        this.isUpdate = false;
      } catch (error) {
        console.error(error);
      } finally {
        this.loadingUpdate = false;
      }
    },
  },
};
</script>
