export default {
  getOrigins(state) {
    return state.origins;
  },
  getActiveOrigins(state) {
    return state.origins ? state.origins.filter((o) => o.active) : null;
  },
  getActiveCustomOrigins(state) {
    return state.origins ? state.origins.filter((o) => o.active && !o.default) : null;
  },
};
