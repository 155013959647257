import * as MutationsType from "./mutationsType";
import moment from "moment";
import { eventsCollection } from "@/config/firebase";
import requestAxios from "@/helpers/request_axios";
import { roles } from "@/helpers/get_constants";

export default {
  async getAllEvents({ commit, rootState }) {
    const agent = rootState.Agent.agent;
    let reference = eventsCollection;

    if ([roles.ENTREPRENEUR, roles.SUPERVISOR].includes(agent.role))
      reference = reference.where("team_id", "==", agent.team_id);
    if (agent.role === roles.ADVISOR) reference = reference.where("agent_id", "==", agent.uid);

    let events = await reference.get();
    commit(MutationsType.SET_EVENTS, []);

    if (!events.empty) {
      let allEvents = [];
      let e;

      events.forEach((event) => {
        e = { id: event.id, ...event.data() };
        e.end = e.end ?? moment(e.start).add(1, "h").toDate();
        e.timed = true;
        allEvents.push(e);
      });

      commit(MutationsType.SET_EVENTS, allEvents);
    }
  },
  async updateEvent({ commit }, { eventId, data }) {
    try {
      let response = await requestAxios({
        url: `/awy/api/v1/event/${eventId}`,
        method: "PATCH",
        data: data,
      });
      commit(MutationsType.UPDATE_EVENT, response.data.data);
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  },
};
