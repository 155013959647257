var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    [
      _c(
        "v-col",
        {
          staticClass: "grey--text text--darken-3 font-weight-bold pa-4 pb-0",
          attrs: { cols: "12" }
        },
        [_vm._v("\n    HISTORIAL DE LA ORDEN\n  ")]
      ),
      _vm._v(" "),
      _c(
        "v-col",
        { attrs: { cols: "12" } },
        [
          _vm.getCurrentTimeline && _vm.getCurrentTimeline.length > 0
            ? _c(
                "v-timeline",
                { attrs: { dense: "" } },
                [
                  _c(
                    "v-slide-x-transition",
                    { attrs: { group: "" } },
                    _vm._l(_vm.getCurrentTimeline, function(c, index) {
                      return _c(
                        "v-timeline-item",
                        {
                          key: index,
                          attrs: {
                            "fill-dot": "",
                            color: _vm.getCommentTypes[c.type].color,
                            small: ""
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "icon",
                                fn: function() {
                                  return [
                                    _vm.getAllAgentsMap[c.uid] &&
                                    _vm.getAllAgentsMap[c.uid].photo
                                      ? _c(
                                          "v-avatar",
                                          { attrs: { size: "24" } },
                                          [
                                            _c("img", {
                                              attrs: {
                                                src:
                                                  _vm.getAllAgentsMap[c.uid]
                                                    .photo,
                                                alt:
                                                  _vm.getAllAgentsMap[c.uid]
                                                    .name
                                              }
                                            })
                                          ]
                                        )
                                      : _vm._e()
                                  ]
                                },
                                proxy: true
                              }
                            ],
                            null,
                            true
                          )
                        },
                        [
                          _vm._v(" "),
                          _c(
                            "v-card",
                            { staticClass: "pl-6 pa-2" },
                            [
                              _c("v-row", [
                                _vm.getAllAgentsMap[c.uid]
                                  ? _c("strong", {
                                      staticClass: "pr-1",
                                      domProps: {
                                        textContent: _vm._s(
                                          _vm.getAllAgentsMap[c.uid].full_name
                                        )
                                      }
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.getAllAgentsMap[c.uid]
                                  ? _c("strong", [_vm._v("·")])
                                  : _vm._e(),
                                _vm._v(" "),
                                c.created_at
                                  ? _c("strong", {
                                      staticClass: "ml-1",
                                      domProps: {
                                        textContent: _vm._s(
                                          _vm.$formatTimestamp(
                                            c.created_at,
                                            "short"
                                          )
                                        )
                                      }
                                    })
                                  : _vm._e()
                              ]),
                              _vm._v(" "),
                              _c("div", {
                                staticClass: "caption pb-2",
                                domProps: {
                                  innerHTML: _vm._s(c.text_formatted || c.text)
                                }
                              }),
                              _vm._v(" "),
                              _vm.getAllAgentsMap[c.uid]
                                ? _c(
                                    "v-chip",
                                    {
                                      staticClass: "my-1",
                                      attrs: {
                                        color:
                                          _vm.getCommentTypes[c.type].color,
                                        rounded: "",
                                        outlined: "",
                                        small: ""
                                      }
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        { attrs: { left: "", size: "12" } },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.getCommentTypes[c.type].icon
                                            )
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("span", [_vm._v("Comentario")])
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      )
                    }),
                    1
                  )
                ],
                1
              )
            : _c("div", { staticClass: "text-center py-4" }, [
                _vm._v("Aún no hay comentarios")
              ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }