var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    [
      _vm.getAgent && _vm.getProjects
        ? _c(
            "v-container",
            { staticClass: "rounded-lg" },
            [
              _c(
                "v-row",
                { staticClass: "pt-4" },
                [
                  _c(
                    "v-col",
                    {
                      attrs: {
                        cols: "12",
                        sm: "10",
                        md: "8",
                        "offset-sm": "1",
                        "offset-md": "2"
                      }
                    },
                    [
                      _c(
                        "h2",
                        {
                          staticClass:
                            "text-h4 primary--text pb-4 font-weight-black pt-8 pt-md-0 pt-lg-0"
                        },
                        [_vm._v("Nuevo trabajador")]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-card",
                        { staticClass: "px-4 py-6 rounded-lg" },
                        [
                          _c(
                            "h5",
                            { staticClass: "font-weight-regular mb-5" },
                            [
                              _vm._v(
                                "\n            Al crear un nuevo usuario, se enviará un mensaje al correo registrado para que pueda generar una\n            contraseña y poder iniciar sesión. En el caso que no le llegue el correo, deberá ingresar al link de la\n            plataforma y recuperar su contraseña.\n          "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c("AgentForm", { attrs: { action: "create" } })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _c("v-container", [_c("ShowLoading")], 1)
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }