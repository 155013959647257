var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-list-item",
    {
      key: "darkmode",
      on: {
        click: function($event) {
          $event.stopPropagation()
          return _vm.toggle_dark_mode_m($event)
        }
      }
    },
    [
      _c(
        "v-list-item-avatar",
        [
          _c("v-icon", {
            class: _vm.toggle_dark_mode ? "fas fa-moon" : "far fa-moon",
            attrs: { text: "Modo oscuro" }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-list-item-content",
        [
          _c("v-list-item-title", [_vm._v("Modo oscuro")]),
          _vm._v(" "),
          _c("v-list-item-subtitle", [_vm._v("(Beta)")])
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-list-item-action",
        [
          _c("v-switch", {
            attrs: { color: "primary" },
            on: { click: _vm.toggle_dark_mode_m },
            model: {
              value: _vm.toggle_dark_mode,
              callback: function($$v) {
                _vm.toggle_dark_mode = $$v
              },
              expression: "toggle_dark_mode"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }