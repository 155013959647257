var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticStyle: { height: "400px" } },
    [
      _c(
        "v-row",
        {
          staticClass: "fill-height",
          attrs: { "align-content": "center", justify: "center" }
        },
        [
          _c("div", { attrs: { id: "container" } }, [
            _c("div", { staticClass: "loading-text" }, [
              _c("span", { staticClass: "letter" }, [_vm._v("L")]),
              _vm._v(" "),
              _c("span", { staticClass: "letter" }, [_vm._v("o")]),
              _vm._v(" "),
              _c("span", { staticClass: "letter" }, [_vm._v("a")]),
              _vm._v(" "),
              _c("span", { staticClass: "letter" }, [_vm._v("d")]),
              _vm._v(" "),
              _c("span", { staticClass: "letter" }, [_vm._v("i")]),
              _vm._v(" "),
              _c("span", { staticClass: "letter" }, [_vm._v("n")]),
              _vm._v(" "),
              _c("span", { staticClass: "letter" }, [_vm._v("g")])
            ])
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }