<template>
  <div>
    <v-list-item key="webhooks" @click.stop="toggle = !toggle">
      <v-list-item-avatar>
        <v-icon>fa-network-wired</v-icon>
      </v-list-item-avatar>
      <v-list-item-content class="py-4">
        <v-list-item-title>Integraciones</v-list-item-title>
        <v-list-item-subtitle>Conecta tus canales digitales con el CRM</v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action>
        <v-icon>
          {{ !toggle ? "fas fa-angle-down" : "fas fa-angle-up" }}
        </v-icon>
      </v-list-item-action>
    </v-list-item>
    <v-container v-if="toggle">
      <v-col v-if="getIntegrationSendGrid || getIntegrationWhatsApp || getIntegrationZoomWebinars" cols="12">
        <v-row class="px-3">
          <v-col cols="12" class="pa-0">
            <h5 class="mb-4">Plataformas conectadas</h5>
          </v-col>
          <v-col v-if="getIntegrationWhatsApp && !getIntegrationWhatsApp.disabled" cols="12" class="pa-0 mb-3">
            <WhatsAppDetail :dialog-to-edit.sync="dialogWhatsApp" />
          </v-col>
          <v-col v-if="getIntegrationSendGrid && getAgent.role === $roles.SUPER_ADMIN" cols="12" class="pa-0 mb-3">
            <SendgridDetail :dialog-to-edit.sync="dialogSendGrid" />
          </v-col>
          <v-col v-if="getIntegrationZoomWebinars && getAgent.role === $roles.SUPER_ADMIN" cols="12" class="pa-0">
            <ZoomWebinarsDetail :dialog-to-edit.sync="dialogZoomWebinars" />
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="12" class="mt-4">
        <h5 class="mb-4">Integraciones disponibles</h5>
        <v-row>
          <v-col
            v-if="!getIntegrationWhatsApp || (getIntegrationWhatsApp && getIntegrationWhatsApp.disabled)"
            cols="6"
            sm="4"
            md="2"
          >
            <WhatsAppCard
              :is-disabled="getIntegrationWhatsApp && getIntegrationWhatsApp.disabled"
              :dialog-whatsapp.sync="dialogWhatsApp"
            />
          </v-col>
          <v-col v-if="!getIntegrationSendGrid && getAgent.role === $roles.SUPER_ADMIN" cols="6" sm="4" md="2">
            <SendgridCard :dialog-sendgrid.sync="dialogSendGrid" />
          </v-col>
          <v-col v-if="!getIntegrationZoomWebinars && getAgent.role === $roles.SUPER_ADMIN" cols="6" sm="4" md="2">
            <ZoomWebinarsCard :dialog-zoom-webinars.sync="dialogZoomWebinars" />
          </v-col>
        </v-row>
      </v-col>

      <ZoomWebinarsModal v-if="dialogZoomWebinars" :dialog.sync="dialogZoomWebinars" />
      <SendGridModal v-if="dialogSendGrid" :dialog.sync="dialogSendGrid" />
      <WhatsAppModal v-if="dialogWhatsApp" :dialog.sync="dialogWhatsApp" />
    </v-container>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import ZoomWebinarsCard from "@/views/settings/integrations/zoomwebinars/Card";
import ZoomWebinarsDetail from "@/views/settings/integrations/zoomwebinars/Detail";
import ZoomWebinarsModal from "@/views/settings/integrations/zoomwebinars/Modal";
import SendgridCard from "@/views/settings/integrations/sendgrid/Card";
import SendgridDetail from "@/views/settings/integrations/sendgrid/Detail";
import SendGridModal from "@/views/settings/integrations/sendgrid/Modal";
import WhatsAppCard from "@/views/settings/integrations/whatsapp/Card";
import WhatsAppDetail from "@/views/settings/integrations/whatsapp/Detail";
import WhatsAppModal from "@/views/settings/integrations/whatsapp/Modal";

export default {
  name: "Webhooks",
  components: {
    ZoomWebinarsCard,
    ZoomWebinarsDetail,
    ZoomWebinarsModal,
    SendgridCard,
    SendgridDetail,
    SendGridModal,
    WhatsAppCard,
    WhatsAppDetail,
    WhatsAppModal,
  },
  data() {
    return {
      toggle: false,
      dialogZoomWebinars: false,
      dialogSendGrid: false,
      dialogWhatsApp: false,
      loading: false,
    };
  },
  computed: {
    ...mapGetters(["getIntegrationZoomWebinars", "getIntegrationSendGrid", "getIntegrationWhatsApp", "getAgent"]),
  },
  async created() {
    await this.getAllIntegrations();
  },
  methods: {
    ...mapActions(["getAllIntegrations", "enableWebhook", "deleteWebhook"]),
  },
};
</script>
