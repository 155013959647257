var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pt-16 pt-md-0 pt-lg-0" },
    [
      !_vm.isLoading
        ? _c("div", [
            _c("span", { staticClass: "caption" }, [
              _vm._v(
                "Última actualización: " +
                  _vm._s(_vm.$moment(_vm.dateUpdated).fromNow())
              )
            ]),
            _vm._v(" "),
            _c("iframe", {
              staticStyle: { border: "0" },
              attrs: {
                src: _vm.reportUrl,
                frameborder: "0",
                allowfullscreen: ""
              }
            })
          ])
        : _c("v-container", [_c("ShowLoading")], 1)
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }