var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.getTimeline(_vm.ticketId) && _vm.getTimeline(_vm.ticketId).length > 0
        ? _c(
            "v-timeline",
            { attrs: { dense: "" } },
            [
              _c(
                "v-slide-x-transition",
                { attrs: { group: "" } },
                _vm._l(_vm.sortTimeLine, function(c, index) {
                  return _c(
                    "v-timeline-item",
                    {
                      key: index,
                      attrs: {
                        "fill-dot": "",
                        color: _vm.getCommentTypes[c.type].color,
                        small: ""
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "icon",
                            fn: function() {
                              return [
                                _vm.getAllAgentsMap[c.uid] &&
                                _vm.getAllAgentsMap[c.uid].photo
                                  ? _c("v-avatar", { attrs: { size: "24" } }, [
                                      _c("img", {
                                        attrs: {
                                          src: _vm.getAllAgentsMap[c.uid].photo,
                                          alt: _vm.getAllAgentsMap[c.uid].name
                                        }
                                      })
                                    ])
                                  : _vm._e()
                              ]
                            },
                            proxy: true
                          }
                        ],
                        null,
                        true
                      )
                    },
                    [
                      _vm._v(" "),
                      _c(
                        "v-card",
                        { staticClass: "pl-6 py-2 pr-2" },
                        [
                          _c("v-row", [
                            _vm.getAllAgentsMap[c.uid]
                              ? _c("strong", {
                                  staticClass: "pr-1",
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.getAllAgentsMap[c.uid].full_name
                                    )
                                  }
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.getAllAgentsMap[c.uid]
                              ? _c("strong", [_vm._v("·")])
                              : _vm._e(),
                            _vm._v(" "),
                            c.created_at
                              ? _c("strong", {
                                  staticClass: "ml-1",
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.$formatTimestamp(
                                        c.created_at,
                                        "short"
                                      )
                                    )
                                  }
                                })
                              : _vm._e()
                          ]),
                          _vm._v(" "),
                          _c("div", {
                            staticClass: "caption pb-2",
                            domProps: {
                              innerHTML: _vm._s(c.text_formatted || c.text)
                            }
                          }),
                          _vm._v(" "),
                          c.order_id
                            ? _c(
                                "div",
                                { staticClass: "mb-1" },
                                [
                                  c.products
                                    ? _c(
                                        "v-col",
                                        {
                                          staticClass: "pr-5",
                                          attrs: { cols: "12" }
                                        },
                                        [
                                          _c(
                                            "h6",
                                            { staticClass: "caption mb-2" },
                                            [_vm._v("Productos relacionados:")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-simple-table",
                                            {
                                              staticClass: "mb-4",
                                              staticStyle: {
                                                "overflow-x": "auto",
                                                border: "1px solid lightgrey"
                                              }
                                            },
                                            [
                                              _c(
                                                "thead",
                                                {
                                                  staticClass: "grey lighten-4"
                                                },
                                                [
                                                  _c("tr", [
                                                    _c(
                                                      "th",
                                                      {
                                                        staticClass:
                                                          "text-center"
                                                      },
                                                      [_vm._v("Nombre")]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "th",
                                                      {
                                                        staticClass:
                                                          "text-center"
                                                      },
                                                      [_vm._v("Cantidad")]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "th",
                                                      {
                                                        staticClass:
                                                          "text-center"
                                                      },
                                                      [_vm._v("Subtotal")]
                                                    )
                                                  ])
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "tbody",
                                                { staticClass: "text-center" },
                                                [
                                                  _vm._l(c.products, function(
                                                    product,
                                                    i
                                                  ) {
                                                    return _c(
                                                      "tr",
                                                      { key: i },
                                                      [
                                                        _c("td", [
                                                          _vm._v(
                                                            _vm._s(
                                                              product.name ||
                                                                product.id
                                                            )
                                                          )
                                                        ]),
                                                        _vm._v(" "),
                                                        _c("td", [
                                                          _vm._v(
                                                            _vm._s(
                                                              product.quantity
                                                            )
                                                          )
                                                        ]),
                                                        _vm._v(" "),
                                                        _c("td", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$formatCurrency(
                                                                product.price *
                                                                  product.quantity
                                                              )
                                                            )
                                                          )
                                                        ])
                                                      ]
                                                    )
                                                  }),
                                                  _vm._v(" "),
                                                  _c("tr", [
                                                    _c(
                                                      "td",
                                                      {
                                                        staticClass:
                                                          "text-right subtitle",
                                                        attrs: { colspan: " 2" }
                                                      },
                                                      [_vm._v("Total")]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "td",
                                                      {
                                                        staticClass:
                                                          "grey lighten-5"
                                                      },
                                                      [
                                                        _c(
                                                          "b",
                                                          {
                                                            staticClass:
                                                              "primary--text subtitle"
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$formatCurrency(
                                                                  c.total_paid
                                                                )
                                                              )
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    )
                                                  ])
                                                ],
                                                2
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  ![
                                    _vm.$roles.ADVISOR,
                                    _vm.$roles.SUPERVISOR
                                  ].includes(_vm.getAgent.role)
                                    ? _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            text: "",
                                            small: "",
                                            to: {
                                              name: "order_detail",
                                              params: { id: c.order_id }
                                            },
                                            target: "_blank",
                                            color: "primary"
                                          }
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              staticClass: "pr-2",
                                              attrs: { "x-small": "" }
                                            },
                                            [_vm._v("fas fa-external-link-alt")]
                                          ),
                                          _vm._v(
                                            " Ver detalle de la orden\n            "
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "v-chip",
                            {
                              staticClass: "my-1 mr-1",
                              attrs: {
                                color: _vm.getCommentTypes[c.type].color,
                                rounded: "",
                                outlined: "",
                                small: ""
                              }
                            },
                            [
                              _c(
                                "v-icon",
                                { attrs: { left: "", size: "12" } },
                                [
                                  _vm._v(
                                    _vm._s(_vm.getCommentTypes[c.type].icon)
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              c.type == _vm.$activityTypes.CALL
                                ? _c("span", [_vm._v("Llamada")])
                                : c.type == _vm.$activityTypes.MESSAGE
                                ? _c("span", [_vm._v("Correo")])
                                : c.type == _vm.$activityTypes.EVENT
                                ? _c("span", [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          c.event_date +
                                            " " +
                                            c.event_time +
                                            " " +
                                            (c.event_time_end
                                              ? " - " + c.event_time_end
                                              : "")
                                        ) +
                                        "\n            "
                                    )
                                  ])
                                : _c("span", [_vm._v("Comentario")])
                            ],
                            1
                          ),
                          _vm._v(" "),
                          c.remote_jid
                            ? _c(
                                "v-chip",
                                {
                                  attrs: {
                                    color: _vm.getCommentTypes[5].color,
                                    rounded: "",
                                    outlined: "",
                                    small: ""
                                  }
                                },
                                [
                                  _c(
                                    "v-icon",
                                    { attrs: { left: "", size: "12" } },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.getCommentTypes[5].icon)
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("span", [_vm._v("WhatsApp")])
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          c.type === _vm.$activityTypes.EVENT &&
                          c.uid === _vm.getAgent.uid
                            ? _c(
                                "div",
                                { staticClass: "text-right pa-2" },
                                [
                                  _c("BtnCalendar", {
                                    attrs: {
                                      "event-id": c.id,
                                      date: c.event_date,
                                      "hour-start": c.event_time,
                                      "hour-end": c.event_time_end,
                                      title: c.event_name,
                                      detail: c.detail
                                    },
                                    on: {
                                      "update:eventId": function($event) {
                                        return _vm.$set(c, "id", $event)
                                      },
                                      "update:event-id": function($event) {
                                        return _vm.$set(c, "id", $event)
                                      },
                                      "update:date": function($event) {
                                        return _vm.$set(c, "event_date", $event)
                                      },
                                      "update:hourStart": function($event) {
                                        return _vm.$set(c, "event_time", $event)
                                      },
                                      "update:hour-start": function($event) {
                                        return _vm.$set(c, "event_time", $event)
                                      },
                                      "update:hourEnd": function($event) {
                                        return _vm.$set(
                                          c,
                                          "event_time_end",
                                          $event
                                        )
                                      },
                                      "update:hour-end": function($event) {
                                        return _vm.$set(
                                          c,
                                          "event_time_end",
                                          $event
                                        )
                                      },
                                      "update:title": function($event) {
                                        return _vm.$set(c, "event_name", $event)
                                      },
                                      "update:detail": function($event) {
                                        return _vm.$set(c, "detail", $event)
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                }),
                1
              )
            ],
            1
          )
        : _c("div", { staticClass: "text-center py-4" }, [
            _vm._v("Aún no hay comentarios")
          ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }