export default {
  getTags(state) {
    return state.tags;
  },
  getActiveTags(state) {
    let tags = [];
    if (state.tags) {
      tags = [];
      for (let t in state.tags) {
        if (state.tags[t].active) tags.push(state.tags[t]);
      }
    }
    return tags;
  },
  getTagsToArray(state) {
    let tags;
    if (state.tags) {
      tags = [];
      for (let t in state.tags) {
        tags.push(state.tags[t]);
      }
    }
    return tags;
  },
};
