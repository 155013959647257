const SET_TICKET = "SET_TICKET";
const SET_TICKETS = "SET_TICKETS";
const SET_NEW_TICKETS = "SET_NEW_TICKETS";
const ADD_TICKET = "ADD_TICKET";
const SET_TICKET_STATE = "SET_TICKET_STATE";
const SHOW_TICKET_DETAIL = "SHOW_TICKET_DETAIL";
const HIDE_TICKET_DETAIL = "HIDE_TICKET_DETAIL";
const UPDATE_TICKET = "UPDATE_TICKET";
const SET_TICKET_DOWNLOADS = "SET_TICKET_DOWNLOADS";
const ADD_TICKET_DOWNLOAD = "ADD_TICKET_DOWNLOAD";
const UPDATE_TICKET_DOWNLOAD = "UPDATE_TICKET_DOWNLOAD";
const REMOVE_TICKET_DOWNLOAD = "REMOVE_TICKET_DOWNLOAD";

export {
  SET_TICKET,
  SET_TICKETS,
  SET_NEW_TICKETS,
  ADD_TICKET,
  SET_TICKET_STATE,
  SHOW_TICKET_DETAIL,
  HIDE_TICKET_DETAIL,
  UPDATE_TICKET,
  SET_TICKET_DOWNLOADS,
  ADD_TICKET_DOWNLOAD,
  UPDATE_TICKET_DOWNLOAD,
  REMOVE_TICKET_DOWNLOAD,
};
