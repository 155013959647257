import { auth } from "@/config/firebase";

async function getCurrentUser() {
  let user = { role: "anonymous" };
  let userAuthenticated = auth.currentUser;

  if (userAuthenticated) {
    let token = await userAuthenticated.getIdTokenResult();
    return { ...userAuthenticated, ...token.claims };
  }

  return user;
}

export default getCurrentUser;
