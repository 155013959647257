<template>
  <v-card v-if="getOrderStates" class="pa-4 pb-0">
    <v-col cols="12" class="grey--text text--darken-3 font-weight-bold pa-0">
      <span v-if="allowEdit">EDITAR</span>
      ESTADO
    </v-col>
    <v-col cols="12">
      <v-form ref="formOrderState" lazy-validation>
        <v-row>
          <v-col cols="12">
            <v-autocomplete
              v-model="state"
              :value="getOrder.state"
              :items="getOrderStates"
              :readonly="getOrder.state === $orderStates.TERMINADO"
              style="font-size: 15px;"
              item-text="name"
              item-value="id"
              label="Estado"
              class="pt-2"
            ></v-autocomplete>
          </v-col>
          <v-col v-if="allowEdit" cols="12" class="text-center pt-0">
            <v-btn :loading="loading" small rounded color="primary" @click="save">Guardar</v-btn>
            <v-btn small rounded @click="cancel">Cancelar</v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-col>
  </v-card>
</template>
<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "OrderCardState",
  data() {
    return {
      loading: false,
      state: null,
    };
  },
  computed: {
    ...mapGetters(["getOrder", "getOrderStates"]),
    allowEdit() {
      return this.state !== this.getOrder.state;
    },
  },
  created() {
    this.state = this.getOrder.state;
  },
  methods: {
    ...mapActions(["setOrderState"]),
    async save() {
      try {
        this.loading = true;

        if (this.$refs.formOrderState.validate()) {
          await this.setOrderState({ id: this.getOrder.id, state: this.state });
        }
      } catch (error) {
        console.error("save:", error);
      } finally {
        this.loading = false;
      }
    },
    cancel() {
      this.state = this.getOrder.state;
    },
  },
};
</script>
