import * as MutationsType from "./mutationsType";
import { productsCollection } from "@/config/firebase";

export default {
  async getAllProducts({ commit, rootState }) {
    const { agent, agentRoles } = rootState.Agent;
    let reference = productsCollection;

    if (agent.role === agentRoles.entrepreneur.id) reference = reference.where("team_id", "==", agent.team_id);

    const snap = await reference.get();
    let products = [],
      productsMap = {};

    if (!snap.empty) {
      snap.forEach((doc) => {
        let data = { id: doc.id, ...doc.data() };
        productsMap[doc.id] = data;
        products.push(data);
      });
    }

    commit(MutationsType.SET_PRODUCTS, products);
    commit(MutationsType.SET_PRODUCTS_MAP, productsMap);
  },
};
