import requestAxios from "@/helpers/request_axios";
import * as MutationsType from "./mutationsType";
import { integrationsCollection } from "@/config/firebase";

export default {
  async getAllIntegrations({ state, rootState, commit }) {
    let snap = await integrationsCollection.get();
    if (!snap.empty)
      snap.forEach((doc) => {
        commit(MutationsType[`SET_INTEGRATION_${doc.id.toUpperCase()}`], { ...doc.data(), id: doc.id });
      });

    if (!state.integrationWhatsApp || (state.integrationWhatsApp && !state.integrationWhatsApp.disabled))
      await integrationsCollection
        .doc("whatsapp")
        .collection("integrated_agents")
        .doc(rootState.Agent.agent.uid)
        .onSnapshot(async (doc) => {
          commit(
            MutationsType["SET_INTEGRATION_WHATSAPP"],
            doc.exists
              ? {
                  ...doc.data(),
                  id: "whatsapp",
                }
              : null,
          );
        });
  },
  async getIntegrationById({ commit }, payload) {
    let doc = await integrationsCollection.doc(payload.id).get();
    let config = doc.exists ? { id: doc.id, ...doc.data() } : null;

    commit(MutationsType.SET_INTEGRATION_CONFIG, config);
  },
  // eslint-disable-next-line
  async saveIntegration({ commit }, payload) {
    try {
      let response = await requestAxios({
        url: "/awy/api/v1/integration",
        method: "POST",
        data: payload,
      });
      commit(MutationsType[`SET_INTEGRATION_${payload.id.toUpperCase()}`], { ...response.data.data, id: payload.id });
      return response.data;
    } catch (error) {
      console.error("saveIntegration: ", error);
      throw error.response.data;
    }
  },
  // eslint-disable-next-line
  async updateIntegration({ commit }, payload) {
    try {
      let response = await requestAxios({
        url: `/awy/api/v1/integration/${payload.id}`,
        method: "PUT",
        data: payload.data,
      });
      commit(MutationsType[`SET_INTEGRATION_${payload.id.toUpperCase()}`], { ...response.data.data, id: payload.id });
      return response.data;
    } catch (error) {
      console.error("updateIntegration: ", error);
      throw error.response.data;
    }
  },
  // eslint-disable-next-line
  async removeIntegration({ commit }, payload) {
    try {
      let response = await requestAxios({
        url: `/awy/api/v1/integration/${payload.id}`,
        method: "DELETE",
      });
      commit(MutationsType[`SET_INTEGRATION_${payload.id.toUpperCase()}`], null);
      return response.data;
    } catch (error) {
      console.error("removeIntegration: ", error);
      throw error.response.data;
    }
  },
  // eslint-disable-next-line
  async verifyIntegrationZoomWebinars({ commit }, payload) {
    try {
      let response = await requestAxios({
        url: "/awy/api/v1/integration/zoomwebinars/verify-account",
        method: "POST",
        data: payload,
      });
      return response.data;
    } catch (error) {
      console.error("verifyIntegrationZoomWebinars: ", error);
      throw error.response.data;
    }
  },
  // eslint-disable-next-line
  async registerUsersToZoomWebinar({ commit }, payload) {
    try {
      let response = await requestAxios({
        url: "/awy/api/v1/integration/zoomwebinars/register-attendees",
        method: "POST",
        data: payload,
      });
      return response.data;
    } catch (error) {
      console.error("registerUsersToZoomWebinar: ", error);
      throw error.response.data;
    }
  },
  // eslint-disable-next-line
  async verifySendGridAPIKey({ commit }, payload) {
    try {
      let response = await requestAxios({
        url: "/awy/api/v1/integration/sendgrid/verify-apikey-permissions",
        method: "POST",
        data: payload,
      });
      return response.data;
    } catch (error) {
      console.error("verifySendGridAPIKey: ", error);
      throw error.response.data;
    }
  },
  // eslint-disable-next-line
  async verifySendGridSendMail({ commit }, payload) {
    try {
      let response = await requestAxios({
        url: "/awy/api/v1/integration/sendgrid/verify-send-mail",
        method: "POST",
        data: payload,
      });
      return response.data;
    } catch (error) {
      console.error("verifySendGridSendMail: ", error);
      throw error.response.data;
    }
  },
  // eslint-disable-next-line
  async verifySendGridConfigInboundParse({ commit }, payload) {
    try {
      let response = await requestAxios({
        url: "/awy/api/v1/integration/sendgrid/config-inbound-parse",
        method: "POST",
        data: payload,
      });
      return response.data;
    } catch (error) {
      console.error("verifySendGridConfigInboundParse: ", error);
      throw error.response.data;
    }
  },
  // eslint-disable-next-line
  async removeSendGridConfig({ commit }, payload) {
    try {
      let response = await requestAxios({
        url: "/awy/api/v1/integration/sendgrid/config",
        method: "DELETE",
        data: payload,
      });
      commit(MutationsType["SET_INTEGRATION_SENDGRID"], null);
      return response.data;
    } catch (error) {
      console.error("removeSendGridConfig: ", error);
      throw error.response.data;
    }
  },
  // eslint-disable-next-line
  async generateAuthQRWhatsApp({ commit }) {
    try {
      let response = await requestAxios({
        url: "/awy/api/v1/integration/whatsapp/generate-auth-qr",
        method: "POST",
      });
      return response.data;
    } catch (error) {
      console.error("generateAuthQRWhatsApp: ", error);
      throw error.response.data;
    }
  },
  // eslint-disable-next-line
  async removeWhatsAppConfig({ rootState, commit }) {
    try {
      let response = await requestAxios({
        url: "/awy/api/v1/integration/whatsapp/config",
        method: "DELETE",
        data: { agent_id: rootState.Agent.agent.uid },
      });
      commit(MutationsType["SET_INTEGRATION_WHATSAPP"], null);
      return response.data;
    } catch (error) {
      console.error("removeWhatsAppConfig: ", error);
      throw error.response.data;
    }
  },
};
