var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    [
      _vm.getAgent && _vm.getOrderStates && _vm.getProducts && _vm.orders
        ? _c(
            "div",
            [
              _c(
                "v-row",
                { staticClass: "d-flex align-center pt-4 pt-sm-10" },
                [
                  _c("v-col", { attrs: { cols: "12", sm: "6", md: "9" } }, [
                    _c(
                      "p",
                      {
                        staticClass:
                          "text-h4 primary--text mb-0 font-weight-black hidden-sm-and-down"
                      },
                      [_vm._v("Órdenes")]
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    {
                      staticClass:
                        "d-flex justify-md-end justify-space-between",
                      attrs: { cols: "12", sm: "6", md: "3" }
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            loading: _vm.loadExport,
                            outlined: "",
                            color: "primary",
                            dark: ""
                          },
                          on: { click: _vm.exportData }
                        },
                        [
                          _vm._v("\n          Descargar\n          "),
                          _c("v-icon", { attrs: { right: "", size: "16" } }, [
                            _vm._v("fa-file-download")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-row",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.showFilters,
                      expression: "showFilters"
                    }
                  ],
                  staticClass: "d-flex align-center py-4"
                },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "py-0",
                      attrs: { cols: "12", sm: "6", md: "3" }
                    },
                    [
                      _c(
                        "v-card",
                        { staticClass: "pt-2 px-4 elevation-3 rounded-lg" },
                        [
                          _c("v-autocomplete", {
                            staticClass:
                              "px-1 d-flex align-center select-filters",
                            attrs: {
                              value: "todos",
                              items: _vm.getStatesToSelect,
                              "item-text": "name",
                              "item-value": "id",
                              label: "Estado",
                              dense: ""
                            },
                            on: {
                              change: function($event) {
                                return _vm.applyFilters("state", $event)
                              }
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    {
                      staticClass: "py-0",
                      attrs: { cols: "12", sm: "6", md: "3" }
                    },
                    [
                      _c(
                        "v-card",
                        { staticClass: "pt-2 px-4 elevation-3 rounded-lg" },
                        [
                          _c("v-autocomplete", {
                            staticClass:
                              "px-1 d-flex align-center select-filters",
                            attrs: {
                              items: _vm.getProductsToSelect,
                              value: "todos",
                              "item-text": "name",
                              "item-value": "id",
                              label: "Producto",
                              dense: ""
                            },
                            on: {
                              change: function($event) {
                                return _vm.applyFilters("products_ids", $event)
                              }
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    {
                      staticClass: "py-0",
                      attrs: { cols: "12", sm: "6", md: "3" }
                    },
                    [
                      _c(
                        "v-card",
                        { staticClass: "px-4 elevation-3 rounded-lg py-1" },
                        [
                          _c("v-text-field", {
                            staticClass: "d-flex align-center imput-icon",
                            attrs: {
                              label: "Buscar cliente",
                              dense: "",
                              solo: "",
                              "single-line": "",
                              "hide-details": "",
                              flat: "",
                              "append-icon": "fa fa-search"
                            },
                            on: { input: _vm.applySearch },
                            model: {
                              value: _vm.query,
                              callback: function($$v) {
                                _vm.query = $$v
                              },
                              expression: "query"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card",
                { staticClass: "pa-4 mb-5 rounded-lg" },
                [
                  _c("v-progress-linear", {
                    attrs: {
                      active: _vm.loadingOrders,
                      indeterminate: true,
                      absolute: "",
                      top: "",
                      color: "secondary accent-4"
                    }
                  }),
                  _vm._v(" "),
                  _vm.orders
                    ? _c(
                        "v-col",
                        { staticClass: "pa-4", attrs: { cols: "12" } },
                        [
                          _vm.orders
                            ? _c("v-data-table", {
                                staticClass: "table-orders",
                                attrs: {
                                  headers: _vm.headers,
                                  items: _vm.orders,
                                  "show-expand": "",
                                  "sort-by": "",
                                  dense: "",
                                  "items-per-page": 40,
                                  "hide-default-footer": ""
                                },
                                on: { "item-expanded": _vm.onShowExpanded },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "item.created_at",
                                      fn: function(ref) {
                                        var item = ref.item
                                        return [
                                          _vm._v(
                                            "\n            " +
                                              _vm._s(
                                                _vm.$formatTimestamp(
                                                  item.created_at
                                                )
                                              ) +
                                              "\n          "
                                          )
                                        ]
                                      }
                                    },
                                    {
                                      key: "item.operations",
                                      fn: function(ref) {
                                        var item = ref.item
                                        return [
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                text: "",
                                                icon: "",
                                                to: {
                                                  name: "order_detail",
                                                  params: {
                                                    id: item.objectID || item.id
                                                  }
                                                },
                                                target: "_blank"
                                              }
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  attrs: {
                                                    color: "primary",
                                                    dark: "",
                                                    small: ""
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "fas fa-external-link-alt"
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      }
                                    },
                                    {
                                      key: "item.state",
                                      fn: function(ref) {
                                        var item = ref.item
                                        return [
                                          _vm._v(
                                            "\n            " +
                                              _vm._s(
                                                _vm.getOrderStatesMap[
                                                  item.state
                                                ]
                                                  ? _vm.getOrderStatesMap[
                                                      item.state
                                                    ].name
                                                  : "-"
                                              ) +
                                              "\n          "
                                          )
                                        ]
                                      }
                                    },
                                    {
                                      key: "expanded-item",
                                      fn: function(ref) {
                                        var item = ref.item
                                        return [
                                          _c(
                                            "td",
                                            {
                                              staticClass: "pa-8",
                                              attrs: {
                                                colspan: _vm.headers.length
                                              }
                                            },
                                            [
                                              _c(
                                                "v-row",
                                                [
                                                  _c("v-progress-linear", {
                                                    staticClass: "mb-3",
                                                    attrs: {
                                                      active: !item.products,
                                                      indeterminate: true,
                                                      top: "",
                                                      color:
                                                        "secondary accent-4"
                                                    }
                                                  }),
                                                  _vm._v(" "),
                                                  _vm._l(
                                                    _vm.schemaSale,
                                                    function(schema, i) {
                                                      return _c(
                                                        "v-col",
                                                        {
                                                          key: i,
                                                          attrs: {
                                                            md: "3",
                                                            cols: "12"
                                                          }
                                                        },
                                                        [
                                                          _c("v-text-field", {
                                                            attrs: {
                                                              label:
                                                                schema.header,
                                                              value:
                                                                schema.type ===
                                                                "date"
                                                                  ? _vm.$formatTimestamp(
                                                                      item[
                                                                        schema
                                                                          .key
                                                                      ],
                                                                      "date_z"
                                                                    )
                                                                  : item[
                                                                      schema.key
                                                                    ],
                                                              readonly: ""
                                                            }
                                                          })
                                                        ],
                                                        1
                                                      )
                                                    }
                                                  )
                                                ],
                                                2
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "v-row",
                                                [
                                                  _c("v-spacer"),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-col",
                                                    {
                                                      attrs: {
                                                        md: "3",
                                                        cols: "12"
                                                      }
                                                    },
                                                    [
                                                      _c("v-text-field", {
                                                        attrs: {
                                                          label:
                                                            "Método de pago:",
                                                          value:
                                                            item.payment_method,
                                                          readonly: ""
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "v-row",
                                                [
                                                  _c("v-col", {
                                                    attrs: {
                                                      md: "6",
                                                      cols: "12"
                                                    }
                                                  }),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-col",
                                                    {
                                                      attrs: {
                                                        md: "6",
                                                        cols: "12"
                                                      }
                                                    },
                                                    [
                                                      _c("p", [
                                                        _vm._v(
                                                          "Productos relacionados:"
                                                        )
                                                      ]),
                                                      _vm._v(" "),
                                                      _c(
                                                        "v-simple-table",
                                                        {
                                                          staticClass: "mb-4",
                                                          staticStyle: {
                                                            "overflow-x":
                                                              "auto",
                                                            border:
                                                              "1px solid lightgrey"
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "thead",
                                                            {
                                                              staticClass:
                                                                "grey lighten-4"
                                                            },
                                                            [
                                                              _c("tr", [
                                                                _c(
                                                                  "th",
                                                                  {
                                                                    staticClass:
                                                                      "text-center"
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "Nombre"
                                                                    )
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "th",
                                                                  {
                                                                    staticClass:
                                                                      "text-center"
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "Cantidad"
                                                                    )
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "th",
                                                                  {
                                                                    staticClass:
                                                                      "text-center"
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "Subtotal"
                                                                    )
                                                                  ]
                                                                )
                                                              ])
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "tbody",
                                                            {
                                                              staticClass:
                                                                "text-center"
                                                            },
                                                            [
                                                              _vm._l(
                                                                item.products,
                                                                function(
                                                                  product,
                                                                  i
                                                                ) {
                                                                  return _c(
                                                                    "tr",
                                                                    { key: i },
                                                                    [
                                                                      _c("td", [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            product.name ||
                                                                              product.id
                                                                          )
                                                                        )
                                                                      ]),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c("td", [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            product.quantity
                                                                          )
                                                                        )
                                                                      ]),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c("td", [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm.$formatCurrency(
                                                                              product.price *
                                                                                product.quantity
                                                                            )
                                                                          )
                                                                        )
                                                                      ])
                                                                    ]
                                                                  )
                                                                }
                                                              ),
                                                              _vm._v(" "),
                                                              _c("tr", [
                                                                _c(
                                                                  "td",
                                                                  {
                                                                    staticClass:
                                                                      "text-right body-1",
                                                                    attrs: {
                                                                      colspan:
                                                                        " 2"
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "Total"
                                                                    )
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "td",
                                                                  {
                                                                    staticClass:
                                                                      "grey lighten-5"
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "b",
                                                                      {
                                                                        staticClass:
                                                                          "primary--text subtitle"
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm.$formatCurrency(
                                                                              item.total_paid
                                                                            )
                                                                          )
                                                                        )
                                                                      ]
                                                                    )
                                                                  ]
                                                                )
                                                              ])
                                                            ],
                                                            2
                                                          )
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  3571155009
                                )
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.totalPage
                            ? _c(
                                "v-row",
                                { staticClass: "pa-0" },
                                [
                                  _c("v-spacer"),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "text-center",
                                      attrs: { cols: "12", md: "6" }
                                    },
                                    [
                                      _c("v-pagination", {
                                        attrs: {
                                          length: _vm.totalPage,
                                          "prev-icon": "fas fa-angle-left",
                                          "next-icon": "fas fa-angle-right",
                                          "total-visible": "6"
                                        },
                                        on: { input: _vm.loadOrders },
                                        model: {
                                          value: _vm.page,
                                          callback: function($$v) {
                                            _vm.page = $$v
                                          },
                                          expression: "page"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass:
                                        "d-flex align-center justify-end",
                                      attrs: { cols: "12", md: "3" }
                                    },
                                    [
                                      _c(
                                        "h5",
                                        { staticClass: "font-weight-thin" },
                                        [
                                          _vm._v(
                                            "Total de órdenes: " +
                                              _vm._s(_vm.totalOrders)
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        : _c("v-container", [_c("ShowLoading")], 1)
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }