import Vue from "vue";
import * as MutationsType from "./mutationsType";

export default {
  [MutationsType.SET_WEBHOOKS](state, payload) {
    state.webhooks = payload;
  },
  [MutationsType.ADD_WEBHOOK](state, payload) {
    state.webhooks.push(payload);
  },
  [MutationsType.UPDATE_WEBHOOK](state, payload) {
    let ind = (state.webhooks || []).findIndex((t) => t.id === payload.id);

    if (ind >= 0) {
      let webhook = state.webhooks[ind];
      webhook.active = payload.active;

      Vue.set(state.webhooks, ind, { id: payload.id, ...webhook });
    }
  },
  [MutationsType.DELETE_WEBHOOK](state, payload) {
    let ind = state.webhooks.findIndex((t) => t.id === payload);
    Vue.delete(state.webhooks, ind);
  },
};
