import * as MutationsType from "./mutationsType";
import requestAxios from "@/helpers/request_axios";
import { segmentsCollection, segmentsDownloadsCollection, segmentsAutomationsCollection } from "@/config/firebase";
import { roles } from "@/helpers/get_constants";

export default {
  async getAllSegments({ commit, rootState }) {
    const agent = rootState.Agent.agent;
    let reference = segmentsCollection;

    if (agent.role === roles.ENTREPRENEUR) reference = reference.where("team_id", "==", agent.team_id);

    let segments = [];
    let snap = await reference.orderBy("created_at", "desc").get();

    if (!snap.empty)
      snap.forEach((doc) => {
        segments.push({ id: doc.id, ...doc.data() });
      });

    commit(MutationsType.SET_SEGMENTS, segments);
  },
  // eslint-disable-next-line
  async createSegment({ commit }, payload) {
    try {
      let response = await requestAxios({
        url: "/awy/api/v1/segments",
        method: "POST",
        data: payload,
      });
      return response.data;
    } catch (error) {
      console.error("createSegment: ", error);
      return error.response.data;
    }
  },
  async updateSegment({ commit }, payload) {
    try {
      let response = await requestAxios({
        url: `/awy/api/v1/segments/${payload.id}`,
        method: "PUT",
        data: payload,
      });
      commit(MutationsType.UPDATE_SEGMENT, response.data.data);
      return response.data;
    } catch (error) {
      console.error("updateSegment: ", error);
      throw error.response.data;
    }
  },
  // eslint-disable-next-line
  async removeSegment({ commit }, { id }) {
    try {
      let response = await requestAxios({
        url: `/awy/api/v1/segments/${id}`,
        method: "DELETE",
      });
      commit(MutationsType.REMOVE_SEGMENT, id);
      return response.data;
    } catch (error) {
      console.error("removeSegment: ", error);
      throw error.response.data;
    }
  },
  // eslint-disable-next-line
  async downloadSegmentResults({}, { id }) {
    try {
      let response = await requestAxios({
        url: `/awy/api/v1/segments/${id}/downloads`,
        method: "POST",
      });
      return response.data;
    } catch (error) {
      console.error("downloadSegmentResults: ", error);
      throw error.response.data;
    }
  },
  async syncSegmentResults({ commit }, { id }) {
    try {
      let response = await requestAxios({
        url: `/awy/api/v1/segments/${id}/sync`,
        method: "POST",
      });
      commit(MutationsType.UPDATE_SEGMENT, response.data.data);
      return response.data;
    } catch (error) {
      console.error("syncSegmentResults: ", error);
      throw error.response.data;
    }
  },
  // eslint-disable-next-line
  async sendWhatsAppMessageToSegmentResults({}, { id, message }) {
    try {
      let response = await requestAxios({
        url: `/awy/api/v1/segments/${id}/send-whatsapp-message`,
        method: "POST",
        data: { message },
      });
      return response.data;
    } catch (error) {
      console.error("sendWhatsAppMessageToSegmentResults: ", error);
      throw error.response.data;
    }
  },
  async getAllSegmentsDownloads({ commit, rootState, state }) {
    const agent = rootState.Agent.agent;
    let reference = segmentsDownloadsCollection;

    if (roles.ENTREPRENEUR === agent.role) reference = reference.where("team_id", "==", agent.team_id);

    const unsubscribe = await reference.orderBy("created_at", "asc").onSnapshot(async (snapshot) => {
      if (!state.segmentsDownloads) commit(MutationsType.SET_SEGMENTS_DOWNLOADS, []);

      snapshot.docChanges().forEach((change) => {
        if (change.type === "added")
          commit(MutationsType.ADD_SEGMENT_DOWNLOAD, { ...change.doc.data(), id: change.doc.id });

        if (change.type === "modified")
          commit(MutationsType.UPDATE_SEGMENT_DOWNLOAD, { data: change.doc.data(), id: change.doc.id });

        if (change.type === "removed") commit(MutationsType.REMOVE_SEGMENT_DOWNLOAD, { id: change.doc.id });
      });
    });

    commit(MutationsType.UNSUBSCRIBE_GET_ALL_SEGMENTS_DOWNLOADS, unsubscribe);
  },
  async unsubscribeGetAllSegmentsDownloads({ commit, state }) {
    state.unsubscribeAllSegmentsDownloads();
    commit(MutationsType.SET_SEGMENTS_DOWNLOADS, null);
    commit(MutationsType.UNSUBSCRIBE_GET_ALL_SEGMENTS_DOWNLOADS, null);
  },
  async getAllSegmentsAutomations({ commit, rootState, state }) {
    const agent = rootState.Agent.agent;
    let reference = segmentsAutomationsCollection;

    if (roles.ENTREPRENEUR === agent.role) reference = reference.where("team_id", "==", agent.team_id);

    const unsubscribe = await reference.orderBy("created_at", "asc").onSnapshot(async (snapshot) => {
      if (!state.segmentsAutomations) commit(MutationsType.SET_SEGMENTS_AUTOMATIONS, []);

      snapshot.docChanges().forEach((change) => {
        if (change.type === "added")
          commit(MutationsType.ADD_SEGMENT_AUTOMATION, { ...change.doc.data(), id: change.doc.id });

        if (change.type === "modified")
          commit(MutationsType.UPDATE_SEGMENT_AUTOMATION, { data: change.doc.data(), id: change.doc.id });

        if (change.type === "removed") commit(MutationsType.REMOVE_SEGMENT_AUTOMATION, { id: change.doc.id });
      });
    });

    commit(MutationsType.UNSUBSCRIBE_GET_ALL_SEGMENTS_AUTOMATIONS, unsubscribe);
  },
  async unsubscribeGetAllSegmentsAutomations({ commit, state }) {
    state.unsubscribeAllSegmentsAutomations();
    commit(MutationsType.SET_SEGMENTS_AUTOMATIONS, null);
    commit(MutationsType.UNSUBSCRIBE_GET_ALL_SEGMENTS_AUTOMATIONS, null);
  },
};
