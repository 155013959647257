var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "pt-0 pb-0" },
    [
      _c(
        "v-row",
        { staticClass: "justify-end pr-3" },
        [
          _c(
            "v-dialog",
            {
              attrs: { persistent: "", "max-width": "500" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function(ref) {
                    var on = ref.on
                    return [
                      _c(
                        "v-btn",
                        _vm._g(
                          {
                            attrs: {
                              "x-small": "",
                              color: "primary",
                              outlined: "",
                              dark: ""
                            },
                            on: {
                              click: function($event) {
                                _vm.dialog = true
                              }
                            }
                          },
                          on
                        ),
                        [_vm._v("Administrar etiquetas")]
                      )
                    ]
                  }
                }
              ]),
              model: {
                value: _vm.dialog,
                callback: function($$v) {
                  _vm.dialog = $$v
                },
                expression: "dialog"
              }
            },
            [
              _vm._v(" "),
              _c(
                "v-card",
                [
                  _c(
                    "v-card-title",
                    { staticClass: "title px-2" },
                    [
                      _c("h5", { staticClass: "pl-2" }, [_vm._v("ETIQUETAS")]),
                      _vm._v(" "),
                      _c("v-spacer"),
                      _vm._v(" "),
                      [
                        _vm.$roles.SUPER_ADMIN,
                        _vm.$roles.ADMIN,
                        _vm.$roles.ENTREPRENEUR
                      ].includes(_vm.getAgent.role) &&
                      !["create", "update"].includes(_vm.action)
                        ? _c(
                            "v-btn",
                            {
                              staticClass: "mr-2",
                              attrs: {
                                small: "",
                                rounded: "",
                                color: "primary"
                              },
                              on: { click: _vm.create }
                            },
                            [_vm._v("\n            Nueva etiqueta\n          ")]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          attrs: { icon: "" },
                          on: {
                            click: function($event) {
                              _vm.dialog = false
                            }
                          }
                        },
                        [
                          _c("v-icon", { attrs: { small: "" } }, [
                            _vm._v("fa fa-times")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-card-text",
                    { staticClass: "pa-5" },
                    [
                      _c(
                        "v-row",
                        [
                          _vm.openForm
                            ? _c(
                                "v-col",
                                { staticClass: "pb-5", attrs: { cols: "12" } },
                                [
                                  _c(
                                    "v-form",
                                    {
                                      ref: "formTag",
                                      attrs: { "lazy-validation": "" }
                                    },
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "py-0",
                                          attrs: { cols: "12" }
                                        },
                                        [
                                          _c("h3", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.action == "create"
                                                  ? "Nueva etiqueta"
                                                  : "Editar etiqueta"
                                              )
                                            )
                                          ])
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              label: "Nombre",
                                              placeholder: " ",
                                              rules: _vm.rules.name
                                            },
                                            model: {
                                              value: _vm.tag.name,
                                              callback: function($$v) {
                                                _vm.$set(_vm.tag, "name", $$v)
                                              },
                                              expression: "tag.name"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "d-flex",
                                          attrs: { cols: "12" }
                                        },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              label: "Abreviación",
                                              hint: "Para acciones automáticas",
                                              placeholder: " ",
                                              rules:
                                                _vm.rules.noRepeatTagHashtag
                                            },
                                            model: {
                                              value: _vm.tag.hashtag,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.tag,
                                                  "hashtag",
                                                  $$v
                                                )
                                              },
                                              expression: "tag.hashtag"
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "v-alert",
                                            {
                                              staticClass: "my-auto ml-3",
                                              attrs: {
                                                dense: "",
                                                text: "",
                                                color: "primary"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " #l" +
                                                  _vm._s(_vm.tag.hashtag) +
                                                  " "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "d-flex justify-center",
                                          attrs: { cols: "12" }
                                        },
                                        [
                                          _c("v-color-picker", {
                                            attrs: {
                                              rules: _vm.rules.required,
                                              "hide-inputs": "",
                                              "dot-size": "12",
                                              type: "hex",
                                              "canvas-height": "100"
                                            },
                                            model: {
                                              value: _vm.tag.color,
                                              callback: function($$v) {
                                                _vm.$set(_vm.tag, "color", $$v)
                                              },
                                              expression: "tag.color"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c("v-col", { attrs: { cols: "12" } }, [
                                        _c("span", [
                                          _vm._v("Previsualización:")
                                        ]),
                                        _vm._v(" "),
                                        _c("br"),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "text-center py-3" },
                                          [
                                            _c(
                                              "v-chip",
                                              {
                                                attrs: { color: _vm.tag.color }
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.tag.name || "etiqueta"
                                                  )
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "text-right",
                                          attrs: { cols: "12" }
                                        },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                loading: _vm.loading,
                                                color: "primary",
                                                rounded: ""
                                              },
                                              on: { click: _vm.saveTag }
                                            },
                                            [
                                              _vm._v(
                                                "\n                    Guardar\n                  "
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                color: "gray darken-1",
                                                text: "",
                                                rounded: ""
                                              },
                                              on: { click: _vm.resetTagForm }
                                            },
                                            [_vm._v("Cancelar")]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.getActiveTags.length > 0
                        ? _c("v-simple-table", {
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function() {
                                    return [
                                      _c("thead", [
                                        _c("tr", [
                                          _c(
                                            "th",
                                            { staticClass: "text-left" },
                                            [
                                              _vm._v(
                                                "\n                    Etiqueta\n                  "
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "th",
                                            { staticClass: "text-center" },
                                            [
                                              _vm._v(
                                                "\n                    Abreviación\n                  "
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          [
                                            _vm.$roles.SUPER_ADMIN,
                                            _vm.$roles.ADMIN,
                                            _vm.$roles.ENTREPRENEUR
                                          ].includes(_vm.getAgent.role)
                                            ? _c(
                                                "th",
                                                { staticClass: "text-right" },
                                                [
                                                  _vm._v(
                                                    "\n                    Operaciones\n                  "
                                                  )
                                                ]
                                              )
                                            : _vm._e()
                                        ])
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "tbody",
                                        _vm._l(_vm.getActiveTags, function(
                                          t,
                                          i
                                        ) {
                                          return _c("tr", { key: i }, [
                                            _c(
                                              "td",
                                              [
                                                _c(
                                                  "v-chip",
                                                  {
                                                    attrs: {
                                                      small: "",
                                                      color: t.color
                                                    }
                                                  },
                                                  [_vm._v(_vm._s(t.name))]
                                                )
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "td",
                                              { staticClass: "text-center" },
                                              [_vm._v(_vm._s(t.hashtag))]
                                            ),
                                            _vm._v(" "),
                                            [
                                              _vm.$roles.SUPER_ADMIN,
                                              _vm.$roles.ADMIN,
                                              _vm.$roles.ENTREPRENEUR
                                            ].includes(_vm.getAgent.role)
                                              ? _c(
                                                  "td",
                                                  { staticClass: "text-right" },
                                                  [
                                                    _vm.auxTag.id != t.id
                                                      ? _c(
                                                          "v-icon",
                                                          {
                                                            staticClass: "pr-4",
                                                            attrs: {
                                                              "x-small": ""
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.update(
                                                                  t
                                                                )
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              "fas fa-pencil-alt"
                                                            )
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                    _vm._v(" "),
                                                    _c(
                                                      "v-icon",
                                                      {
                                                        attrs: {
                                                          "x-small": ""
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.remove(t)
                                                          }
                                                        }
                                                      },
                                                      [_vm._v("fa fa-trash")]
                                                    )
                                                  ],
                                                  1
                                                )
                                              : _vm._e()
                                          ])
                                        }),
                                        0
                                      )
                                    ]
                                  },
                                  proxy: true
                                }
                              ],
                              null,
                              false,
                              2064311777
                            )
                          })
                        : _c("h4", { staticClass: "text-center pa-2" }, [
                            _vm._v("No hay etiquetas")
                          ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.auxTag
        ? _c(
            "v-dialog",
            {
              attrs: { persistent: "", "max-width": "400" },
              model: {
                value: _vm.dialogDelete,
                callback: function($$v) {
                  _vm.dialogDelete = $$v
                },
                expression: "dialogDelete"
              }
            },
            [
              _c(
                "v-card",
                { staticClass: "py-3" },
                [
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "v-row",
                        [
                          _c("v-col", { attrs: { cols: "12" } }, [
                            _c("h3", { staticClass: "font-weight-thin" }, [
                              _vm._v(
                                "\n              ¿Está seguro que\n              "
                              ),
                              _c("span", { staticClass: "font-weight-bold" }, [
                                _vm._v(
                                  'desea eliminar la etiqueta "' +
                                    _vm._s(_vm.auxTag.name) +
                                    '"'
                                )
                              ]),
                              _vm._v("?.\n            ")
                            ])
                          ]),
                          _vm._v(" "),
                          _vm.errorFormDelete
                            ? _c(
                                "v-col",
                                { attrs: { cols: "12" } },
                                [
                                  _c(
                                    "v-alert",
                                    {
                                      attrs: {
                                        dense: "",
                                        text: "",
                                        type: "error"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(_vm.errorFormDelete) +
                                          "\n            "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-card-actions",
                    { staticClass: "mr-2" },
                    [
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          attrs: { rounded: "", text: "" },
                          on: {
                            click: function($event) {
                              _vm.resetTagForm()
                              _vm.dialogDelete = false
                            }
                          }
                        },
                        [_vm._v("Cancelar")]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            loading: _vm.loading,
                            color: "red",
                            rounded: "",
                            dark: ""
                          },
                          on: { click: _vm.deleteTag }
                        },
                        [_vm._v("Eliminar")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }