var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", scrollable: "", "max-width": "600" },
      model: {
        value: _vm.dialog,
        callback: function($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _c(
        "v-card",
        { staticClass: "py-2" },
        [
          _c(
            "v-card-title",
            { staticClass: "headline d-flex" },
            [
              _c("span", { staticClass: "ml-0" }, [
                _vm._v("Mensaje masivo por WhatsApp")
              ]),
              _vm._v(" "),
              _c("v-spacer"),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  attrs: { icon: "" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("update:dialog", false)
                    }
                  }
                },
                [
                  _c("v-icon", { attrs: { small: "" } }, [
                    _vm._v("fas fa-times")
                  ])
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card-text",
            [
              _c(
                "v-form",
                { ref: "formMassiveMessage", attrs: { "lazy-validation": "" } },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "8" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              label: "Segmento",
                              value: _vm.segment.name,
                              readonly: ""
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "4" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              label: "Plantilla",
                              value: "-",
                              disabled: "",
                              readonly: ""
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c("v-textarea", {
                            staticClass: "expand",
                            attrs: {
                              rules: [_vm.$rules.REQUIRED],
                              label: "Mensaje",
                              placeholder: "Escribe tu mensaje aquí",
                              "auto-grow": "",
                              rows: "3"
                            },
                            model: {
                              value: _vm.message,
                              callback: function($$v) {
                                _vm.message = $$v
                              },
                              expression: "message"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.formErrorMessage
                        ? _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c(
                                "v-alert",
                                {
                                  attrs: { dense: "", text: "", type: "error" }
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.formErrorMessage) +
                                      "\n            "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        {
                          staticClass: "text-right pb-0",
                          attrs: { cols: "12" }
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { rounded: "", text: "" },
                              on: {
                                click: function($event) {
                                  return _vm.$emit("update:dialog", false)
                                }
                              }
                            },
                            [_vm._v("Cancelar")]
                          ),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              staticClass: "my-auto ml-5",
                              attrs: {
                                rounded: "",
                                color: "primary",
                                loading: _vm.loading
                              },
                              on: { click: _vm.onSendMessage }
                            },
                            [_vm._v("Enviar mensaje masivo")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }