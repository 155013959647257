import Vue from "vue";
import * as MutationsType from "./mutationsType";

export default {
  [MutationsType.SET_TAGS](state, payload) {
    state.tags = payload;
  },
  [MutationsType.ADD_TAG](state, payload) {
    state.tags.unshift(payload);
  },
  [MutationsType.UPDATE_TAG](state, payload) {
    let ind = state.tags.findIndex((t) => {
      return t.id == payload.id;
    });
    if (ind >= 0) Vue.set(state.tags, ind, { ...state.tags[ind], ...payload });
  },
  [MutationsType.REMOVE_TAG](state, payload) {
    let ind = state.tags.findIndex((t) => t.id === payload.id);
    Vue.delete(state.tags, ind);
  },
};
