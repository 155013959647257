import Vue from "vue";
import * as MutationsType from "./mutationsType";

export default {
  [MutationsType.SET_TICKET](state, payload) {
    state.ticket = payload;
  },
  [MutationsType.SET_TICKETS](state, payload) {
    state.tickets = payload;
  },
  [MutationsType.SET_NEW_TICKETS](state, payload) {
    state.newTickets = payload;
  },
  [MutationsType.ADD_TICKET](state, payload) {
    state.tickets.push(payload);
  },
  [MutationsType.UPDATE_TICKET](state, payload) {
    let ind = (state.tickets || []).findIndex((t) => t.id === payload.id);
    if (ind >= 0) Vue.set(state.tickets, ind, payload.value || { ...state.tickets[ind], ...payload.add_value });
  },
  [MutationsType.SET_TICKET_STATE](state, payload) {
    state.ticket.state = payload;
  },
  [MutationsType.SHOW_TICKET_DETAIL](state, payload) {
    state.ticketId = payload;
    state.showTicketDetailDrawer = !state.showTicketDetailDrawer;
  },
  [MutationsType.HIDE_TICKET_DETAIL](state) {
    state.ticketId = null;
    state.showTicketDetailDrawer = false;
  },
  [MutationsType.SET_TICKET_DOWNLOADS](state, payload) {
    state.ticketsDownloads = payload;
  },
  [MutationsType.ADD_TICKET_DOWNLOAD](state, payload) {
    state.ticketsDownloads.unshift(payload);
  },
  [MutationsType.UPDATE_TICKET_DOWNLOAD](state, payload) {
    let ind = (state.ticketsDownloads || []).findIndex((t) => t.id === payload.id);
    if (ind >= 0) Vue.set(state.ticketsDownloads, ind, { ...state.ticketsDownloads[ind], ...payload.data });
  },
  [MutationsType.REMOVE_TICKET_DOWNLOAD](state, payload) {
    let ind = state.ticketsDownloads.findIndex((t) => t.id === payload.id);
    if (ind >= 0) Vue.delete(state.ticketsDownloads, ind);
  },
};
