import * as MutationsType from "./mutationsType";

export default {
  [MutationsType.SET_ORDER](state, payload) {
    state.order = payload;
  },
  [MutationsType.SET_ORDERS](state, payload) {
    state.orders = payload;
  },
  [MutationsType.ADD_ORDER](state, payload) {
    state.orders.push(payload);
  },
  [MutationsType.UPDATE_ORDER](state, payload) {
    state.order = { ...state.order, ...payload };
  },
};
