<template>
  <v-app id="segments">
    <v-container v-if="getAgent && getAgents && getSegments" class="pt-4 pt-sm-10">
      <v-row class="d-flex align-center">
        <v-col cols="12" sm="6" md="9">
          <p class="text-h4 primary--text mb-0 font-weight-black hidden-sm-and-down">Segmentos</p>
        </v-col>
        <v-col cols="12" sm="6" md="3" class="text-right">
          <v-menu offset-y bottom left>
            <template v-slot:activator="{ on }">
              <v-btn outlined icon v-on="on">
                <v-icon size="16">fa fa-ellipsis-v</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item :to="{ name: 'segment_downloads' }">
                <v-list-item-title>Descargas</v-list-item-title>
              </v-list-item>
              <v-list-item :to="{ name: 'segment_automations' }">
                <v-list-item-title>Automatizaciones</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="px-4 hidden-md-and-up">
          <v-btn :to="{ name: 'segment_register' }" color="primary" elevation-12 block>
            <span> <v-icon size="16" class="mr-2">fa-plus</v-icon> Nuevo segmento </span>
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-card class="pa-4 mb-5 rounded-lg">
            <v-data-table
              :headers="headers"
              :items="getSegments"
              show-expand
              sort-by
              dense
              :items-per-page="40"
              hide-default-footer
            >
              <template v-slot:item.module="{ item }">
                {{ modulesName[item.module] }}
              </template>
              <template v-slot:item.filters="{ item }">
                {{ item.filters.map((a) => a.label).join(", ") }}
              </template>
              <template v-slot:item.columns="{ item }">
                {{ item.columns.map((a) => a.header).join(", ") }}
              </template>
              <template v-slot:item.operations="{ item }">
                <div :style="{ width: '100px' }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn text icon v-on="on" @click="editSegment(item.id)">
                        <v-icon color="primary" dark small>far fa-edit</v-icon>
                      </v-btn>
                    </template>
                    <span>Editar segmento</span>
                  </v-tooltip>
                  <v-menu offset-y bottom left>
                    <template v-slot:activator="{ on }">
                      <v-btn small icon v-on="on">
                        <v-icon small>fa fa-ellipsis-v</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item @click="onDownload(item.id)">
                        <v-list-item-title>Descargar</v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="deleteSegment(item.id)">
                        <v-list-item-title>Eliminar</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </div>
              </template>
              <template v-slot:expanded-item="{ item }">
                <td :colspan="headers.length + 1" class="px-8 py-5">
                  <v-row>
                    <v-col cols="12" sm="8" md="9">
                      <v-row>
                        <v-col cols="12" sm="6">
                          <span class="grey--text text--darken-2">ID Segmento:</span>
                          {{ item.id }}
                        </v-col>
                        <v-col cols="12" sm="6">
                          <span class="grey--text text--darken-2">Generado por:</span>
                          {{ item.created_uid ? getAllAgentsMap[item.created_uid].full_name : "-" }}
                        </v-col>
                        <v-col cols="12">
                          <h5 class="body-1 my-2">{{ item.name }}</h5>
                        </v-col>
                        <v-col cols="12" sm="8" class="py-0">
                          <SegmentDetail :segment="item" />
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="12" sm="4" md="3">
                      <v-row>
                        <v-col cols="12">
                          <span class="grey--text text--darken-2">Última actualización:</span>
                          {{ item.updated_at ? $formatTimestamp(item.updated_at) : "Aún no se ha ejecutado" }}
                        </v-col>
                        <v-col cols="12" class="d-flex">
                          <h5 class="body-1 mr-2">{{ item.counter || 0 }}</h5>
                          <h6 class="caption my-auto">registros</h6>
                        </v-col>
                        <v-col cols="12">
                          <v-btn
                            :loading="loadingToSync && segmentSelected && segmentSelected.id === item.id"
                            color="primary"
                            rounded
                            small
                            block
                            @click="onSync(item.id)"
                          >
                            <v-icon dark x-small class="mr-2">fas fa-sync-alt</v-icon> Actualizar segmento
                          </v-btn>
                        </v-col>
                        <v-col cols="12" class="mt-5">
                          <span class="grey--text text--darken-2">Acciones disponibles:</span>
                        </v-col>
                        <v-col cols="12">
                          <v-btn
                            :loading="loadingToDownload && segmentSelected && segmentSelected.id === item.id"
                            color="accent"
                            rounded
                            small
                            block
                            @click="onDownload(item.id)"
                          >
                            <v-icon dark x-small class="mr-2">fas fa-download</v-icon> Descargar resultados
                          </v-btn>
                          <v-btn
                            v-if="item.columns.find((c) => c.key === 'phone')"
                            :loading="loadingToSendWhatsApp && segmentSelected && segmentSelected.id === item.id"
                            color="#25d366"
                            rounded
                            small
                            dark
                            block
                            class="mt-3"
                            @click="sendMessageOnWhatsApp(item.id)"
                          >
                            <v-icon dark x-small class="mr-2">fab fa-whatsapp</v-icon> Enviar mensaje masivo
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </td>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>

      <v-btn
        :to="{ name: 'segment_register' }"
        fixed
        bottom
        right
        x-large
        color="primary"
        elevation-12
        rounded
        class="hidden-sm-and-down"
      >
        <span> <v-icon size="16" class="mr-2">fa-plus</v-icon> Nuevo segmento </span>
      </v-btn>

      <!-- modals -->
      <EditSegmentModal
        v-if="dialogEditSegment && segmentSelected"
        :dialog.sync="dialogEditSegment"
        :segment.sync="segmentSelected"
      />
      <SendWhatsAppMessageModal
        v-if="dialogSendWhatsAppMessage && segmentSelected"
        :dialog.sync="dialogSendWhatsAppMessage"
        :segment.sync="segmentSelected"
      />
      <v-dialog v-if="segmentSelected" v-model="dialogDeleteSegment" persistent max-width="400">
        <v-card class="py-3">
          <v-card-text>
            <v-form ref="formDeleteSegment">
              <v-row>
                <v-col cols="12">
                  <h3 class="font-weight-thin">
                    ¿Está seguro que
                    <span class="font-weight-bold"> desea eliminar el segmento '{{ segmentSelected.name }}'</span>
                    ?
                  </h3>
                </v-col>
                <v-col v-if="errorFormDeleteSegment" cols="12">
                  <v-alert dense text type="error">
                    {{ errorFormDeleteSegment }}
                  </v-alert>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions class="mr-2">
            <v-spacer></v-spacer>
            <v-btn rounded text @click="dialogDeleteSegment = false">Cancelar</v-btn>
            <v-btn :loading="loadingToDelete" color="red" rounded dark @click="onDelete">
              Eliminar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
    <v-container v-else>
      <ShowLoading />
    </v-container>
  </v-app>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import EditSegmentModal from "./modals/EditSegment";
import SendWhatsAppMessageModal from "./modals/SendWhatsAppMessage";
import SegmentDetail from "./components/SegmentDetail";

export default {
  name: "Segments",
  components: { EditSegmentModal, SendWhatsAppMessageModal, SegmentDetail },
  data() {
    return {
      segments: [],
      segmentSelected: null,
      modulesName: {
        tickets: "Oportunidades",
        leads: "Contactos",
        orders: "Órdenes",
      },
      headers: [
        {
          text: "Nombre",
          align: "left",
          sortable: false,
          value: "name",
        },
        {
          text: "Módulo",
          align: "center",
          value: "module",
          sortable: false,
        },
        {
          text: "Filtros",
          align: "center",
          sortable: false,
          value: "filters",
        },
        {
          text: "Columnas",
          align: "center",
          sortable: false,
          value: "columns",
        },
        {
          text: "Operación",
          align: "center",
          sortable: false,
          value: "operations",
        },
      ],
      dialogEditSegment: false,
      dialogDeleteSegment: false,
      dialogSendWhatsAppMessage: false,
      errorFormDeleteSegment: null,
      loadingToDownload: false,
      loadingToDelete: false,
      loadingToSync: false,
      loadingToSendWhatsApp: false,
    };
  },
  computed: {
    ...mapGetters(["getAgent", "getAgents", "getAllAgentsMap", "getSegments"]),
  },
  watch: {
    getAgent(value) {
      if (value) this.init();
    },
  },
  created() {
    if (this.getAgent) this.init();
  },
  methods: {
    ...mapActions(["getAllAgents", "getAllSegments", "downloadSegmentResults", "syncSegmentResults", "removeSegment"]),
    async init() {
      if (!this.getAgents) await this.getAllAgents();

      await this.getAllSegments();
    },
    editSegment(segmentId) {
      let segment = this.getSegments.find((c) => c.id === segmentId);
      this.segmentSelected = {
        ...segment,
        filters: [...segment.filters.map((f) => ({ ...f }))],
        columns: [...segment.columns],
        id: segmentId,
      };
      this.dialogEditSegment = true;
    },
    deleteSegment(segmentId) {
      this.segmentSelected = { ...this.getSegments.find((c) => c.id === segmentId) };
      this.dialogDeleteSegment = true;
    },
    async onDelete() {
      try {
        this.loadingToDelete = true;

        await this.removeSegment({ id: this.segmentSelected.id });

        this.dialogDeleteSegment = false;
      } catch (error) {
        this.errorFormDeleteSegment = error.message || "Ocurrió un error al eliminar segmento";
        console.error(error);
      } finally {
        this.loadingToDelete = false;
      }
    },
    async onDownload(segmentId) {
      try {
        this.loadingToDownload = true;
        this.segmentSelected = { id: segmentId };

        let response = await this.downloadSegmentResults({ id: segmentId });
        if (response.data) this.$router.replace({ name: "segment_downloads" });
      } catch (error) {
        console.error("Segments - download: ", error);
      } finally {
        this.loadingToDownload = false;
      }
    },
    async onSync(segmentId) {
      try {
        this.loadingToSync = true;
        this.segmentSelected = { id: segmentId };

        await this.syncSegmentResults({ id: segmentId });
      } catch (error) {
        console.error("Segments - sync: ", error);
      } finally {
        this.loadingToSync = false;
      }
    },
    async sendMessageOnWhatsApp(segmentId) {
      let segment = this.getSegments.find((c) => c.id === segmentId);
      this.segmentSelected = {
        ...segment,
        filters: [...segment.filters.map((f) => ({ ...f }))],
        columns: [...segment.columns],
        id: segmentId,
      };
      this.dialogSendWhatsAppMessage = true;
    },
  },
};
</script>
