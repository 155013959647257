import * as MutationsType from "./mutationsType";
import requestAxios from "@/helpers/request_axios";
import { webhooksCollection } from "@/config/firebase";

export default {
  async getAllWebhooks({ commit }) {
    commit(MutationsType.SET_WEBHOOKS, []);

    let snap = await webhooksCollection.orderBy("created_at", "desc").get();
    if (!snap.empty) {
      let webhooks = [];

      snap.forEach((doc) => {
        webhooks.push({ id: doc.id, ...doc.data() });
      });

      commit(MutationsType.SET_WEBHOOKS, webhooks);
    }
  },
  async connectWebhook({ commit }, payload) {
    try {
      let response = await requestAxios({
        url: "/awy/api/v1/webhook/config",
        method: "POST",
        data: {
          type: payload.webhook_type,
          ...payload.data,
        },
      });

      commit(MutationsType.ADD_WEBHOOK, { id: payload.webhook_type, ...response.data.data });
      return response.data;
    } catch (error) {
      console.error("webhook/connectWebhook: ", error);
      return error.response.data;
    }
  },
  async enableWebhook({ commit }, payload) {
    try {
      let response = await requestAxios({
        url: "/awy/api/v1/webhook/config",
        method: "PATCH",
        data: {
          mode: "update",
          type: payload.webhook_type,
        },
      });

      commit(MutationsType.UPDATE_WEBHOOK, { id: payload.webhook_type, active: response.data.data.active });
      return response.data;
    } catch (error) {
      console.error("webhook/enableWebhook: ", error);
      return error.response.data;
    }
  },
  async deleteWebhook({ commit }, payload) {
    try {
      let response = await requestAxios({
        url: "/awy/api/v1/webhook/config",
        method: "PATCH",
        data: {
          mode: "delete",
          type: payload.webhook_type,
        },
      });

      commit(MutationsType.DELETE_WEBHOOK, payload.webhook_type);
      return response.data;
    } catch (error) {
      console.error("webhook/deleteWebhook: ", error);
      return error.response.data;
    }
  },
};
