<template>
  <v-btn :href="`https://wa.me/${getPhone}`" target="_blank" text icon @click.stop>
    <v-icon dark small>fab fa-whatsapp</v-icon>
  </v-btn>
</template>
<script>
export default {
  props: {
    number: {
      type: String,
      default: "",
    },
  },
  computed: {
    getPhone() {
      let phone = this.number?.replaceAll(" ", "");
      if (this.number.includes("+")) return phone;
      return `+51${phone}`;
    },
  },
};
</script>
