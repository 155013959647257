function getRandomColor() {
  const alphabet = "0123456789ABCDEF";
  let color = "#";
  for (var i = 0; i < 6; i++) {
    color += alphabet[Math.floor(Math.random() * 16)];
  }
  return color;
}

export default getRandomColor;
