<template>
  <div>
    <v-list-item key="teams" @click.stop="toggle = !toggle">
      <v-list-item-avatar>
        <v-icon>fas fa-users</v-icon>
      </v-list-item-avatar>
      <v-list-item-content class="py-4">
        <v-list-item-title>Equipos y agentes</v-list-item-title>
        <v-list-item-subtitle>Organiza a tus trabajadores en equipos para una mayor productividad</v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action>
        <v-icon>
          {{ !toggle ? "fas fa-angle-down" : "fas fa-angle-up" }}
        </v-icon>
      </v-list-item-action>
    </v-list-item>
    <v-container v-if="toggle" id="tickets">
      <v-col cols="12" class="">
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header expand-icon="fas fa-angle-down">
              Equipos
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <Teams />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-container>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import Teams from "@/views/settings/teams-agents/Teams";

export default {
  name: "TeamsAgents",
  components: {
    Teams,
  },
  data() {
    return {
      toggle: false,
    };
  },
  async created() {
    await this.getAllAgents();
    await this.getAllTeams();
  },
  methods: {
    ...mapActions(["getAllTeams", "getAllAgents"]),
  },
};
</script>
