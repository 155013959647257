<template>
  <v-card>
    <v-col cols="12" class="grey--text text--darken-3 font-weight-bold pa-4 pb-0 d-flex">
      <span class="w100">
        <span v-if="editProducts">EDITAR</span>
        PRODUCTOS RELACIONADOS
      </span>
      <div v-if="allowUpdateItemsProducts" class="text-right">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn icon small v-on="on" @click="addItemProduct">
              <v-icon small>fas fa-plus</v-icon>
            </v-btn>
          </template>
          <span>Agregar producto</span>
        </v-tooltip>
      </div>
    </v-col>
    <v-col cols="12">
      <v-form ref="formProducts" lazy-validation>
        <ItemsProducts :products.sync="products" :action.sync="actionItemsProducts" :format-one-row="true" />
        <v-col v-if="editProducts" cols="12" class="text-center">
          <v-btn :loading="loadingSave" small rounded color="primary" @click="saveOrderProducts">Guardar</v-btn>
          <v-btn small rounded @click="cancelEdit">Cancelar</v-btn>
        </v-col>
      </v-form>
    </v-col>
  </v-card>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import ItemsProducts from "@/components/items/Products";

export default {
  name: "OrderCardProducts",
  components: {
    ItemsProducts,
  },
  data() {
    return {
      loadingSave: false,
      products: null,
      productsToSelect: [],
      resetProducts: false,
      editProducts: false,
    };
  },
  computed: {
    ...mapGetters(["getOrder", "getProductsMap"]),
    allowUpdateItemsProducts() {
      return this.getOrder.state !== this.$orderStates.TERMINADO;
    },
    actionItemsProducts() {
      return this.allowUpdateItemsProducts ? "update" : "read";
    },
  },
  watch: {
    "getOrder.state"(value) {
      if (value === this.$orderStates.TERMINADO) {
        this.resetProducts = true;
        this.editProducts = false;
        this.products = this.getOrder.products.map((p) => ({ ...p }));
      }
    },
    products: {
      deep: true,
      handler(value, oldValue) {
        if (oldValue && this.allowUpdateItemsProducts && !this.resetProducts) {
          this.editProducts = true;
        } else {
          this.editProducts = false;
          this.resetProducts = false;
        }
      },
    },
  },
  created() {
    this.products = (this.getOrder.products || []).map((p) => ({ ...p }));
  },
  methods: {
    ...mapActions(["setOrderProducts"]),
    addItemProduct() {
      this.products.push({
        id: "",
        name: "",
        price: 0,
        quantity: 1,
      });
    },
    cancelEdit() {
      this.resetProducts = true;
      this.products = (this.getOrder.products || []).map((p) => ({ ...p }));
    },
    async saveOrderProducts() {
      try {
        this.loadingSave = true;

        if (this.$refs.formProducts.validate() && this.products.length) {
          await this.setOrderProducts({ id: this.getOrder.id, products: this.products });

          this.editProducts = false;
        }
      } catch (error) {
        console.error("saveOrderProducts: ", error);
      } finally {
        this.loadingSave = false;
      }
    },
  },
};
</script>
