import requestAxios from "@/helpers/request_axios";
import * as MutationsType from "./mutationsType";
import { ticketsStatesCollection, ordersStatesCollection } from "@/config/firebase";
import { roles } from "@/helpers/get_constants";

export default {
  async getAllTicketStates({ commit, rootState }) {
    const agent = rootState.Agent.agent;
    const isByTeam = agent.role === roles.ENTREPRENEUR;

    const snap = await ticketsStatesCollection.orderBy("created_at").get();

    commit(MutationsType.SET_TICKET_STATES, []);

    if (!snap.empty) {
      snap.forEach((state) => {
        if (!isByTeam || (isByTeam && (state.data().team_id === agent.team_id || state.data().default)))
          commit(MutationsType.ADD_TICKET_STATE, { id: state.id, ...state.data() });
      });
    }
  },
  async createTicketState({ commit }, { state }) {
    try {
      let response = await requestAxios({
        url: "/awy/api/v1/ticket/states",
        method: "POST",
        data: state,
      });
      commit(MutationsType.ADD_TICKET_STATE, response.data.data);
      return response.data;
    } catch (error) {
      console.error("createTicketState: ", error.response.data);
      throw error.response.data;
    }
  },
  async updateTicketState({ commit }, { state }) {
    try {
      let response = await requestAxios({
        url: `/awy/api/v1/ticket/states/${state.id}`,
        method: "PUT",
        data: state,
      });
      commit(MutationsType.UPDATE_TICKET_STATE, response.data.data);
      return response.data;
    } catch (error) {
      console.error("updateTicketState: ", error.response.data);
      throw error.response.data;
    }
  },
  async removeTicketState({ commit }, payload) {
    try {
      let response = await requestAxios({
        url: `/awy/api/v1/ticket/states/${payload.state.id}`,
        method: "DELETE",
        data: payload,
      });
      commit(MutationsType.REMOVE_TICKET_STATE, response.data.data.state_deleted);
      return response.data;
    } catch (error) {
      console.error("removeTicketState: ", error.response.data);
      throw error.response.data;
    }
  },
  async getAllOrderStates({ commit }) {
    const snap = await ordersStatesCollection.orderBy("created_at").get();
    let states = [];
    let statesMap = {};
    let data;

    if (!snap.empty) {
      snap.forEach((state) => {
        data = { id: state.id, ...state.data() };
        states.push(data);
        statesMap[state.id] = data;
      });
    }

    commit(MutationsType.SET_ORDER_STATES, states);
    commit(MutationsType.SET_ORDER_STATES_MAP, statesMap);
  },
};
