<template>
  <div id="ticket_form">
    <v-col cols="12" class="pa-0">
      <v-form v-if="ticket && schema" id="form-ticket" ref="form" lazy-validation>
        <VFormBase :col="12" :value="ticket" :schema="schema" />
        <v-col v-if="action === 'create'" cols="12">
          <v-btn color="primary" :loading="loading" rounded @click="create">Guardar oportunidad</v-btn>
        </v-col>
        <v-col v-else cols="12">
          <v-btn small rounded color="primary" :loading="loading" @click="update">Guardar</v-btn>
          <v-btn small rounded @click="cancelUpdate">Cancelar</v-btn>
        </v-col>
      </v-form>
    </v-col>
  </div>
</template>
<script>
import Vue from "vue";
import VueGtm from "vue-gtm";
import VFormBase from "vuetify-form-base";
import { mapGetters, mapActions } from "vuex";
import { getCountryList, getCityList } from "@/helpers";
import getModel from "@/helpers/get_model";
import { gtm_id, ticketFormSchema } from "Config/config";

export default {
  name: "TicketForm",
  components: { VFormBase },
  props: {
    action: {
      type: String,
      default: "create",
    },
    isLoading: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      ticket: null,
      schema: null,
      loading: false,
      countries: null,
      agentSelected: null,
      countrySelected: null,
    };
  },
  computed: {
    ...mapGetters([
      "getAgent",
      "getTeam",
      "getAgentsToArray",
      "getTicket",
      "getSchemaTicketForm",
      "getProjects",
      "getActiveCustomOrigins",
    ]),
  },
  watch: {
    async getTicket(value) {
      if (value && this.action === "update") this.ticket = Object.assign({}, value);
    },
    async "ticket.country"(value) {
      if (value) {
        if (!this.countries) await this.loadCountries();
        this.countrySelected = this.countries.find((c) => {
          return c.name == value;
        });
        if (this.countrySelected) {
          this.cities = await getCityList(this.countrySelected.alpha2code);
          this.schema.city.items = this.cities;
        }
      }
    },
    async "ticket.agent"(value) {
      if (value && value.length > 0) {
        let agent = this.getAgentsToArray.find((c) => {
          return c.full_name == value;
        });
        if (agent) {
          this.agentSelected = {
            email: agent.email,
            full_name: agent.full_name,
            uid: agent.uid,
          };
        }
      }
    },
  },
  created() {
    this.init();
  },
  methods: {
    ...mapActions([
      "createTicket",
      "getAllAgents",
      "updateTicket",
      "getTeamById",
      "getTeamSchema",
      "getAllProjects",
      "getAllOrigins",
    ]),
    async init() {
      let gtmId = gtm_id;
      let schema = ticketFormSchema;
      let replaceFieldRules = false;

      if (this.getAgent.role === this.$roles.ENTREPRENEUR) {
        if (!this.getTeam) await this.getTeamById({ id: this.getAgent.team_id });
        gtmId = this.getTeam.gtm_id;

        if (!this.getSchemaTicketForm) await this.getTeamSchema({ id: this.getTeam.id, schemaIds: ["ticket_form"] });
        schema = this.getSchemaTicketForm;

        replaceFieldRules = true;
      }

      Vue.use(VueGtm, { id: gtmId, enabled: true, debug: true });

      const isUpdate = this.action === "update";

      this.schema = this.getFormSchemaUpdated(schema, isUpdate, replaceFieldRules);
      this.ticket = getModel(this.schema, isUpdate ? this.getTicket : {});

      this.loadCountries();
      this.loadProjects(isUpdate);

      if (!isUpdate) {
        await this.loadAgents();
        await this.loadOrigins();
      }

      this.$emit("update:isLoading", false);
    },
    getFormSchemaUpdated(schema, update = false, replaceFieldRules = false) {
      let schemaUpdated = {};
      let schemaField;
      let formSchema = Object.assign({}, schema);
      let fieldSelected;

      for (let field in formSchema) {
        schemaField = null;
        fieldSelected = Object.assign({}, formSchema[field]);

        if (fieldSelected.rules && replaceFieldRules)
          fieldSelected.rules = fieldSelected.rules.map((rule) => this.$rules[rule.toUpperCase()]);

        if (update && fieldSelected.form_update) {
          fieldSelected.flex = { xs: 12 };
          schemaField = fieldSelected;
        } else if (!update) {
          schemaField = fieldSelected;
        }

        if (schemaField && schemaField.type != "hidden") schemaUpdated[field] = schemaField;
      }

      return schemaUpdated;
    },
    async loadAgents() {
      if (!this.getAgentsToArray) await this.getAllAgents();

      this.schema.agent.items = this.getAgentsToArray.map((a) => a.full_name);
      this.ticket.agent = this.getAgent.full_name;
    },
    async loadOrigins() {
      if (this.schema.origin) {
        if (!this.getActiveCustomOrigins) await this.getAllOrigins();

        this.schema.origin.items = this.getActiveCustomOrigins.map((o) => o.name);
      }
    },
    async loadCountries() {
      this.countries = await getCountryList();
      this.schema.country.items = this.countries.map((c) => c.name);
    },
    async loadProjects(isUpdate) {
      if (!this.getProjects) await this.getAllProjects();

      this.schema.project.items = this.getProjects.map((p) => p.id);

      if (!isUpdate && this.getAgent.role === this.$roles.ENTREPRENEUR) this.ticket.project = this.getAgent.team_id;
    },
    addExtraData() {
      if (this.agentSelected) {
        this.ticket.agent_id = this.agentSelected.uid;
        this.ticket.agent = this.agentSelected;
      }
      if (this.countrySelected) {
        this.ticket.country = this.countrySelected.name;
        this.ticket.country_alpha3code = this.countrySelected.alpha3code;
      }
    },
    async create() {
      try {
        this.loading = true;

        if (this.$refs.form.validate()) {
          this.addExtraData();
          let response = await this.createTicket({
            ticket: this.ticket,
          });
          if (response.data) {
            let channel = `${this.ticket.medium_contact || this.getAgent.institution} (${this.getAgent.email})`;
            this.$gtm.trackEvent({
              event: "formTicketCreated",
              category: channel,
              action: "ticket_created",
              label: this.ticket.project || "default",
              noninteraction: false,
            });
            this.$router.replace({ name: "ticket_detail", params: { id: response.data.id } });
          }
        }
      } catch (error) {
        console.error("ticket/create: ", error);
      } finally {
        this.loading = false;
      }
    },
    async update() {
      try {
        this.loading = true;

        if (this.$refs.form.validate()) {
          this.addExtraData();
          const response = await this.updateTicket({ ticket_id: this.getTicket.id, data: this.ticket });
          if (response) this.cancelUpdate();
        }
      } catch (error) {
        console.error("ticket/update: ", error);
      } finally {
        this.loading = false;
      }
    },
    cancelUpdate() {
      this.$emit("cancelInformation");
    },
  },
};
</script>
<style>
#form-ticket .item {
  padding: 0.5rem;
}
</style>
