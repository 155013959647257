var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "root" } },
    [
      _c(
        "v-app",
        [
          _vm.user
            ? _c("DrawerApp", {
                staticClass: "hidden-sm-and-down",
                attrs: { user: _vm.getAgent, drawer: _vm.drawer },
                on: {
                  "update:drawer": function($event) {
                    _vm.drawer = $event
                  }
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.user
            ? _c("Navbar", {
                staticClass: "hidden-lg-and-up hidden-md-and-up",
                attrs: {
                  user: _vm.getAgent,
                  clipped: _vm.clipped,
                  title: _vm.title,
                  drawer: _vm.drawer
                },
                on: {
                  "update:drawer": function($event) {
                    _vm.drawer = $event
                  }
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _c(
            "v-main",
            [
              _c(
                "v-container",
                [
                  _c(
                    "v-fade-transition",
                    { attrs: { mode: "out-in" } },
                    [_c("router-view")],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _vm.getSelectedTicketId ? _c("TicketDetailDrawer") : _vm._e(),
          _vm._v(" "),
          _c("AgentDetailDrawer"),
          _vm._v(" "),
          _vm.user
            ? _c("BottomNavigationApp", {
                staticClass: "hidden-lg-and-up hidden-md-and-up"
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.notification
            ? _c(
                "v-snackbar",
                {
                  attrs: {
                    bottom: "",
                    right: "",
                    "multi-line": "",
                    timeout: 6000,
                    elevation: "20"
                  },
                  model: {
                    value: _vm.showNotification,
                    callback: function($$v) {
                      _vm.showNotification = $$v
                    },
                    expression: "showNotification"
                  }
                },
                [
                  _vm._v(
                    "\n      " + _vm._s(_vm.notification.text) + "\n      "
                  ),
                  _c("v-spacer"),
                  _vm._v(" "),
                  _vm.notification.link
                    ? _c(
                        "v-btn",
                        {
                          attrs: {
                            primary: "",
                            icon: "",
                            to: _vm.notification.link,
                            target: "_blank"
                          }
                        },
                        [
                          _c("v-icon", { attrs: { dark: "", small: "" } }, [
                            _vm._v("fas fa-external-link-alt")
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      staticClass: "ml-0",
                      attrs: { primary: "", icon: "" },
                      on: {
                        click: function($event) {
                          _vm.showNotification = false
                        }
                      }
                    },
                    [
                      _c("v-icon", { attrs: { dark: "", small: "" } }, [
                        _vm._v("fas fa-times")
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }