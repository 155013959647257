<template>
  <v-dialog v-model="dialog" scrollable max-width="800" @click:outside="closeDialog" @keydown.esc="closeDialog">
    <v-card class="py-3">
      <v-card-title class="subheadline">
        <v-icon small>far fa-lightbulb</v-icon>
        <span class="ml-2">Tips</span>
        <v-spacer></v-spacer>
        <v-btn icon @click="closeDialog">
          <v-icon small>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" class="d-flex">
            <span class="circle primary white--text mr-2">1</span>
            <v-row class="pr-5">
              <v-col cols="12" class="pt-0">
                <h4 class="font-weight-thin">
                  Al escribir los siguientes <span class="font-weight-bold">hashtags</span> podrás realizar las
                  siguientes acciones para
                  <span class="font-weight-bold">
                    gestionar tus oportunidades
                    <span class="black--text">
                      (cambiar estado, añadir etiquetas y establecer recordatorios)
                    </span>
                  </span>
                  desde la caja de comentario o desde tus conversaciones en WhatsApp (previa integración).
                </h4>
              </v-col>
              <v-col cols="12" class="pt-2">
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">
                          Acción
                        </th>
                        <th class="text-center">
                          Hashtag
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          Crear una nueva oportunidad desde una conversación en WhatsApp
                        </td>
                        <td>#n</td>
                      </tr>
                      <template v-for="t in getActiveTicketStates">
                        <tr v-if="t.hashtag" :key="t.id">
                          <td>
                            Actualizar estado a <strong>{{ t.name }}</strong>
                          </td>
                          <td>{{ t.hashtag }}</td>
                        </tr>
                      </template>
                      <template v-for="t in getActiveTags">
                        <tr v-if="t.hashtag" :key="t.id">
                          <td>
                            Agregar etiqueta <v-chip small :color="t.color">{{ t.name }}</v-chip>
                          </td>
                          <td>{{ t.hashtag }}</td>
                        </tr>
                      </template>
                      <template v-for="t in reminderParams">
                        <tr v-if="t.hashtag" :key="t.id">
                          <td>Establecer recordatorio en <strong>N</strong> {{ t.name }}(s)</td>
                          <td>Ejm: En {{ t.amount }} {{ t.name }}(s) => {{ t.hashtag }}</td>
                        </tr>
                      </template>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "TipsModal",
  props: {
    openDialog: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialog: false,
      reminderParams: [
        { id: "h", name: "hora", amount: 1, hashtag: "#r1h" },
        { id: "s", name: "semana", amount: 2, hashtag: "#r2s" },
        { id: "d", name: "día", amount: 5, hashtag: "#r5d" },
        { id: "m", name: "mes", amount: 1, hashtag: "#r1m" },
      ],
    };
  },
  computed: {
    ...mapGetters(["getActiveTags", "getActiveTicketStates"]),
  },
  watch: {
    openDialog(value) {
      this.dialog = value;
    },
  },
  async created() {
    this.dialog = this.openDialog;

    if (!this.getActiveTags?.length) await this.getAllTags();
    if (!this.getActiveTicketStates?.length) await this.getAllTicketStates();
  },
  methods: {
    ...mapActions(["getAllTags", "getAllTicketStates"]),
    closeDialog() {
      this.$emit("update:openDialog", false);
    },
  },
};
</script>
