<template>
  <v-app>
    <v-container v-if="getAgent && getProjects" class="rounded-lg">
      <v-row class="pt-4">
        <v-col cols="12" sm="10" md="8" offset-sm="1" offset-md="2">
          <h2 class="text-h4 primary--text pb-4 font-weight-black pt-8 pt-md-0 pt-lg-0">Nuevo trabajador</h2>
          <v-card class="px-4 py-6 rounded-lg">
            <h5 class="font-weight-regular mb-5">
              Al crear un nuevo usuario, se enviará un mensaje al correo registrado para que pueda generar una
              contraseña y poder iniciar sesión. En el caso que no le llegue el correo, deberá ingresar al link de la
              plataforma y recuperar su contraseña.
            </h5>
            <AgentForm action="create" />
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-container v-else>
      <ShowLoading />
    </v-container>
  </v-app>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import AgentForm from "@/components/forms/AgentForm";

export default {
  name: "AgentRegister",
  components: {
    AgentForm,
  },
  computed: {
    ...mapGetters(["getAgent", "getProjects"]),
  },
  watch: {
    getAgent(value) {
      if (value) this.init();
    },
  },
  async created() {
    if (this.getAgent) this.init();
  },
  methods: {
    ...mapActions(["getAllProjects", "getAllTeams"]),
    async init() {
      await this.getAllProjects();
      await this.getAllTeams();
    },
  },
};
</script>
