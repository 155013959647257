<template>
  <div v-if="getAgent">
    <v-form lazy-validation>
      <v-row class="text-left">
        <v-col cols="12" class="pb-0">
          <template>
            <v-row class="pb-2">
              <v-col cols="12" md="6" class="py-0">
                <label for="etiqueta">Etiqueta</label>
              </v-col>
              <v-col cols="12" md="6" class="pa-0">
                <TagModal />
              </v-col>
            </v-row>
            <v-combobox
              id="etiqueta"
              v-model="tags"
              :items="getActiveTags"
              chips
              multiple
              class="h-auto pt-0"
              item-id="name"
              item-text="name"
              return-object
              solo
              @change="changeTags"
            >
              <template v-slot:selection="{ attrs, item, select, selected }">
                <v-chip
                  v-bind="attrs"
                  :input-value="selected"
                  :color="item.color"
                  @click="select"
                  @click:close="removeTag(item)"
                >
                  <strong>{{ item.name }}</strong
                  >&nbsp;
                  <span></span>
                </v-chip>
              </template>
              <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title> El tag no existe. Presiona <kbd>enter</kbd> para crearlo. </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-combobox>
          </template>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import TagModal from "@/components/modals/TagModal";
import getRandomColor from "@/helpers/get_randomcolor";
export default {
  components: { TagModal },
  data() {
    return { tags: [], loadSaveTags: false };
  },
  computed: {
    ...mapGetters(["getAgent", "getActiveTags", "getTicket", "getTags"]),
  },
  watch: {
    getTags() {
      if (!this.loadSaveTags) this.tags = this.setTags(this.getTicket.tags);
    },
    getTicket(value, oldValue) {
      if (JSON.stringify(value.tags || []) === JSON.stringify(oldValue.tags || []))
        this.tags = this.setTags(value.tags);
    },
  },
  async mounted() {
    if (!this.getTags) await this.getAllTags();
  },
  methods: {
    ...mapActions(["createTag", "updateTicket", "getAllTags", "updateTag"]),
    changeTags(e) {
      if (e.length > 0) {
        let last = e[e.length - 1];
        if (!last.color) this.tags[e.length - 1] = { id: last, name: last, color: getRandomColor(), active: true };
      }
      this.saveTags();
    },

    removeTag(item) {
      this.tags.splice(this.tags.indexOf(item), 1);
      this.tags = [...this.tags];
    },

    setTags(tags) {
      let formattedTags = [];

      if (this.getTags && tags) {
        for (let i = 0; i < tags.length; i++) {
          let ind = this.getTags.findIndex((item) => item.id == tags[i]);
          formattedTags.push(this.getTags[ind]);
        }
      }

      return formattedTags;
    },
    cancelTags(tags) {
      this.tags = this.setTags(tags || this.getTicket.tags);
    },

    async saveTags() {
      try {
        this.loadSaveTags = true;

        let tagsToSave = [],
          auxTag,
          isRepeated,
          promises = [];

        for (let i = 0; i < this.tags.length; i++) {
          auxTag = Object.assign({}, this.tags[i]);
          isRepeated = this.getTags.find((item) => {
            return item.name === auxTag.name && item.active;
          });

          if (isRepeated) tagsToSave.push(auxTag.id);
          else promises.push(this.createTag({ tag: auxTag }));
        }

        let createdTag = await Promise.all(await promises);
        tagsToSave.push(...createdTag);

        const response = await this.updateTicket({ ticket_id: this.getTicket.id, data: { tags: tagsToSave } });
        if (response) this.cancelTags(tagsToSave);
      } catch (error) {
        console.error("ticket/saveTags: ", error);
      } finally {
        this.loadSaveTags = false;
      }
    },
  },
};
</script>
