import axios from "axios";
import { auth } from "@/config/firebase";
import { app } from "Config/config";

async function requestAxios(request_data) {
  const token = await auth.currentUser.getIdToken(true);
  if (!token) throw new Error("No token for this user");

  request_data.headers = { Authorization: `Bearer ${token}`, ...request_data.headers };
  if (process.env.NODE_ENV === "development")
    request_data.url = `http://localhost:5000/${app.projectId}/us-central1${request_data.url}`;

  return axios({
    withCredentials: true,
    ...request_data,
  });
}

export default requestAxios;
