<template>
  <div id="contact_form">
    <v-col cols="12" class="pa-0">
      <v-form v-if="contact" id="form-contact" ref="form" lazy-validation>
        <VFormBase :value="contact" :schema="schema" />
        <v-col v-if="action === 'create'" cols="12">
          <v-btn color="primary" rounded :loading="loading" @click="create">Guardar contacto</v-btn>
        </v-col>
        <v-col v-else cols="12">
          <v-btn small rounded color="accent" :loading="loading" @click="update">Guardar</v-btn>
          <v-btn small rounded @click="cancelUpdate">Cancelar</v-btn>
        </v-col>
      </v-form>
    </v-col>
  </div>
</template>
<script>
import VFormBase from "vuetify-form-base";
import { mapGetters, mapActions } from "vuex";
import { getCountryList, getCityList } from "@/helpers";
import getModel from "@/helpers/get_model";
import config from "Config/config";

export default {
  name: "ContactForm",
  components: { VFormBase },
  props: {
    action: {
      type: String,
      default: "create",
    },
    isLoading: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      contact: null,
      schema: null,
      loading: false,
      countries: null,
      countrySelected: null,
      agentSelected: null,
    };
  },
  computed: {
    ...mapGetters(["getAgentsToArray", "getContact", "getAgent", "getTeam", "getSchemaContactForm"]),
  },
  watch: {
    getAgent(value) {
      if (value) this.init();
    },
    async getContact(value) {
      if (value && this.action === "update") this.contact = Object.assign({}, value);
    },
    async "contact.country"(value) {
      if (value) {
        if (!this.countries) await this.loadCountries();
        this.countrySelected = this.countries.find((c) => {
          return c.name == value;
        });
        if (this.countrySelected) {
          this.cities = await getCityList(this.countrySelected.alpha2code);
          this.schema.city.items = this.cities;
        }
      }
    },
    async "contact.agent"(value) {
      if (value && value.length > 0) {
        let agent_find = this.getAgentsToArray.find((c) => {
          return c.full_name == value;
        });
        if (agent_find) {
          this.agentSelected = {
            email: agent_find.email,
            full_name: agent_find.full_name,
            uid: agent_find.uid,
          };
        }
      }
    },
  },
  created() {
    if (this.getAgent) this.init();
  },
  methods: {
    ...mapActions(["createContact", "getAllAgents", "updateContact", "getTeamById", "getTeamSchema"]),
    async init() {
      let schema = config.contactFormSchema;
      let replaceFieldRules = false;

      if (this.getAgent.role === this.$roles.ENTREPRENEUR) {
        if (!this.getTeam) await this.getTeamById({ id: this.getAgent.team_id });

        if (!this.getSchemaContactForm) await this.getTeamSchema({ id: this.getTeam.id, schemaIds: ["contact_form"] });
        schema = this.getSchemaContactForm;

        replaceFieldRules = true;
      }

      this.loadCountries();

      const isUpdate = this.action === "update";

      this.schema = this.getFormSchemaUpdated(schema, isUpdate, replaceFieldRules);
      this.contact = getModel(this.schema, isUpdate ? this.getContact : {});

      if (!isUpdate) {
        if (!this.getAgentsToArray) await this.getAllAgents();
        this.loadAgents();
      }

      this.$emit("update:isLoading", false);
    },
    getFormSchemaUpdated(schema, update = false, replaceFieldRules = false) {
      let schemaUpdated = {};
      let schemaField;
      let formSchema = Object.assign({}, schema);
      let fieldSelected;

      for (let field in formSchema) {
        schemaField = null;
        fieldSelected = Object.assign({}, formSchema[field]);

        if (fieldSelected.rules && replaceFieldRules)
          fieldSelected.rules = fieldSelected.rules.map((rule) => this.$rules[rule.toUpperCase()]);

        if (update && fieldSelected.form_update) {
          fieldSelected.flex = { xs: 12 };
          schemaField = fieldSelected;
        } else if (!update) {
          schemaField = fieldSelected;
        }

        if (schemaField && schemaField.type != "hidden") schemaUpdated[field] = schemaField;
      }

      return schemaUpdated;
    },
    loadAgents() {
      this.schema.agent.items = this.getAgentsToArray.map((c) => c.full_name);
      this.contact.agent = this.getAgent.full_name;
    },
    async loadCountries() {
      this.countries = await getCountryList();
      this.schema.country.items = this.countries.map((c) => c.name);
    },
    addExtraData() {
      if (this.agentSelected) {
        this.contact.agent_id = this.agentSelected.uid;
        this.contact.agent = this.agentSelected;
      }
      if (this.countrySelected) {
        this.contact.country = this.countrySelected.name;
        this.contact.country_alpha3code = this.countrySelected.alpha3code;
      }
    },
    async create() {
      try {
        this.loading = true;

        if (this.$refs.form.validate()) {
          this.addExtraData();
          let response = await this.createContact({
            contact: this.contact,
          });
          if (response.data) this.$router.replace({ name: "contact_detail", params: { id: response.data.id } });
        }
      } catch (error) {
        console.error("contact/create: ", error);
      } finally {
        this.loading = false;
      }
    },
    async update() {
      try {
        this.loading = true;
        this.addExtraData();
        if (this.$refs.form.validate()) {
          const response = await this.updateContact({ contact_id: this.getContact.id, data: this.contact });
          if (response) this.cancelUpdate();
        }
      } catch (error) {
        console.error("contact/update: ", error);
      } finally {
        this.loading = false;
      }
    },
    cancelUpdate() {
      this.$emit("cancelInformation");
    },
  },
};
</script>
<style>
#form-contact .item {
  padding: 0.5rem;
}
</style>
