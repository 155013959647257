var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    [
      _vm.getAgent
        ? _c(
            "v-container",
            { staticClass: "rounded-lg pb-5 mb-5 pt-4 pt-sm-10" },
            [
              _c(
                "v-row",
                { staticClass: "pt-4" },
                [
                  _c(
                    "v-col",
                    {
                      attrs: {
                        cols: "12",
                        sm: "10",
                        md: "8",
                        "offset-sm": "1",
                        "offset-md": "2"
                      }
                    },
                    [
                      _c(
                        "h2",
                        {
                          staticClass:
                            "text-h4 primary--text pb-4 font-weight-black pt-8 pt-md-0 pt-lg-0"
                        },
                        [_vm._v("Nuevo segmento")]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-card",
                        { staticClass: "pa-4 rounded-lg" },
                        [_c("SegmentForm")],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _c("v-container", [_c("ShowLoading")], 1)
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }