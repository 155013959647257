var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.user
    ? _c(
        "v-app-bar",
        { attrs: { fixed: "", "clipped-left": _vm.clipped, flat: "" } },
        [_c("v-toolbar-title", [_vm._v(_vm._s(_vm.title))])],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }