<template>
  <v-app-bar v-if="user" fixed :clipped-left="clipped" flat>
    <v-toolbar-title>{{ title }}</v-toolbar-title>
    <!-- <v-spacer></v-spacer>
    <v-btn icon link :to="'/settings'">
      <v-icon>fas fa-cog</v-icon>
    </v-btn> -->
  </v-app-bar>
</template>

<script>
export default {
  name: "Navbar",
  props: {
    drawer: {
      type: Boolean,
      default: false,
    },
    user: {
      type: Object,
      default: () => {},
      required: false,
    },
    clipped: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      showDrawer: false,
    };
  },
  watch: {
    user(value) {
      if (!value) this.$router.replace("/login");
    },
  },
  methods: {
    show() {
      this.showDrawer = !this.showDrawer;
      this.$emit("update:drawer", this.showDrawer);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
