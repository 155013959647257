var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-form",
    { ref: "formSegment", attrs: { "lazy-validation": "" } },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "7" } },
            [
              _c("v-text-field", {
                attrs: {
                  rules: [_vm.$rules.REQUIRED],
                  placeholder: " ",
                  label: "Nombre"
                },
                model: {
                  value: _vm.segment.name,
                  callback: function($$v) {
                    _vm.$set(_vm.segment, "name", $$v)
                  },
                  expression: "segment.name"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "5" } },
            [
              _c("v-select", {
                attrs: {
                  items: [
                    { id: "tickets", name: "Oportunidades" },
                    { id: "leads", name: "Contactos" },
                    { id: "orders", name: "Órdenes" }
                  ],
                  rules: [_vm.$rules.REQUIRED],
                  readonly: false,
                  "item-value": "id",
                  "item-text": "name",
                  placeholder: "Seleccione",
                  label: "Módulo"
                },
                model: {
                  value: _vm.segment.module,
                  callback: function($$v) {
                    _vm.$set(_vm.segment, "module", $$v)
                  },
                  expression: "segment.module"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.loadingDataToSegment
        ? _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "text-center py-15", attrs: { cols: "12" } },
                [
                  _c("v-progress-circular", {
                    attrs: { indeterminate: "", color: "primary" }
                  }),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(" "),
                  _c("span", { staticClass: "caption primary--text" }, [
                    _vm._v("Cargando filtros y columnas")
                  ])
                ],
                1
              )
            ],
            1
          )
        : _c(
            "v-row",
            [
              _c("v-col", { attrs: { cols: "12" } }, [
                _c("h4", [_vm._v("Filtros")])
              ]),
              _vm._v(" "),
              _c(
                "v-col",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.segment.filters.length > 0,
                      expression: "segment.filters.length > 0"
                    }
                  ],
                  attrs: { cols: "12", sm: "7" }
                },
                [
                  _c(
                    "v-row",
                    [
                      _vm._l(_vm.segment.filters, function(filter) {
                        return [
                          _c(
                            "v-col",
                            {
                              key: filter.id,
                              staticClass: "pt-0",
                              attrs: { cols: "12" }
                            },
                            [
                              _c(
                                "v-card",
                                { staticClass: "rounded-lg" },
                                [
                                  _c(
                                    "v-card-title",
                                    { staticClass: "justify-right pb-2" },
                                    [
                                      _c("v-spacer"),
                                      _vm._v(" "),
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: { icon: "", small: "" },
                                          on: {
                                            click: function($event) {
                                              return _vm.removeFilterSelected(
                                                filter.id
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { small: "" } },
                                            [_vm._v("fas fa-times")]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-card-text",
                                    { staticClass: "pb-0" },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            _vm.modulesName[_vm.segment.module]
                                          ) +
                                          " que en el campo "
                                      ),
                                      _c(
                                        "v-chip",
                                        { attrs: { outlined: "" } },
                                        [_vm._v(_vm._s(filter.label))]
                                      ),
                                      _vm._v(
                                        "\n                sea:\n                "
                                      ),
                                      filter.type === "date"
                                        ? [
                                            _c(
                                              "v-row",
                                              { staticClass: "pb-2" },
                                              [
                                                _c(
                                                  "v-col",
                                                  {
                                                    attrs: {
                                                      cols: "12",
                                                      sm: "6"
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "v-menu",
                                                      {
                                                        attrs: {
                                                          "close-on-content-click": false,
                                                          "nudge-right": 40,
                                                          transition:
                                                            "scale-transition",
                                                          "offset-y": "",
                                                          "min-width": "290px"
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "activator",
                                                              fn: function(
                                                                ref
                                                              ) {
                                                                var on = ref.on
                                                                var attrs =
                                                                  ref.attrs
                                                                return [
                                                                  _c(
                                                                    "v-text-field",
                                                                    _vm._g(
                                                                      _vm._b(
                                                                        {
                                                                          attrs: {
                                                                            rules: [
                                                                              _vm
                                                                                .$rules
                                                                                .REQUIRED
                                                                            ],
                                                                            label:
                                                                              "Fecha inicial",
                                                                            "prepend-icon":
                                                                              "far fa-calendar-alt",
                                                                            readonly:
                                                                              ""
                                                                          },
                                                                          model: {
                                                                            value:
                                                                              filter
                                                                                .values
                                                                                .start,
                                                                            callback: function(
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                filter.values,
                                                                                "start",
                                                                                $$v
                                                                              )
                                                                            },
                                                                            expression:
                                                                              "filter.values.start"
                                                                          }
                                                                        },
                                                                        "v-text-field",
                                                                        attrs,
                                                                        false
                                                                      ),
                                                                      on
                                                                    )
                                                                  )
                                                                ]
                                                              }
                                                            }
                                                          ],
                                                          null,
                                                          true
                                                        ),
                                                        model: {
                                                          value:
                                                            filter.ui.menuStart,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              filter.ui,
                                                              "menuStart",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "filter.ui.menuStart"
                                                        }
                                                      },
                                                      [
                                                        _vm._v(" "),
                                                        _c("v-date-picker", {
                                                          attrs: {
                                                            max: _vm.today
                                                          },
                                                          on: {
                                                            input: function(
                                                              $event
                                                            ) {
                                                              filter.ui.menuStart = false
                                                            }
                                                          },
                                                          model: {
                                                            value:
                                                              filter.values
                                                                .start,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                filter.values,
                                                                "start",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "filter.values.start"
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "v-col",
                                                  {
                                                    attrs: {
                                                      cols: "12",
                                                      sm: "6"
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "v-menu",
                                                      {
                                                        attrs: {
                                                          "close-on-content-click": false,
                                                          "nudge-right": 40,
                                                          transition:
                                                            "scale-transition",
                                                          "offset-y": "",
                                                          "min-width": "290px"
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "activator",
                                                              fn: function(
                                                                ref
                                                              ) {
                                                                var on = ref.on
                                                                var attrs =
                                                                  ref.attrs
                                                                return [
                                                                  _c(
                                                                    "v-text-field",
                                                                    _vm._g(
                                                                      _vm._b(
                                                                        {
                                                                          attrs: {
                                                                            rules: [
                                                                              _vm
                                                                                .$rules
                                                                                .REQUIRED,
                                                                              function(
                                                                                v
                                                                              ) {
                                                                                return (
                                                                                  v >=
                                                                                    filter
                                                                                      .values
                                                                                      .start ||
                                                                                  "Fecha inválida"
                                                                                )
                                                                              }
                                                                            ],
                                                                            label:
                                                                              "Fecha final",
                                                                            "prepend-icon":
                                                                              "far fa-calendar-alt",
                                                                            readonly:
                                                                              ""
                                                                          },
                                                                          model: {
                                                                            value:
                                                                              filter
                                                                                .values
                                                                                .end,
                                                                            callback: function(
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                filter.values,
                                                                                "end",
                                                                                $$v
                                                                              )
                                                                            },
                                                                            expression:
                                                                              "filter.values.end"
                                                                          }
                                                                        },
                                                                        "v-text-field",
                                                                        attrs,
                                                                        false
                                                                      ),
                                                                      on
                                                                    )
                                                                  )
                                                                ]
                                                              }
                                                            }
                                                          ],
                                                          null,
                                                          true
                                                        ),
                                                        model: {
                                                          value:
                                                            filter.ui.menuEnd,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              filter.ui,
                                                              "menuEnd",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "filter.ui.menuEnd"
                                                        }
                                                      },
                                                      [
                                                        _vm._v(" "),
                                                        _c("v-date-picker", {
                                                          attrs: {
                                                            max: _vm.today
                                                          },
                                                          on: {
                                                            input: function(
                                                              $event
                                                            ) {
                                                              filter.ui.menuEnd = false
                                                            }
                                                          },
                                                          model: {
                                                            value:
                                                              filter.values.end,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                filter.values,
                                                                "end",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "filter.values.end"
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ]
                                        : [
                                            _c("v-autocomplete", {
                                              staticClass: "mt-2",
                                              attrs: {
                                                items: filter.items,
                                                rules: [
                                                  _vm.$rules.REQUIRED,
                                                  function(v) {
                                                    return (
                                                      v.length > 0 ||
                                                      "Debe seleccionar aunque sea uno"
                                                    )
                                                  }
                                                ],
                                                "item-value": filter.item_text
                                                  ? filter.item_value
                                                    ? filter.item_value
                                                    : "id"
                                                  : "",
                                                "item-text": filter.item_text
                                                  ? filter.item_text
                                                  : "",
                                                chips: "",
                                                multiple: "",
                                                rounded: "",
                                                filled: "",
                                                dense: ""
                                              },
                                              model: {
                                                value: filter.values,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    filter,
                                                    "values",
                                                    $$v
                                                  )
                                                },
                                                expression: "filter.values"
                                              }
                                            })
                                          ]
                                    ],
                                    2
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ]
                      })
                    ],
                    2
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                {
                  attrs: {
                    cols: "12",
                    sm: _vm.segment.filters.length > 0 ? 5 : 12
                  }
                },
                [
                  _c("span", { staticClass: "text--secondary body" }, [
                    _vm._v(
                      "\n        Seleccione los filtro que desea aplicar:\n      "
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "v-chip-group",
                    {
                      attrs: { column: "", multiple: "" },
                      model: {
                        value: _vm.filtersSelected,
                        callback: function($$v) {
                          _vm.filtersSelected = $$v
                        },
                        expression: "filtersSelected"
                      }
                    },
                    [
                      _vm._l(_vm.filtersAvailable, function(filter) {
                        return [
                          _c(
                            "v-chip",
                            {
                              key: filter.id,
                              attrs: {
                                filter: "",
                                outlined: "",
                                value: filter.id
                              }
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(filter.label) +
                                  "\n          "
                              )
                            ]
                          )
                        ]
                      })
                    ],
                    2
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("v-col", { attrs: { cols: "12" } }, [
                _c("h4", [_vm._v("Columnas")])
              ]),
              _vm._v(" "),
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c("span", { staticClass: "text--secondary body" }, [
                    _vm._v(
                      "\n        Seleccione la información que desea obtener (4 máx):\n      "
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "v-chip-group",
                    {
                      attrs: { column: "", multiple: "", max: "4" },
                      model: {
                        value: _vm.columnsSelected,
                        callback: function($$v) {
                          _vm.columnsSelected = $$v
                        },
                        expression: "columnsSelected"
                      }
                    },
                    [
                      _vm._l(_vm.columnsAvailable, function(column) {
                        return [
                          _c(
                            "v-chip",
                            {
                              key: column.key,
                              attrs: {
                                filter: "",
                                outlined: "",
                                value: column.key
                              }
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(column.header) +
                                  "\n          "
                              )
                            ]
                          )
                        ]
                      })
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _vm.columnsSelected.length === 0
                    ? _c("span", { staticClass: "red--text caption" }, [
                        _vm._v("Seleccione al menos uno")
                      ])
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _vm.errorForm
                ? _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c("v-alert", { attrs: { text: "", type: "error" } }, [
                        _vm._v(
                          "\n        " + _vm._s(_vm.errorForm) + "\n      "
                        )
                      ])
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "v-col",
                {
                  class: _vm.action === "create" ? "text-left" : "text-right",
                  attrs: { cols: "12" }
                },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "primary",
                        rounded: "",
                        loading: _vm.loadingToSave
                      },
                      on: { click: _vm.onSave }
                    },
                    [_vm._v("Guardar segmento")]
                  )
                ],
                1
              )
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }