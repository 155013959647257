<template>
  <v-dialog v-model="dialog" persistent max-width="600">
    <v-card class="py-3">
      <v-card-title class="subheadline">
        <div class="ml-0 mt-2">
          <span>Asignación por Porcentaje</span>
          <br />
          <h6 class="text-secondary caption">
            Está funcionalidad es válida para el registro de oportunidades de forma automática
          </h6>
        </div>
        <v-spacer></v-spacer>
        <v-btn icon @click="$emit('update:dialog', false)">
          <v-icon small>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-select
              v-model="projectId"
              value="default"
              :items="getProjects"
              label="Proyecto"
              item-text="id"
              item-value="id"
              class="px-1"
            ></v-select>
          </v-col>
          <v-col cols="12" class="pb-0">
            <h4 class="px-1">Agentes a cargo del proyecto "{{ project.name || project.id }}"</h4>
          </v-col>
          <v-col cols="12">
            <v-form ref="formAllocationByPortion" lazy-validation>
              <template v-for="(p, i) in portionsPerAgents">
                <v-row :key="i" class="px-2">
                  <v-col cols="8">
                    <v-text-field :value="getAllAgentsMap[agents[i]].full_name" label="Agente" readonly></v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      v-model.number="portionsPerAgents[i].porcent"
                      label="Porcentaje de oportunidades"
                      suffix="%"
                      :rules="[$rules.REQUIRED, $rules.GREATER_THAN_ZERO]"
                      @input="onChangePorcent(i)"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </template>
            </v-form>
          </v-col>
          <v-col v-if="responseMessage" cols="12">
            <v-alert dense text :type="responseStatus">
              {{ responseMessage }}
            </v-alert>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="mr-2">
        <v-spacer></v-spacer>
        <v-btn rounded text @click="$emit('update:dialog', false)">Cancelar</v-btn>
        <v-btn :loading="loading" color="primary" rounded @click="onSave">
          Guardar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions, mapGetters } from "vuex";

export default {
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      projectId: "",
      project: null,
      agents: [],
      portionsPerAgents: [],
      loading: false,
      responseStatus: null,
      responseMessage: null,
    };
  },
  computed: {
    ...mapGetters(["getProjects", "getAllAgentsMap"]),
  },
  watch: {
    projectId(value) {
      this.loadProject(value);

      this.responseStatus = null;
      this.responseMessage = null;
    },
  },
  created() {
    this.projectId = "default";
    this.loadProject(this.projectId);
  },
  methods: {
    ...mapActions(["updatePortionsPerAgent"]),
    loadProject(projectId) {
      let project = this.getProjects.find((p) => p.id === projectId);

      this.project = {
        id: project.id,
        agents: [...project.agents],
        portions_per_agent: (project.portions_per_agent || []).map((a) => ({ ...a })),
      };
      this.agents = this.project.agents || [];
      this.portionsPerAgents = this.project.portions_per_agent || [];

      if (!this.portionsPerAgents.length && this.agents.length) {
        for (let i = 0; i < this.agents.length; i++)
          this.portionsPerAgents[i] = {
            current: 0,
            max: 0,
            porcent: 0,
            agent_id: this.agents[i],
          };
      }
    },
    onChangePorcent(i) {
      this.portionsPerAgents[i].max = Math.ceil(
        (this.portionsPerAgents.length * Math.PI * this.portionsPerAgents[i].porcent) / 100,
      );
    },
    async onSave() {
      try {
        this.loading = true;
        this.responseMessage = null;

        if (this.$refs.formAllocationByPortion.validate()) {
          let sumPorcent = 0;
          this.portionsPerAgents.forEach((ppa) => {
            sumPorcent += ppa.porcent;
          });
          if (sumPorcent !== 100) throw new Error("La suma de porcentajes debe ser 100");

          const response = await this.updatePortionsPerAgent({
            id: this.projectId,
            data: { portions_per_agent: this.portionsPerAgents },
          });

          this.responseStatus = response.status;
          this.responseMessage = response.message;
        }
      } catch (error) {
        this.responseStatus = "error";
        this.responseMessage =
          error.message || "Ocurrió un problema al guardar la configuración de la asignación por porción";
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
