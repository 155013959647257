<template>
  <v-app id="login" :style="{ background: $vuetify.theme.themes[theme].background }">
    <v-card class="fill-height rounded-lg pa-0 mb-8">
      <v-container class="fill-height pa-0" fluid>
        <v-row class="fill-height">
          <v-col cols="12" sm="8" md="6" class="fill-height">
            <div class="fill-height d-flex flex-column justify-center px-8">
              <div class="d-flex justify-center text-center items-center">
                <div class="col-9 col-md-6 pt-3 pb-5">
                  <img :src="getLogoImage" width="100%" />
                </div>
              </div>
              <h3 class="font-weight-thin title pt-2 pb-5 text-center">La forma más simple de vender más</h3>
              <div>
                <div class="pa-8 text-center">
                  <v-form ref="formSignin" lazy-validation class="text-center">
                    <v-text-field
                      v-model="user.email"
                      :rules="[$rules.REQUIRED, $rules.EMAIL]"
                      label="Correo electrónico"
                      placeholder=" "
                      class="mb-4"
                      @keyup.enter="loginUser"
                    ></v-text-field>
                    <v-text-field
                      v-model="user.password"
                      :rules="[$rules.REQUIRED]"
                      label="Contraseña"
                      placeholder=" "
                      type="password"
                      @keyup.enter="loginUser"
                    ></v-text-field>
                    <v-col v-if="error" class="error--text pb-2" cols="12">{{ error }}</v-col>
                    <v-btn
                      rounded
                      large
                      color="primary"
                      class="font-weight-bold px-4 my-4 w-full"
                      :loading="loading"
                      @click="loginUser"
                      >Iniciar sesion</v-btn
                    >
                  </v-form>
                  <v-btn
                    text
                    small
                    large
                    color="primary"
                    @click="
                      dialogRecovery = true;
                      resetForm();
                    "
                  >
                    Recuperar contraseña
                  </v-btn>
                </div>
              </div>
              <v-dialog v-model="dialogRecovery" max-width="400" persistent>
                <v-card class="pa-2">
                  <v-col>
                    <h3 class="font-weight-thin title pt-2 pb-5">Recuperar contraseña</h3>
                    <h5>
                      Ingrese su correo electrónico y le enviaremos un correo con un link para que cambie su contraseña
                    </h5>
                  </v-col>
                  <v-col>
                    <v-form ref="formRecovery" lazy-validation>
                      <v-text-field
                        v-model="email"
                        :rules="[$rules.REQUIRED, $rules.EMAIL]"
                        label="Correo electrónico"
                        placeholder=" "
                      ></v-text-field>
                    </v-form>
                  </v-col>
                  <v-col v-if="errorSend" class="error--text pb-2" cols="12">{{ errorSend }}</v-col>
                  <v-col class="text-center">
                    <v-btn rounded color="primary" :loading="sending" @click="send">
                      Enviar
                    </v-btn>
                    <v-btn rounded text @click="dialogRecovery = false">
                      Cancelar
                    </v-btn>
                  </v-col>
                  <v-col v-if="successSend">
                    <v-alert dense text type="success">
                      Se envió con éxito el correo para reestablecer tu contraseña
                    </v-alert>
                  </v-col>
                </v-card>
              </v-dialog>
            </div>
          </v-col>
          <v-col cols="12" sm="8" md="6" class="pa-0">
            <div
              class="fill-height fondo rounded-xl"
              :style="{ 'background-image': 'url(' + getBAckgroundImage() + ')' }"
            ></div>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-app>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import imageBackground from "@/assets/fondo.png";
import logoImage from "@/assets/logo.png";

export default {
  name: "Login",
  data() {
    return {
      user: {
        email: "",
        password: "",
      },
      email: "",
      loading: false,
      error: null,
      dialogRecovery: false,
      errorSend: null,
      successSend: false,
      sending: false,
    };
  },
  computed: {
    ...mapGetters(["getAgent"]),
    getLogoImage() {
      return logoImage;
    },
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
  },
  methods: {
    ...mapActions(["login", "recoveryPassword", "setAgent", "setAgentAuthenticated"]),
    getBAckgroundImage() {
      return imageBackground;
    },
    async loginUser() {
      this.loading = true;
      if (this.$refs.formSignin.validate()) {
        let response = await this.login({
          user: this.user,
        });
        if (!response.data) {
          this.error = this.showErrorFirebase(response.error.code);
        }
      }
      this.loading = false;
    },
    async send() {
      try {
        this.sending = true;
        this.successSend = false;
        this.errorSend = false;

        if (this.$refs.formRecovery.validate()) {
          let response = await this.recoveryPassword({
            email: this.email,
          });

          if (response.error) throw response.error.code;

          this.successSend = true;
          this.resetForm();
        }
      } catch (e) {
        this.errorSend = this.showErrorFirebase(e);
      } finally {
        this.sending = false;
      }
    },
    showErrorFirebase(code) {
      let text = "";
      switch (code) {
        case "auth/wrong-password":
          text = "Contraseña incorrecta";
          break;
        case "auth/user-not-found":
          text = "El correo electrónico no está registrado";
          break;
        case "auth/user-disabled":
          text = "El correo electrónico está inhabilitado";
          break;
      }
      return text;
    },
    resetForm() {
      this.email = "";
      if (this.$refs.formRecovery) this.$refs.formRecovery.resetValidation();
    },
  },
};
</script>

<style scoped>
.fondo {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-blend-mode: soft-light;
}
</style>
