<template>
  <v-card class="py-3" @click="$emit('open')">
    <v-img :src="require(`@/assets/social_icons/${id}.png`)" width="40" class="mx-auto"></v-img>
    <div class="d-flex mt-2">
      <span class="text-center mx-auto caption">{{ name }}</span>
    </div>
  </v-card>
</template>
<script>
export default {
  name: "IntegrationCard",
  props: {
    id: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      default: "canal",
    },
  },
};
</script>
