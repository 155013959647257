<template>
  <div>
    <v-list-item key="tickets" @click.stop="toggle = !toggle">
      <v-list-item-avatar>
        <v-icon>fas fa-ticket-alt</v-icon>
      </v-list-item-avatar>
      <v-list-item-content class="py-4">
        <v-list-item-title>Oportunidades</v-list-item-title>
        <v-list-item-subtitle>Configura algunos campos para tu sección de oportunidades</v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action>
        <v-icon>
          {{ !toggle ? "fas fa-angle-down" : "fas fa-angle-up" }}
        </v-icon>
      </v-list-item-action>
    </v-list-item>
    <v-container v-if="toggle" id="tickets">
      <v-col cols="12" class="">
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header expand-icon="fas fa-angle-down">
              Estados
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <States />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-container>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import States from "@/views/settings/tickets/States";

export default {
  name: "Tickets",
  components: {
    States,
  },
  data() {
    return {
      toggle: false,
    };
  },
  async created() {
    await this.getAllTicketStates();
  },
  methods: {
    ...mapActions(["getAllTicketStates"]),
  },
};
</script>
