export const SET_SEGMENTS = "SET_SEGMENTS";
export const ADD_SEGMENT = "ADD_SEGMENT";
export const UPDATE_SEGMENT = "UPDATE_SEGMENT";
export const REMOVE_SEGMENT = "REMOVE_SEGMENT";
export const SET_SEGMENTS_DOWNLOADS = "SET_SEGMENTS_DOWNLOADS";
export const ADD_SEGMENT_DOWNLOAD = "ADD_SEGMENT_DOWNLOAD";
export const UPDATE_SEGMENT_DOWNLOAD = "UPDATE_SEGMENT_DOWNLOAD";
export const REMOVE_SEGMENT_DOWNLOAD = "REMOVE_SEGMENT_DOWNLOAD";
export const UNSUBSCRIBE_GET_ALL_SEGMENTS_DOWNLOADS = "UNSUBSCRIBE_GET_ALL_SEGMENTS_DOWNLOADS";
export const SET_SEGMENTS_AUTOMATIONS = "SET_SEGMENTS_AUTOMATIONS";
export const ADD_SEGMENT_AUTOMATION = "ADD_SEGMENT_AUTOMATION";
export const UPDATE_SEGMENT_AUTOMATION = "UPDATE_SEGMENT_AUTOMATION";
export const REMOVE_SEGMENT_AUTOMATION = "REMOVE_SEGMENT_AUTOMATION";
export const UNSUBSCRIBE_GET_ALL_SEGMENTS_AUTOMATIONS = "UNSUBSCRIBE_GET_ALL_SEGMENTS_AUTOMATIONS";
