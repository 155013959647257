<template>
  <v-app>
    <div v-if="getAgent && getOrderStates && getProducts && orders">
      <v-row class="d-flex align-center pt-4 pt-sm-10">
        <v-col cols="12" sm="6" md="9">
          <p class="text-h4 primary--text mb-0 font-weight-black hidden-sm-and-down">Órdenes</p>
        </v-col>
        <v-col cols="12" sm="6" md="3" class="d-flex justify-md-end justify-space-between">
          <v-btn :loading="loadExport" outlined color="primary" dark @click="exportData">
            Descargar
            <v-icon right size="16">fa-file-download</v-icon>
          </v-btn>
        </v-col>
      </v-row>

      <v-row v-show="showFilters" class="d-flex align-center py-4">
        <v-col cols="12" sm="6" md="3" class="py-0">
          <v-card class="pt-2 px-4 elevation-3 rounded-lg">
            <v-autocomplete
              value="todos"
              :items="getStatesToSelect"
              item-text="name"
              item-value="id"
              label="Estado"
              dense
              class="px-1 d-flex align-center select-filters"
              @change="applyFilters('state', $event)"
            ></v-autocomplete>
          </v-card>
        </v-col>
        <v-col cols="12" sm="6" md="3" class="py-0">
          <v-card class="pt-2 px-4 elevation-3 rounded-lg">
            <v-autocomplete
              :items="getProductsToSelect"
              value="todos"
              item-text="name"
              item-value="id"
              label="Producto"
              class="px-1 d-flex align-center select-filters"
              dense
              @change="applyFilters('products_ids', $event)"
            ></v-autocomplete>
          </v-card>
        </v-col>
        <v-spacer />
        <v-col cols="12" sm="6" md="3" class="py-0">
          <v-card class="px-4 elevation-3 rounded-lg py-1">
            <v-text-field
              v-model="query"
              label="Buscar cliente"
              dense
              solo
              single-line
              hide-details
              flat
              class="d-flex align-center imput-icon"
              append-icon="fa fa-search"
              @input="applySearch"
            ></v-text-field>
            <!-- <div class="my-auto hidden-md-and-up">
              <v-btn icon class="mr-0" @click="showFilters = !showFilters">
                <v-icon>fas fa-filter</v-icon>
              </v-btn>
            </div> -->
          </v-card>
        </v-col>
      </v-row>
      <v-card class="pa-4 mb-5 rounded-lg">
        <v-progress-linear
          :active="loadingOrders"
          :indeterminate="true"
          absolute
          top
          color="secondary accent-4"
        ></v-progress-linear>
        <v-col v-if="orders" cols="12" class="pa-4">
          <v-data-table
            v-if="orders"
            :headers="headers"
            :items="orders"
            show-expand
            sort-by
            class="table-orders"
            dense
            :items-per-page="40"
            hide-default-footer
            @item-expanded="onShowExpanded"
          >
            <template v-slot:item.created_at="{ item }">
              {{ $formatTimestamp(item.created_at) }}
            </template>
            <template v-slot:item.operations="{ item }">
              <v-btn text icon :to="{ name: 'order_detail', params: { id: item.objectID || item.id } }" target="_blank">
                <v-icon color="primary" dark small>fas fa-external-link-alt</v-icon>
              </v-btn>
            </template>
            <template v-slot:item.state="{ item }">
              {{ getOrderStatesMap[item.state] ? getOrderStatesMap[item.state].name : "-" }}
            </template>
            <template v-slot:expanded-item="{ item }">
              <td :colspan="headers.length" class="pa-8">
                <v-row>
                  <v-progress-linear
                    :active="!item.products"
                    :indeterminate="true"
                    top
                    color="secondary accent-4"
                    class="mb-3"
                  ></v-progress-linear>
                  <v-col v-for="(schema, i) in schemaSale" :key="i" md="3" cols="12">
                    <v-text-field
                      :label="schema.header"
                      :value="schema.type === 'date' ? $formatTimestamp(item[schema.key], 'date_z') : item[schema.key]"
                      readonly
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-spacer></v-spacer>
                  <v-col md="3" cols="12">
                    <v-text-field label="Método de pago:" :value="item.payment_method" readonly></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col md="6" cols="12"> </v-col>
                  <v-col md="6" cols="12">
                    <p>Productos relacionados:</p>
                    <v-simple-table class="mb-4" style="overflow-x: auto; border: 1px solid lightgrey;">
                      <thead class="grey lighten-4">
                        <tr>
                          <th class="text-center">Nombre</th>
                          <th class="text-center">Cantidad</th>
                          <th class="text-center">Subtotal</th>
                        </tr>
                      </thead>
                      <tbody class="text-center">
                        <tr v-for="(product, i) in item.products" :key="i">
                          <td>{{ product.name || product.id }}</td>
                          <td>{{ product.quantity }}</td>
                          <td>{{ $formatCurrency(product.price * product.quantity) }}</td>
                        </tr>
                        <tr>
                          <td colspan=" 2" class="text-right body-1">Total</td>
                          <td class="grey lighten-5">
                            <b class="primary--text subtitle">{{ $formatCurrency(item.total_paid) }}</b>
                          </td>
                        </tr>
                      </tbody>
                    </v-simple-table>
                  </v-col>
                </v-row>
              </td>
            </template>
          </v-data-table>

          <v-row v-if="totalPage" class="pa-0">
            <v-spacer></v-spacer>
            <v-col cols="12" md="6" class="text-center">
              <v-pagination
                v-model="page"
                :length="totalPage"
                prev-icon="fas fa-angle-left"
                next-icon="fas fa-angle-right"
                total-visible="6"
                @input="loadOrders"
              ></v-pagination>
            </v-col>
            <v-col cols="12" md="3" class="d-flex align-center justify-end">
              <h5 class="font-weight-thin">Total de órdenes: {{ totalOrders }}</h5>
            </v-col>
          </v-row>
        </v-col>
      </v-card>
    </div>
    <v-container v-else>
      <ShowLoading />
    </v-container>
  </v-app>
</template>
<script>
import Vue from "vue";
import { mapGetters, mapActions } from "vuex";
import { saleExportData, orderFilters } from "Config/config";

export default {
  name: "Orders",
  data() {
    return {
      query: "",
      page: 1,
      totalPage: 0,
      filtersSelected: {},
      customFilters: orderFilters,
      orders: null,
      totalOrders: 0,
      productSelected: "todos",
      stateSelected: "todos",
      showFilters: true,
      timerToSearch: null,
      headers: [
        {
          text: "Nombres y apellidos",
          align: "left",
          sortable: false,
          value: "name",
        },
        {
          text: "Correo electrónico",
          align: "left",
          sortable: false,
          value: "email",
        },
        {
          text: "Estado",
          align: "center",
          sortable: false,
          value: "state",
        },
        {
          text: "Fecha",
          align: "center",
          value: "created_at",
          sortable: true,
        },
        {
          text: "Operación",
          align: "center",
          sortable: false,
          value: "operations",
        },
      ],
      loadingOrders: true,
      loadExport: false,
      schemaSale: null,
    };
  },
  computed: {
    ...mapGetters(["getOrders", "getAgent", "getProducts", "getOrderStates", "getOrderStatesMap"]),
    getStatesToSelect() {
      return [{ id: "todos", name: "Todos" }, ...this.getOrderStates];
    },
    getProductsToSelect() {
      return [{ id: "todos", name: "Todos" }, ...this.getProducts];
    },
  },
  watch: {
    getAgent(value) {
      if (value) this.init();
    },
  },
  async created() {
    if (this.getAgent) this.init();
  },
  methods: {
    ...mapActions([
      "searchOrders",
      "getAllAgents",
      "getOrderById",
      "downloadOrders",
      "getAllOrderStates",
      "getAllProducts",
    ]),
    async init() {
      this.schemaSale = saleExportData.filter((s) => s.show_on_detail);

      if (this.$roles.ENTREPRENEUR === this.getAgent.role) this.filtersSelected["team_id"] = this.getAgent.team_id;

      if (!this.getOrderStates) await this.getAllOrderStates();
      if (!this.getProducts) await this.getAllProducts();

      await this.loadOrders();
    },
    async loadOrders() {
      try {
        this.loadingOrders = true;

        let { data } = await this.searchOrders({
          filters: this.filtersSelected,
          search: this.query,
          page: this.page - 1,
        });

        this.orders = data.results || [];
        this.totalPage = data.pagination.total_pages;
        this.totalOrders = data.pagination.total;
      } catch (error) {
        console.error("orders/loadOrders: ", error);
      } finally {
        this.loadingOrders = false;
      }
    },
    applyFilters(filterId, filterValue) {
      this.filtersSelected[filterId] = filterValue === "todos" ? null : filterValue;
      this.page = 1;

      this.loadOrders();
    },
    applySearch() {
      clearTimeout(this.timerToSearch);

      this.timerToSearch = setTimeout(async () => {
        this.loadOrders();
      }, 250);
    },
    async onShowExpanded({ item }) {
      if (!item.products) {
        let order = await this.getOrderById({ id: item.id });
        let ind = this.orders.findIndex((o) => o.id === item.id);
        if (ind >= 0) Vue.set(this.orders, ind, { ...this.orders[ind], ...order });
      }
    },
    async exportData() {
      try {
        this.loadExport = true;
        let response = await this.downloadOrders();
        let url = window.URL.createObjectURL(new Blob([response]));
        let link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `apolocrm-orders-${this.$moment().format("DD-MM-YYYY-hh-mm")}.xlsx`);
        document.body.appendChild(link);
        link.click();
      } catch (error) {
        console.error(error);
      } finally {
        this.loadExport = false;
      }
    },
  },
};
</script>
<style>
.max-w180 {
  max-width: 180px;
}
.v-avatar {
  cursor: pointer;
}
.text-decoration-none {
  text-decoration: none;
}
th[aria-label="Email: Not sorted."],
.hidden {
  display: none;
}
.v-btn {
  text-transform: none;
}
.theme--light.table-orders.v-data-table tbody tr:hover:not(.v-data-table__expand-row) {
  background-color: transparent !important;
}
.table-orders.v-data-table tbody tr.expanded.expanded__content {
  box-shadow: none;
}
.table-orders .v-data-table__mobile-row * {
  font-size: 12px !important;
}
</style>
