var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-col",
    { staticClass: "pa-0", attrs: { cols: "12" } },
    [
      _c(
        "v-card",
        { staticClass: "rounded-lg pa-2" },
        [
          _c(
            "v-col",
            { staticClass: "py-0 px-3", attrs: { cols: "12" } },
            [
              _c("Detail", {
                attrs: {
                  id: _vm.getIntegrationSendGrid.id,
                  name: "SendGrid",
                  type: "integration",
                  active: _vm.getIntegrationSendGrid.active,
                  "show-detail": _vm.showDetail,
                  "able-edit": _vm.getIntegrationSendGrid.active,
                  error: _vm.error,
                  loading: _vm.loading
                },
                on: {
                  "update:showDetail": function($event) {
                    _vm.showDetail = $event
                  },
                  "update:show-detail": function($event) {
                    _vm.showDetail = $event
                  },
                  "update:ableEdit": function($event) {
                    return _vm.$set(
                      _vm.getIntegrationSendGrid,
                      "active",
                      $event
                    )
                  },
                  "update:able-edit": function($event) {
                    return _vm.$set(
                      _vm.getIntegrationSendGrid,
                      "active",
                      $event
                    )
                  },
                  "update:loading": function($event) {
                    _vm.loading = $event
                  },
                  openEnable: _vm.openEnable,
                  openEdit: _vm.openEdit,
                  openRemove: _vm.openRemove
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _vm.showDetail
            ? _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { staticClass: "pb-0", attrs: { cols: "12" } },
                        [
                          _c("h5", { staticClass: "pb-2" }, [
                            _vm._v("Envío de correos")
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _vm.getIntegrationSendGrid.op_send_mail
                        ? _c(
                            "v-col",
                            {
                              staticClass: "py-0 caption",
                              attrs: { cols: "12" }
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "d-flex" },
                                [
                                  _c("v-checkbox", {
                                    attrs: {
                                      value: true,
                                      readonly: "",
                                      color: "accent",
                                      "hide-details": ""
                                    },
                                    model: {
                                      value:
                                        _vm.getIntegrationSendGrid
                                          .send_agent_assigned,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.getIntegrationSendGrid,
                                          "send_agent_assigned",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "getIntegrationSendGrid.send_agent_assigned"
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("span", { staticClass: "mt-2" }, [
                                    _vm._v("Envío al agente asignado")
                                  ])
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "d-flex" },
                                [
                                  _c("v-checkbox", {
                                    attrs: {
                                      value: true,
                                      readonly: "",
                                      color: "accent",
                                      "hide-details": ""
                                    },
                                    model: {
                                      value:
                                        _vm.getIntegrationSendGrid
                                          .send_supervisors,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.getIntegrationSendGrid,
                                          "send_supervisors",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "getIntegrationSendGrid.send_supervisors"
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("span", { staticClass: "mt-2" }, [
                                    _vm._v(
                                      "Envío a supervisores del equipo del agente asignado"
                                    )
                                  ])
                                ],
                                1
                              )
                            ]
                          )
                        : _c(
                            "v-col",
                            { staticClass: "caption", attrs: { cols: "12" } },
                            [_c("span", [_vm._v("No configurado")])]
                          )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { staticClass: "pb-0", attrs: { cols: "12" } },
                        [
                          _c("h5", { staticClass: "pb-2" }, [
                            _vm._v("Recepción de correos")
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _vm.getIntegrationSendGrid.op_receive_mail
                        ? _c(
                            "v-col",
                            {
                              staticClass: "py-0 caption",
                              attrs: { cols: "12" }
                            },
                            [
                              _c("span", [
                                _vm._v(
                                  "\n            Recibiendo los correos de la bandeja de entrada de\n            "
                                ),
                                _c("strong", [
                                  _vm._v(
                                    "_@" +
                                      _vm._s(
                                        _vm.getIntegrationSendGrid.inbound_parse
                                          .hostname
                                      )
                                  )
                                ]),
                                _vm._v(
                                  "\n            y convirtiendo en oportunidades con proyecto inbound_mail.\n          "
                                )
                              ])
                            ]
                          )
                        : _c(
                            "v-col",
                            { staticClass: "caption", attrs: { cols: "12" } },
                            [_c("span", [_vm._v("No configurado")])]
                          )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.dialogToEnable
            ? _c("ModalEnableIntegration", {
                attrs: {
                  name: "SendGrid",
                  "dialog-to-enable": _vm.dialogToEnable,
                  active: _vm.getIntegrationSendGrid.active,
                  error: _vm.error,
                  loading: _vm.loading
                },
                on: {
                  "update:dialogToEnable": function($event) {
                    _vm.dialogToEnable = $event
                  },
                  "update:dialog-to-enable": function($event) {
                    _vm.dialogToEnable = $event
                  },
                  "update:active": function($event) {
                    return _vm.$set(
                      _vm.getIntegrationSendGrid,
                      "active",
                      $event
                    )
                  },
                  "update:error": function($event) {
                    _vm.error = $event
                  },
                  "update:loading": function($event) {
                    _vm.loading = $event
                  },
                  enable: _vm.enable
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.dialogToRemove
            ? _c("ModalRemoveIntegration", {
                attrs: {
                  name: "SendGrid",
                  "dialog-to-remove": _vm.dialogToRemove,
                  error: _vm.error,
                  loading: _vm.loading
                },
                on: {
                  "update:dialogToRemove": function($event) {
                    _vm.dialogToRemove = $event
                  },
                  "update:dialog-to-remove": function($event) {
                    _vm.dialogToRemove = $event
                  },
                  "update:error": function($event) {
                    _vm.error = $event
                  },
                  "update:loading": function($event) {
                    _vm.loading = $event
                  },
                  remove: _vm.remove
                }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }