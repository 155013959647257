import requestAxios from "@/helpers/request_axios";
import * as MutationsType from "./mutationsType";
import { ordersCollection } from "@/config/firebase";

export default {
  async getOrderById({ commit }, { id }) {
    let doc = await ordersCollection.doc(id).get();
    let order;
    if (doc.exists) order = { ...doc.data(), id: doc.id };
    commit(MutationsType.SET_ORDER, order);
    return order;
  },
  async getAllOrders({ commit, rootState }) {
    const { agent, agentRoles } = rootState.Agent;
    let reference = ordersCollection;

    if (agent.role === agentRoles.entrepreneur.id) reference = reference.where("team_id", "==", agent.team_id);

    const querySnapshot = await reference.orderBy("created_at", "desc").get();

    commit(MutationsType.SET_ORDERS, []);
    if (!querySnapshot.empty) {
      querySnapshot.forEach((doc) => {
        commit(MutationsType.ADD_ORDER, { ...doc.data(), id: doc.id });
      });
    }
  },
  async updateOrder({ commit }, { id, data }) {
    try {
      let response = await requestAxios({
        url: `/awy/api/v1/order/${id}`,
        method: "PATCH",
        data: data,
      });
      commit(MutationsType.UPDATE_ORDER, data);
      return response.data;
    } catch (error) {
      console.error("updateOrder: ", error);
      throw error.response.data;
    }
  },
  async setOrderState({ commit }, { id, state }) {
    try {
      let response = await requestAxios({
        url: `/awy/api/v1/order/${id}/state`,
        method: "PATCH",
        data: { state },
      });
      commit(MutationsType.UPDATE_ORDER, { state: state });
      return response.data;
    } catch (error) {
      console.error("setOrderState: ", error);
      throw error.response.data;
    }
  },
  async setOrderProducts({ commit }, { id, products }) {
    try {
      let response = await requestAxios({
        url: `/awy/api/v1/order/${id}/products`,
        method: "PATCH",
        data: products,
      });
      commit(MutationsType.UPDATE_ORDER, { products: response.data.data });
      return response.data;
    } catch (error) {
      console.error("setOrderProducts: ", error);
      throw error.response.data;
    }
  },
  // eslint-disable-next-line
  async searchOrders({}, { filters, search, page, perPage = 40 }) {
    try {
      let response = await requestAxios({
        url: "/awy/api/v1/order/search",
        method: "POST",
        data: {
          filters,
          search,
          page,
          perPage,
        },
      });
      return response.data;
    } catch (error) {
      console.error("searchOrders: ", error);
      throw error.response.data;
    }
  },
  // eslint-disable-next-line
  async downloadOrders({ commit }) {
    try {
      let response = await requestAxios({
        url: "/awy/api/v1/order/download",
        method: "GET",
        responseType: "blob",
      });
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  },
  // eslint-disable-next-line
  async exportOrderTemplate({ commit }) {
    try {
      let response = await requestAxios({
        url: "/awy/api/v1/order/export-template",
        method: "GET",
        responseType: "blob",
      });
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  },
  // eslint-disable-next-line
  async importOrders({ commit }, { file }) {
    try {
      let formdata = new FormData();
      formdata.append("file", file);
      let response = await requestAxios({
        url: "/awy/api/v1/order/import",
        method: "POST",
        data: formdata,
        headers: { "Content-Type": "multipart/form-data" },
      });
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  },
};
