var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-btn",
    {
      attrs: {
        href: "https://wa.me/" + _vm.getPhone,
        target: "_blank",
        text: "",
        icon: ""
      },
      on: {
        click: function($event) {
          $event.stopPropagation()
        }
      }
    },
    [
      _c("v-icon", { attrs: { dark: "", small: "" } }, [
        _vm._v("fab fa-whatsapp")
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }