<template>
  <v-dialog v-model="dialog" persistent max-width="400">
    <v-card class="py-3">
      <v-card-title class="subheadline">
        <div>
          <v-img :src="require(`@/assets/social_icons/${id}.png`)" width="20px" class="mr-2"></v-img>
        </div>
        <span class="ml-0">{{ name }}</span>
        <v-spacer></v-spacer>
        <v-btn icon @click="$emit('update:dialog', false)">
          <v-icon small>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" class="text-center">
            <h3 class="font-weight-thin">
              Esta integración está deshabilitada para tu plan actual. Para más información puedes contactarte con un
              asesor de Apolo CRM.
            </h3>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "ModalIntegrationDisabled",
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
  },
};
</script>
