var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-list-item",
        {
          key: "teams",
          on: {
            click: function($event) {
              $event.stopPropagation()
              _vm.toggle = !_vm.toggle
            }
          }
        },
        [
          _c("v-list-item-avatar", [_c("v-icon", [_vm._v("fas fa-users")])], 1),
          _vm._v(" "),
          _c(
            "v-list-item-content",
            { staticClass: "py-4" },
            [
              _c("v-list-item-title", [_vm._v("Equipos y agentes")]),
              _vm._v(" "),
              _c("v-list-item-subtitle", [
                _vm._v(
                  "Organiza a tus trabajadores en equipos para una mayor productividad"
                )
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-list-item-action",
            [
              _c("v-icon", [
                _vm._v(
                  "\n        " +
                    _vm._s(
                      !_vm.toggle ? "fas fa-angle-down" : "fas fa-angle-up"
                    ) +
                    "\n      "
                )
              ])
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.toggle
        ? _c(
            "v-container",
            { attrs: { id: "tickets" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "v-expansion-panels",
                    [
                      _c(
                        "v-expansion-panel",
                        [
                          _c(
                            "v-expansion-panel-header",
                            { attrs: { "expand-icon": "fas fa-angle-down" } },
                            [_vm._v("\n            Equipos\n          ")]
                          ),
                          _vm._v(" "),
                          _c("v-expansion-panel-content", [_c("Teams")], 1)
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }