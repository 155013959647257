var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    {
      staticClass: "py-3",
      on: {
        click: function($event) {
          return _vm.$emit("open")
        }
      }
    },
    [
      _c("v-img", {
        staticClass: "mx-auto",
        attrs: {
          src: require("@/assets/social_icons/" + _vm.id + ".png"),
          width: "40"
        }
      }),
      _vm._v(" "),
      _c("div", { staticClass: "d-flex mt-2" }, [
        _c("span", { staticClass: "text-center mx-auto caption" }, [
          _vm._v(_vm._s(_vm.name))
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }