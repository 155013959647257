// config.js
module.exports = {
  siteURL: "https://autoplan.apolocrm.com",
  app: {
    apiKey: "AIzaSyAV5o1bqu9TKLrjNXXBsundAlCeieiZLNE",
    authDomain: "autoplan-web.firebaseapp.com",
    databaseURL: "https://autoplan-web.firebaseio.com",
    projectId: "autoplan-web",
    storageBucket: "autoplan-web.appspot.com",
    messagingSenderId: "377027264354",
    appId: "1:377027264354:web:721a8fcb8917e91b02b63b",
  },
  tokens: ["ChocWazL8MRSepDJEGx6+XxQpzwKYJIInfViruxpINOq9rlowLAYdc3A05t3xufdDMOLCDMsd7nq95z9kYnYvg33D"],
  gtm_id: "GTM-TJVPVN5",
  statitics_url: "https://datastudio.google.com/embed/reporting/1KqIE2318vpacvNnsgkdZAkBgd-evvpaX/page/DVWQB",
  report_activities_url:
    "https://datastudio.google.com/embed/reporting/dcedc7cd-30ca-4500-b922-016bc06aece9/page/nYLqB",
  fcm_key: "BKam_lWTXtrE0CUZrG1GHD3vJXlSTsk6LPU__NLarONqNzQc_WOpFstKyVFAWu7EMYaZ1AxQTdMWY7oeGrJV5_g",
  algolia: {
    id: "ZEWKTLZEND",
    key: "c9e06528a1a28678ca656a26f8b212a2",
    admin_key: "8cb1b76aa4db257c8fc7eef1609e543c",
  },
  whatsappServerURL: "",
  hideOriginForAdvisors: false,
  allowAllocationSameAgent: false,
  allowAllocationByPortion: false,
  contactFilters: {
    country: { id: "country", name: "País", primary: true },
    agent_id: {
      id: "agent_id",
      name: "Agente",
      primary: true,
      readonly_advisor: true,
      show_attr: "full_name",
    },
  },
  contactImportData: [
    { header: "Nombres", key: "name", width: 30, type: "string" },
    { header: "Apellidos", key: "last_name", width: 30, type: "string" },
    { header: "DNI", key: "dni", width: 20, type: "string" },
    { header: "Correo electrónico", key: "email", width: 20, type: "email" },
    { header: "Teléfono", key: "phone", width: 20, type: "string" },
    { header: "Departamento", key: "city", width: 20, type: "string" },
    { header: "Provincia", key: "province", width: 20, type: "string" },
    { header: "Comentario", key: "comentarios", width: 20, type: "string" },
    {
      header: "Correo del Encargado",
      key: "agent_id",
      width: 20,
      type: "agent",
    },
    { header: "Origen", key: "origin", width: 20, type: "string" },
  ],
  contactExportData: [
    { header: "Nombres", key: "name", type: "string", width: 30 },
    { header: "Apellidos", key: "last_name", type: "last_name", width: 30 },
    { header: "DNI", key: "dni", width: 20, type: "string" },
    { header: "Correo electrónico", key: "email", type: "email", width: 30 },
    { header: "Teléfono", key: "phone", type: "string", width: 20 },
    { header: "País", key: "country", width: 20, type: "string" },
    { header: "Departamento", key: "city", width: 20, type: "string" },
    { header: "Provincia", key: "province", width: 20, type: "string" },
    { header: "Comentario", key: "comentarios", width: 20, type: "string" },
    { header: "Etiquetas", key: "tags", width: 20, type: "tags" },
    {
      header: "¿Aceptó términos y condiciones?",
      key: "terms",
      type: "terms",
      width: 20,
    },
    { header: "Encargado", key: "agent_id", type: "agent", width: 20 },
    {
      header: "Última actividad",
      key: "last_comment",
      type: "string",
      width: 20,
    },
    {
      header: "Fecha de última actividad",
      key: "last_activity",
      type: "timestamp",
      width: 20,
    },
    {
      header: "Fecha de registro",
      key: "created_at",
      type: "timestamp",
      width: 20,
    },
    { header: "Origen", key: "origin", type: "string", width: 20 },
  ],
  contactFormSchema: {
    name: {
      type: "text",
      label: "Nombres",
      placeholder: "Nombres",
      flex: { xs: 12, sm: 6 },
      rules: [(v) => !!v || "Campo requerido"],
      show_on_detail: true,
      form_update: true,
      searchable: true,
    },
    last_name: {
      type: "text",
      label: "Apellidos",
      placeholder: "Apellidos",
      flex: { xs: 12, sm: 6 },
      rules: [(v) => !!v || "Campo requerido"],
      show_on_detail: true,
      form_update: true,
      searchable: true,
    },
    dni: {
      type: "text",
      label: "DNI",
      placeholder: "DNI",
      flex: { xs: 12, sm: 6 },
      show_on_detail: true,
      form_update: true,
      searchable: true,
    },
    phone: {
      type: "text",
      label: "Teléfono",
      placeholder: "Teléfono",
      flex: { xs: 12, sm: 6 },
      rules: [
        (v) =>
          // eslint-disable-next-line
          /^$|^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/.test(v) || "Teléfono no válido",
      ],
      show_on_detail: true,
      form_update: true,
    },
    email: {
      type: "email",
      label: "Correo electrónico",
      placeholder: "Correo electrónico",
      flex: { xs: 12 },
      rules: [
        (v) => !!v || "Campo requerido",
        (v) =>
          // eslint-disable-next-line
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            v
          ) || "Correo electrónico no válido",
      ],
      show_on_detail: true,
      searchable: true,
    },
    country: {
      type: "select",
      label: "País",
      items: [],
      placeholder: "Seleccione",
      flex: { xs: 12, sm: 6, md: 4 },
      show_on_detail: true,
      form_update: true,
    },
    city: {
      type: "select",
      label: "Departamento",
      items: [],
      placeholder: "Seleccione",
      flex: { xs: 12, sm: 6, md: 4 },
      show_on_detail: true,
      form_update: true,
    },
    province: {
      type: "text",
      label: "Provincia",
      placeholder: "Provincia",
      flex: { xs: 12, sm: 6, md: 4 },
      show_on_detail: true,
      form_update: true,
    },
    comment: {
      type: "text",
      label: "Comentario",
      placeholder: "Comente aquí",
      flex: { xs: 12 },
    },
    agent: {
      type: "select",
      label: "Encargado",
      items: [],
      placeholder: "Seleccione",
      flex: { xs: 12, sm: 6 },
      rules: [(v) => !!v || "Campo requerido"],
    },
    newsletter: {
      type: "checkbox",
      label: "Desea recibir ofertas y promociones de Autoplan",
      flex: { xs: 12 },
      class: "pt-5",
    },
    terms: {
      type: "checkbox",
      label: "Aceptó los términos y condiciones de Autoplan",
      flex: { xs: 12 },
    },
    tags: {
      type: "hidden",
      label: "Etiquetas",
      flex: { xs: 12 },
    },
  },
  tableContact: [
    {
      text: "Contacto",
      align: "center",
      sortable: false,
      value: "full_name",
    },
    {
      text: "Email",
      align: "center",
      sortable: false,
      value: "email",
    },
    {
      text: "País",
      align: "center",
      sortable: false,
      value: "country",
    },
    {
      text: "Registrado",
      align: "center",
      value: "created_at",
      sortable: true,
    },
    {
      text: "Encargado",
      align: "center",
      sortable: false,
      value: "agent_id",
    },
    {
      text: "Operación",
      align: "center",
      sortable: false,
      value: "operations",
    },
  ],
  contactActivityImportData: [
    {
      header: "Correo electrónico",
      key: "contact_email",
      width: 20,
      type: "email",
    },
    {
      header: "Tipo de actividad",
      key: "activity_type",
      width: 20,
      type: "activity",
    },
    { header: "Comentario", key: "comment", width: 40, type: "string" },
  ],
  saleImportData: [
    { header: "Nombres", key: "name", width: 30, type: "string" },
    { header: "Apellidos", key: "last_name", width: 30, type: "string" },
    { header: "Correo electrónico", key: "email", width: 20, type: "email" },
    { header: "Teléfono", key: "phone", width: 20, type: "string" },
    { header: "Dirección", key: "address", width: 20, type: "string" },
    { header: "Producto", key: "products", width: 20, type: "product" },
    { header: "Cantidad", key: "quantity", width: 20, type: "product_detail" },
    { header: "Precio", key: "price", width: 20, type: "product_detail" },
    { header: "Descuento", key: "discount", width: 20, type: "number" },
    {
      header: "Total de compra*",
      key: "total_paid",
      width: 20,
      type: "number",
    },
    {
      header: "Método de pago (PAGO EXITOSO/PROCESANDO PAGO)",
      key: "payment_method",
      width: 20,
      type: "string",
    },
    {
      header: "Estado del pago",
      key: "paid_status",
      width: 20,
      type: "string",
    },
    { header: "Vendedor", key: "seller", width: 20, type: "string" },
    {
      header: "Fecha de venta (dd/mm/yyyy)",
      key: "sales_date",
      width: 20,
      type: "date",
    },
    { header: "Origen", key: "origin", width: 20, type: "string" },
  ],
  saleExportData: [
    { header: "Id", key: "id", type: "string", width: 30 },
    {
      header: "Nombres",
      key: "name",
      type: "string",
      width: 30,
      show_on_detail: true,
    },
    {
      header: "Apellidos",
      key: "last_name",
      type: "last_name",
      width: 30,
      show_on_detail: true,
    },
    {
      header: "Correo electrónico",
      key: "email",
      type: "email",
      width: 30,
      show_on_detail: true,
    },
    {
      header: "Teléfono",
      key: "phone",
      type: "string",
      width: 20,
      show_on_detail: true,
    },
    {
      header: "Dirección",
      key: "address",
      type: "string",
      width: 20,
      show_on_detail: true,
    },
    {
      header: "Producto/Cantidad/Precio",
      key: "products",
      type: "products",
      width: 30,
    },
    { header: "Descuento", key: "discount", width: 20, type: "number" },
    { header: "Total de compra", key: "total_paid", type: "number", width: 20 },
    { header: "Estado", key: "state", type: "string", width: 20 },
    {
      header: "Método de pago",
      key: "payment_method",
      type: "string",
      width: 20,
    },
    { header: "Encargado", key: "created_uid", width: 20, type: "agent" },
    { header: "Origen", key: "origin", width: 20, type: "string" },
    {
      header: "Fecha de registro",
      key: "created_at",
      type: "timestamp",
      width: 20,
    },
  ],
  orderFilters: {
    state: { id: "state", name: "Estado", primary: true },
    products_ids: { id: "products_ids", name: "Producto", primary: true },
  },
  ticketStates: [
    { id: "nuevo", name: "Nuevo" },
    { id: "interesado", name: "Interesado" },
    { id: "sin-respuesta", name: "Sin respuesta" },
    { id: "desestimado", name: "Desestimado" },
    { id: "curioso", name: "Curioso" },
    { id: "marketing", name: "Marketing" },
    { id: "cliente", name: "Cliente" },
  ],
  ticketFilters: {
    state: { id: "state", name: "Estado", primary: true },
    origin: { id: "origin", name: "Origen", primary: true },
    agent_id: {
      id: "agent_id",
      name: "Agente",
      primary: true,
      readonly_advisor: true,
      item_text: "full_name",
    },
  },
  tableTicket: [
    {
      text: "Nombre del Ticket",
      align: "left",
      value: "ticket_name",
    },
    {
      text: "Contacto",
      align: "center",
      sortable: false,
      value: "full_name",
    },
    {
      text: "Estado",
      align: "center",
      sortable: false,
      value: "state",
    },
    {
      text: "Registrado",
      align: "center",
      value: "created_at",
      sortable: true,
    },
    {
      text: "Origen",
      align: "center",
      sortable: false,
      value: "origin",
    },
    {
      text: "Encargado",
      align: "center",
      sortable: false,
      value: "agent_id",
    },
    {
      text: "Operación",
      align: "right",
      sortable: false,
      value: "operations",
    },
  ],
  ticketFormSchema: {
    ticket_name: {
      type: "text",
      label: "Nombre del ticket",
      placeholder: "Nombre del ticket",
      flex: { xs: 12 },
      show_on_detail: true,
      form_update: true,
    },
    name: {
      type: "text",
      label: "Nombres",
      placeholder: "Nombres",
      flex: { xs: 12, sm: 6 },
      rules: [(v) => !!v || "Campo requerido"],
      show_on_detail: true,
      form_update: true,
      searchable: true,
    },
    last_name: {
      type: "text",
      label: "Apellidos",
      placeholder: "Apellidos",
      flex: { xs: 12, sm: 6 },
      show_on_detail: true,
      form_update: true,
      searchable: true,
    },
    dni: {
      type: "text",
      label: "DNI",
      placeholder: "DNI",
      flex: { xs: 12, sm: 6 },
      show_on_detail: true,
      form_update: true,
      searchable: true,
    },
    phone: {
      type: "text",
      label: "Teléfono",
      placeholder: "Teléfono",
      flex: { xs: 12, sm: 6 },
      rules: [
        (v) =>
          // eslint-disable-next-line
          /^$|^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/.test(v) || "Teléfono no válido",
      ],
      show_on_detail: true,
    },
    email: {
      type: "email",
      label: "Correo electrónico",
      placeholder: "Correo electrónico",
      flex: { xs: 12, sm: 6 },
      rules: [
        (v) =>
          // eslint-disable-next-line
          !v ||
          (!!v &&
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
              v
            )) ||
          "Correo electrónico no válido",
      ],
      show_on_detail: true,
      searchable: true,
    },
    lead_type: {
      type: "select",
      label: "Tipo de cliente",
      items: ["Particular", "Comercial", "Taxi"],
      placeholder: "Seleccione",
      flex: { xs: 12, sm: 6 },
      show_on_detail: true,
      form_update: true,
    },
    country: {
      type: "autocomplete",
      label: "País",
      items: [],
      placeholder: "Seleccione",
      flex: { xs: 12, sm: 6 },
      show_on_detail: true,
      form_update: true,
    },
    city: {
      type: "combobox",
      label: "Departamento",
      items: [],
      placeholder: "Seleccione",
      flex: { xs: 12, sm: 6 },
      show_on_detail: true,
      form_update: true,
    },
    province: {
      type: "text",
      label: "Provincia",
      placeholder: "Provincia",
      flex: { xs: 12, sm: 6 },
      show_on_detail: true,
      form_update: true,
    },
    district: {
      type: "text",
      label: "Distrito",
      placeholder: "Distrito",
      flex: { xs: 12, sm: 6 },
      show_on_detail: true,
      form_update: true,
    },
    budget: {
      type: "select",
      label: "¿Cuánto te gustaría invertir?",
      placeholder: "¿Cuánto te gustaría invertir?",
      items: [
        "$ 198.12",
        "$ 217.92",
        "$ 237.74",
        "$ 257.56",
        "$ 277.36",
        "$ 297.18",
        "$ 316.99",
        "$ 336.78",
        "$ 356.60",
        "$ 376.43",
        "$ 396.23",
      ],
      flex: { xs: 12, sm: 6 },
      show_on_detail: true,
      form_update: true,
    },
    type_use: {
      type: "select",
      label: "Tipo de Uso",
      placeholder: "Tipo de Uso",
      items: ["particular", "comercial", "taxi"],
      flex: { xs: 12, sm: 6 },
      show_on_detail: true,
      form_update: true,
    },
    application: {
      type: "text",
      label: "Aplicación",
      placeholder: "Aplicación",
      flex: { xs: 12, sm: 6 },
      show_on_detail: true,
      form_update: true,
    },
    comment: {
      type: "text",
      label: "Comentario",
      placeholder: "Comente aquí",
      flex: { xs: 12 },
    },
    medium_contact: {
      type: "select",
      label: "Medio de contacto",
      items: ["Whatsapp", "Facebook", "Instagram", "Web", "Llamada", "Correo", "Sala de ventas", "Consulta", "Evento"],
      placeholder: "Seleccione",
      flex: { xs: 12, sm: 6 },
      rules: [(v) => !!v || "Campo requerido"],
      show_on_detail: true,
      form_update: true,
    },
    are_you_associated: {
      type: "checkbox",
      label: "¿Está asociado?",
      flex: { xs: 12, sm: 6 },
      show_on_detail: true,
      form_update: true,
      data_type: "boolean",
    },
    origin: {
      type: "autocomplete",
      label: "Origen",
      items: [],
      placeholder: "Seleccione",
      flex: { xs: 12, sm: 6 },
    },
    agent: {
      type: "select",
      label: "Encargado",
      items: [],
      placeholder: "Seleccione",
      flex: { xs: 12, sm: 6 },
      rules: [(v) => !!v || "Campo requerido"],
    },
    tags: {
      type: "hidden",
      data_type: "tags",
      label: "Etiquetas",
      flex: { xs: 12 },
    },
    terms: {
      type: "hidden",
      data_type: "boolean",
      label: "Términos y condiciones",
      flex: { xs: 12 },
    },
  },
  ticketImportData: [
    {
      header: "Nombre del Ticket",
      key: "ticket_name",
      type: "string",
      width: 30,
    },
    {
      header: "Nombres*",
      key: "name",
      width: 30,
      type: "string",
      validate: true,
      required: true,
    },
    { header: "Apellidos", key: "last_name", width: 30, type: "string" },
    { header: "DNI", key: "dni", width: 20, type: "string" },
    { header: "Correo electrónico", key: "email", width: 20, type: "email" },
    { header: "Teléfono", key: "phone", width: 20, type: "string" },
    {
      header: "Tipo de cliente (Particular/Comercial/Taxi)",
      key: "lead_type",
      width: 20,
      type: "string",
    },
    {
      header: "¿Cuánto te gustaría invertir?",
      key: "budget",
      width: 20,
      type: "string",
    },
    { header: "Departamento", key: "city", width: 20, type: "string" },
    { header: "Provincia", key: "province", width: 20, type: "string" },
    { header: "Comentario", key: "comentarios", width: 20, type: "string" },
    {
      header: "Correo del Encargado",
      key: "agent_id",
      width: 20,
      type: "agent",
      validate: true,
    },
    {
      header: "Términos y condiciones (si/no)",
      key: "terms",
      width: 20,
      type: "boolean",
    },
    {
      header: "Origen*",
      key: "origin",
      width: 20,
      type: "string",
      validate: true,
      required: true,
    },
  ],
  ticketExportData: [
    { header: "Ticket", key: "ticket_name", type: "string", width: 30 },
    { header: "Nombres", key: "name", type: "string", width: 30 },
    { header: "Apellidos", key: "last_name", type: "last_name", width: 30 },
    { header: "DNI", key: "dni", width: 20, type: "string" },
    { header: "Correo electrónico", key: "email", type: "email", width: 30 },
    { header: "Teléfono", key: "phone", type: "string", width: 20 },
    { header: "Tipo de cliente", key: "lead_type", type: "string", width: 20 },
    { header: "País", key: "country", width: 20, type: "string" },
    { header: "Departamento", key: "city", width: 20, type: "string" },
    { header: "Provincia", key: "province", width: 20, type: "string" },
    { header: "¿Cuánto te gustaría invertir?", key: "budget", width: 20, type: "string" },
    { header: "Tipo de uso", key: "type_use", width: 20, type: "string" },
    { header: "Aplicación", key: "application", width: 20, type: "string" },
    {
      header: "¿Está asociado?",
      key: "are_you_associated",
      width: 20,
      type: "boolean",
    },
    { header: "Comentario", key: "comentarios", width: 20, type: "string" },
    {
      header: "Términos y condiciones (si/no)",
      key: "terms",
      width: 20,
      type: "boolean",
    },
    { header: "Etiquetas", key: "tags", width: 20, type: "tags" },
    { header: "Estado", key: "state", type: "state", width: 20 },
    { header: "Proyecto", key: "project", type: "string", width: 20 },
    { header: "Encargado", key: "agent_id", type: "agent", width: 20 },
    {
      header: "Fecha de registro",
      key: "created_at",
      type: "timestamp",
      width: 20,
    },
    {
      header: "Última actividad",
      key: "last_comment",
      type: "string",
      width: 20,
    },
    {
      header: "Fecha de última actividad",
      key: "last_activity",
      type: "timestamp",
      width: 20,
    },
    { header: "Origen", key: "origin", type: "string", width: 20 },
  ],
  ticketActivityImportData: [
    { header: "ID del Ticket", key: "ticket_id", width: 20, type: "string" },
    {
      header: "Tipo de actividad",
      key: "activity_type",
      width: 20,
      type: "activity",
    },
    { header: "Comentario", key: "comment", width: 40, type: "string" },
  ],
  orderFormSchema: {
    name: {
      type: "text",
      label: "Nombres",
      placeholder: "Nombres",
      flex: { xs: 12, sm: 6 },
      rules: ["required"],
      order: 2,
      searchable: true,
    },
    last_name: {
      type: "text",
      label: "Apellidos",
      placeholder: "Apellidos",
      flex: { xs: 12, sm: 6 },
      order: 3,
      searchable: true,
    },
    phone: {
      type: "text",
      label: "Teléfono",
      placeholder: "Teléfono",
      flex: { xs: 12, sm: 6 },
      order: 4,
    },
    dni: {
      type: "text",
      label: "DNI",
      placeholder: "DNI",
      flex: { xs: 12, sm: 6 },
      show_on_detail: true,
      form_update: true,
      searchable: true,
    },
    email: {
      type: "email",
      label: "Correo electrónico",
      placeholder: "Correo electrónico",
      flex: { xs: 12 },
      rules: ["required", "email"],
      order: 6,
      searchable: true,
    },
    document_type: {
      type: "select",
      label: "Tipo de documento",
      items: ["DNI", "Carnet de extranjería"],
      placeholder: "Seleccione",
      flex: { sm: 6 },
      order: 7,
    },
    document_number: {
      type: "text",
      label: "Número de documento",
      placeholder: "Número de documento",
      flex: { sm: 6 },
      order: 7,
      searchable: true,
    },
    origin: {
      type: "autocomplete",
      label: "Origen",
      items: ["whatsApp", "facebook", "instagram", "twitter", "linkedIn", "tienda"],
      placeholder: "Seleccione",
      rules: ["required"],
      flex: { xs: 12, sm: 6 },
      order: 9,
    },
  },
  agentTable: [
    {
      text: "Encargado",
      align: "left",
      value: "full_name",
    },
    {
      text: "Correo electrónico",
      align: "center",
      value: "email",
    },
    {
      text: "Rol",
      align: "center",
      sortable: false,
      value: "role",
    },
    {
      text: "Equipo",
      align: "center",
      value: "team",
    },
    {
      text: "Proyectos",
      align: "center",
      value: "projects",
    },
    {
      text: "Estado",
      align: "center",
      sortable: false,
      value: "active",
    },
    {
      text: "Operación",
      align: "center",
      sortable: false,
      value: "operations",
    },
  ],
  agentFormSchema: {
    name: {
      type: "text",
      label: "Nombres",
      placeholder: "Nombres",
      flex: { xs: 12, sm: 6 },
      rules: [(v) => !!v || "Campo requerido"],
      show_on_detail: true,
      form_update: true,
    },
    last_name: {
      type: "text",
      label: "Apellidos",
      placeholder: "Apellidos",
      flex: { xs: 12, sm: 6 },
      rules: [(v) => !!v || "Campo requerido"],
      show_on_detail: true,
      form_update: true,
    },
    email: {
      type: "email",
      label: "Correo electrónico",
      placeholder: "Correo electrónico",
      flex: { xs: 12 },
      rules: [
        (v) => !!v || "Campo requerido",
        (v) =>
          // eslint-disable-next-line
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            v
          ) || "Correo electrónico no válido",
      ],
      show_on_detail: true,
    },
  },
  theme: {
    // dark: true,
    themes: {
      light: {
        primary: "#683cbe",
        secondary: "#ff6600",
        accent: "#607d8b",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FB8C00",
      },
      dark: {
        primary: "#683cbe",
        secondary: "#424242",
        accent: "#82B1FF",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FB8C00",
      },
    },
  },
};
