<template>
  <v-bottom-navigation :model="activeOptions.length" fixed grow :style="cssProps">
    <v-btn v-for="item in activeOptions" :key="item.title" link :to="item.path">
      <v-icon small>{{ item.icon }}</v-icon>
    </v-btn>
  </v-bottom-navigation>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "BottomNavigationApp",
  computed: {
    ...mapGetters(["getMenuOptions"]),
    activeOptions() {
      const items = this.getMenuOptions.filter((item) => item.showOnBottomBar === true);
      return [
        ...items,
        {
          icon: "fas fa-bars",
          title: "Menu",
          path: "/menu",
          showOnBottomBar: true,
          items: [],
        },
      ];
    },
    cssProps() {
      var colorPrimary = this.$vuetify.theme.dark
        ? this.$vuetify.theme.themes["dark"].primary
        : this.$vuetify.theme.themes["light"].primary;
      return {
        "--primary-color": colorPrimary,
      };
    },
  },
};
</script>
<style>
.v-item-group.v-bottom-navigation .v-btn.v-btn--active {
  color: var(--primary-color) !important;
  /* border-top: 4px solid var(--primary-color); */
}
.v-item-group.v-bottom-navigation .v-btn.v-btn--active:not(:hover):before {
  opacity: 0.12;
}
</style>
