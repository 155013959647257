var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-navigation-drawer",
    {
      attrs: { temporary: "", right: "", app: "", width: "400" },
      model: {
        value: _vm.localDrawer,
        callback: function($$v) {
          _vm.localDrawer = $$v
        },
        expression: "localDrawer"
      }
    },
    [
      _c("v-card", { staticClass: "pt-4 elevation-0" }, [
        _c(
          "div",
          { staticClass: "d-flex justify-end hidden-md-and-up" },
          [
            _c(
              "v-btn",
              {
                staticClass: "ma-1",
                attrs: {
                  "x-small": "",
                  outlined: "",
                  fixed: "",
                  fab: "",
                  right: ""
                },
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                    $event.preventDefault()
                    _vm.localDrawer = false
                  }
                }
              },
              [
                _c("v-icon", { attrs: { small: "" } }, [_vm._v("fas fa-times")])
              ],
              1
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _vm.getTicket
        ? [
            _c(
              "v-toolbar",
              {
                attrs: { flat: "" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "extension",
                      fn: function() {
                        return [
                          _c(
                            "v-tabs",
                            {
                              attrs: { "slider-color": "primary" },
                              model: {
                                value: _vm.tab,
                                callback: function($$v) {
                                  _vm.tab = $$v
                                },
                                expression: "tab"
                              }
                            },
                            [
                              _c(
                                "v-tab",
                                { key: "1", attrs: { href: "#tab-1" } },
                                [_vm._v("información")]
                              ),
                              _vm._v(" "),
                              _c(
                                "v-tab",
                                { key: "2", attrs: { href: "#tab-2" } },
                                [_vm._v("Actividad")]
                              ),
                              _vm._v(" "),
                              _c(
                                "v-tab",
                                { key: "3", attrs: { href: "#tab-3" } },
                                [_vm._v("Relacionado")]
                              )
                            ],
                            1
                          )
                        ]
                      },
                      proxy: true
                    }
                  ],
                  null,
                  false,
                  890437812
                )
              },
              [
                _c(
                  "v-list-item",
                  { staticClass: "pa-0", attrs: { "two-line": "" } },
                  [
                    _c(
                      "v-list-item-avatar",
                      {
                        staticClass: "pa-2",
                        attrs: { size: "48", color: "accent" }
                      },
                      [
                        _c("span", { staticClass: "white--text subhead" }, [
                          _vm._v(" " + _vm._s(_vm.avatarText))
                        ])
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "v-list-item-content",
                      [
                        _c("v-list-item-title", {
                          domProps: {
                            textContent: _vm._s("" + _vm.getTicket.full_name)
                          }
                        }),
                        _vm._v(" "),
                        _c("v-list-item-subtitle", {
                          domProps: { textContent: _vm._s(_vm.getTicket.email) }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "v-tabs-items",
              {
                model: {
                  value: _vm.tab,
                  callback: function($$v) {
                    _vm.tab = $$v
                  },
                  expression: "tab"
                }
              },
              [
                _c(
                  "v-tab-item",
                  { key: "1", attrs: { value: "tab-1" } },
                  [
                    !_vm.editInformation
                      ? _c(
                          "div",
                          { staticClass: "text-right px-4" },
                          [
                            _c(
                              "v-tooltip",
                              {
                                attrs: { top: "" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function(ref) {
                                        var on = ref.on
                                        return [
                                          _c(
                                            "v-btn",
                                            _vm._g(
                                              {
                                                attrs: {
                                                  color: "primary",
                                                  fab: "",
                                                  "x-small": "",
                                                  dark: ""
                                                }
                                              },
                                              on
                                            ),
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  attrs: { size: "16" },
                                                  on: {
                                                    click: function($event) {
                                                      $event.stopPropagation()
                                                      $event.preventDefault()
                                                      _vm.editInformation = true
                                                    }
                                                  }
                                                },
                                                [_vm._v("fas fa-pencil-alt")]
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  2892898916
                                )
                              },
                              [
                                _vm._v(" "),
                                _c("span", [_vm._v("Editar información")])
                              ]
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "v-form",
                      {
                        ref: "formInformation",
                        attrs: { "lazy-validation": "" }
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "fill-height text-center px-3 pt-2" },
                          [
                            _vm.editInformation
                              ? _c("v-col", { attrs: { cols: "12" } }, [
                                  _c("h4", [
                                    _vm._v(
                                      "Editar información de la Oportunidad"
                                    )
                                  ])
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.editInformation
                              ? _c(
                                  "v-col",
                                  { attrs: { cols: "12" } },
                                  [
                                    _c("TicketForm", {
                                      attrs: { action: "update" },
                                      on: {
                                        cancelInformation: _vm.cancelInformation
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _c(
                                  "v-card",
                                  { staticClass: "mb-4" },
                                  [
                                    _vm._l(_vm.ticketFields, function(
                                      ticketField,
                                      i
                                    ) {
                                      return [
                                        _c(
                                          "v-list",
                                          { key: i, staticClass: "text-left" },
                                          [
                                            _c(
                                              "v-list-item",
                                              [
                                                _c(
                                                  "v-list-item-content",
                                                  [
                                                    _c(
                                                      "v-list-item-title",
                                                      {
                                                        staticClass:
                                                          "font-weight-regular"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                        " +
                                                            _vm._s(
                                                              _vm.getTicket[
                                                                ticketField
                                                                  .field
                                                              ]
                                                            ) +
                                                            "\n                        "
                                                        ),
                                                        ticketField.field ===
                                                          "phone" &&
                                                        _vm.getTicket.phone
                                                          ? _c("BtnWhatsapp", {
                                                              attrs: {
                                                                number:
                                                                  _vm.getTicket
                                                                    .phone
                                                              }
                                                            })
                                                          : _vm._e(),
                                                        _vm._v(" "),
                                                        ticketField.field ===
                                                          "email" &&
                                                        _vm.getTicket.email
                                                          ? _c("BtnGmail", {
                                                              attrs: {
                                                                email:
                                                                  _vm.getTicket
                                                                    .email
                                                              }
                                                            })
                                                          : _vm._e()
                                                      ],
                                                      1
                                                    ),
                                                    _vm._v(" "),
                                                    _c("v-list-item-subtitle", [
                                                      _vm._v(
                                                        _vm._s(
                                                          ticketField.label
                                                        )
                                                      )
                                                    ])
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c("v-divider", {
                                              staticClass: "mx-2"
                                            })
                                          ],
                                          1
                                        )
                                      ]
                                    })
                                  ],
                                  2
                                )
                          ],
                          1
                        )
                      ]
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "v-tab-item",
                  { key: "2", attrs: { value: "tab-2" } },
                  [
                    _c(
                      "v-card",
                      { staticClass: "pa-2 py-4 ma-2" },
                      [
                        _c("CommentForm", {
                          attrs: {
                            "ticket-id": _vm.getSelectedTicketId,
                            "col-fixed": 12
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "v-card",
                      { staticClass: "pa-2 py-4 ma-2" },
                      [
                        _c("Tags"),
                        _vm._v(" "),
                        _c("v-divider", { staticClass: "mt-4" }),
                        _vm._v(" "),
                        _c("Timeline", {
                          attrs: { "ticket-id": _vm.getSelectedTicketId }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "v-tab-item",
                  { key: "3", attrs: { value: "tab-3" } },
                  [
                    _c(
                      "v-card",
                      { staticClass: "ma-2 pa-2" },
                      [
                        _c(
                          "div",
                          { staticClass: "d-flex justify-space-between" },
                          [
                            _c("p", { staticClass: "font-weight-black" }, [
                              _vm.editState
                                ? _c("span", [_vm._v("EDITAR")])
                                : _vm._e(),
                              _vm._v(" ESTADO")
                            ])
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "v-form",
                          {
                            ref: "formState",
                            attrs: { "lazy-validation": "" }
                          },
                          [
                            _c(
                              "v-row",
                              {
                                staticClass: "fill-height text-center px-3 pt-2"
                              },
                              [
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12" } },
                                  [
                                    _c("v-autocomplete", {
                                      staticClass: "pt-2",
                                      staticStyle: { "font-size": "15px" },
                                      attrs: {
                                        value: _vm.getTicket.state,
                                        items: _vm.getActiveTicketStates,
                                        readonly:
                                          _vm.getTicketStatesMap[
                                            _vm.getTicket.state
                                          ].final,
                                        rules: _vm.stateCustomRules,
                                        "item-text": "name",
                                        "item-value": "id",
                                        label: "Estado"
                                      },
                                      model: {
                                        value: _vm.state,
                                        callback: function($$v) {
                                          _vm.state = $$v
                                        },
                                        expression: "state"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _vm.editState
                                  ? _c(
                                      "v-col",
                                      { attrs: { cols: "12" } },
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              loading: _vm.loadSaveState,
                                              small: "",
                                              rounded: "",
                                              color: "primary"
                                            },
                                            on: { click: _vm.saveState }
                                          },
                                          [_vm._v("Guardar")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: { small: "", rounded: "" },
                                            on: { click: _vm.cancelState }
                                          },
                                          [_vm._v("Cancelar")]
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.getProducts && _vm.getProducts.length && _vm.products
                      ? _c(
                          "v-card",
                          { staticClass: "ma-2 pa-2" },
                          [
                            _c(
                              "div",
                              { staticClass: "d-flex justify-space-between" },
                              [
                                _c("p", { staticClass: "font-weight-black" }, [
                                  _vm.editProducts
                                    ? _c("span", [_vm._v("EDITAR")])
                                    : _vm._e(),
                                  _vm._v(
                                    "\n              PRODUCTOS RELACIONADOS\n            "
                                  )
                                ]),
                                _vm._v(" "),
                                _vm.getTicket.state !==
                                _vm.$ticketStates.ORDEN_CREADA
                                  ? _c(
                                      "div",
                                      { staticClass: "text-right" },
                                      [
                                        _c(
                                          "v-tooltip",
                                          {
                                            attrs: { bottom: "" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "activator",
                                                  fn: function(ref) {
                                                    var on = ref.on
                                                    return [
                                                      _c(
                                                        "v-btn",
                                                        _vm._g(
                                                          {
                                                            attrs: {
                                                              icon: "",
                                                              small: ""
                                                            },
                                                            on: {
                                                              click:
                                                                _vm.addItemProduct
                                                            }
                                                          },
                                                          on
                                                        ),
                                                        [
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              attrs: {
                                                                small: ""
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "fas fa-plus"
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              false,
                                              2430396974
                                            )
                                          },
                                          [
                                            _vm._v(" "),
                                            _c("span", [
                                              _vm._v("Agregar producto")
                                            ])
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "v-form",
                              {
                                ref: "formProducts",
                                attrs: { "lazy-validation": "" }
                              },
                              [
                                _c("ItemsProducts", {
                                  attrs: {
                                    products: _vm.products,
                                    action: _vm.actionItemsProducts
                                  },
                                  on: {
                                    "update:products": function($event) {
                                      _vm.products = $event
                                    },
                                    "update:action": function($event) {
                                      _vm.actionItemsProducts = $event
                                    }
                                  }
                                }),
                                _vm._v(" "),
                                _vm.editProducts
                                  ? _c(
                                      "v-col",
                                      {
                                        staticClass: "text-center",
                                        attrs: { cols: "12" }
                                      },
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              loading: _vm.loadSaveProducts,
                                              small: "",
                                              rounded: "",
                                              color: "primary"
                                            },
                                            on: {
                                              click: _vm.saveTicketProducts
                                            }
                                          },
                                          [_vm._v("Guardar")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: { small: "", rounded: "" },
                                            on: { click: _vm.cancelProducts }
                                          },
                                          [_vm._v("Cancelar")]
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "v-card",
                      { staticClass: "ma-2 pa-2" },
                      [
                        _c("p", { staticClass: "font-weight-black" }, [
                          _vm._v("ASISTENCIA")
                        ]),
                        _vm._v(" "),
                        _c(
                          "v-col",
                          { staticClass: "d-flex", attrs: { cols: "12" } },
                          [
                            _vm.getTicket.agent_id && !_vm.loadSaveAgent
                              ? _c(
                                  "div",
                                  [
                                    _c(
                                      "v-avatar",
                                      {
                                        attrs: { size: "48px", color: "black" }
                                      },
                                      [
                                        _vm.getAllAgentsMap[
                                          _vm.getTicket.agent_id
                                        ].photo
                                          ? _c("img", {
                                              attrs: {
                                                src:
                                                  _vm.getAllAgentsMap[
                                                    _vm.getTicket.agent_id
                                                  ].photo,
                                                alt: "Avatar"
                                              }
                                            })
                                          : _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "white--text headline"
                                              },
                                              [
                                                _vm._v(
                                                  "\n                  " +
                                                    _vm._s(
                                                      _vm.getAllAgentsMap[
                                                        _vm.getTicket.agent_id
                                                      ].full_name.substring(
                                                        0,
                                                        1
                                                      )
                                                    ) +
                                                    "\n                "
                                                )
                                              ]
                                            )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              : _c(
                                  "div",
                                  [
                                    _c(
                                      "v-avatar",
                                      {
                                        attrs: { size: "50px", color: "black" }
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "white--text headline"
                                          },
                                          [_vm._v("-")]
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                ),
                            _vm._v(" "),
                            _vm.getAgent.role === _vm.$roles.ADVISOR ||
                            _vm.getTicketStatesMap[_vm.getTicket.state].final
                              ? _c("v-text-field", {
                                  staticClass: "pl-2",
                                  attrs: { label: "Encargado", readonly: "" },
                                  model: {
                                    value: _vm.getAgent.full_name,
                                    callback: function($$v) {
                                      _vm.$set(_vm.getAgent, "full_name", $$v)
                                    },
                                    expression: "getAgent.full_name"
                                  }
                                })
                              : _c("v-autocomplete", {
                                  staticClass: "pl-2 w95",
                                  attrs: {
                                    items: _vm.getAgentsToArray,
                                    disabled: _vm.loadSaveAgent,
                                    "item-text": "full_name",
                                    "item-value": "uid",
                                    value: _vm.getTicket.agent_id,
                                    label: "Encargado",
                                    placeholder: "Sin asignar"
                                  },
                                  model: {
                                    value: _vm.agentTicket,
                                    callback: function($$v) {
                                      _vm.agentTicket = $$v
                                    },
                                    expression: "agentTicket"
                                  }
                                })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    !_vm.hideOriginForAdvisors ||
                    _vm.getAgent.role !== _vm.$roles.ADVISOR
                      ? _c(
                          "v-card",
                          { staticClass: "ma-2 pa-2" },
                          [
                            _c("p", { staticClass: "font-weight-black" }, [
                              _vm._v("FUENTE")
                            ]),
                            _vm._v(" "),
                            _c(
                              "v-col",
                              { staticClass: "size", attrs: { cols: "12" } },
                              [
                                _c("div", [
                                  _vm._v("\n              Obtenido desde "),
                                  _c("strong", [
                                    _vm._v(_vm._s(_vm.getTicket.origin))
                                  ])
                                ])
                              ]
                            ),
                            _vm._v(" "),
                            _vm.getTicket.created_uid
                              ? _c(
                                  "v-col",
                                  {
                                    staticClass: "d-flex",
                                    attrs: { cols: "12" }
                                  },
                                  [
                                    _c(
                                      "div",
                                      [
                                        _c(
                                          "v-avatar",
                                          {
                                            attrs: {
                                              size: "50px",
                                              color: "black"
                                            }
                                          },
                                          [
                                            _vm.getAllAgentsMap[
                                              _vm.getTicket.created_uid
                                            ].photo
                                              ? _c("img", {
                                                  attrs: {
                                                    src:
                                                      _vm.getAllAgentsMap[
                                                        _vm.getTicket
                                                          .created_uid
                                                      ].photo,
                                                    alt: "Avatar"
                                                  }
                                                })
                                              : _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "white--text headline"
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                  " +
                                                        _vm._s(
                                                          _vm.getAllAgentsMap[
                                                            _vm.getTicket
                                                              .created_uid
                                                          ].full_name.substring(
                                                            0,
                                                            1
                                                          )
                                                        ) +
                                                        "\n                "
                                                    )
                                                  ]
                                                )
                                          ]
                                        )
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c("v-text-field", {
                                      staticClass: "pl-2 w95",
                                      attrs: {
                                        value:
                                          _vm.getAllAgentsMap[
                                            _vm.getTicket.created_uid
                                          ].full_name,
                                        label: "Registrado por:",
                                        readonly: ""
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      : _vm._e()
                  ],
                  1
                )
              ],
              1
            )
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }