import * as MutationsType from "./mutationsType";
import { originsCollection } from "@/config/firebase";
import { roles } from "@/helpers/get_constants";

export default {
  async getAllOrigins({ commit, rootState }) {
    const agent = rootState.Agent.agent;
    let reference = originsCollection;
    let origins = [];

    if (agent.role === roles.ENTREPRENEUR) {
      reference = reference.where("team_id", "==", agent.team_id);

      const snapDefaultOrigins = await originsCollection.where("default", "==", true).get();
      snapDefaultOrigins.forEach((doc) => {
        origins.push({ ...doc.data(), id: doc.id });
      });
    }

    const snap = await reference.get();

    if (!snap.empty) {
      snap.forEach((doc) => {
        origins.push({ ...doc.data(), id: doc.id });
      });
    }

    commit(MutationsType.SET_ORIGINS, origins);
  },
};
