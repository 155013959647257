<template>
  <div>
    <Card id="sendgrid" name="SendGrid" @open="open" />
  </div>
</template>
<script>
import Card from "@/components/integrations/Card";

export default {
  name: "SendGridCard",
  components: {
    Card,
  },
  props: {
    dialogSendgrid: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    open() {
      this.$emit("update:dialogSendgrid", true);
    },
  },
};
</script>
