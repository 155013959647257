<template>
  <v-app>
    <div v-if="getAgent && getAgents && getActiveTicketStates && getTickets">
      <div>
        <TicketHeader
          :query.sync="query"
          :show-filters.sync="showFilters"
          :custom-filters.sync="customFilters"
          @loadTickets="loadTickets"
          @applyFilter="applyFilter"
        />
        <div class="px-4 hidden-md-and-up">
          <v-btn :to="{ name: 'ticket_register' }" color="primary" elevation-12 block>
            <span> <v-icon size="16" class="mr-2">fa-plus</v-icon> Agregar </span>
          </v-btn>
        </div>
      </div>

      <v-card class="mb-5 pb-5 rounded-lg">
        <v-progress-linear
          :active="loadingTickets"
          :indeterminate="true"
          absolute
          top
          color="secondary accent-4"
        ></v-progress-linear>
        <v-col v-if="tickets && getTicketStatesMap" cols="12" class="px-4 py-4">
          <v-data-table
            :headers="headers"
            :items="tickets"
            :item-class="(item) => (item.id == getSelectedTicketId ? 'accent' : '')"
            :items-per-page="50"
            dense
            sort-by
            :mobile-breakpoint="0"
            hide-default-footer
            class="text-headers-colors"
            @click:row="(item, row) => showTicketDetail({ ticketId: item.id })"
          >
            <template v-slot:item.created_at="{ item }">
              {{ $formatTimestamp(item.created_at, false, true) }}
            </template>
            <template v-slot:item.last_activity="{ item }">
              {{ $formatTimestamp(item.last_activity, false, true) }}
            </template>
            <template v-slot:item.full_name="{ item }"> {{ item.name }} {{ item.last_name }} </template>
            <template v-slot:item.state="{ item }">
              {{ getTicketStatesMap[item.state] ? getTicketStatesMap[item.state].name : "" }}
            </template>
            <template v-slot:item.created_uid="{ item }">
              {{ item.created_uid !== "none" && item.created_uid ? getAllAgentsMap[item.created_uid].name : "-" }}
            </template>
            <template v-slot:item.agent_id="{ item }">
              {{ item.agent_id !== "none" && item.agent_id ? getAllAgentsMap[item.agent_id].name : "Sin asignar" }}
            </template>
            <template v-slot:item.operations="{ item }">
              <BtnWhatsapp v-if="item.phone" :number="item.phone" />
              <BtnGmail v-if="item.email" :email="item.email" />
              <v-btn :to="{ name: 'ticket_detail', params: { id: item.id } }" target="_blank" text icon @click.stop>
                <v-icon color="primary" dark small>fas fa-external-link-alt</v-icon>
              </v-btn>
            </template>
          </v-data-table>
          <v-row class="pa-0">
            <v-spacer></v-spacer>
            <v-col cols="12" md="6" class="text-center">
              <v-pagination
                v-model="page"
                :length="totalPage"
                prev-icon="fas fa-angle-left"
                next-icon="fas fa-angle-right"
                total-visible="6"
                @input="loadTickets"
              ></v-pagination>
            </v-col>
            <v-col cols="12" md="3" class="d-flex align-center justify-end">
              <h5 class="font-weight-thin">
                Total de tickets: {{ totalTickets - getTickets.length + tickets.length }}
              </h5>
            </v-col>
          </v-row>
        </v-col>
        <!-- End Table -->
      </v-card>

      <v-btn
        :to="{ name: 'ticket_register' }"
        fixed
        bottom
        right
        x-large
        color="primary"
        elevation-12
        rounded
        class="hidden-sm-and-down"
      >
        <span> <v-icon size="16" class="mr-2">fa-plus</v-icon> Agregar </span>
      </v-btn>
    </div>
    <v-container v-else>
      <ShowLoading />
    </v-container>
  </v-app>
</template>

<script>
import Vue from "vue";
import { mapGetters, mapActions } from "vuex";
import config from "Config/config";
import TicketHeader from "@/views/tickets/inc/Header.vue";
import BtnWhatsapp from "@/components/buttons/BtnWhatsapp.vue";
import BtnGmail from "@/components/buttons/BtnGmail.vue";

export default {
  name: "Tickets",
  components: {
    TicketHeader,
    BtnWhatsapp,
    BtnGmail,
  },
  data() {
    return {
      tickets: null,
      customFilters: config.ticketFilters,
      filtersSelected: {},
      query: "",
      filters: "",
      page: 1,
      countAppliedFilters: 0,
      totalPage: 1,
      totalTickets: 0,
      headers: [],
      loadingTickets: true,
      showFilters: true,
    };
  },
  computed: {
    ...mapGetters([
      "getAgent",
      "getAgents",
      "getAllAgentsArray",
      "getAllAgentsMap",
      "getTickets",
      "getNewTickets",
      "getTicketStatesMap",
      "getActiveTicketStates",
      "getSelectedTicketId",
      "getProducts",
      "getOrigins",
    ]),
  },
  watch: {
    getAgent(value) {
      if (value) this.init();
    },
    getTickets(value) {
      this.tickets = [...value];
    },
    getNewTickets(value) {
      if (value && this.query === "" && this.countAppliedFilters === 0) {
        for (const ticket of value) {
          let ind = this.tickets.findIndex((t) => t.id === ticket.id);
          if (ind >= 0) Vue.set(this.tickets, ind, ticket);
          else this.tickets.unshift(ticket);
        }
      }
    },
  },
  async created() {
    if (this.getAgent) this.init();
  },
  methods: {
    ...mapActions([
      "searchTickets",
      "getAllAgents",
      "downloadTickets",
      "showTicketDetail",
      "setGroupTickets",
      "getAllNewTickets",
      "getAllTicketStates",
      "getAllProducts",
      "getAllOrigins",
    ]),
    async init() {
      if (!this.getProducts) await this.getAllProducts();
      if (!this.getActiveTicketStates) await this.getAllTicketStates();
      if (!this.getAllAgentsArray) await this.getAllAgents();
      if (!this.getOrigins) await this.getAllOrigins();

      this.loadTableHeader();
      this.loadFilters();

      await this.loadTickets();

      this.getAllNewTickets({ last_ticket: this.tickets[0] });
    },
    loadTableHeader() {
      let headers;

      if (this.getAgent.role === this.$roles.ADVISOR) headers = config.tableTicket.filter((t) => !t.hide_advisor);

      this.headers = headers || config.tableTicket;
    },
    loadFilters() {
      if (this.getAgent.role === this.$roles.ADVISOR) this.filtersSelected["agent_id"] = this.getAgent.uid;
      if ([this.$roles.SUPERVISOR, this.$roles.ENTREPRENEUR].includes(this.getAgent.role))
        this.filtersSelected["team_id"] = this.getAgent.team_id;

      for (let filter in this.customFilters) {
        let items = [{ id: "", name: "Todos", full_name: "Todos" }];

        if (filter === "state") items.push(...this.getActiveTicketStates);
        else if (filter === "agent_id") items.push({ id: "none", full_name: "Sin asignar" }, ...this.getAllAgentsArray);
        else if (filter === "origin") items.push(...this.getOrigins.map((o) => ({ ...o, id: o.name })));

        this.customFilters[filter] = {
          ...this.customFilters[filter],
          items,
          value: this.filtersSelected[filter] || "",
        };

        this.filtersSelected[filter] = this.customFilters[filter].value;
      }
    },
    applyFilter(filterId, filterValue) {
      if (!this.customFilters[filterId].value && filterValue) this.countAppliedFilters += 1;
      if (this.customFilters[filterId].value && !filterValue) this.countAppliedFilters -= 1;

      this.customFilters[filterId].value = filterValue;
      this.filtersSelected[filterId] = filterValue;
      this.page = 1;

      this.loadTickets();
    },
    async loadTickets() {
      try {
        this.loadingTickets = true;

        let { data } = await this.searchTickets({
          filters: this.filtersSelected,
          search: this.query,
          page: this.page - 1,
        });

        this.tickets = data.results || [];
        this.totalPage = data.pagination.total_pages;
        this.totalTickets = data.pagination.total;
        this.page = data.pagination.page + 1;

        this.setGroupTickets({ tickets_selected: this.tickets });
      } catch (error) {
        console.error(error);
      } finally {
        this.loadingTickets = false;
      }
    },
  },
};
</script>

<style>
.max-w180 {
  max-width: 180px;
}

.v-avatar {
  cursor: pointer;
}

.text-decoration-none {
  text-decoration: none;
}

th[aria-label="Email: Not sorted."],
.hidden {
  display: none;
}

.v-btn {
  text-transform: none;
}

.imput-icon .v-input__icon--append i {
  font-size: 16px;
}
</style>
