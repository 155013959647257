var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-bottom-navigation",
    {
      style: _vm.cssProps,
      attrs: { model: _vm.activeOptions.length, fixed: "", grow: "" }
    },
    _vm._l(_vm.activeOptions, function(item) {
      return _c(
        "v-btn",
        { key: item.title, attrs: { link: "", to: item.path } },
        [_c("v-icon", { attrs: { small: "" } }, [_vm._v(_vm._s(item.icon))])],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }