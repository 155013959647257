import axios from "axios";

async function getCountryList(toSelected = false) {
  return await axios({
    method: "get",
    url: "https://console.vecinos360.com/web_service/ws.extras.php?ent=3",
  }).then((response) => {
    let countries = [];
    if (toSelected) {
      countries.push({ id: 0, name: "Todos" });
    }
    response.data.datos.forEach((country, index) => {
      countries.push({
        id: index + 1,
        alpha2code: country.alpha2Code,
        alpha3code: country.alpha3Code,
        name: country.pais,
        calling_code: country.callingCodes.toString(),
      });
    });
    return countries;
  });
}

async function getCityList(countryCode) {
  return await axios({
    method: "get",
    url: "https://console.vecinos360.com/web_service/ws.extras.php?ent=4&iso=" + countryCode,
  }).then((response) => {
    let countries = [];
    let data = response.data.datos;
    for (const key in data) {
      countries.push(data[key]);
    }
    return countries;
  });
}

export { getCountryList, getCityList };
