<template>
  <v-app id="ticket_detail" class="pa-2">
    <v-row v-if="getAgent && getTicket && getActiveTicketStates && getAgentsToArray" class="pt-8 pt-md-0 pt-lg-0">
      <!-- Información -->
      <v-col cols="12" class="pa-1">
        <v-expansion-panels v-model="tabs" outlined>
          <v-expansion-panel>
            <v-expansion-panel-header class="text--darken-2 font-weight-bold">
              INFORMACIÓN
              <div v-if="!editInformation" class="text-right">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn size="16" icon small class="mr-4" v-on="on">
                      <v-icon size="16" @click.stop.prevent="editInformation = true">fas fa-pencil-alt</v-icon>
                    </v-btn>
                  </template>
                  <span>Editar información</span>
                </v-tooltip>
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-form ref="formInformation" lazy-validation>
                <v-row class="fill-height text-center px-3">
                  <v-col v-if="editInformation" cols="12">
                    <h4>Editar información de la Oportunidad</h4>
                  </v-col>
                  <v-col v-if="editInformation" cols="12">
                    <TicketForm action="update" @cancelInformation="cancelInformation" />
                  </v-col>
                  <v-col v-else cols="12">
                    <v-row class="fill-height text-left">
                      <v-col cols="12" class="pt-0">
                        <span class="caption">ID OPORTUNIDAD:</span>
                        <v-chip color="accent" :ripple="false" small>{{ ticket.id }}</v-chip>
                      </v-col>
                      <template v-for="(ticketField, i) in ticketFields">
                        <v-col :key="i" cols="12" md="3" class="pa-1 px-4 d-flex">
                          <v-text-field
                            dense
                            :value="getTicket[ticketField.field]"
                            :label="ticketField.label"
                            :readonly="!editInformation"
                          ></v-text-field>
                          <BtnWhatsapp
                            v-if="ticketField.field === 'phone' && getTicket.phone"
                            :number="getTicket.phone"
                          />
                          <BtnGmail v-if="ticketField.field === 'email' && getTicket.email" :email="getTicket.email" />
                        </v-col>
                      </template>
                    </v-row>
                  </v-col>
                </v-row>
              </v-form>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
      <!-- Ticket information -->

      <v-col cols="12" md="5" class="pa-1">
        <v-row>
          <v-col cols="12">
            <v-expansion-panels v-model="tabs" multiple dense>
              <!-- Estado -->
              <v-expansion-panel>
                <v-expansion-panel-header class="text--darken-2 font-weight-bold">
                  <span><span v-if="editState">EDITAR</span> ESTADO</span>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-form ref="formState" lazy-validation>
                    <v-row class="fill-height text-center px-3 pt-2">
                      <v-col cols="12" class="pa-0">
                        <v-autocomplete
                          v-model="state"
                          :value="getTicket.state"
                          :items="getActiveTicketStates"
                          :readonly="getTicketStatesMap[getTicket.state].final"
                          :rules="stateCustomRules"
                          style="font-size: 15px;"
                          item-text="name"
                          item-value="id"
                          label="Estado"
                          class="pt-2"
                          dense
                        ></v-autocomplete>
                      </v-col>
                      <v-col v-if="editState" cols="12">
                        <v-btn :loading="loadSaveState" small rounded color="accent" @click="saveState">Guardar</v-btn>
                        <v-btn small rounded @click="cancelState">Cancelar</v-btn>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <!-- Productos -->
              <v-expansion-panel v-if="getProducts && getProducts.length && products">
                <v-expansion-panel-header class="text--darken-2 font-weight-bold">
                  <span><span v-if="editProducts">EDITAR</span> PRODUCTOS RELACIONADOS</span>
                  <div v-if="allowUpdateItemsProducts" class="text-right">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn size="16" icon small class="mr-4" v-on="on" @click.stop.prevent="addItemProduct">
                          <v-icon small>fas fa-plus</v-icon>
                        </v-btn>
                      </template>
                      <span>Agregar producto</span>
                    </v-tooltip>
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-col cols="12" class="pa-0">
                    <v-form ref="formProducts" lazy-validation>
                      <ItemsProducts :products.sync="products" :action.sync="actionItemsProducts" />
                      <v-col v-if="editProducts" cols="12" class="text-center">
                        <v-btn :loading="loadSaveProducts" small rounded color="accent" @click="saveTicketProducts"
                          >Guardar</v-btn
                        >
                        <v-btn small rounded @click="cancelProducts">Cancelar</v-btn>
                      </v-col>
                    </v-form>
                  </v-col>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <!-- Contacto -->
              <v-expansion-panel>
                <v-expansion-panel-header class="text--darken-2 font-weight-bold">
                  <span>
                    <span v-if="!getTicket.contact_id">VINCULAR</span>
                    CONTACTO
                  </span>
                  <div v-if="getTicket.contact_id" class="text-right">
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          size="16"
                          icon
                          small
                          class="pa-2 mr-4"
                          target="_blank"
                          :to="{ name: 'contact_detail', params: { id: getTicket.contact_id } }"
                          v-on="on"
                        >
                          <v-icon size="16">fas fa-external-link-alt</v-icon>
                        </v-btn>
                      </template>
                      <span>Ver contacto</span>
                    </v-tooltip>
                    <v-tooltip v-if="!editContact" top>
                      <template v-slot:activator="{ on }">
                        <v-btn size="16" icon small class="mr-4" v-on="on">
                          <v-icon size="16" @click.stop.prevent="editContact = true">fas fa-pencil-alt</v-icon>
                        </v-btn>
                      </template>
                      <span>Editar contacto</span>
                    </v-tooltip>
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-form v-if="getTicket.contact_id && getContact" ref="formContact" lazy-validation>
                    <v-row class="fill-height text-center px-3 pt-2">
                      <v-col v-if="editContact" cols="12">
                        <h4>Editar información del Contacto</h4>
                      </v-col>
                      <v-col v-if="editContact" cols="12">
                        <ContactForm action="update" @cancelInformation="cancelContact" />
                      </v-col>
                      <v-col v-else cols="12" class="py-2">
                        <v-row class="fill-height text-center">
                          <template v-for="(contactField, i) in contactFields">
                            <v-col :key="i" cols="12" class="py-1">
                              <v-text-field
                                :value="getContact[contactField.field]"
                                :label="contactField.label"
                                :readonly="!editContact"
                                dense
                              ></v-text-field>
                            </v-col>
                          </template>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-form>
                  <v-form v-else ref="formTicketContact" lazy-validation>
                    <v-row class="fill-height text-center px-3">
                      <v-col cols="12">
                        <v-text-field
                          v-model.trim="contactEmail"
                          :rules="[$rules.REQUIRED, $rules.EMAIL]"
                          label="Correo electrónico"
                          placeholder="Correo electrónico del contacto"
                          hint="Se creará un nuevo contacto en caso que este no este registrado"
                          class="pb-2"
                        ></v-text-field>
                      </v-col>
                      <v-col v-if="contactEmail" cols="12" class="text-center">
                        <v-btn :loading="loadSaveTicketContact" small rounded color="accent" @click="saveTicketContact"
                          >Guardar</v-btn
                        >
                        <v-btn small rounded @click="cancelTicketContact">Cancelar</v-btn>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <!-- Etiquetas -->
              <v-expansion-panel>
                <v-expansion-panel-header class="text--darken-2 font-weight-bold">
                  ETIQUETAS
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <Tags />
                </v-expansion-panel-content>
              </v-expansion-panel>
              <!-- Asistencia -->
              <v-expansion-panel>
                <v-expansion-panel-header class="text--darken-2 font-weight-bold">
                  ASISTENCIA
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-row class="fill-height text-center px-3">
                    <v-col cols="12" class="d-flex">
                      <div v-if="getTicket.agent_id && !loadSaveAgent">
                        <v-avatar size="50px" color="black">
                          <img
                            v-if="getAllAgentsMap[getTicket.agent_id].photo"
                            :src="getAllAgentsMap[getTicket.agent_id].photo"
                            alt="Avatar"
                          />
                          <span v-else class="white--text headline">
                            {{ getAllAgentsMap[getTicket.agent_id].full_name.substring(0, 1) }}
                          </span>
                        </v-avatar>
                      </div>
                      <div v-else>
                        <v-avatar size="50px" color="black">
                          <span class="white--text headline">-</span>
                        </v-avatar>
                      </div>
                      <v-text-field
                        v-if="getAgent.role === $roles.ADVISOR"
                        v-model="getAgent.full_name"
                        label="Encargado"
                        class="pl-2"
                        readonly
                      >
                      </v-text-field>
                      <v-autocomplete
                        v-else
                        v-model="agentTicket"
                        :items="getAgentsToArray"
                        :disabled="loadSaveAgent"
                        item-text="full_name"
                        item-value="uid"
                        :value="getTicket.agent_id"
                        label="Encargado"
                        placeholder="Sin asignar"
                        class="pl-2 w95"
                      ></v-autocomplete>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <!-- Fuente -->
              <v-expansion-panel v-if="!hideOriginForAdvisors || getAgent.role !== $roles.ADVISOR">
                <v-expansion-panel-header class="text--darken-2 font-weight-bold">
                  FUENTE
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-row class="fill-height text-center px-3">
                    <v-col class="size" cols="12">
                      <div>
                        Obtenido desde <strong>{{ getTicket.origin }}</strong>
                      </div>
                    </v-col>
                    <v-col v-if="getTicket.created_uid" class="d-flex" cols="12">
                      <div>
                        <v-avatar size="50px" color="black">
                          <img
                            v-if="getAllAgentsMap[getTicket.created_uid].photo"
                            :src="getAllAgentsMap[getTicket.created_uid].photo"
                            alt="Avatar"
                          />
                          <span v-else class="white--text headline">
                            {{ getAllAgentsMap[getTicket.created_uid].full_name.substring(0, 1) }}
                          </span>
                        </v-avatar>
                      </div>
                      <v-text-field
                        :value="getAllAgentsMap[getTicket.created_uid].full_name"
                        label="Registrado por:"
                        class="pl-2 w95"
                        readonly
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-row>
      </v-col>
      <!-- Timeline -->
      <v-col cols="12" md="7" class="pa-1">
        <v-row>
          <!-- Box -->
          <v-col cols="12" class="mt-0">
            <v-card>
              <v-col class="grey--text text--darken-2 font-weight-bold pa-4" cols="12">
                HISTORIAL DE SEGUIMIENTO A LA OPORTUNIDAD
              </v-col>
              <v-col cols="12 pa-4 pt-0">
                <CommentForm :ticket-id="ticket.id" />
              </v-col>
              <v-divider></v-divider>
              <v-col cols="12">
                <Timeline :ticket-id="ticket.id" />
              </v-col>
            </v-card>
          </v-col>
          <!-- Comments -->
        </v-row>
      </v-col>
    </v-row>
    <v-container v-else>
      <ShowLoading />
    </v-container>
  </v-app>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import config from "Config/config";
import TicketForm from "@/components/forms/TicketForm";
import ContactForm from "@/components/forms/ContactForm";
import Timeline from "@/components/tickets/Timeline";
import CommentForm from "@/components/tickets/CommentForm";
import Tags from "./Tags";
import BtnWhatsapp from "@/components/buttons/BtnWhatsapp.vue";
import BtnGmail from "@/components/buttons/BtnGmail.vue";
import ItemsProducts from "@/components/items/Products";

export default {
  name: "TicketDetail",
  components: {
    TicketForm,
    ContactForm,
    Tags,
    Timeline,
    CommentForm,
    BtnWhatsapp,
    BtnGmail,
    ItemsProducts,
  },
  props: {
    ticketId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      ticket: {
        id: this.ticketId || this.$route.params.id,
      },
      tabs: [0, 1, 2, 3],
      products: null,
      editInformation: false,
      editContact: false,
      editProducts: false,
      resetProducts: false,
      loadSaveProducts: false,
      loadSaveInformation: false,
      loadSaveTags: false,
      loadSaveAgent: false,
      loadSaveState: false,
      loadSaveTicketContact: false,
      state: 0,
      ticketFields: [],
      contactFields: [],
      menu_comment_date: false,
      dialog_comment_time: false,
      contactEmail: "",
      hideOriginForAdvisors: config.hideOriginForAdvisors,
    };
  },
  computed: {
    ...mapGetters([
      "getAgent",
      "getAllAgentsMap",
      "getAgentsToArray",
      "getTicket",
      "getActiveTicketStates",
      "getTicketStates",
      "getTicketStatesMap",
      "getContact",
      "getSchemaTicketForm",
      "getSchemaContactForm",
      "getProducts",
    ]),
    editState() {
      return this.getTicket && this.getTicket.state !== this.state;
    },
    agentTicket: {
      get() {
        return this.getTicket ? this.getTicket.agent_id : 0;
      },
      set(value) {
        if (this.getTicket.agent_id !== value) this.saveAgent(value);
      },
    },
    stateCustomRules() {
      return [
        (v) =>
          v !== this.$ticketStates.ORDEN_CREADA ||
          (v === this.$ticketStates.ORDEN_CREADA && this.getTicket.products && this.getTicket.products.length > 0) ||
          "Es necesario tener productos relacionados para crear una orden",
        (v) =>
          v !== this.$ticketStates.ORDEN_CREADA ||
          (v === this.$ticketStates.ORDEN_CREADA && !!this.getTicket.contact_id) ||
          "Es necesario que la oportunidad tenga un contacto vinculado",
      ];
    },
    allowUpdateItemsProducts() {
      return this.getTicket.state !== this.$ticketStates.ORDEN_CREADA;
    },
    actionItemsProducts() {
      return this.allowUpdateItemsProducts ? "update" : "read";
    },
  },
  watch: {
    "getTicket.state"(value) {
      this.state = value;

      if (this.state === this.$ticketStates.ORDEN_CREADA) {
        this.resetProducts = true;
        this.editProducts = false;
        this.products = (this.getTicket.products || []).map((p) => ({ ...p }));
      }
    },
    async "getTicket.contact_id"(value) {
      await this.getContactById({ contact_id: value });
    },
    products: {
      deep: true,
      handler(value, oldValue) {
        if (oldValue && this.allowUpdateItemsProducts && !this.resetProducts) this.editProducts = true;
        else {
          this.editProducts = false;
          this.resetProducts = false;
        }
      },
    },
  },
  async mounted() {
    await this.getTicketById({ ticket_id: this.ticket.id });
    await this.getAllProducts();
    await this.getAllTicketStates();
    await this.initSchemas();
    this.getAllAgents(false);

    this.products = (this.getTicket.products || []).map((p) => ({ ...p }));
    this.state = this.getTicket.state;
  },
  methods: {
    ...mapActions([
      "getTicketById",
      "getContactById",
      "getAllTicketStates",
      "getAllAgents",
      "getTeamSchema",
      "setTicketState",
      "setTicketAgent",
      "getAllProducts",
      "setTicketProducts",
      "setTicketContact",
    ]),
    async initSchemas() {
      let ticketFormSchema = config.ticketFormSchema;
      let contactFormSchema = config.contactFormSchema;

      if (this.getAgent.role === this.$roles.ENTREPRENEUR) {
        await this.getTeamSchema({ id: this.getAgent.team_id, schemaIds: ["ticket_form", "contact_form"] });

        ticketFormSchema = this.getSchemaTicketForm;
        contactFormSchema = this.getSchemaContactForm;
      }

      this.ticketFields = this.loadSchemaFields(ticketFormSchema);
      this.contactFields = this.loadSchemaFields(contactFormSchema);
    },
    loadSchemaFields(schema) {
      let fields = [];
      for (let field in schema) {
        if (schema[field].show_on_detail) {
          fields.push({ field: field, ...schema[field] });
        }
      }
      return fields;
    },
    async saveState() {
      try {
        this.loadSaveState = true;

        if (this.$refs.formState.validate()) {
          await this.setTicketState({
            ticket_id: this.getTicket.id,
            state: this.state,
          });
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.loadSaveState = false;
      }
    },
    cancelState() {
      this.state = this.getTicket.state;
    },
    cancelInformation() {
      this.editInformation = false;
    },
    cancelContact() {
      this.editContact = false;
    },
    async saveAgent(agent_id) {
      try {
        this.loadSaveAgent = true;

        await this.setTicketAgent({ ticket_id: this.getTicket.id, agent_id: agent_id });
      } catch (error) {
        console.error("ticket/saveAgent: ", error);
        this.agentTicket = this.getTicket.agent_id;
      } finally {
        this.loadSaveAgent = false;
      }
    },
    addItemProduct() {
      this.products.push({
        id: "",
        name: "",
        price: 0,
        quantity: 1,
      });
    },
    async saveTicketProducts() {
      try {
        this.loadSaveProducts = true;

        if (this.$refs.formProducts.validate()) {
          await this.setTicketProducts({ id: this.getTicket.id, products: this.products });

          this.editProducts = false;
        }
      } catch (error) {
        console.error("saveTicketProducts: ", error);
      } finally {
        this.loadSaveProducts = false;
      }
    },
    cancelProducts() {
      this.resetProducts = true;
      this.products = (this.getTicket.products || []).map((p) => ({ ...p }));
    },
    async saveTicketContact() {
      try {
        this.loadSaveTicketContact = true;

        if (this.$refs.formTicketContact.validate())
          await this.setTicketContact({ id: this.getTicket.id, email: this.contactEmail });
      } catch (error) {
        console.error(error);
      } finally {
        this.loadSaveTicketContact = false;
      }
    },
    cancelTicketContact() {
      this.contactEmail = "";
      this.$refs.formTicketContact.resetValidation();
    },
  },
};
</script>
<style>
.v-card__title .v-toolbar__content {
  padding: 0;
}
.v-text-field.v-text-field--solo .v-input__control {
  min-height: 28px;
}
.sticky-75 {
  position: sticky;
  top: 75px;
  z-index: 1;
}
.v-input--selection-controls {
  margin-top: 0;
  padding-bottom: 0;
}
.v-messages.theme--light {
  display: none;
}
.w95 {
  width: 95%;
}
.w100 {
  width: 100%;
}
.h-auto {
  height: auto !important;
}
.v-toolbar-info .v-toolbar__content {
  padding: 0 !important;
  height: 58px !important;
}
</style>
