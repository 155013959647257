var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-list-item",
        {
          key: "webhooks",
          on: {
            click: function($event) {
              $event.stopPropagation()
              _vm.toggle = !_vm.toggle
            }
          }
        },
        [
          _c(
            "v-list-item-avatar",
            [_c("v-icon", [_vm._v("fa-network-wired")])],
            1
          ),
          _vm._v(" "),
          _c(
            "v-list-item-content",
            { staticClass: "py-4" },
            [
              _c("v-list-item-title", [_vm._v("Integraciones")]),
              _vm._v(" "),
              _c("v-list-item-subtitle", [
                _vm._v("Conecta tus canales digitales con el CRM")
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-list-item-action",
            [
              _c("v-icon", [
                _vm._v(
                  "\n        " +
                    _vm._s(
                      !_vm.toggle ? "fas fa-angle-down" : "fas fa-angle-up"
                    ) +
                    "\n      "
                )
              ])
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.toggle
        ? _c(
            "v-container",
            [
              _vm.getIntegrationSendGrid ||
              _vm.getIntegrationWhatsApp ||
              _vm.getIntegrationZoomWebinars
                ? _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c(
                        "v-row",
                        { staticClass: "px-3" },
                        [
                          _c(
                            "v-col",
                            { staticClass: "pa-0", attrs: { cols: "12" } },
                            [
                              _c("h5", { staticClass: "mb-4" }, [
                                _vm._v("Plataformas conectadas")
                              ])
                            ]
                          ),
                          _vm._v(" "),
                          _vm.getIntegrationWhatsApp &&
                          !_vm.getIntegrationWhatsApp.disabled
                            ? _c(
                                "v-col",
                                {
                                  staticClass: "pa-0 mb-3",
                                  attrs: { cols: "12" }
                                },
                                [
                                  _c("WhatsAppDetail", {
                                    attrs: {
                                      "dialog-to-edit": _vm.dialogWhatsApp
                                    },
                                    on: {
                                      "update:dialogToEdit": function($event) {
                                        _vm.dialogWhatsApp = $event
                                      },
                                      "update:dialog-to-edit": function(
                                        $event
                                      ) {
                                        _vm.dialogWhatsApp = $event
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.getIntegrationSendGrid &&
                          _vm.getAgent.role === _vm.$roles.SUPER_ADMIN
                            ? _c(
                                "v-col",
                                {
                                  staticClass: "pa-0 mb-3",
                                  attrs: { cols: "12" }
                                },
                                [
                                  _c("SendgridDetail", {
                                    attrs: {
                                      "dialog-to-edit": _vm.dialogSendGrid
                                    },
                                    on: {
                                      "update:dialogToEdit": function($event) {
                                        _vm.dialogSendGrid = $event
                                      },
                                      "update:dialog-to-edit": function(
                                        $event
                                      ) {
                                        _vm.dialogSendGrid = $event
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.getIntegrationZoomWebinars &&
                          _vm.getAgent.role === _vm.$roles.SUPER_ADMIN
                            ? _c(
                                "v-col",
                                { staticClass: "pa-0", attrs: { cols: "12" } },
                                [
                                  _c("ZoomWebinarsDetail", {
                                    attrs: {
                                      "dialog-to-edit": _vm.dialogZoomWebinars
                                    },
                                    on: {
                                      "update:dialogToEdit": function($event) {
                                        _vm.dialogZoomWebinars = $event
                                      },
                                      "update:dialog-to-edit": function(
                                        $event
                                      ) {
                                        _vm.dialogZoomWebinars = $event
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "v-col",
                { staticClass: "mt-4", attrs: { cols: "12" } },
                [
                  _c("h5", { staticClass: "mb-4" }, [
                    _vm._v("Integraciones disponibles")
                  ]),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    [
                      !_vm.getIntegrationWhatsApp ||
                      (_vm.getIntegrationWhatsApp &&
                        _vm.getIntegrationWhatsApp.disabled)
                        ? _c(
                            "v-col",
                            { attrs: { cols: "6", sm: "4", md: "2" } },
                            [
                              _c("WhatsAppCard", {
                                attrs: {
                                  "is-disabled":
                                    _vm.getIntegrationWhatsApp &&
                                    _vm.getIntegrationWhatsApp.disabled,
                                  "dialog-whatsapp": _vm.dialogWhatsApp
                                },
                                on: {
                                  "update:dialogWhatsapp": function($event) {
                                    _vm.dialogWhatsApp = $event
                                  },
                                  "update:dialog-whatsapp": function($event) {
                                    _vm.dialogWhatsApp = $event
                                  }
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.getIntegrationSendGrid &&
                      _vm.getAgent.role === _vm.$roles.SUPER_ADMIN
                        ? _c(
                            "v-col",
                            { attrs: { cols: "6", sm: "4", md: "2" } },
                            [
                              _c("SendgridCard", {
                                attrs: {
                                  "dialog-sendgrid": _vm.dialogSendGrid
                                },
                                on: {
                                  "update:dialogSendgrid": function($event) {
                                    _vm.dialogSendGrid = $event
                                  },
                                  "update:dialog-sendgrid": function($event) {
                                    _vm.dialogSendGrid = $event
                                  }
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.getIntegrationZoomWebinars &&
                      _vm.getAgent.role === _vm.$roles.SUPER_ADMIN
                        ? _c(
                            "v-col",
                            { attrs: { cols: "6", sm: "4", md: "2" } },
                            [
                              _c("ZoomWebinarsCard", {
                                attrs: {
                                  "dialog-zoom-webinars": _vm.dialogZoomWebinars
                                },
                                on: {
                                  "update:dialogZoomWebinars": function(
                                    $event
                                  ) {
                                    _vm.dialogZoomWebinars = $event
                                  },
                                  "update:dialog-zoom-webinars": function(
                                    $event
                                  ) {
                                    _vm.dialogZoomWebinars = $event
                                  }
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _vm.dialogZoomWebinars
                ? _c("ZoomWebinarsModal", {
                    attrs: { dialog: _vm.dialogZoomWebinars },
                    on: {
                      "update:dialog": function($event) {
                        _vm.dialogZoomWebinars = $event
                      }
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.dialogSendGrid
                ? _c("SendGridModal", {
                    attrs: { dialog: _vm.dialogSendGrid },
                    on: {
                      "update:dialog": function($event) {
                        _vm.dialogSendGrid = $event
                      }
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.dialogWhatsApp
                ? _c("WhatsAppModal", {
                    attrs: { dialog: _vm.dialogWhatsApp },
                    on: {
                      "update:dialog": function($event) {
                        _vm.dialogWhatsApp = $event
                      }
                    }
                  })
                : _vm._e()
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }