import * as MutationsType from "./mutationsType";
import { auth, agentsCollection } from "@/config/firebase";
import requestAxios from "@/helpers/request_axios";
import { roles } from "@/helpers/get_constants";

export default {
  // eslint-disable-next-line
  async createAgent({ commit }, payload) {
    try {
      let response = await requestAxios({
        url: "/awy/api/v1/agent",
        method: "POST",
        data: { agent: payload },
      });
      return response.data;
    } catch (error) {
      console.error("agent/createAgent: ", error);
      throw error.response.data;
    }
  },
  async updateAgent({ commit }, payload) {
    try {
      let response = await requestAxios({
        url: `/awy/api/v1/agent/${payload.uid}`,
        method: "PATCH",
        data: { agent: payload },
      });
      let data = response.data;
      commit(MutationsType.UPDATE_AGENT, data.agent);
      return data;
    } catch (error) {
      console.error("agent/createAgent: ", error);
      throw error.response.data;
    }
  },
  async handleAgentAccount({ commit }, payload) {
    try {
      let response = await requestAxios({
        url: "/awy/api/v1/agent/handle-account",
        method: "POST",
        data: { agent_id: payload },
      });
      let data = response.data;
      commit(MutationsType.UPDATE_AGENT, data.agent);
      commit(MutationsType.SET_AGENT_SELECTED, data.agent);
      return data;
    } catch (error) {
      console.error("agent/handleAgentAccount: ", error);
      throw error.response.data;
    }
  },
  async getAgentById({ commit }, payload) {
    if (payload.agent) {
      let doc = await agentsCollection.doc(payload.agent.uid).get();
      let data = doc.data();
      data.role = data.role || roles.ADVISOR;
      data.uid = doc.id;

      commit(payload.is_selected ? MutationsType.SET_AGENT_SELECTED : MutationsType.SET_AGENT, data);

      // aditions menu items
      if (!payload.is_selected) commit(MutationsType.SET_MENU_ITEMS, { role: data.role });
    }
  },
  // eslint-disable-next-line
  async login({ commit }, payload) {
    try {
      let user = await auth.signInWithEmailAndPassword(payload.user.email, payload.user.password);
      return { data: user };
    } catch (error) {
      return { error: error };
    }
  },
  // eslint-disable-next-line
  async recoveryPassword({ commit }, payload) {
    try {
      await auth.sendPasswordResetEmail(payload.email);
      return true;
    } catch (error) {
      return { error: error };
    }
  },
  async logout({ commit }) {
    try {
      await auth.signOut();
      commit(MutationsType.SET_AGENT, null);
    } catch (error) {
      console.error(error);
    }
  },
  async getAllAgents({ commit, state }) {
    try {
      let agents = {};
      let allAgents = [];
      let allAgentsMap = {};

      const querySnapshot = await agentsCollection.get();
      querySnapshot.forEach((doc) => {
        var data = doc.data();
        if (!data.system_agent) {
          var agent = { id: doc.id, ...data };
          allAgents.push(agent);
          allAgentsMap[agent.id] = agent;
        }
      });

      let agentsToSelect = allAgents;

      if ([roles.SUPERVISOR, roles.ENTREPRENEUR].includes(state.agent.role))
        agentsToSelect = agentsToSelect.filter((a) => a.team_id === state.agent.team_id);
      if (state.agent.role === roles.ADVISOR) agentsToSelect = agentsToSelect.filter((a) => a.id === state.agent.uid);

      for (let i = 0; i < agentsToSelect.length; i++) agents[agentsToSelect[i].id] = agentsToSelect[i];

      commit(MutationsType.SET_AGENTS, agents);
      commit(MutationsType.SET_ALL_AGENTS, allAgents);
      commit(MutationsType.SET_ALL_AGENTS_MAP, allAgentsMap);
    } catch (error) {
      console.error(error);
    }
  },
  setAgent({ commit }, agent) {
    commit(MutationsType.SET_AGENT, agent);
  },
  setAgentAuthenticated({ commit }, agent) {
    commit(MutationsType.SET_AGENT_AUTHENTICATED, agent);
  },
  // eslint-disable-next-line
  async setAgentRole({ commit }, payload) {
    try {
      let response = await requestAxios({
        url: "/awy/api/v1/agent/set-permissions",
        method: "POST",
        data: {
          agent_id: payload.agent_id,
          agent_role: payload.agent_role,
        },
      });
      return response.data;
    } catch (error) {
      console.error("agent/setAgentRole: ", error);
      return error.response.data;
    }
  },
  showAgentDetail({ commit }, payload) {
    commit(MutationsType.SHOW_AGENT_DETAIL, payload.agentId);
  },
};
