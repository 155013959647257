<template>
  <div>
    <v-row class="justify-end mr-0 pb-1">
      <v-btn
        rounded
        color="primary"
        @click="
          resetFormState();
          dialogEnable = true;
        "
      >
        <v-icon size="16">fa-plus</v-icon>
        <span class="hidden-sm-and-down ml-2">Agregar estado</span>
      </v-btn>
    </v-row>
    <v-row>
      <v-col cols="12" class="pt-0">
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">
                  #
                </th>
                <th class="text-left">
                  Nombre
                </th>
                <th class="text-left">
                  Descripción
                </th>
                <th class="text-left">
                  Abreviación
                </th>
                <th class="text-center">
                  Operaciones
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(ticketState, i) in getActiveTicketStates" :key="i">
                <td>{{ i + 1 }}</td>
                <td>{{ ticketState.name }}</td>
                <td>{{ ticketState.description }}</td>
                <td class="text-center">{{ ticketState.hashtag }}</td>
                <td class="text-right">
                  <div v-if="!ticketState.default">
                    <v-btn text small color="primary" @click="openEdit(ticketState.id)">Editar </v-btn>
                    <v-menu bottom left>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn x-small icon class="my-auto mr-5" v-bind="attrs" v-on="on">
                          <v-icon x-small>fas fa-ellipsis-v</v-icon>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item @click="openDelete(ticketState.id)">
                          <v-list-item-title>Eliminar</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </div>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>

    <!-- Modals -->
    <v-dialog v-model="dialogEnable" persistent max-width="400">
      <v-card class="py-3">
        <v-card-text>
          <v-form ref="formState">
            <v-row>
              <v-col cols="12">
                <h3 class="font-weight-thin">
                  {{ stateSelected ? `Editar estado "${stateSelected.name}"` : "Nuevo estado" }}
                </h3>
              </v-col>
              <v-col v-if="!stateSelected" cols="12">
                <v-text-field
                  v-model="state.name"
                  label="Nombre"
                  placeholder=" "
                  :rules="rules.noRepeatStateName"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-textarea
                  v-model="state.description"
                  label="Descripción"
                  rows="2"
                  placeholder=" "
                  :rules="rules.required"
                ></v-textarea>
              </v-col>
              <v-col cols="12" class="d-flex mt-4">
                <v-text-field
                  v-model="state.hashtag"
                  label="Abreviación"
                  hint="Para acciones automáticas"
                  placeholder=" "
                  :rules="[...rules.noRepeatStateHashtag, $rules.STRING_LOWERCASE]"
                ></v-text-field>
                <v-alert dense text color="primary" class="my-auto ml-3"> #e{{ state.hashtag }} </v-alert>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="ma-2">
          <v-spacer></v-spacer>
          <v-btn rounded text @click="dialogEnable = false">Cancelar</v-btn>
          <v-btn v-if="stateSelected" :loading="loading" color="primary" rounded @click="edit">
            Editar
          </v-btn>
          <v-btn v-else :loading="loading" color="primary" rounded @click="save">
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-if="stateSelected" v-model="dialogDelete" persistent max-width="400">
      <v-card class="py-3">
        <v-card-text>
          <v-form ref="formDeleteState">
            <v-row>
              <v-col cols="12">
                <h3 class="font-weight-thin">
                  ¿Está seguro que
                  <span class="font-weight-bold">desea eliminar el estado con {{ stateSelected.name }}</span
                  >?.
                </h3>
                <h3 class="font-weight-thin py-3">
                  Para completar está acción debe seleccionar el nuevo estado que reemplazará al estado que va a
                  eliminar
                </h3>
                <v-select
                  v-model="stateToReplace"
                  label="Reemplazar por"
                  :items="ticketStates"
                  item-text="name"
                  item-value="id"
                  :rules="rules.required"
                  return-object
                ></v-select>
              </v-col>
              <v-col v-if="errorFormDelete" cols="12">
                <v-alert dense text type="error">
                  {{ errorFormDelete }}
                </v-alert>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="mr-2">
          <v-spacer></v-spacer>
          <v-btn rounded text @click="dialogDelete = false">Cancelar</v-btn>
          <v-btn :loading="loading" color="red" rounded dark @click="remove(stateSelected)">Eliminar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "TicketsStates",
  data() {
    return {
      toggle: false,
      state: {
        id: "",
        name: "",
        description: "",
        hashtag: "",
        active: true,
      },
      stateSelected: null,
      stateToReplace: null,
      ticketStates: null,
      errorFormDelete: null,
      dialogEnable: false,
      dialogDelete: false,
      loading: false,
      rules: {
        required: [(v) => !!v || "Campo requerido"],
        noRepeatStateName: [
          (v) => !!v || "Campo requerido",
          // eslint-disable-next-line
          (v) =>
            !this.getActiveTicketStates.find(
              (t) => t.name.toLowerCase().trim() == this.state.name.toLowerCase().trim(),
            ) || "El estado ya existe",
        ],
        noRepeatStateHashtag: [
          (v) => !!v || "Campo requerido",
          // eslint-disable-next-line
          (v) =>
            !this.getActiveTicketStates.find(
              (t) => t.hashtag?.slice(2).toLowerCase().trim() == this.state.hashtag?.toLowerCase().trim(),
            ) ||
            v === this.stateSelected?.hashtag?.slice(2) ||
            "El hashtag ya existe",
        ],
      },
    };
  },
  computed: {
    ...mapGetters(["getActiveTicketStates"]),
  },
  methods: {
    ...mapActions(["createTicketState", "updateTicketState", "removeTicketState"]),
    openEdit(state_id) {
      this.stateSelected = this.getActiveTicketStates.find((s) => s.id === state_id);
      this.state = {
        ...this.stateSelected,
        hashtag: this.stateSelected.hashtag ? this.stateSelected.hashtag.slice(2) : "",
      };
      this.dialogEnable = true;
    },
    openDelete(state_id) {
      this.stateSelected = this.getActiveTicketStates.find((s) => s.id === state_id);
      this.ticketStates = [...this.getActiveTicketStates.filter((s) => s.id !== this.stateSelected.id)];
      this.stateToReplace = null;
      this.errorFormDelete = null;
      this.dialogDelete = true;
    },
    async edit() {
      try {
        this.loading = true;

        if (this.$refs.formState.validate()) {
          await this.updateTicketState({ state: this.state });

          this.resetFormState();
          this.dialogEnable = false;
        }
      } catch (error) {
        console.error("ticketState/edit", error);
      } finally {
        this.loading = false;
      }
    },
    async save() {
      try {
        this.loading = true;

        if (this.$refs.formState.validate()) {
          this.state.name = this.state.name.trim();
          await this.createTicketState({ state: this.state });

          this.resetFormState();
          this.dialogEnable = false;
        }
      } catch (error) {
        console.error("ticketState/save", error);
      } finally {
        this.loading = false;
      }
    },
    async remove() {
      try {
        this.loading = true;

        if (this.$refs.formDeleteState.validate()) {
          await this.removeTicketState({
            state: this.stateSelected,
            stateToReplace: this.stateToReplace,
          });

          this.dialogDelete = false;
          this.stateSelected = null;
        }
      } catch (error) {
        console.error("ticketState/remove", error);
        this.errorFormDelete = error.message;
      } finally {
        this.loading = false;
      }
    },
    resetFormState() {
      this.stateSelected = null;
      this.state = {
        id: "",
        name: "",
        description: "",
        hashtag: "",
        active: true,
      };
      if (this.$refs.formState) this.$refs.formState.resetValidation();
    },
  },
};
</script>
