<template>
  <v-dialog v-model="dialogToEnable" persistent max-width="400">
    <v-card class="py-3">
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <h3 class="font-weight-thin">
              ¿Desea
              <span class="font-weight-bold">{{ active ? "desactivar" : "activar" }}</span>
              la integración con {{ name }}?
            </h3>
          </v-col>
          <v-col v-if="error" cols="12">
            <v-alert dense text type="error">
              {{ error }}
            </v-alert>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="mr-2">
        <v-spacer></v-spacer>
        <v-btn rounded text @click="$emit('update:dialogToEnable', false)">Cancelar</v-btn>
        <v-btn :loading="loading" color="primary" rounded @click="$emit('enable')">
          {{ active ? "Desactivar" : "Activar" }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "ModalEnableIntegration",
  props: {
    dialogToEnable: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      required: true,
    },
    active: {
      type: Boolean,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
      default: "",
    },
  },
};
</script>
