<template>
  <v-col cols="12" class="pa-0">
    <v-card class="rounded-lg pa-2">
      <v-col cols="12" class="py-0 px-3">
        <Detail
          :id="getIntegrationZoomWebinars.id"
          name="Zoom Webinars"
          type="integration"
          :active="getIntegrationZoomWebinars.active"
          :show-detail.sync="showDetail"
          :able-edit="true"
          :able-enable="false"
          :error="error"
          :loading.sync="loading"
          @openEdit="openEdit"
          @openRemove="openRemove"
        />
      </v-col>
      <v-col v-if="showDetail" cols="12">
        <v-row>
          <v-col cols="12" class="pb-0">
            <h5 class="pb-2">Conexión</h5>
          </v-col>
          <v-col v-if="getIntegrationZoomWebinars.active" cols="12" class="py-0 caption">
            <span class="mt-2">Configurado correctamente con la cuenta {{ getIntegrationZoomWebinars.user }}</span>
          </v-col>
          <v-col v-else cols="12" class="caption"><span>No configurado</span></v-col>
        </v-row>
      </v-col>

      <ModalRemoveIntegration
        v-if="dialogToRemove"
        name="Zoom Webinars"
        :dialog-to-remove.sync="dialogToRemove"
        :error.sync="error"
        :loading.sync="loading"
        @remove="remove"
      />
    </v-card>
  </v-col>
</template>
<script>
import Detail from "@/components/integrations/Detail";
import ModalRemoveIntegration from "@/views/settings/modals/RemoveIntegration";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "ZoomWebinarsDetail",
  components: {
    Detail,
    ModalRemoveIntegration,
  },
  props: {
    dialogToEdit: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    showDetail: false,
    dialogToEnable: false,
    dialogToRemove: false,
    loading: false,
    error: null,
  }),
  computed: {
    ...mapGetters(["getIntegrationZoomWebinars"]),
  },
  methods: {
    ...mapActions(["updateIntegration", "removeIntegration"]),
    openEnable() {
      this.dialogToEnable = true;
    },
    openRemove() {
      this.dialogToRemove = true;
    },
    openEdit() {
      this.$emit("update:dialogToEdit", true);
    },
    async enable() {
      try {
        this.loading = true;
        this.error = null;

        await this.updateIntegration({
          id: "zoom_webinars",
          data: { active: !this.getIntegrationZoomWebinars.active },
        });

        this.dialogToEnable = false;
      } catch (error) {
        this.error = error.message || "Hubo un problema al activar/desactivar la integración";
      } finally {
        this.loading = false;
      }
    },
    async remove() {
      try {
        this.loading = true;
        this.error = null;

        await this.removeIntegration({ id: "zoom_webinars" });

        this.dialogToRemove = false;
      } catch (error) {
        this.error = error.message || "Hubo un problema al eliminar la integración con Zoom Webinars";
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
