export default {
  getProducts(state) {
    return state.products;
  },
  getActiveProducts(state) {
    return state.products ? state.products.filter((p) => p.active) : null;
  },
  getProductsMap(state) {
    return state.productsMap;
  },
};
