<template>
  <v-menu bottom left offset-y open-on-hover>
    <template v-slot:activator="{ on, attrs }">
      <v-btn text icon v-bind="attrs" v-on="on" @click.stop>
        <v-icon dark small>far fa-envelope</v-icon>
      </v-btn>
    </template>
    <v-list>
      <v-list-item :href="`https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=${email}`" target="_blank">
        <v-list-item-title>GMail</v-list-item-title>
      </v-list-item>
      <v-list-item :href="`https://outlook.live.com/?path=/mail/action/compose&to=${email}`" target="_blank">
        <v-list-item-title>Outlook</v-list-item-title>
      </v-list-item>
      <v-list-item :href="`https://compose.mail.yahoo.com/?to=${email}`" target="_blank">
        <v-list-item-title>Yahoo</v-list-item-title>
      </v-list-item>
      <v-list-item :href="`mailto:${email}`">
        <v-list-item-title>Mailto</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>
<script>
export default {
  props: {
    email: {
      type: String,
      default: "",
    },
  },
};
</script>
