<template>
  <v-app>
    <div class="pt-12 pt-md-0 pt-lg-0">
      <MenuOptions />
      <SettingsOptions />
    </div>
  </v-app>
</template>

<script>
import MenuOptions from "@/components/inc/MenuOptions";
import SettingsOptions from "@/components/inc/SettingsOptions";
export default {
  name: "Menu",
  components: { MenuOptions, SettingsOptions },
};
</script>
