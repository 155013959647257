import Vue from "vue";
import * as MutationsType from "./mutationsType";

export default {
  [MutationsType.SET_SEGMENTS](state, payload) {
    state.segments = payload;
  },
  [MutationsType.ADD_SEGMENT](state, payload) {
    state.segments.push(payload);
  },
  [MutationsType.UPDATE_SEGMENT](state, payload) {
    let ind = state.segments.findIndex((segment) => segment.id === payload.id);
    if (ind >= 0) Vue.set(state.segments, ind, { ...state.segments[ind], ...payload });
  },
  [MutationsType.REMOVE_SEGMENT](state, payload) {
    let ind = state.segments.findIndex((t) => t.id === payload);
    Vue.delete(state.segments, ind);
  },
  [MutationsType.SET_SEGMENTS_DOWNLOADS](state, payload) {
    state.segmentsDownloads = payload;
  },
  [MutationsType.ADD_SEGMENT_DOWNLOAD](state, payload) {
    state.segmentsDownloads.unshift(payload);
  },
  [MutationsType.UPDATE_SEGMENT_DOWNLOAD](state, payload) {
    let ind = state.segmentsDownloads.findIndex((segment) => segment.id === payload.id);
    if (ind >= 0) Vue.set(state.segmentsDownloads, ind, { ...state.segmentsDownloads[ind], ...payload.data });
  },
  [MutationsType.REMOVE_SEGMENT_DOWNLOAD](state, payload) {
    let ind = state.segmentsDownloads.findIndex((t) => t.id === payload.id);
    Vue.delete(state.segmentsDownloads, ind);
  },
  [MutationsType.UNSUBSCRIBE_GET_ALL_SEGMENTS_DOWNLOADS](state, payload) {
    state.unsubscribeAllSegmentsDownloads = payload;
  },
  [MutationsType.SET_SEGMENTS_AUTOMATIONS](state, payload) {
    state.segmentsAutomations = payload;
  },
  [MutationsType.ADD_SEGMENT_AUTOMATION](state, payload) {
    state.segmentsAutomations.unshift(payload);
  },
  [MutationsType.UPDATE_SEGMENT_AUTOMATION](state, payload) {
    let ind = state.segmentsAutomations.findIndex((segment) => segment.id === payload.id);
    if (ind >= 0) Vue.set(state.segmentsAutomations, ind, { ...state.segmentsAutomations[ind], ...payload.data });
  },
  [MutationsType.REMOVE_SEGMENT_AUTOMATION](state, payload) {
    let ind = state.segmentsAutomations.findIndex((t) => t.id === payload.id);
    Vue.delete(state.segmentsAutomations, ind);
  },
  [MutationsType.UNSUBSCRIBE_GET_ALL_SEGMENTS_AUTOMATIONS](state, payload) {
    state.unsubscribeAllSegmentsAutomations = payload;
  },
};
