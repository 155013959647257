import requestAxios from "@/helpers/request_axios";
import * as MutationsType from "./mutationsType";
import {
  serverTimestamp,
  contactsTimelinesCollection,
  ticketsTimelinesCollection,
  ordersTimelinesCollection,
} from "@/config/firebase";

export default {
  // eslint-disable-next-line
  async fetchContactTimeline({ commit }, payload) {
    contactsTimelinesCollection
      .doc(payload.contact_id)
      .collection("comments")
      .onSnapshot(async (snap) => {
        snap.docs.forEach((doc) => {
          commit(MutationsType.ADD_CONTACT_ACTIVITY, { ...doc.data(), id: doc.id, contactId: payload.contact_id });
        });
      });
  },
  // eslint-disable-next-line
  async getTicketTimeline({ commit }, payload) {
    ticketsTimelinesCollection
      .doc(payload.ticket_id)
      .collection("comments")
      .onSnapshot(async (snap) => {
        snap.docs.forEach((doc) => {
          commit(MutationsType.ADD_ACTIVITY, { ...doc.data(), id: doc.id, ticketId: payload.ticket_id });
        });
      });
  },
  async getOrderTimeline({ commit }, { id }) {
    ordersTimelinesCollection
      .doc(id)
      .collection("comments")
      .orderBy("created_at", "asc")
      .onSnapshot(async (snap) => {
        snap.docs.forEach((doc) => {
          commit(MutationsType.ADD_ACTIVITY, { ...doc.data(), id: doc.id });
        });
      });
  },
  // eslint-disable-next-line
  async addActivity({ commit, rootState }, payload) {
    const agent = rootState.Agent.agent;
    const comment = payload.comment;
    let collection, timeline_id;
    let activity = {
      text: comment.text,
      type: comment.type,
      uid: agent.uid,
      team_id: agent.team_id || "",
      created_at: serverTimestamp,
    };

    if (activity.type === 4) {
      activity.detail = comment.text;
      activity.text = `${comment.event_name}: ${comment.text}`;
      activity.event_name = comment.event_name;
      activity.event_date = comment.date_start;
      activity.event_time = comment.time_start;
      activity.event_time_end = comment.time_end;
    }

    if (payload.contact_id) {
      collection = contactsTimelinesCollection;
      timeline_id = payload.contact_id;
      activity.contact_id = payload.contact_id;
    } else {
      collection = ticketsTimelinesCollection;
      timeline_id = payload.ticket_id;
      activity.ticket_id = payload.ticket_id;
    }

    collection.doc(timeline_id).collection("comments").add(activity);
  },
  // eslint-disable-next-line
  async exportContactActivityTemplate({ commit }, payload) {
    try {
      let response = await requestAxios({
        url: "/awy/api/v1/contact/export-template-activities",
        method: "GET",
        responseType: "blob",
      });
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  },
  // eslint-disable-next-line
  async importContactActivities({ commit }, payload) {
    try {
      let formdata = new FormData();
      formdata.append("file", payload.file);
      let response = await requestAxios({
        url: "/awy/api/v1/contact/import-activities",
        method: "POST",
        data: formdata,
        headers: { "Content-Type": "multipart/form-data" },
      });
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  },
  // eslint-disable-next-line
  async exportTicketActivityTemplate({ commit }, payload) {
    try {
      let response = await requestAxios({
        url: "/awy/api/v1/ticket/export-template-activities",
        method: "GET",
        responseType: "blob",
      });
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  },
  // eslint-disable-next-line
  async importTicketActivities({ commit }, payload) {
    try {
      let formdata = new FormData();
      formdata.append("file", payload.file);
      let response = await requestAxios({
        url: "/awy/api/v1/ticket/import-activities",
        method: "POST",
        data: formdata,
        headers: { "Content-Type": "multipart/form-data" },
      });
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  },
};
