<template>
  <v-dialog v-model="dialog" persistent scrollable max-width="700">
    <v-card class="py-2">
      <v-card-title class="headline">
        <span class="ml-0">Editar segmento</span>
        <v-spacer></v-spacer>
        <v-btn icon @click="$emit('update:dialog', false)">
          <v-icon small>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-row>
          <!-- <pre>{{ segment.name }}</pre> -->
          <v-col cols="12">
            <SegmentForm action="update" :segment-selected.sync="segment" @afterEdit="$emit('update:dialog', false)" />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import SegmentForm from "@/views/segments/forms/Segment";

export default {
  name: "EditSegmentModal",
  components: { SegmentForm },
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    segment: {
      type: Object,
      required: true,
    },
  },
};
</script>
