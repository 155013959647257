var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("Card", {
        attrs: { id: "whatsapp", name: "WhatsApp" },
        on: { open: _vm.open }
      }),
      _vm._v(" "),
      _vm.dialogIntegrationDisabled
        ? _c("IntegrationDisabled", {
            attrs: {
              id: "whatsapp",
              dialog: _vm.dialogIntegrationDisabled,
              name: "WhatsApp"
            },
            on: {
              "update:dialog": function($event) {
                _vm.dialogIntegrationDisabled = $event
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }