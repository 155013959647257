<template>
  <v-app id="order_detail" class="py-8 pt-md-0 pt-lg-0">
    <v-row v-if="getOrder && getAgents">
      <v-col cols="12" md="5">
        <v-row>
          <v-col cols="12" class="mt-0">
            <v-card>
              <v-col cols="12" class="grey--text text--darken-3 font-weight-bold pa-4 pb-0">
                INFORMACIÓN
              </v-col>
              <v-col cols="12">
                <v-row class="px-4">
                  <v-col v-for="(schema, i) in schemaSale" :key="i" cols="12">
                    <v-text-field
                      :label="schema.header"
                      :value="
                        schema.type === 'date' ? $formatTimestamp(getOrder[schema.key], 'date_z') : getOrder[schema.key]
                      "
                      readonly
                      hide-details
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="py-2">
            <CardState />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="py-2">
            <CardPayment />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="py-2">
            <v-card class="pa-4 pb-0">
              <v-col cols="12" class="grey--text text--darken-3 font-weight-bold pa-0">
                FUENTE
              </v-col>
              <v-col cols="12">
                <v-row>
                  <v-col class="size" cols="12">
                    <div>
                      Obtenido desde <strong>{{ getOrder.origin }}</strong>
                    </div>
                  </v-col>
                  <v-col v-if="getOrder.ticket_created_uid" class="d-flex" cols="12">
                    <div>
                      <v-avatar size="50px" color="accent">
                        <img
                          v-if="getAllAgentsMap[getOrder.ticket_created_uid].photo"
                          :src="getAllAgentsMap[getOrder.ticket_created_uid].photo"
                          alt="Avatar"
                        />
                        <span v-else class="white--text headline">
                          {{ getAllAgentsMap[getOrder.ticket_created_uid].full_name.substring(0, 1) }}
                        </span>
                      </v-avatar>
                    </div>
                    <v-text-field
                      :value="getAllAgentsMap[getOrder.ticket_created_uid].full_name"
                      label="Encargado que registró la oportunidad"
                      class="pl-2 w95"
                      readonly
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" class="d-flex">
                    <div>
                      <v-avatar size="50px" color="accent">
                        <img
                          v-if="getAllAgentsMap[getOrder.created_uid].photo"
                          :src="getAllAgentsMap[getOrder.created_uid].photo"
                          alt="Avatar"
                        />
                        <span v-else class="white--text headline">
                          {{ getAllAgentsMap[getOrder.created_uid].full_name.substring(0, 1) }}
                        </span>
                      </v-avatar>
                    </div>
                    <v-text-field
                      label="Encargado que generó la orden"
                      :value="getAllAgentsMap[getOrder.created_uid].full_name"
                      class="pl-2 w95"
                      readonly
                    ></v-text-field>
                  </v-col>
                  <v-col v-if="getOrder.ticket_id" cols="12" class="d-flex">
                    <v-text-field label="Oportunidad" :value="getOrder.ticket_name" readonly></v-text-field>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          icon
                          :to="{ name: 'ticket_detail', params: { id: getOrder.ticket_id } }"
                          class="my-auto ml-3"
                          target="_blank"
                          v-on="on"
                        >
                          <v-icon size="16">fas fa-external-link-alt</v-icon>
                        </v-btn>
                      </template>
                      <span>Ver oportunidad</span>
                    </v-tooltip>
                  </v-col>
                  <v-col v-if="getOrder.contact_id" cols="12" class="d-flex">
                    <v-text-field label="Contacto" :value="getOrder.full_name" readonly></v-text-field>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          icon
                          :to="{ name: 'contact_detail', params: { id: getOrder.contact_id } }"
                          class="my-auto ml-3"
                          target="_blank"
                          v-on="on"
                        >
                          <v-icon size="16">fas fa-external-link-alt</v-icon>
                        </v-btn>
                      </template>
                      <span>Ver contacto</span>
                    </v-tooltip>
                  </v-col>
                </v-row>
              </v-col>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="7">
        <v-row>
          <v-col cols="12" class="mt-0">
            <CardProducts />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="mt-0">
            <CardTimeline />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-container v-else>
      <ShowLoading />
    </v-container>
  </v-app>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { saleExportData } from "Config/config";
import CardState from "@/views/orders/cards/State";
import CardProducts from "@/views/orders/cards/Products";
import CardTimeline from "@/views/orders/cards/Timeline";
import CardPayment from "@/views/orders/cards/Payment";

export default {
  name: "OrderDetail",
  components: {
    CardState,
    CardProducts,
    CardTimeline,
    CardPayment,
  },
  data() {
    return {
      schemaSale: null,
    };
  },
  computed: {
    ...mapGetters([
      "getOrder",
      "getAgents",
      "getProducts",
      "getOrderStates",
      "getAllAgentsMap",
      "getOrderPaymentMethods",
    ]),
  },
  async created() {
    this.schemaSale = saleExportData.filter((s) => s.show_on_detail);

    const orderId = this.$route.params.id;

    await this.getOrderById({ id: orderId });
    await this.getOrderTimeline({ id: orderId });
    if (!this.getProducts) await this.getAllProducts();
    if (!this.getAgents) await this.getAllAgents();
    await this.getAllOrderStates();
  },
  methods: {
    ...mapActions(["getOrderById", "getOrderTimeline", "getAllAgents", "getAllProducts", "getAllOrderStates"]),
  },
};
</script>
