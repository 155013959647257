<template>
  <div>
    <Card id="zoom_webinars" name="Zoom Webinars" @open="open" />
  </div>
</template>
<script>
import Card from "@/components/integrations/Card";

export default {
  name: "ZoomWebinars",
  components: {
    Card,
  },
  props: {
    dialogZoomWebinars: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    open() {
      this.$emit("update:dialogZoomWebinars", true);
    },
  },
};
</script>
