import * as MutationsType from "./mutationsType";

export default {
  [MutationsType.SET_PRODUCTS](state, payload) {
    state.products = payload;
  },
  [MutationsType.SET_PRODUCTS_MAP](state, payload) {
    state.productsMap = payload;
  },
};
