function getModel(schema, data = {}) {
  let model = {};
  let val;
  for (var att in schema) {
    val = schema[att].value || data[att];
    if (!val) {
      switch (schema[att].type) {
        case "text":
          val = "";
          break;
        case "email":
          val = "";
          break;
        case "password":
          val = "";
          break;
        case "select":
          val = "";
          break;
        case "file":
          val = [];
          break;
        case "checkbox":
          val = false;
          break;
        case "radio":
          val = "";
          break;
        case "switch":
          val = false;
          break;
        case "slider":
          val = 0;
          break;
        case "combobox":
          val = "";
          break;
        case "autocomplete":
          val = "";
          break;
      }
    }
    model[att] = val;
  }
  return model;
}

export default getModel;
