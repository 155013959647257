<template>
  <v-app id="tickets-downloads-register">
    <v-container v-if="getAgent && getAgentsToArray" class="mb-10 pb-10">
      <v-row class="pt-4">
        <v-col cols="12" sm="10" md="8" offset-sm="1" offset-md="2">
          <v-row>
            <v-col cols="12" class="py-0">
              <h2 class="text-h4 primary--text pb-4 font-weight-black pt-8 pt-md-0 pt-lg-0">
                Iniciar descarga de Oportunidades
              </h2>
            </v-col>
            <v-col cols="12">
              <v-card class="pa-4 rounded-lg">
                <v-form ref="formDownload" lazy-validation>
                  <v-row>
                    <v-col cols="12" sm="7" class="pt-0">
                      <h5 class="font-weight-regular mb-2">
                        Al iniciar y completar una nueva descarga, el archivo estará disponible por 30 días.
                      </h5>
                    </v-col>
                    <v-col cols="12" sm="5" class="text-sm-right pt-0">
                      <v-btn :to="{ name: 'ticket_downloads' }" color="primary" active-class="no-active" outlined>
                        Ver descargables disponibles
                      </v-btn>
                    </v-col>
                    <v-col cols="12" class="pb-2">
                      <v-text-field
                        v-model="name"
                        :rules="[
                          $rules.REQUIRED,
                          (v) => (v.length > 0 && v.length < 40) || 'Debe ser menor a 40 carácteres alfanúmericos',
                        ]"
                        label="Nombre"
                        placeholder="Nombre de la descarga"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" class="pb-2">
                      <v-menu
                        v-model="menuDateStart"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        max-width="290"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            v-model="dateStart"
                            :rules="[$rules.REQUIRED]"
                            label="Fecha inicial"
                            readonly
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker v-model="dateStart" @input="menuDateStart = false"></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="12" sm="6" class="pb-2">
                      <v-menu
                        v-model="menuDateEnd"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        max-width="290"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            v-model="dateEnd"
                            :rules="[$rules.REQUIRED, (v) => v >= dateStart || 'La fecha debe ser mayor a la inicial']"
                            label="Fecha final"
                            readonly
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker v-model="dateEnd" @input="menuDateEnd = false"></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col v-if="getAgent.role !== $roles.ADVISOR" cols="12" sm="6" class="pb-3">
                      <v-autocomplete
                        v-model="agent"
                        :items="getAgents"
                        label="Encargado"
                        item-value="id"
                        item-text="full_name"
                        placeholder="Seleccione encargado"
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12">
                      <v-btn :loading="loading" rounded color="primary" @click="save">
                        Iniciar descarga
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <v-container v-else>
      <ShowLoading />
    </v-container>
  </v-app>
</template>
<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "TicketDownloadsRegister",
  data() {
    return {
      name: "",
      agent: null,
      dateStart: this.$moment().format("YYYY-MM-DD"),
      dateEnd: this.$moment().format("YYYY-MM-DD"),
      menuDateStart: false,
      menuDateEnd: false,
      loading: false,
    };
  },
  computed: {
    ...mapGetters(["getAgent", "getAgentsToArray"]),
    getAgents() {
      return [{ id: null, full_name: "Todos" }, ...this.getAgentsToArray];
    },
  },
  async created() {
    if (!this.getAgentsToArray) await this.getAllAgents();
  },
  methods: {
    ...mapActions(["getAllAgents", "setTicketDownload"]),
    async save() {
      try {
        this.loading = true;

        if (this.$refs.formDownload.validate()) {
          await this.setTicketDownload({
            name: this.name,
            filters: {
              agent_id: this.agent,
              date_start: this.dateStart,
              date_end: this.dateEnd,
            },
          });

          this.$router.replace({ name: "ticket_downloads" });
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
