var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    { attrs: { id: "tickets-downloads" } },
    [
      _vm.getTicketDownloads && _vm.getAgents
        ? _c(
            "v-container",
            { staticClass: "mb-10 pb-10" },
            [
              _c(
                "v-row",
                { staticClass: "pt-4" },
                [
                  _c("v-col", { attrs: { cols: "12" } }, [
                    _c(
                      "h2",
                      {
                        staticClass:
                          "text-h4 primary--text pb-4 font-weight-black pt-8 pt-md-0 pt-lg-0"
                      },
                      [
                        _vm._v(
                          "\n          Descargas de Oportunidades\n        "
                        )
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "12 hidden-md-and-up" } },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            to: { name: "ticket_downloads_register" },
                            color: "primary",
                            "elevation-12": "",
                            block: ""
                          }
                        },
                        [
                          _c(
                            "span",
                            [
                              _c(
                                "v-icon",
                                { staticClass: "mr-2", attrs: { size: "16" } },
                                [_vm._v("fas fa-file-download")]
                              ),
                              _vm._v("\n            Nueva descarga\n          ")
                            ],
                            1
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c(
                        "v-card",
                        { staticClass: "pa-4 rounded-lg fill-height" },
                        [
                          _c(
                            "v-row",
                            { staticClass: "justify-center" },
                            [
                              _vm.getTicketDownloads.length
                                ? _c(
                                    "v-col",
                                    {
                                      staticClass: "py-0",
                                      attrs: { cols: "12" }
                                    },
                                    [
                                      _c("v-data-table", {
                                        staticClass: "text-headers-colors",
                                        attrs: {
                                          headers: _vm.headers,
                                          items: _vm.getTicketDownloads,
                                          "items-per-page": 50,
                                          dense: "",
                                          "sort-by": "",
                                          "mobile-breakpoint": 0,
                                          "hide-default-footer": "",
                                          "show-expand": ""
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "item.agent",
                                              fn: function(ref) {
                                                var item = ref.item
                                                return [
                                                  _vm._v(
                                                    "\n                  " +
                                                      _vm._s(
                                                        item.filters.agent_id
                                                          ? _vm.getAllAgentsMap[
                                                              item.filters
                                                                .agent_id
                                                            ].full_name
                                                          : "todos"
                                                      ) +
                                                      "\n                "
                                                  )
                                                ]
                                              }
                                            },
                                            {
                                              key: "item.counter",
                                              fn: function(ref) {
                                                var item = ref.item
                                                return [
                                                  _vm._v(
                                                    "\n                  " +
                                                      _vm._s(
                                                        item.counter || 0
                                                      ) +
                                                      "\n                "
                                                  )
                                                ]
                                              }
                                            },
                                            {
                                              key: "item.operations",
                                              fn: function(ref) {
                                                var item = ref.item
                                                return [
                                                  item.state === "completado" &&
                                                  item.counter
                                                    ? _c(
                                                        "v-tooltip",
                                                        {
                                                          attrs: { bottom: "" },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key:
                                                                  "activator",
                                                                fn: function(
                                                                  ref
                                                                ) {
                                                                  var on =
                                                                    ref.on
                                                                  return [
                                                                    _c(
                                                                      "v-btn",
                                                                      _vm._g(
                                                                        {
                                                                          attrs: {
                                                                            text:
                                                                              "",
                                                                            icon:
                                                                              ""
                                                                          },
                                                                          on: {
                                                                            click: function(
                                                                              $event
                                                                            ) {
                                                                              $event.stopPropagation()
                                                                              $event.preventDefault()
                                                                              return _vm.download(
                                                                                item.url
                                                                              )
                                                                            }
                                                                          }
                                                                        },
                                                                        on
                                                                      ),
                                                                      [
                                                                        _c(
                                                                          "v-icon",
                                                                          {
                                                                            attrs: {
                                                                              color:
                                                                                "primary",
                                                                              dark:
                                                                                "",
                                                                              small:
                                                                                ""
                                                                            }
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "fas fa-download"
                                                                            )
                                                                          ]
                                                                        )
                                                                      ],
                                                                      1
                                                                    )
                                                                  ]
                                                                }
                                                              }
                                                            ],
                                                            null,
                                                            true
                                                          )
                                                        },
                                                        [
                                                          _vm._v(" "),
                                                          _c("span", [
                                                            _vm._v(
                                                              "Descargar archivo"
                                                            )
                                                          ])
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  [
                                                    "descargando",
                                                    "procesando",
                                                    "convirtiendo"
                                                  ].includes(item.state)
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "text-center"
                                                        },
                                                        [
                                                          _c(
                                                            "v-progress-circular",
                                                            {
                                                              attrs: {
                                                                size: 20,
                                                                color:
                                                                  "primary",
                                                                indeterminate:
                                                                  ""
                                                              }
                                                            }
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e()
                                                ]
                                              }
                                            },
                                            {
                                              key: "expanded-item",
                                              fn: function(ref) {
                                                var item = ref.item
                                                return [
                                                  _c(
                                                    "td",
                                                    {
                                                      staticClass: "pa-8",
                                                      attrs: {
                                                        colspan:
                                                          _vm.headers.length
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "v-row",
                                                        [
                                                          _c(
                                                            "v-col",
                                                            {
                                                              staticClass:
                                                                "pt-0",
                                                              attrs: {
                                                                cols: "12",
                                                                sm: "4"
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "grey--text text--darken-2"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "ID Descarga:"
                                                                  )
                                                                ]
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "v-chip",
                                                                {
                                                                  attrs: {
                                                                    color:
                                                                      "accent",
                                                                    ripple: false,
                                                                    small: ""
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      item.id
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "v-col",
                                                            {
                                                              staticClass:
                                                                "pt-0",
                                                              attrs: {
                                                                cols: "12",
                                                                sm: "4"
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "grey--text text--darken-2"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "Generado por:"
                                                                  )
                                                                ]
                                                              ),
                                                              _vm._v(
                                                                "\n                        " +
                                                                  _vm._s(
                                                                    item.created_uid
                                                                      ? _vm
                                                                          .getAllAgentsMap[
                                                                          item
                                                                            .created_uid
                                                                        ]
                                                                          .full_name
                                                                      : "-"
                                                                  ) +
                                                                  "\n                      "
                                                              )
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "v-col",
                                                            {
                                                              staticClass:
                                                                "pt-0",
                                                              attrs: {
                                                                cols: "12",
                                                                sm: "4"
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "grey--text text--darken-2"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "Fecha de registro:"
                                                                  )
                                                                ]
                                                              ),
                                                              _vm._v(
                                                                "\n                        " +
                                                                  _vm._s(
                                                                    _vm.$formatTimestamp(
                                                                      item.created_at,
                                                                      false,
                                                                      true
                                                                    )
                                                                  ) +
                                                                  "\n                      "
                                                              )
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "12"
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "h5",
                                                                {
                                                                  staticClass:
                                                                    "body-2"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      item.name
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "12",
                                                                sm: "4"
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "grey--text text--darken-4"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "Filtros"
                                                                  )
                                                                ]
                                                              ),
                                                              _vm._v(" "),
                                                              _c("br"),
                                                              _vm._v(" "),
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "grey--text text--darken-2"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "Fecha inicio:"
                                                                  )
                                                                ]
                                                              ),
                                                              _vm._v(
                                                                "\n                        " +
                                                                  _vm._s(
                                                                    item.filters
                                                                      .date_start ||
                                                                      "-"
                                                                  ) +
                                                                  "\n                        "
                                                              ),
                                                              _c("br"),
                                                              _vm._v(" "),
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "grey--text text--darken-2"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "Fecha final:"
                                                                  )
                                                                ]
                                                              ),
                                                              _vm._v(
                                                                "\n                        " +
                                                                  _vm._s(
                                                                    item.filters
                                                                      .date_end ||
                                                                      "-"
                                                                  ) +
                                                                  "\n                        "
                                                              ),
                                                              _c("br"),
                                                              _vm._v(" "),
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "grey--text text--darken-2"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "Agente:"
                                                                  )
                                                                ]
                                                              ),
                                                              _vm._v(
                                                                "\n                        " +
                                                                  _vm._s(
                                                                    item.filters
                                                                      .agent_id
                                                                      ? _vm
                                                                          .getAllAgentsMap[
                                                                          item
                                                                            .filters
                                                                            .agent_id
                                                                        ]
                                                                          .full_name
                                                                      : "todos"
                                                                  ) +
                                                                  "\n                      "
                                                              )
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "12",
                                                                sm: "4"
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "grey--text text--darken-2"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "Estado:"
                                                                  )
                                                                ]
                                                              ),
                                                              _vm._v(
                                                                "\n                        " +
                                                                  _vm._s(
                                                                    item.state
                                                                  ) +
                                                                  "\n                        "
                                                              ),
                                                              _c("br"),
                                                              _vm._v(" "),
                                                              item.state ===
                                                              "error"
                                                                ? _c("span", [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        item.error
                                                                      )
                                                                    )
                                                                  ])
                                                                : _vm._e()
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          item.counter
                                                            ? _c(
                                                                "v-col",
                                                                {
                                                                  attrs: {
                                                                    cols: "12",
                                                                    sm: "4"
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      staticClass:
                                                                        "grey--text text--darken-2"
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "Registros descargados:"
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _vm._v(
                                                                    "\n                        " +
                                                                      _vm._s(
                                                                        item.counter ||
                                                                          0
                                                                      ) +
                                                                      "\n                      "
                                                                  )
                                                                ]
                                                              )
                                                            : _vm._e()
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          false,
                                          3522334501
                                        )
                                      })
                                    ],
                                    1
                                  )
                                : _c(
                                    "v-col",
                                    {
                                      staticClass:
                                        "text-center text--secondary pt-10",
                                      attrs: { cols: "12" }
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "fas fa-file-download"
                                      }),
                                      _vm._v(" "),
                                      _c("h6", { staticClass: "caption" }, [
                                        _vm._v(
                                          "Aún no hay descargas disponibles"
                                        )
                                      ])
                                    ]
                                  )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  staticClass: "hidden-sm-and-down",
                  attrs: {
                    to: { name: "ticket_downloads_register" },
                    fixed: "",
                    bottom: "",
                    right: "",
                    "x-large": "",
                    color: "primary",
                    "elevation-12": "",
                    rounded: ""
                  }
                },
                [
                  _c(
                    "span",
                    [
                      _c(
                        "v-icon",
                        { staticClass: "mr-2", attrs: { size: "16" } },
                        [_vm._v("fas fa-file-download")]
                      ),
                      _vm._v("\n        Nueva descarga\n      ")
                    ],
                    1
                  )
                ]
              )
            ],
            1
          )
        : _c("v-container", [_c("ShowLoading")], 1)
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }