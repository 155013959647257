<template>
  <div class="pt-16 pt-md-0 pt-lg-0">
    <div v-if="!isLoading">
      <span class="caption">Última actualización: {{ $moment(dateUpdated).fromNow() }}</span>
      <iframe :src="reportUrl" frameborder="0" style="border: 0;" allowfullscreen></iframe>
    </div>
    <v-container v-else>
      <ShowLoading />
    </v-container>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { statitics_url } from "Config/config";

export default {
  name: "Statistics",
  data() {
    return {
      isLoading: true,
      reportUrl: null,
      dateUpdated: "",
    };
  },
  computed: {
    ...mapGetters(["getAgent", "getTeam"]),
  },
  watch: {
    getAgent: {
      deep: true,
      handler(value) {
        if (value) this.init();
      },
    },
  },
  created() {
    if (this.getAgent) this.init();
  },
  methods: {
    ...mapActions(["getTeamById"]),
    async init() {
      this.dateUpdated = this.loadDateUpdated();
      this.reportUrl = await this.loadReportUrl();

      this.isLoading = false;
    },
    loadDateUpdated() {
      const date = new Date();
      date.setHours(date.getHours() >= 6 && date.getHours() < 18 ? 6 : 18);
      date.setMinutes(0);
      this.dateUpdated = date;
    },
    async loadReportUrl() {
      if (this.getAgent.role === this.$roles.ENTREPRENEUR) {
        if (!this.getTeam) await this.getTeamById({ id: this.getAgent.team_id });

        return this.getTeam.statitics_url;
      }

      return statitics_url;
    },
  },
};
</script>
<style scoped>
iframe {
  width: 100%;
  height: 84vh;
}
</style>
