import { render, staticRenderFns } from "./Importer.vue?vue&type=template&id=42dc1195&"
import script from "./Importer.vue?vue&type=script&lang=js&"
export * from "./Importer.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/workspace/hosting/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('42dc1195')) {
      api.createRecord('42dc1195', component.options)
    } else {
      api.reload('42dc1195', component.options)
    }
    module.hot.accept("./Importer.vue?vue&type=template&id=42dc1195&", function () {
      api.rerender('42dc1195', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/contacts/options/Importer.vue"
export default component.exports