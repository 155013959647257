<template>
  <v-container class="pt-0 pb-0">
    <v-row class="justify-end pr-3">
      <v-dialog v-model="dialog" persistent max-width="500">
        <template v-slot:activator="{ on }">
          <v-btn x-small color="primary" outlined dark v-on="on" @click="dialog = true">Administrar etiquetas</v-btn>
        </template>
        <v-card>
          <v-card-title class="title px-2">
            <h5 class="pl-2">ETIQUETAS</h5>
            <v-spacer></v-spacer>
            <v-btn
              v-if="
                [$roles.SUPER_ADMIN, $roles.ADMIN, $roles.ENTREPRENEUR].includes(getAgent.role) &&
                !['create', 'update'].includes(action)
              "
              small
              rounded
              color="primary"
              class="mr-2"
              @click="create"
            >
              Nueva etiqueta
            </v-btn>
            <v-btn icon @click="dialog = false"><v-icon small>fa fa-times</v-icon></v-btn>
          </v-card-title>
          <v-card-text class="pa-5">
            <v-row>
              <v-col v-if="openForm" cols="12" class="pb-5">
                <v-form ref="formTag" lazy-validation>
                  <v-col cols="12" class="py-0">
                    <h3>{{ action == "create" ? "Nueva etiqueta" : "Editar etiqueta" }}</h3>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field v-model="tag.name" label="Nombre" placeholder=" " :rules="rules.name"></v-text-field>
                  </v-col>
                  <v-col cols="12" class="d-flex">
                    <v-text-field
                      v-model="tag.hashtag"
                      label="Abreviación"
                      hint="Para acciones automáticas"
                      placeholder=" "
                      :rules="rules.noRepeatTagHashtag"
                    ></v-text-field>
                    <v-alert dense text color="primary" class="my-auto ml-3"> #l{{ tag.hashtag }} </v-alert>
                  </v-col>
                  <v-col cols="12" class="d-flex justify-center">
                    <v-color-picker
                      v-model="tag.color"
                      :rules="rules.required"
                      hide-inputs
                      dot-size="12"
                      type="hex"
                      canvas-height="100"
                    ></v-color-picker>
                  </v-col>
                  <v-col cols="12">
                    <span>Previsualización:</span>
                    <br />
                    <div class="text-center py-3">
                      <v-chip :color="tag.color">{{ tag.name || "etiqueta" }}</v-chip>
                    </div>
                  </v-col>
                  <v-col cols="12" class="text-right">
                    <v-btn :loading="loading" color="primary" rounded @click="saveTag">
                      Guardar
                    </v-btn>
                    <v-btn color="gray darken-1" text rounded @click="resetTagForm">Cancelar</v-btn>
                  </v-col>
                </v-form>
              </v-col>
            </v-row>
            <v-simple-table v-if="getActiveTags.length > 0">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">
                      Etiqueta
                    </th>
                    <th class="text-center">
                      Abreviación
                    </th>
                    <th
                      v-if="[$roles.SUPER_ADMIN, $roles.ADMIN, $roles.ENTREPRENEUR].includes(getAgent.role)"
                      class="text-right"
                    >
                      Operaciones
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(t, i) in getActiveTags" :key="i">
                    <td>
                      <v-chip small :color="t.color">{{ t.name }}</v-chip>
                    </td>
                    <td class="text-center">{{ t.hashtag }}</td>
                    <td
                      v-if="[$roles.SUPER_ADMIN, $roles.ADMIN, $roles.ENTREPRENEUR].includes(getAgent.role)"
                      class="text-right"
                    >
                      <v-icon v-if="auxTag.id != t.id" x-small class="pr-4" @click="update(t)"
                        >fas fa-pencil-alt</v-icon
                      >
                      <v-icon x-small @click="remove(t)">fa fa-trash</v-icon>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <h4 v-else class="text-center pa-2">No hay etiquetas</h4>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-row>

    <!-- Modals -->
    <v-dialog v-if="auxTag" v-model="dialogDelete" persistent max-width="400">
      <v-card class="py-3">
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <h3 class="font-weight-thin">
                ¿Está seguro que
                <span class="font-weight-bold">desea eliminar la etiqueta "{{ auxTag.name }}"</span>?.
              </h3>
            </v-col>
            <v-col v-if="errorFormDelete" cols="12">
              <v-alert dense text type="error">
                {{ errorFormDelete }}
              </v-alert>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="mr-2">
          <v-spacer></v-spacer>
          <v-btn
            rounded
            text
            @click="
              resetTagForm();
              dialogDelete = false;
            "
            >Cancelar</v-btn
          >
          <v-btn :loading="loading" color="red" rounded dark @click="deleteTag">Eliminar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import getRandomColor from "@/helpers/get_randomcolor";

export default {
  name: "TagModal",
  data() {
    return {
      dialog: false,
      tag: {
        id: "",
        name: "",
        hashtag: "",
        color: getRandomColor(),
        active: true,
      },
      auxTag: {
        id: "",
        name: "",
        hashtag: "",
      },
      openForm: false,
      action: "",
      loading: false,
      isRepeated: false,
      dialogDelete: false,
      errorFormDelete: null,
      rules: {
        required: [(v) => !!v || "Campo requerido"],
        name: [(v) => !!v || "Campo requerido", (v) => (v && !this.isRepeated) || "La etiqueta ya existe"],
        noRepeatTagHashtag: [
          (v) => !!v || "Campo requerido",
          // eslint-disable-next-line
          (v) =>
            !this.getActiveTags.find(
              (t) => t.hashtag?.slice(2).toLowerCase().trim() == this.tag.hashtag?.toLowerCase().trim(),
            ) ||
            v === this.auxTag?.hashtag?.slice(2) ||
            "El hashtag ya existe",
        ],
      },
    };
  },
  computed: {
    ...mapGetters(["getAgent", "getTags", "getActiveTags"]),
  },
  watch: {
    "tag.name"(value) {
      this.tag.name = value.trim().toLowerCase();
    },
  },
  methods: {
    ...mapActions(["getAllTags", "createTag", "updateTag", "removeTag"]),
    async saveTag() {
      try {
        this.loading = true;
        this.isRepeated = this.getTags.find((item) => {
          return item.name === this.tag.name && item.active && item.name !== this.auxTag.name;
        });

        if (this.$refs.formTag.validate()) {
          const response =
            this.action === "create"
              ? await this.createTag({ tag: this.tag })
              : await this.updateTag({ tag: this.tag });

          if (response.data) this.resetTagForm();
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    async deleteTag() {
      try {
        this.loading = true;
        this.errorFormDelete = null;

        await this.removeTag({ tag: this.auxTag });

        this.dialogDelete = false;
      } catch (error) {
        this.errorFormDelete = error.message || "Hubo un error al eliminar la etiqueta";
      } finally {
        this.loading = false;
      }
    },
    create() {
      this.resetTagForm();
      this.action = "create";
      this.openForm = true;
    },
    update(tag) {
      this.auxTag = { ...tag, hashtag: tag.hashtag?.slice(2) };
      this.tag = this.auxTag;
      this.action = "update";
      this.openForm = true;
    },
    remove(tag) {
      this.auxTag = { ...tag };
      this.dialogDelete = true;
    },
    resetTagForm() {
      this.action = "";
      this.tag.id = "";
      this.tag.name = "";
      this.tag.hashtag = "";
      this.tag.color = getRandomColor();
      if (this.$refs.formTag) this.$refs.formTag.resetValidation();
      this.openForm = false;
      this.auxTag = { id: "" };
    },
  },
};
</script>
