var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-col",
    { staticClass: "pa-0", attrs: { cols: "12" } },
    [
      _c(
        "v-card",
        { staticClass: "rounded-lg pa-2" },
        [
          _c(
            "v-col",
            { staticClass: "py-0 px-3", attrs: { cols: "12" } },
            [
              _c("Detail", {
                attrs: {
                  id: _vm.getIntegrationWhatsApp.id,
                  name: "WhatsApp",
                  type: "integration",
                  active: _vm.getIntegrationWhatsApp.active,
                  "show-detail": _vm.showDetail,
                  "able-edit": true,
                  "able-enable": false,
                  "able-remove": true,
                  error: _vm.error,
                  loading: _vm.loading
                },
                on: {
                  "update:showDetail": function($event) {
                    _vm.showDetail = $event
                  },
                  "update:show-detail": function($event) {
                    _vm.showDetail = $event
                  },
                  "update:loading": function($event) {
                    _vm.loading = $event
                  },
                  openEdit: _vm.openEdit,
                  openRemove: _vm.openRemove
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _vm.showDetail
            ? _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c(
                            "v-alert",
                            {
                              staticClass: "caption",
                              attrs: { dense: "", text: "", type: "info" }
                            },
                            [
                              _vm._v(
                                '\n            Cuando envíes el hashtag "#n" automaticamente se creará una oportunidad en Apolo CRM. Puedes gestionarla\n            con diferentes acciones como: cambiar estado, añadir etiquetas, establecer recordatorios (ejm: #r1m) todas\n            estás desde la conversación que tengas con tu cliente desde WhatsApp. Sí quieres más información de los\n            beneficios de está integración, comunícate con un asesor de Apolo CRM.\n          '
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { staticClass: "pb-0", attrs: { cols: "12" } },
                        [
                          _c("h5", { staticClass: "pb-2" }, [
                            _vm._v("Conexión")
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _vm.getIntegrationWhatsApp.active
                        ? _c(
                            "v-col",
                            {
                              staticClass: "py-0 caption",
                              attrs: { cols: "12" }
                            },
                            [
                              _c("span", { staticClass: "mt-2" }, [
                                _vm._v(
                                  "Configurado correctamente con WhatsApp " +
                                    _vm._s(_vm.getIntegrationWhatsApp.account)
                                )
                              ])
                            ]
                          )
                        : _c(
                            "v-col",
                            { staticClass: "caption", attrs: { cols: "12" } },
                            [_c("span", [_vm._v("No configurado")])]
                          )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.dialogToEnable
            ? _c("ModalEnableIntegration", {
                attrs: {
                  name: "WhatsApp",
                  "dialog-to-enable": _vm.dialogToEnable,
                  active: _vm.getIntegrationWhatsApp.active,
                  error: _vm.error,
                  loading: _vm.loading
                },
                on: {
                  "update:dialogToEnable": function($event) {
                    _vm.dialogToEnable = $event
                  },
                  "update:dialog-to-enable": function($event) {
                    _vm.dialogToEnable = $event
                  },
                  "update:active": function($event) {
                    return _vm.$set(
                      _vm.getIntegrationWhatsApp,
                      "active",
                      $event
                    )
                  },
                  "update:error": function($event) {
                    _vm.error = $event
                  },
                  "update:loading": function($event) {
                    _vm.loading = $event
                  },
                  enable: _vm.enable
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.dialogToRemove
            ? _c("ModalRemoveIntegration", {
                attrs: {
                  name: "WhatsApp",
                  "dialog-to-remove": _vm.dialogToRemove,
                  error: _vm.error,
                  loading: _vm.loading
                },
                on: {
                  "update:dialogToRemove": function($event) {
                    _vm.dialogToRemove = $event
                  },
                  "update:dialog-to-remove": function($event) {
                    _vm.dialogToRemove = $event
                  },
                  "update:error": function($event) {
                    _vm.error = $event
                  },
                  "update:loading": function($event) {
                    _vm.loading = $event
                  },
                  remove: _vm.remove
                }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }