export default {
  agent: null,
  agentSelected: null,
  agentAuthenticated: false,
  agents: null,
  allAgents: null,
  allAgentsMap: null,
  sellerAgents: [],
  agentRoles: {
    advisor: { id: "advisor", name: "Asesor" },
    supervisor: { id: "supervisor", name: "Supervisor" },
    admin: { id: "admin", name: "Administrador" },
    super_admin: { id: "super_admin", name: "Super Administrador" },
    entrepreneur: { id: "entrepreneur", name: "Emprendedor" },
  },
  showAgentDetailDrawer: null,
  agentId: null,
  menu: [],
  menuBase: [
    {
      icon: "fas fa-ticket-alt",
      title: "Oportunidades",
      path: "/opportunities",
      showOnBottomBar: true,
      items: [],
    },
    {
      icon: "fas fa-users",
      title: "Contactos",
      path: "/contacts",
      showOnBottomBar: true,
      items: [],
    },
    {
      icon: "far fa-calendar-alt",
      title: "Eventos",
      path: "/events",
      showOnBottomBar: true,
      items: [],
    },
    {
      icon: "fas fa-ad",
      title: "Campañas",
      path: "/campaigns",
      items: [],
    },
  ],
  menuOrder: {
    icon: "fas fa-store",
    title: "Órdenes",
    path: "/orders",
    items: [],
  },
  menuAgents: {
    icon: "fas fa-user-tie",
    title: "Trabajadores",
    path: "/agents",
    items: [],
  },
  menuStatistics: {
    icon: "fas fa-chart-line",
    title: "Estadísticas",
    path: "/statistics",
    items: [],
  },
  menuSegments: {
    icon: "fas fa-cubes",
    title: "Segmentos",
    path: "/segments",
    items: [],
  },
};
