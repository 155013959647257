<template>
  <v-card>
    <v-col cols="12" class="grey--text text--darken-3 font-weight-bold pa-4 pb-0">
      HISTORIAL DE LA ORDEN
    </v-col>
    <v-col cols="12">
      <v-timeline v-if="getCurrentTimeline && getCurrentTimeline.length > 0" dense>
        <v-slide-x-transition group>
          <v-timeline-item
            v-for="(c, index) in getCurrentTimeline"
            :key="index"
            fill-dot
            :color="getCommentTypes[c.type].color"
            small
          >
            <template v-slot:icon>
              <v-avatar v-if="getAllAgentsMap[c.uid] && getAllAgentsMap[c.uid].photo" size="24">
                <img :src="getAllAgentsMap[c.uid].photo" :alt="getAllAgentsMap[c.uid].name" />
              </v-avatar>
            </template>
            <v-card class="pl-6 pa-2">
              <v-row>
                <strong v-if="getAllAgentsMap[c.uid]" class="pr-1" v-text="getAllAgentsMap[c.uid].full_name"></strong>
                <strong v-if="getAllAgentsMap[c.uid]">·</strong>
                <strong v-if="c.created_at" class="ml-1" v-text="$formatTimestamp(c.created_at, 'short')"></strong>
              </v-row>
              <div class="caption pb-2" v-html="c.text_formatted || c.text"></div>
              <v-chip
                v-if="getAllAgentsMap[c.uid]"
                :color="getCommentTypes[c.type].color"
                rounded
                outlined
                small
                class="my-1"
              >
                <v-icon left size="12">{{ getCommentTypes[c.type].icon }}</v-icon>
                <span>Comentario</span>
              </v-chip>
            </v-card>
          </v-timeline-item>
        </v-slide-x-transition>
      </v-timeline>
      <div v-else class="text-center py-4">Aún no hay comentarios</div>
    </v-col>
  </v-card>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "OrderCardTimeline",
  computed: {
    ...mapGetters(["getCurrentTimeline", "getAllAgentsMap", "getCommentTypes"]),
  },
};
</script>
