<template>
  <v-app>
    <v-container v-if="getAgent" class="rounded-lg pb-5 mb-5 pt-4 pt-sm-10">
      <v-row class="pt-4">
        <v-col cols="12" sm="10" md="8" offset-sm="1" offset-md="2">
          <h2 class="text-h4 primary--text pb-4 font-weight-black pt-8 pt-md-0 pt-lg-0">Nuevo segmento</h2>
          <v-card class="pa-4 rounded-lg">
            <SegmentForm />
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-container v-else>
      <ShowLoading />
    </v-container>
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";
import SegmentForm from "@/views/segments/forms/Segment";

export default {
  name: "SegmentRegister",
  components: { SegmentForm },
  computed: {
    ...mapGetters(["getAgent"]),
  },
};
</script>
