<template>
  <v-app id="importer_activities">
    <v-container>
      <v-row class="pt-4">
        <v-col cols="12" sm="10" md="8" offset-sm="1" offset-md="2">
          <h2 class="text-h4 primary--text pb-4 font-weight-black pt-8 pt-md-0 pt-lg-0">
            Importador de actividades de Oportunidades
          </h2>
          <v-card class="px-4 py-6 rounded-lg">
            <v-row>
              <v-col cols="12">
                <v-row>
                  <v-col cols="12" sm="8">
                    <h5 class="font-weight-regular subtitle-2">
                      Primero debes <strong>descargar la plantilla de importación</strong> la cual contiene los datos
                      necesario para importar actividades de los oportunidades.
                    </h5>
                  </v-col>
                  <v-col cols="12" sm="4" class="text-sm-right">
                    <v-btn :loading="loadingTemplate" color="primary" outlined @click="exportTemplate">
                      Descargar plantilla
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-alert dense text>
                      <v-row>
                        <v-col cols="12" sm="4">
                          <h4 class="grey--text text--darken-3">Tipos de actividades:</h4>
                        </v-col>
                        <v-col cols="12" sm="3">
                          <h5 class="grey--text text--darken-2 text-sm-right">
                            <v-icon class="pr-1" size="18">fas fa-envelope</v-icon> mensaje
                          </h5>
                        </v-col>
                        <v-col cols="12" sm="2">
                          <h5 class="grey--text text--darken-2 text-sm-center">
                            <v-icon class="pr-1" size="18">fas fa-phone</v-icon> llamada
                          </h5>
                        </v-col>
                        <v-col cols="12" sm="3">
                          <h5 class="grey--text text--darken-2">
                            <v-icon class="pr-1" size="18">fas fa-comments</v-icon> comentario
                          </h5>
                        </v-col>
                      </v-row>
                    </v-alert>
                  </v-col>
                </v-row>
                <v-row class="mt-5">
                  <v-col cols="12">
                    <v-form ref="formImporterActivities" lazy-validation>
                      <v-row>
                        <v-col cols="12" sm="8">
                          <v-file-input
                            v-model="file"
                            chips
                            label="Seleccione archivo a importar"
                            placeholder="solo archivos .xlsx"
                            truncate-length="50"
                            prepend-icon=""
                            append-outer-icon="fa fa-paperclip"
                            accept=".xlsx"
                            :rules="[$rules.REQUIRED]"
                          ></v-file-input>
                        </v-col>
                        <v-col cols="12">
                          <v-btn :loading="loading" color="primary" rounded @click="imports">
                            Importar actividades
                          </v-btn>
                        </v-col>
                        <v-col v-if="responseStatus !== null" cols="12" sm="8">
                          <v-alert text :type="response_status">
                            {{ responseMessage }}
                          </v-alert>
                          <v-row v-if="responseData">
                            <template v-for="(r, i) in responseData">
                              <v-col v-if="r.error" :key="i" cols="12" class="py-1">
                                <div class="d-flex">
                                  <span class="body-2 font-weight-bold">Fila {{ r.error.row }}:</span>
                                  <h5 class="body-2 pl-3">{{ r.message }}</h5>
                                </div>
                                <v-alert dense outlined type="error" class="caption my-1">{{ r.error }}</v-alert>
                              </v-col>
                            </template>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>
<script>
import { mapActions } from "vuex";

export default {
  name: "ImporterActivities",
  data() {
    return {
      loading: false,
      loadingTemplate: false,
      file: null,
      responseStatus: null,
      responseMessage: null,
      responseData: null,
    };
  },
  methods: {
    ...mapActions(["exportTicketActivityTemplate", "importTicketActivities"]),
    async exportTemplate() {
      try {
        this.loadingTemplate = true;

        let response = await this.exportTicketActivityTemplate();
        let url = window.URL.createObjectURL(new Blob([response]));
        let link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "tickets_activities.xlsx");
        document.body.appendChild(link);
        link.click();
      } catch (error) {
        console.error(error);
      } finally {
        this.loadingTemplate = false;
      }
    },
    async imports() {
      try {
        this.loading = true;
        this.responseStatus = null;
        this.responseData = null;
        if (this.$refs.formImporterActivities.validate()) {
          let response = await this.importTicketActivities({ file: this.file });
          this.responseStatus = response.status;
          this.responseMessage = response.message;
          this.responseData = response.data;
          this.cleanForm();
        }
      } catch (error) {
        console.error(error);
        this.responseStatus = "error";
        this.responseMessage = error.message;
      } finally {
        this.loading = false;
      }
    },
    cleanForm() {
      this.file = null;
      this.$refs.formImporterActivities.resetValidation();
    },
  },
};
</script>
