var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.getAgent
    ? _c(
        "div",
        [
          _c(
            "v-form",
            { attrs: { "lazy-validation": "" } },
            [
              _c(
                "v-row",
                { staticClass: "text-left" },
                [
                  _c(
                    "v-col",
                    { staticClass: "pb-0", attrs: { cols: "12" } },
                    [
                      [
                        _c(
                          "v-row",
                          { staticClass: "pb-2" },
                          [
                            _c(
                              "v-col",
                              {
                                staticClass: "py-0",
                                attrs: { cols: "12", md: "6" }
                              },
                              [
                                _c("label", { attrs: { for: "etiqueta" } }, [
                                  _vm._v("Etiqueta")
                                ])
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "v-col",
                              {
                                staticClass: "pa-0",
                                attrs: { cols: "12", md: "6" }
                              },
                              [_c("TagModal")],
                              1
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("v-combobox", {
                          staticClass: "h-auto pt-0",
                          attrs: {
                            id: "etiqueta",
                            items: _vm.getActiveTags,
                            chips: "",
                            multiple: "",
                            "item-id": "name",
                            "item-text": "name",
                            "return-object": "",
                            solo: ""
                          },
                          on: { change: _vm.changeTags },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "selection",
                                fn: function(ref) {
                                  var attrs = ref.attrs
                                  var item = ref.item
                                  var select = ref.select
                                  var selected = ref.selected
                                  return [
                                    _c(
                                      "v-chip",
                                      _vm._b(
                                        {
                                          attrs: {
                                            "input-value": selected,
                                            color: item.color
                                          },
                                          on: {
                                            click: select,
                                            "click:close": function($event) {
                                              return _vm.removeTag(item)
                                            }
                                          }
                                        },
                                        "v-chip",
                                        attrs,
                                        false
                                      ),
                                      [
                                        _c("strong", [
                                          _vm._v(_vm._s(item.name))
                                        ]),
                                        _vm._v(" \n                "),
                                        _c("span")
                                      ]
                                    )
                                  ]
                                }
                              },
                              {
                                key: "no-data",
                                fn: function() {
                                  return [
                                    _c(
                                      "v-list-item",
                                      [
                                        _c(
                                          "v-list-item-content",
                                          [
                                            _c("v-list-item-title", [
                                              _vm._v(
                                                " El tag no existe. Presiona "
                                              ),
                                              _c("kbd", [_vm._v("enter")]),
                                              _vm._v(" para crearlo. ")
                                            ])
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                },
                                proxy: true
                              }
                            ],
                            null,
                            false,
                            1790023647
                          ),
                          model: {
                            value: _vm.tags,
                            callback: function($$v) {
                              _vm.tags = $$v
                            },
                            expression: "tags"
                          }
                        })
                      ]
                    ],
                    2
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }