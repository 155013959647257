var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-navigation-drawer",
    {
      attrs: { temporary: "", app: "", right: "", width: "400" },
      model: {
        value: _vm.localDrawer,
        callback: function($$v) {
          _vm.localDrawer = $$v
        },
        expression: "localDrawer"
      }
    },
    [
      _c(
        "v-container",
        [
          _c(
            "v-col",
            {
              staticClass: "pa-4 primary--text body-1 font-weight-bold",
              attrs: { cols: "12" }
            },
            [
              _c(
                "v-icon",
                {
                  staticClass: "mr-2",
                  attrs: { color: "primary", dark: "", small: "" }
                },
                [_vm._v("far fa-edit")]
              ),
              _vm._v(" Editar\n    ")
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { staticClass: "px-0", attrs: { cols: "12" } },
            [
              _c(
                "v-card",
                { staticClass: "pa-4" },
                [
                  _c("FormCampaignBudget", {
                    attrs: {
                      "campaign-budget-selected": _vm.campaignBudgetSelected,
                      action: _vm.action,
                      sm: 12
                    },
                    on: {
                      "update:campaignBudgetSelected": function($event) {
                        _vm.campaignBudgetSelected = $event
                      },
                      "update:campaign-budget-selected": function($event) {
                        _vm.campaignBudgetSelected = $event
                      },
                      "update:action": function($event) {
                        _vm.action = $event
                      }
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }