import * as MutationsType from "./mutationsType";
/* eslint-disable no-param-reassign */
export default {
  [MutationsType.SET_TIMELINE](state, payload) {
    state.timeline = payload;
  },
  [MutationsType.ADD_ACTIVITY](state, payload) {
    if (!state.timelineIds.includes(payload.id) && payload.created_at) {
      state.timeline.push(payload);
      state.timelineIds.push(payload.id);
    }
  },
  [MutationsType.ADD_CONTACT_ACTIVITY](state, payload) {
    if (!state.contactTlineIds.includes(payload.id) && payload.created_at) {
      state.contactTimeline.push(payload);
      state.contactTlineIds.push(payload.id);
    }
  },
};
