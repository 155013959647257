import * as MutationsType from "./mutationsType";

export default {
  [MutationsType.SET_DEVICE](state, payload) {
    let device = payload;
    if (state.device) {
      device = state.device;

      if (payload.allow) device.notification_types.push(payload.notification_types[0]);
      else device.notification_types = device.notification_types.filter((nt) => nt !== payload.notification_types[0]);
    }
    state.device = device;
  },
};
