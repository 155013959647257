export default {
  webhooks: null,
  webhooksTypes: {
    messenger: {
      id: "messenger",
      name: "Facebook Messenger",
      icon: "fb_messenger.png",
    },
  },
};
