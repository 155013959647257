<template>
  <v-menu bottom left offset-y open-on-hover>
    <template v-slot:activator="{ on, attrs }">
      <v-btn small outlined color="primary" v-bind="attrs" v-on="on">
        <v-icon small>far fa-calendar-plus</v-icon>
        <span class="pl-2">Agregar a calendario</span>
      </v-btn>
    </template>

    <v-list>
      <v-list-item @click="generateGoogleCalendarLink">
        <v-list-item-title>Google Calendar</v-list-item-title>
      </v-list-item>
      <v-list-item @click="generateICS">
        <v-list-item-title>iCal</v-list-item-title>
      </v-list-item>
      <v-list-item @click="generateICS">
        <v-list-item-title>Outlook</v-list-item-title>
      </v-list-item>
      <v-list-item @click="generateYahooCalendarLink">
        <v-list-item-title>Yahoo Calendar</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>
<script>
export default {
  props: {
    eventId: {
      type: String,
      default: "",
    },
    date: {
      type: String,
      default: "",
    },
    hourStart: {
      type: String,
      default: "",
    },
    hourEnd: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    detail: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      eventDate: "",
      eventHourStart: "",
      eventHourEnd: "",
    };
  },
  watch: {
    date(value) {
      this.eventDate = value.replaceAll("-", "");
    },
    hourStart(value) {
      this.eventHourStart = value.replace(":", "");
    },
    hourEnd(value) {
      this.eventHourEnd = value.replace(":", "");
    },
  },
  mounted() {
    this.eventDate = this.date.replaceAll("-", "");
    this.eventHourStart = this.hourStart.replace(":", "");
    this.eventHourEnd = this.hourEnd.replace(":", "");
  },
  methods: {
    generateGoogleCalendarLink() {
      window.open(
        `https://www.google.com/calendar/event?action=TEMPLATE&dates=${this.eventDate}T${this.eventHourStart}00/${this.eventDate}T${this.eventHourEnd}00&text=${this.title}&details=${this.detail}`,
        "_blank",
      );
    },
    generateYahooCalendarLink() {
      window.open(
        `http://calendar.yahoo.com?amp;v=60&amp;VIEW=d&amp;TYPE=20&amp;TITLE=${this.title}&amp;ST=${this.eventDate}T${this.eventHourStart}00&amp;ET=${this.eventDate}T${this.eventHourEnd}00&amp;DESC=${this.detail}`,
        "_blank",
      );
    },
    generateICS() {
      var test =
        "BEGIN:VCALENDAR\n" +
        "CALSCALE:GREGORIAN\n" +
        "METHOD:PUBLISH\n" +
        "PRODID:-//Apolocrm//ES\n" +
        "VERSION:2.0\n" +
        "BEGIN:VEVENT\n" +
        `UID:apolocrm${this.eventId}\n` +
        "DTSTART;VALUE=DATE-TIME:" +
        `${this.eventDate}T${this.eventHourStart}00` +
        "\n" +
        "DTEND;VALUE=DATE-TIME:" +
        `${this.eventDate}T${this.eventHourEnd}00` +
        "\n" +
        "SUMMARY:" +
        this.title +
        "\n" +
        "DESCRIPTION:" +
        this.detail +
        "\n" +
        "END:VEVENT\n" +
        "END:VCALENDAR";

      let data = new File([test], { type: "text/plain" });
      let icsFile = window.URL.createObjectURL(data);
      let link = document.createElement("a");
      link.href = icsFile;
      link.setAttribute("download", `evento-${this.$moment().format("DD-MM-YYYY-hh-mm")}.ics`);
      document.body.appendChild(link);
      link.click();
    },
  },
};
</script>
