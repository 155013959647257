<template>
  <v-app>
    <v-container class="rounded-lg">
      <v-row class="py-4">
        <v-col cols="12" sm="10" md="8" offset-sm="1" offset-md="2">
          <h2 class="text-h4 primary--text pb-4 font-weight-black pt-8 pt-md-0 pt-lg-0">
            Nuevo presupuesto de campaña
          </h2>
          <v-card class="px-4 py-6 rounded-lg">
            <h5 class="font-weight-regular mb-5">
              Cada vez que se registre un nuevo presupuesto de campaña, podremos enlazar con el reporte de canales en la
              sección de estadísticas.
            </h5>
            <CampaignBudgetForm action="create" />
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>
<script>
import { mapActions } from "vuex";
import CampaignBudgetForm from "@/components/forms/CampaignBudgetForm";

export default {
  name: "CampaignRegisterBudget",
  components: {
    CampaignBudgetForm,
  },
  async created() {
    await this.getAllAgents();
    await this.getAllProjects();
  },
  methods: {
    ...mapActions(["getAllAgents", "getAllProjects"]),
  },
};
</script>
