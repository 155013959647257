import Vue from "vue";
import * as MutationsType from "./mutationsType";

export default {
  [MutationsType.SET_TEAM](state, payload) {
    state.team = payload;
  },
  [MutationsType.SET_TEAMS](state, payload) {
    state.teams = payload;
  },
  [MutationsType.ADD_TEAM](state, payload) {
    state.teams.push(payload);
  },
  [MutationsType.UPDATE_TEAM](state, payload) {
    let ind = state.teams.findIndex((team) => team.id === payload.id);
    if (ind >= 0) Vue.set(state.teams, ind, { ...state.teams[ind], ...payload });
  },
  [MutationsType.SET_SCHEMA_TICKET_FORM](state, payload) {
    state.schemaTicketForm = payload;
  },
  [MutationsType.SET_SCHEMA_CONTACT_FORM](state, payload) {
    state.schemaContactForm = payload;
  },
  [MutationsType.SET_SCHEMA_TICKET_EXPORT](state, payload) {
    state.schemaTicketExport = payload;
  },
  [MutationsType.SET_SCHEMA_CONTACT_EXPORT](state, payload) {
    state.schemaContactExport = payload;
  },
};
