<template>
  <v-dialog v-model="dialog" scrollable persistent max-width="800">
    <v-card class="py-3">
      <v-card-title class="headline">
        <div>
          <v-img :src="require(`@/assets/social_icons/${id}.png`)" width="20px" class="mr-2"></v-img>
        </div>
        <span class="ml-0">Zoom Webinars</span>
        <v-spacer></v-spacer>
        <v-btn icon @click="closeDialog">
          <v-icon small>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <h4 class="font-weight-thin">
              Está integración le permitirá registrar asistentes a uno de sus webinars creados.
            </h4>
          </v-col>
          <v-col cols="12" class="d-flex">
            <span class="circle primary white--text mr-2">1</span>
            <v-row class="pr-5">
              <v-col cols="12" class="pt-0">
                <h4 class="font-weight-thin">
                  Deberá ingresar el <span class="font-weight-bold">Usuario ID o correo electrónico</span> y el
                  <span class="font-weight-bold">token de accesso</span> de la cuenta Zoom que cuenta con el complemento
                  de Webinars para verificar la conexión.
                </h4>
              </v-col>
              <v-col cols="12" class="py-0">
                <v-form ref="formVerify" lazy-validation>
                  <v-row>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model="user"
                        label="User ID o correo electrónico"
                        placeholder=" "
                        :rules="[$rules.REQUIRED]"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model="accessToken"
                        label="Token de acceso"
                        placeholder=" "
                        type="password"
                        :rules="[$rules.REQUIRED]"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" class="text-right">
                      <span v-if="verifyError" class="error--text mr-4">{{ verifyError }}</span>
                      <span v-if="accountVerified" class="green--text mr-4 font-weight-bold"
                        ><i class="fas fa-check"></i> Verificado</span
                      >
                      <v-btn
                        rounded
                        color="accent"
                        outlined
                        :disabled="accountVerified"
                        :loading="loadVerify"
                        @click="verify"
                        >Verificar</v-btn
                      >
                    </v-col>
                  </v-row>
                </v-form>
              </v-col>
            </v-row>
          </v-col>
          <v-col v-if="getActiveTags && getOrigins" cols="12" class="d-flex">
            <span class="circle primary white--text mr-2">2</span>
            <v-row class="pr-5">
              <v-col cols="12" class="pt-0">
                <h4 class="font-weight-thin">
                  <span class="font-weight-bold">Seleccione su audencia</span>, y luego
                  <span class="font-weight-bold">seleccione el webinar</span> al cual se va a importar a los asistentes.
                </h4>
              </v-col>
              <v-col cols="12" class="py-0">
                <v-form ref="formFilters" lazy-validation>
                  <v-row>
                    <v-col cols="12" sm="6" md="4">
                      <v-menu
                        v-model="menuStart"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="dateStart"
                            label="Fecha inicial"
                            prepend-icon="far fa-calendar-alt"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker v-model="dateStart" :max="dateEnd" @input="menuStart = false"></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-menu
                        v-model="menuEnd"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="dateEnd"
                            label="Fecha final"
                            prepend-icon="far fa-calendar-alt"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker v-model="dateEnd" @input="menuEnd = false"></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-autocomplete
                        v-model="origin"
                        :items="getOrigins"
                        item-value="id"
                        item-text="name"
                        label="Origen"
                        placeholder="Todos"
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" class="pt-0">
                      <v-select
                        v-model="tags"
                        :items="getActiveTags"
                        item-value="id"
                        item-text="name"
                        attach
                        chips
                        label="Etiquetas"
                        placeholder=" "
                        :rules="[(v) => v.length < 11 || 'Máximo 10 etiquetas']"
                        multiple
                        class="expand"
                      >
                        <template v-slot:selection="{ attrs, item, select, selected }">
                          <v-chip v-bind="attrs" :input-value="selected" :color="item.color" @click="select">
                            <strong>{{ item.name }}</strong
                            >&nbsp;
                            <span></span>
                          </v-chip>
                        </template>
                      </v-select>
                    </v-col>
                    <v-col cols="12" class="text-right pt-0">
                      <v-btn rounded color="accent" outlined :loading="loadView" @click="view">Visualizar 5</v-btn>
                    </v-col>
                    <v-col v-if="tickets" cols="12" class="text-center">
                      <v-simple-table dense>
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th class="text-center">Nombres y Apellidos</th>
                              <th class="text-center">Correo electrónico</th>
                              <th class="text-center">Teléfono</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="item in tickets" :key="item.id">
                              <td class="text-center">{{ item.full_name }}</td>
                              <td class="text-center">{{ item.email }}</td>
                              <td class="text-center">{{ item.phone || "-" }}</td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                      <span v-if="tickets.length === 0" class="text-center caption">No hay registros</span>
                    </v-col>
                  </v-row>
                </v-form>
              </v-col>
              <v-col cols="12">
                <v-autocomplete
                  v-model="webinar"
                  :items="webinars"
                  item-value="id"
                  item-text="topic"
                  label="Webinar"
                  placeholder="Seleccione"
                  :rules="[$rules.REQUIRED]"
                ></v-autocomplete>
              </v-col>
              <v-col v-if="message" cols="12">
                <v-alert dense text :type="status ? 'success' : 'error'">
                  {{ message }}
                </v-alert>
              </v-col>
              <v-col cols="12" class="text-right">
                <v-btn rounded text @click="closeDialog">Cancelar</v-btn>
                <v-btn
                  v-if="!status"
                  rounded
                  color="primary"
                  class="my-auto ml-5"
                  :disabled="!accountVerified || !webinar"
                  :loading="loadImport"
                  @click="importAttendees"
                  >Importar</v-btn
                >
                <v-btn v-else color="primary" rounded @click="closeDialog">Listo</v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "ZoomWebinarsModal",
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      id: "zoom_webinars",
      accountVerified: false,
      user: "",
      accessToken: "",
      userVerified: "",
      accessTokenVerified: "",
      origin: "",
      dateStart: this.$moment().format("YYYY-MM-DD"),
      dateEnd: this.$moment().format("YYYY-MM-DD"),
      tags: [],
      webinar: "",
      webinars: [],
      verifyError: null,
      menuStart: false,
      menuEnd: false,
      status: null,
      message: null,
      tickets: null,
      loadVerify: false,
      loadView: false,
      loadImport: false,
    };
  },
  computed: {
    ...mapGetters(["getIntegrationZoomWebinars", "getActiveTags", "getOrigins"]),
  },
  watch: {
    user(value) {
      if (this.userVerified && this.userVerified !== value) this.accountVerified = false;
    },
    accessToken(value) {
      if (this.accessTokenVerified && this.accessTokenVerified !== value) this.accountVerified = false;
    },
    dateEnd(value) {
      if (this.dateStart && this.dateStart > value) this.dateStart = this.dateEnd;
    },
    accountVerified(value) {
      if (value === false) {
        this.webinar = "";
        this.webinars = [];
      }
    },
  },
  async created() {
    if (!this.getActiveTags.length) await this.getAllTags();
    if (!this.getOrigins) await this.getAllOrigins();
  },
  async mounted() {
    if (this.getIntegrationZoomWebinars) {
      this.user = this.getIntegrationZoomWebinars.user;
      this.accessToken = this.getIntegrationZoomWebinars.access_token;
      await this.verify();
    }
  },
  methods: {
    ...mapActions([
      "getAllTags",
      "getAllOrigins",
      "getTicketsWithFilters",
      "verifyIntegrationZoomWebinars",
      "registerUsersToZoomWebinar",
    ]),
    async verify() {
      try {
        this.loadVerify = true;
        this.verifyError = null;

        if (this.$refs.formVerify.validate()) {
          let response = await this.verifyIntegrationZoomWebinars({
            user: this.user,
            access_token: this.accessToken,
          });

          if (response.error) throw response;

          this.userVerified = this.user;
          this.accessTokenVerified = this.accessToken;
          this.accountVerified = true;
          this.webinars = response.data.webinars;
        }
      } catch (error) {
        this.verifyError = error.message || "Ocurrió un error al verificar";
        this.accountVerified = false;
      } finally {
        this.loadVerify = false;
      }
    },
    async view() {
      try {
        this.loadView = true;

        this.tickets = await this.getTicketsWithFilters({
          filters: {
            date_start: this.dateStart,
            date_end: this.dateEnd,
            tags: this.tags,
            origin: this.origin,
          },
        });
      } catch (error) {
        console.error("view: ", error);
      } finally {
        this.loadView = false;
      }
    },
    async importAttendees() {
      try {
        this.loadImport = true;

        if (this.$refs.formFilters.validate() && this.webinar) {
          let response = await this.registerUsersToZoomWebinar({
            user: this.user,
            access_token: this.accessToken,
            webinar_id: this.webinar,
            filters: {
              date_start: this.dateStart,
              date_end: this.dateEnd,
              tags: this.tags,
              origin: this.origin,
            },
          });
          this.message = response.message;
          this.status = true;
        }
      } catch (error) {
        this.message = error.message || "Ocurrió un error al importar asistentes";
        this.status = false;

        console.error("importAttendees: ", error);
      } finally {
        this.loadImport = false;
      }
    },
    closeDialog() {
      this.origin = "";
      this.tags = [];
      this.dateStart = this.$moment().format("YYYY-MM-DD");
      this.dateEnd = this.$moment().format("YYYY-MM-DD");
      this.accountVerified = null;
      this.webinar = "";
      this.webinars = [];
      this.tickets = null;
      this.$emit("update:dialog", false);
    },
  },
};
</script>
