var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { scrollable: "", persistent: "", "max-width": "800" },
      model: {
        value: _vm.dialog,
        callback: function($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _c(
        "v-card",
        { staticClass: "py-3" },
        [
          _c(
            "v-card-title",
            { staticClass: "headline" },
            [
              _c(
                "div",
                [
                  _c("v-img", {
                    staticClass: "mr-2",
                    attrs: {
                      src: require("@/assets/social_icons/" + _vm.id + ".png"),
                      width: "20px"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c("span", { staticClass: "ml-0" }, [_vm._v("WhatsApp")]),
              _vm._v(" "),
              _c("v-spacer"),
              _vm._v(" "),
              _c(
                "v-btn",
                { attrs: { icon: "" }, on: { click: _vm.closeDialog } },
                [
                  _c("v-icon", { attrs: { small: "" } }, [
                    _vm._v("fas fa-times")
                  ])
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card-text",
            [
              _c(
                "v-row",
                [
                  _c("v-col", { attrs: { cols: "12" } }, [
                    _c("h4", { staticClass: "font-weight-thin" }, [
                      _vm._v(
                        "\n            La integración con WhatsApp le permitirá ingresar oportunidades así como gestionarlas.\n          "
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { staticClass: "pt-2", attrs: { cols: "12" } },
                    [
                      !_vm.getIntegrationWhatsApp
                        ? _c(
                            "v-alert",
                            {
                              staticClass: "caption",
                              attrs: { dense: "", text: "", type: "info" }
                            },
                            [
                              _vm._v(
                                "\n            Antes de empezar, deberá tener la aplicación de WhatsApp en su dispositivo móvil con la cuenta que desea\n            configurar con ApoloCRM.\n          "
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.getIntegrationWhatsApp &&
                      _vm.getIntegrationWhatsApp.active
                        ? _c(
                            "v-alert",
                            { attrs: { dense: "", text: "", type: "success" } },
                            [
                              _vm._v(
                                "\n            Se ha conectado con WhatsApp éxitosamente.\n          "
                              )
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm._v(" "),
                  !_vm.getIntegrationWhatsApp ||
                  !_vm.getIntegrationWhatsApp.active
                    ? _c(
                        "v-col",
                        { staticClass: "d-flex", attrs: { cols: "12" } },
                        [
                          _c(
                            "span",
                            { staticClass: "circle primary white--text mr-2" },
                            [_vm._v("1")]
                          ),
                          _vm._v(" "),
                          _c(
                            "v-row",
                            { staticClass: "pr-5" },
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "pt-0",
                                  attrs: { cols: "12", sm: "6" }
                                },
                                [
                                  _c(
                                    "h4",
                                    { staticClass: "font-weight-thin" },
                                    [
                                      _vm._v(
                                        "\n                Deberá escanear el siguiente "
                                      ),
                                      _c(
                                        "span",
                                        { staticClass: "font-weight-bold" },
                                        [_vm._v("QR")]
                                      ),
                                      _vm._v(" desde\n                "),
                                      _c(
                                        "span",
                                        { staticClass: "font-weight-bold" },
                                        [_vm._v("WhatsApp")]
                                      ),
                                      _vm._v(".\n              ")
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _vm.generateQRError
                                    ? _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c(
                                            "v-alert",
                                            {
                                              attrs: { text: "", type: "error" }
                                            },
                                            [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(_vm.generateQRError) +
                                                  "\n                "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12" } },
                                    [
                                      _c(
                                        "v-alert",
                                        {
                                          staticClass: "caption",
                                          attrs: {
                                            dense: "",
                                            text: "",
                                            type: "info"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            '\n                  Luego que hayas escaneado el código QR con éxito, podrás enviar el hashtag "#" para crear\n                  automaticamente una oportunidad en Apolo CRM. Puedes gestionarla con diferentes acciones como:\n                  cambiar estado, añadir etiquetas, establecer recordatorios (ejm: #r1m) todas estás desde la\n                  conversación que tengas con tu cliente desde WhatsApp. Sí quieres más información de los beneficios\n                  de está integración, comunícate con un asesor de Apolo CRM.\n                '
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                {
                                  staticClass: "py-0 text-center",
                                  attrs: { cols: "12", sm: "6" }
                                },
                                [
                                  _vm.qr
                                    ? _c("img", {
                                        attrs: { src: _vm.qr, alt: "QR" }
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.loadingQR
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex align-center justify-center",
                                          style: {
                                            height: "300px"
                                          }
                                        },
                                        [
                                          _c("v-progress-circular", {
                                            attrs: {
                                              indeterminate: "",
                                              color: "primary"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { color: "primary", rounded: "" },
                                      on: { click: _vm.pingServer }
                                    },
                                    [_vm._v("Generar código QR")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }