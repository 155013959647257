var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-list-item",
        {
          key: "notifications",
          on: {
            click: function($event) {
              $event.stopPropagation()
              _vm.toggle = !_vm.toggle
            }
          }
        },
        [
          _c(
            "v-list-item-avatar",
            [
              _c("v-icon", {
                class: _vm.allowBrowserNotifications
                  ? "far fa-bell"
                  : "far fa-bell-slash"
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-list-item-content",
            [
              _c("v-list-item-title", [_vm._v("Notificaciones")]),
              _vm._v(" "),
              _c("v-list-item-subtitle", [
                _vm.message
                  ? _c("span", { staticClass: "red--text" }, [
                      _vm._v(_vm._s(_vm.message))
                    ])
                  : _c("span", [
                      _vm._v(
                        "\n          Permitir notificaciones en este dispositivo para los siguientes casos\n        "
                      )
                    ])
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-list-item-action",
            [
              _c("v-icon", [
                _vm._v(
                  "\n        " +
                    _vm._s(
                      !_vm.toggle ? "fas fa-angle-down" : "fas fa-angle-up"
                    ) +
                    "\n      "
                )
              ])
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.toggle
        ? _c(
            "v-container",
            [
              _c(
                "v-col",
                { staticClass: "d-flex pb-0", attrs: { cols: "12" } },
                [
                  _c("v-switch", {
                    attrs: {
                      loading: _vm.load[_vm.notificationTypes.ASSIGN_TICKETS],
                      disabled: !_vm.allowBrowserNotifications
                    },
                    on: {
                      click: function($event) {
                        return _vm.handleNotification(
                          _vm.notificationTypes.ASSIGN_TICKETS
                        )
                      }
                    },
                    model: {
                      value:
                        _vm.allowNotification[
                          _vm.notificationTypes.ASSIGN_TICKETS
                        ],
                      callback: function($$v) {
                        _vm.$set(
                          _vm.allowNotification,
                          _vm.notificationTypes.ASSIGN_TICKETS,
                          $$v
                        )
                      },
                      expression:
                        "allowNotification[notificationTypes.ASSIGN_TICKETS]"
                    }
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "pa-1" }, [
                    _vm._v("Cuando sea asignado a una oportunidad")
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                { staticClass: "d-flex py-0", attrs: { cols: "12" } },
                [
                  _c("v-switch", {
                    attrs: {
                      loading: _vm.load[_vm.notificationTypes.START_EVENTS],
                      disabled: !_vm.allowBrowserNotifications
                    },
                    on: {
                      click: function($event) {
                        return _vm.handleNotification(
                          _vm.notificationTypes.START_EVENTS
                        )
                      }
                    },
                    model: {
                      value:
                        _vm.allowNotification[
                          _vm.notificationTypes.START_EVENTS
                        ],
                      callback: function($$v) {
                        _vm.$set(
                          _vm.allowNotification,
                          _vm.notificationTypes.START_EVENTS,
                          $$v
                        )
                      },
                      expression:
                        "allowNotification[notificationTypes.START_EVENTS]"
                    }
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "pa-1" }, [
                    _vm._v("Cuando inicia un evento agendado")
                  ])
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }