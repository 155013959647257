var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    [
      _c("v-col", { staticClass: "pb-0", attrs: { cols: "12" } }, [
        _c("h4", [_vm._v("Filtros")])
      ]),
      _vm._v(" "),
      _c(
        "v-col",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.segment.filters.length > 0,
              expression: "segment.filters.length > 0"
            }
          ],
          attrs: { cols: "12" }
        },
        [
          _c(
            "v-row",
            [
              _vm._l(_vm.segment.filters, function(filter) {
                return [
                  filter.label
                    ? _c(
                        "v-col",
                        {
                          key: filter.id,
                          staticClass: "pt-0",
                          attrs: { cols: "12" }
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.modulesName[_vm.segment.module]) +
                              " que en el campo "
                          ),
                          _c("v-chip", { attrs: { outlined: "", small: "" } }, [
                            _vm._v(_vm._s(filter.label))
                          ]),
                          _vm._v(" sea:\n          "),
                          filter.type === "date"
                            ? [
                                _c(
                                  "v-chip",
                                  {
                                    staticClass: "mr-1",
                                    attrs: { outlined: "", small: "" }
                                  },
                                  [
                                    _vm._v(
                                      " Fecha inicial: " +
                                        _vm._s(filter.values.start) +
                                        " "
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "v-chip",
                                  {
                                    staticClass: "mr-1",
                                    attrs: { outlined: "", small: "" }
                                  },
                                  [
                                    _vm._v(
                                      " Fecha final: " +
                                        _vm._s(filter.values.end) +
                                        " "
                                    )
                                  ]
                                )
                              ]
                            : _vm._l(filter.values, function(value) {
                                return [
                                  _c(
                                    "v-chip",
                                    {
                                      key: value,
                                      staticClass: "mr-1",
                                      attrs: {
                                        outlined: "",
                                        small: "",
                                        value: value
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(
                                            filter.default
                                              ? filter.items.find(function(i) {
                                                  return (
                                                    i[
                                                      filter.item_value || "id"
                                                    ] === value
                                                  )
                                                })[filter.item_text]
                                              : value
                                          ) +
                                          "\n            "
                                      )
                                    ]
                                  )
                                ]
                              })
                        ],
                        2
                      )
                    : _vm._e()
                ]
              })
            ],
            2
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("v-col", { staticClass: "pb-0", attrs: { cols: "12" } }, [
        _c("h4", [_vm._v("Columnas")])
      ]),
      _vm._v(" "),
      _c(
        "v-col",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.segment.columns.length > 0,
              expression: "segment.columns.length > 0"
            }
          ],
          attrs: { cols: "12" }
        },
        [
          _vm._l(_vm.segment.columns, function(column) {
            return [
              _c(
                "v-chip",
                {
                  key: column.key,
                  staticClass: "mb-2 mr-1",
                  attrs: { outlined: "", small: "", value: column.key }
                },
                [_vm._v("\n        " + _vm._s(column.header) + "\n      ")]
              )
            ]
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }