import Vue from "vue";
import Vuex from "vuex";
import Agent from "@/store/modules/agent";
import Contact from "@/store/modules/contact";
import Timeline from "@/store/modules/timeline";
import Event from "@/store/modules/event";
import Order from "@/store/modules/order";
import Products from "@/store/modules/product";
import Ticket from "@/store/modules/ticket";
import Tag from "@/store/modules/tag";
import State from "@/store/modules/state";
import Device from "@/store/modules/device";
import Project from "@/store/modules/project";
import Team from "@/store/modules/team";
import Webhook from "@/store/modules/webhook";
import Integration from "@/store/modules/integration";
import CampaignBudget from "@/store/modules/campaign-budget";
import Segment from "@/store/modules/segment";
import Origin from "@/store/modules/origin";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    Agent,
    Contact,
    Timeline,
    Order,
    Products,
    Event,
    Ticket,
    Tag,
    State,
    Device,
    Project,
    Team,
    Webhook,
    Integration,
    CampaignBudget,
    Segment,
    Origin,
  },
});
