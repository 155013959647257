var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { scrollable: "", persistent: "", "max-width": "800" },
      model: {
        value: _vm.dialog,
        callback: function($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _c(
        "v-card",
        { staticClass: "py-3" },
        [
          _c(
            "v-card-title",
            { staticClass: "headline" },
            [
              _c(
                "div",
                [
                  _c("v-img", {
                    staticClass: "mr-2",
                    attrs: {
                      src: require("@/assets/social_icons/" + _vm.id + ".png"),
                      width: "20px"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c("span", { staticClass: "ml-0" }, [_vm._v("Zoom Webinars")]),
              _vm._v(" "),
              _c("v-spacer"),
              _vm._v(" "),
              _c(
                "v-btn",
                { attrs: { icon: "" }, on: { click: _vm.closeDialog } },
                [
                  _c("v-icon", { attrs: { small: "" } }, [
                    _vm._v("fas fa-times")
                  ])
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card-text",
            [
              _c(
                "v-row",
                [
                  _c("v-col", { attrs: { cols: "12" } }, [
                    _c("h4", { staticClass: "font-weight-thin" }, [
                      _vm._v(
                        "\n            Está integración le permitirá registrar asistentes a uno de sus webinars creados.\n          "
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { staticClass: "d-flex", attrs: { cols: "12" } },
                    [
                      _c(
                        "span",
                        { staticClass: "circle primary white--text mr-2" },
                        [_vm._v("1")]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-row",
                        { staticClass: "pr-5" },
                        [
                          _c(
                            "v-col",
                            { staticClass: "pt-0", attrs: { cols: "12" } },
                            [
                              _c("h4", { staticClass: "font-weight-thin" }, [
                                _vm._v("\n                Deberá ingresar el "),
                                _c(
                                  "span",
                                  { staticClass: "font-weight-bold" },
                                  [_vm._v("Usuario ID o correo electrónico")]
                                ),
                                _vm._v(" y el\n                "),
                                _c(
                                  "span",
                                  { staticClass: "font-weight-bold" },
                                  [_vm._v("token de accesso")]
                                ),
                                _vm._v(
                                  " de la cuenta Zoom que cuenta con el complemento\n                de Webinars para verificar la conexión.\n              "
                                )
                              ])
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            { staticClass: "py-0", attrs: { cols: "12" } },
                            [
                              _c(
                                "v-form",
                                {
                                  ref: "formVerify",
                                  attrs: { "lazy-validation": "" }
                                },
                                [
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12", sm: "6" } },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              label:
                                                "User ID o correo electrónico",
                                              placeholder: " ",
                                              rules: [_vm.$rules.REQUIRED]
                                            },
                                            model: {
                                              value: _vm.user,
                                              callback: function($$v) {
                                                _vm.user = $$v
                                              },
                                              expression: "user"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12", sm: "6" } },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              label: "Token de acceso",
                                              placeholder: " ",
                                              type: "password",
                                              rules: [_vm.$rules.REQUIRED]
                                            },
                                            model: {
                                              value: _vm.accessToken,
                                              callback: function($$v) {
                                                _vm.accessToken = $$v
                                              },
                                              expression: "accessToken"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "text-right",
                                          attrs: { cols: "12" }
                                        },
                                        [
                                          _vm.verifyError
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "error--text mr-4"
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(_vm.verifyError)
                                                  )
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.accountVerified
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "green--text mr-4 font-weight-bold"
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass: "fas fa-check"
                                                  }),
                                                  _vm._v(" Verificado")
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                rounded: "",
                                                color: "accent",
                                                outlined: "",
                                                disabled: _vm.accountVerified,
                                                loading: _vm.loadVerify
                                              },
                                              on: { click: _vm.verify }
                                            },
                                            [_vm._v("Verificar")]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.getActiveTags && _vm.getOrigins
                    ? _c(
                        "v-col",
                        { staticClass: "d-flex", attrs: { cols: "12" } },
                        [
                          _c(
                            "span",
                            { staticClass: "circle primary white--text mr-2" },
                            [_vm._v("2")]
                          ),
                          _vm._v(" "),
                          _c(
                            "v-row",
                            { staticClass: "pr-5" },
                            [
                              _c(
                                "v-col",
                                { staticClass: "pt-0", attrs: { cols: "12" } },
                                [
                                  _c(
                                    "h4",
                                    { staticClass: "font-weight-thin" },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "font-weight-bold" },
                                        [_vm._v("Seleccione su audencia")]
                                      ),
                                      _vm._v(", y luego\n                "),
                                      _c(
                                        "span",
                                        { staticClass: "font-weight-bold" },
                                        [_vm._v("seleccione el webinar")]
                                      ),
                                      _vm._v(
                                        " al cual se va a importar a los asistentes.\n              "
                                      )
                                    ]
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                { staticClass: "py-0", attrs: { cols: "12" } },
                                [
                                  _c(
                                    "v-form",
                                    {
                                      ref: "formFilters",
                                      attrs: { "lazy-validation": "" }
                                    },
                                    [
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              attrs: {
                                                cols: "12",
                                                sm: "6",
                                                md: "4"
                                              }
                                            },
                                            [
                                              _c(
                                                "v-menu",
                                                {
                                                  attrs: {
                                                    "close-on-content-click": false,
                                                    "nudge-right": 40,
                                                    transition:
                                                      "scale-transition",
                                                    "offset-y": "",
                                                    "min-width": "290px"
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "activator",
                                                        fn: function(ref) {
                                                          var on = ref.on
                                                          var attrs = ref.attrs
                                                          return [
                                                            _c(
                                                              "v-text-field",
                                                              _vm._g(
                                                                _vm._b(
                                                                  {
                                                                    attrs: {
                                                                      label:
                                                                        "Fecha inicial",
                                                                      "prepend-icon":
                                                                        "far fa-calendar-alt",
                                                                      readonly:
                                                                        ""
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm.dateStart,
                                                                      callback: function(
                                                                        $$v
                                                                      ) {
                                                                        _vm.dateStart = $$v
                                                                      },
                                                                      expression:
                                                                        "dateStart"
                                                                    }
                                                                  },
                                                                  "v-text-field",
                                                                  attrs,
                                                                  false
                                                                ),
                                                                on
                                                              )
                                                            )
                                                          ]
                                                        }
                                                      }
                                                    ],
                                                    null,
                                                    false,
                                                    615334308
                                                  ),
                                                  model: {
                                                    value: _vm.menuStart,
                                                    callback: function($$v) {
                                                      _vm.menuStart = $$v
                                                    },
                                                    expression: "menuStart"
                                                  }
                                                },
                                                [
                                                  _vm._v(" "),
                                                  _c("v-date-picker", {
                                                    attrs: { max: _vm.dateEnd },
                                                    on: {
                                                      input: function($event) {
                                                        _vm.menuStart = false
                                                      }
                                                    },
                                                    model: {
                                                      value: _vm.dateStart,
                                                      callback: function($$v) {
                                                        _vm.dateStart = $$v
                                                      },
                                                      expression: "dateStart"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-col",
                                            {
                                              attrs: {
                                                cols: "12",
                                                sm: "6",
                                                md: "4"
                                              }
                                            },
                                            [
                                              _c(
                                                "v-menu",
                                                {
                                                  attrs: {
                                                    "close-on-content-click": false,
                                                    "nudge-right": 40,
                                                    transition:
                                                      "scale-transition",
                                                    "offset-y": "",
                                                    "min-width": "290px"
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "activator",
                                                        fn: function(ref) {
                                                          var on = ref.on
                                                          var attrs = ref.attrs
                                                          return [
                                                            _c(
                                                              "v-text-field",
                                                              _vm._g(
                                                                _vm._b(
                                                                  {
                                                                    attrs: {
                                                                      label:
                                                                        "Fecha final",
                                                                      "prepend-icon":
                                                                        "far fa-calendar-alt",
                                                                      readonly:
                                                                        ""
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm.dateEnd,
                                                                      callback: function(
                                                                        $$v
                                                                      ) {
                                                                        _vm.dateEnd = $$v
                                                                      },
                                                                      expression:
                                                                        "dateEnd"
                                                                    }
                                                                  },
                                                                  "v-text-field",
                                                                  attrs,
                                                                  false
                                                                ),
                                                                on
                                                              )
                                                            )
                                                          ]
                                                        }
                                                      }
                                                    ],
                                                    null,
                                                    false,
                                                    1740186094
                                                  ),
                                                  model: {
                                                    value: _vm.menuEnd,
                                                    callback: function($$v) {
                                                      _vm.menuEnd = $$v
                                                    },
                                                    expression: "menuEnd"
                                                  }
                                                },
                                                [
                                                  _vm._v(" "),
                                                  _c("v-date-picker", {
                                                    on: {
                                                      input: function($event) {
                                                        _vm.menuEnd = false
                                                      }
                                                    },
                                                    model: {
                                                      value: _vm.dateEnd,
                                                      callback: function($$v) {
                                                        _vm.dateEnd = $$v
                                                      },
                                                      expression: "dateEnd"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12", md: "4" } },
                                            [
                                              _c("v-autocomplete", {
                                                attrs: {
                                                  items: _vm.getOrigins,
                                                  "item-value": "id",
                                                  "item-text": "name",
                                                  label: "Origen",
                                                  placeholder: "Todos"
                                                },
                                                model: {
                                                  value: _vm.origin,
                                                  callback: function($$v) {
                                                    _vm.origin = $$v
                                                  },
                                                  expression: "origin"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "pt-0",
                                              attrs: { cols: "12" }
                                            },
                                            [
                                              _c("v-select", {
                                                staticClass: "expand",
                                                attrs: {
                                                  items: _vm.getActiveTags,
                                                  "item-value": "id",
                                                  "item-text": "name",
                                                  attach: "",
                                                  chips: "",
                                                  label: "Etiquetas",
                                                  placeholder: " ",
                                                  rules: [
                                                    function(v) {
                                                      return (
                                                        v.length < 11 ||
                                                        "Máximo 10 etiquetas"
                                                      )
                                                    }
                                                  ],
                                                  multiple: ""
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "selection",
                                                      fn: function(ref) {
                                                        var attrs = ref.attrs
                                                        var item = ref.item
                                                        var select = ref.select
                                                        var selected =
                                                          ref.selected
                                                        return [
                                                          _c(
                                                            "v-chip",
                                                            _vm._b(
                                                              {
                                                                attrs: {
                                                                  "input-value": selected,
                                                                  color:
                                                                    item.color
                                                                },
                                                                on: {
                                                                  click: select
                                                                }
                                                              },
                                                              "v-chip",
                                                              attrs,
                                                              false
                                                            ),
                                                            [
                                                              _c("strong", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    item.name
                                                                  )
                                                                )
                                                              ]),
                                                              _vm._v(
                                                                " \n                          "
                                                              ),
                                                              _c("span")
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  false,
                                                  3971904638
                                                ),
                                                model: {
                                                  value: _vm.tags,
                                                  callback: function($$v) {
                                                    _vm.tags = $$v
                                                  },
                                                  expression: "tags"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "text-right pt-0",
                                              attrs: { cols: "12" }
                                            },
                                            [
                                              _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    rounded: "",
                                                    color: "accent",
                                                    outlined: "",
                                                    loading: _vm.loadView
                                                  },
                                                  on: { click: _vm.view }
                                                },
                                                [_vm._v("Visualizar 5")]
                                              )
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _vm.tickets
                                            ? _c(
                                                "v-col",
                                                {
                                                  staticClass: "text-center",
                                                  attrs: { cols: "12" }
                                                },
                                                [
                                                  _c("v-simple-table", {
                                                    attrs: { dense: "" },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function() {
                                                            return [
                                                              _c("thead", [
                                                                _c("tr", [
                                                                  _c(
                                                                    "th",
                                                                    {
                                                                      staticClass:
                                                                        "text-center"
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "Nombres y Apellidos"
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "th",
                                                                    {
                                                                      staticClass:
                                                                        "text-center"
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "Correo electrónico"
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "th",
                                                                    {
                                                                      staticClass:
                                                                        "text-center"
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "Teléfono"
                                                                      )
                                                                    ]
                                                                  )
                                                                ])
                                                              ]),
                                                              _vm._v(" "),
                                                              _c(
                                                                "tbody",
                                                                _vm._l(
                                                                  _vm.tickets,
                                                                  function(
                                                                    item
                                                                  ) {
                                                                    return _c(
                                                                      "tr",
                                                                      {
                                                                        key:
                                                                          item.id
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "td",
                                                                          {
                                                                            staticClass:
                                                                              "text-center"
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                item.full_name
                                                                              )
                                                                            )
                                                                          ]
                                                                        ),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        _c(
                                                                          "td",
                                                                          {
                                                                            staticClass:
                                                                              "text-center"
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                item.email
                                                                              )
                                                                            )
                                                                          ]
                                                                        ),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        _c(
                                                                          "td",
                                                                          {
                                                                            staticClass:
                                                                              "text-center"
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                item.phone ||
                                                                                  "-"
                                                                              )
                                                                            )
                                                                          ]
                                                                        )
                                                                      ]
                                                                    )
                                                                  }
                                                                ),
                                                                0
                                                              )
                                                            ]
                                                          },
                                                          proxy: true
                                                        }
                                                      ],
                                                      null,
                                                      false,
                                                      777938502
                                                    )
                                                  }),
                                                  _vm._v(" "),
                                                  _vm.tickets.length === 0
                                                    ? _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "text-center caption"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "No hay registros"
                                                          )
                                                        ]
                                                      )
                                                    : _vm._e()
                                                ],
                                                1
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                { attrs: { cols: "12" } },
                                [
                                  _c("v-autocomplete", {
                                    attrs: {
                                      items: _vm.webinars,
                                      "item-value": "id",
                                      "item-text": "topic",
                                      label: "Webinar",
                                      placeholder: "Seleccione",
                                      rules: [_vm.$rules.REQUIRED]
                                    },
                                    model: {
                                      value: _vm.webinar,
                                      callback: function($$v) {
                                        _vm.webinar = $$v
                                      },
                                      expression: "webinar"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _vm.message
                                ? _c(
                                    "v-col",
                                    { attrs: { cols: "12" } },
                                    [
                                      _c(
                                        "v-alert",
                                        {
                                          attrs: {
                                            dense: "",
                                            text: "",
                                            type: _vm.status
                                              ? "success"
                                              : "error"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(_vm.message) +
                                              "\n              "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                {
                                  staticClass: "text-right",
                                  attrs: { cols: "12" }
                                },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { rounded: "", text: "" },
                                      on: { click: _vm.closeDialog }
                                    },
                                    [_vm._v("Cancelar")]
                                  ),
                                  _vm._v(" "),
                                  !_vm.status
                                    ? _c(
                                        "v-btn",
                                        {
                                          staticClass: "my-auto ml-5",
                                          attrs: {
                                            rounded: "",
                                            color: "primary",
                                            disabled:
                                              !_vm.accountVerified ||
                                              !_vm.webinar,
                                            loading: _vm.loadImport
                                          },
                                          on: { click: _vm.importAttendees }
                                        },
                                        [_vm._v("Importar")]
                                      )
                                    : _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            color: "primary",
                                            rounded: ""
                                          },
                                          on: { click: _vm.closeDialog }
                                        },
                                        [_vm._v("Listo")]
                                      )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }