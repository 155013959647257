<template>
  <v-card v-if="getOrderPaymentMethods" class="pa-4 pb-0">
    <v-col cols="12" class="grey--text text--darken-3 font-weight-bold pa-0">
      <span v-if="allowEdit">EDITAR</span>
      PAGO
    </v-col>
    <v-col cols="12">
      <v-form ref="formOrderPayment" lazy-validation>
        <v-row>
          <v-col cols="12">
            <v-select
              v-model="method"
              :value="getOrder.payment_method"
              :items="getOrderPaymentMethods"
              label="Método de pago:"
              placeholder="Seleccione método"
              class="pt-2"
            ></v-select>
          </v-col>
          <v-col v-if="allowEdit" cols="12" class="text-center pt-0">
            <v-btn :loading="loading" small rounded color="primary" @click="save">Guardar</v-btn>
            <v-btn small rounded @click="cancel">Cancelar</v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-col>
  </v-card>
</template>
<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "OrderCardPayment",
  data() {
    return {
      method: "",
      loading: false,
    };
  },
  computed: {
    ...mapGetters(["getOrder", "getOrderPaymentMethods"]),
    allowEdit() {
      return this.method !== this.getOrder.payment_method && this.method;
    },
  },
  created() {
    this.method = this.getOrder.payment_method;
  },
  methods: {
    ...mapActions(["updateOrder"]),
    async save() {
      try {
        this.loading = true;

        if (this.$refs.formOrderPayment.validate())
          await this.updateOrder({ id: this.getOrder.id, data: { payment_method: this.method } });
      } catch (error) {
        console.error("save:", error);
      } finally {
        this.loading = false;
      }
    },
    cancel() {
      this.state = this.getOrder.state;
    },
  },
};
</script>
