<template>
  <v-app>
    <v-container v-if="getSegmentsDownloads && getAgent" class="mb-10 pb-10 pt-4 pt-sm-10">
      <v-row class="d-flex">
        <v-col cols="12" sm="6" md="9">
          <h2 class="text-h4 primary--text pb-4 font-weight-black pt-0">
            Descargas de segmentos
          </h2>
        </v-col>
        <v-col cols="12" sm="6" md="3" class="text-sm-right">
          <v-btn
            :to="{ name: 'segments' }"
            outlined
            color="primary"
            dark
            normal
            active-class="no-active"
            class="mr-4 my-auto"
          >
            Ver segmentos
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-card class="pa-4 rounded-lg fill-height">
            <v-row class="justify-center">
              <v-col v-if="getSegmentsDownloads.length" cols="12" class="py-0">
                <v-data-table
                  :headers="headers"
                  :items="getSegmentsDownloads"
                  :items-per-page="50"
                  dense
                  sort-by
                  :mobile-breakpoint="0"
                  hide-default-footer
                  show-expand
                  class="text-headers-colors"
                >
                  <template v-slot:item.created_at="{ item }">
                    {{ $formatTimestamp(item.created_at) }}
                  </template>
                  <template v-slot:item.module="{ item }">
                    {{ modulesName[item.segment.module] }}
                  </template>
                  <template v-slot:item.operations="{ item }">
                    <v-tooltip v-if="item.state === 'completado' && item.counter" bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn text icon @click.stop.prevent="onDownload(item.url)" v-on="on">
                          <v-icon color="primary" dark small>fas fa-download</v-icon>
                        </v-btn>
                      </template>
                      <span>Descargar archivo</span>
                    </v-tooltip>
                    <div v-if="['descargando', 'procesando', 'convirtiendo'].includes(item.state)" class="text-center">
                      <v-progress-circular :size="20" color="primary" indeterminate></v-progress-circular>
                    </div>
                  </template>
                  <template v-slot:expanded-item="{ item }">
                    <td :colspan="headers.length" class="pa-8">
                      <v-row>
                        <v-col cols="12" sm="8">
                          <v-row>
                            <v-col cols="12" class="pb-0">
                              <SegmentDetail :segment="item.segment" />
                            </v-col>
                            <v-col cols="12">
                              <h4>Registros descargados</h4>
                              {{ item.counter || 0 }}
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </td>
                  </template>
                </v-data-table>
              </v-col>
              <v-col v-else cols="12" class="text-center text--secondary pt-10">
                <i class="fas fa-file-download"></i>
                <h6 class="caption">Aún no hay descargas disponibles</h6>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-container v-else>
      <ShowLoading />
    </v-container>
  </v-app>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import SegmentDetail from "../components/SegmentDetail";

export default {
  name: "SegmentDownloads",
  components: { SegmentDetail },
  data() {
    return {
      modulesName: {
        tickets: "Oportunidades",
        leads: "Contactos",
        orders: "Órdenes",
      },
      headers: [
        {
          text: "Segmento",
          align: "left",
          sortable: false,
          value: "name",
        },
        {
          text: "Módulo",
          align: "center",
          value: "module",
          sortable: false,
        },
        {
          text: "Fecha de creación",
          align: "center",
          sortable: false,
          value: "created_at",
        },
        {
          text: "Estado",
          align: "center",
          sortable: false,
          value: "state",
        },
        {
          text: "Operación",
          align: "center",
          sortable: false,
          value: "operations",
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["getAgent", "getSegmentsDownloads"]),
  },
  watch: {
    getAgent(value) {
      if (value) this.init();
    },
  },
  async created() {
    if (this.getAgent) this.init();
  },
  destroyed() {
    this.unsubscribeGetAllSegmentsDownloads();
  },
  methods: {
    ...mapActions(["getAllSegmentsDownloads", "unsubscribeGetAllSegmentsDownloads"]),
    async init() {
      await this.getAllSegmentsDownloads();
    },
    onDownload(fileUrl) {
      let link = document.createElement("a");
      link.href = fileUrl;
      link.setAttribute("download", `apolocrm-orders-${this.$moment().format("DD-MM-YYYY-hh-mm")}.xlsx`);
      document.body.appendChild(link);
      link.click();
    },
  },
};
</script>
