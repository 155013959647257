<template>
  <div>
    <v-row class="justify-end mr-0 pb-1">
      <v-btn
        rounded
        color="primary"
        @click="
          resetFormTeam();
          dialog_enable = true;
        "
      >
        <v-icon size="16">fa-plus</v-icon>
        <span class="hidden-sm-and-down ml-2">Crear equipo</span>
      </v-btn>
    </v-row>
    <v-row v-if="getTeams">
      <v-col cols="12" class="pt-0">
        <v-simple-table v-if="getTeams.length > 0">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">
                  #
                </th>
                <th class="text-left">
                  Nombre
                </th>
                <th class="text-left">
                  Descripción
                </th>
                <th class="text-center">
                  Operaciones
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(t, i) in getTeams" :key="i">
                <td>{{ i + 1 }}</td>
                <td>{{ t.name }}</td>
                <td>{{ t.description }}</td>
                <td class="text-center">
                  <v-btn text small color="primary" @click="openEdit(t.id)">Editar </v-btn>
                  <!--
                  <v-menu bottom left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn x-small icon class="my-auto mr-5" v-bind="attrs" v-on="on">
                        <v-icon x-small>fas fa-ellipsis-v</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item @click="openDelete(t.id)">
                        <v-list-item-title>Eliminar</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                  -->
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <p v-else class="text-center py-2">Aún no hay equipos</p>
      </v-col>
    </v-row>

    <!-- Modals -->
    <v-dialog v-model="dialog_enable" persistent max-width="600">
      <v-card class="py-3">
        <v-card-text>
          <v-form ref="formTeam">
            <v-row>
              <v-col cols="12">
                <h3 class="font-weight-thin">
                  {{ team_selected ? `Editar equipo "${team_selected.name}"` : "Nuevo equipo" }}
                </h3>
              </v-col>
              <v-col v-if="!team_selected" cols="12" class="pt-0">
                <v-text-field
                  v-model="team.name"
                  label="Nombre"
                  placeholder="Nombre"
                  :rules="rules.norepeat"
                ></v-text-field>
              </v-col>
              <v-col cols="12" class="py-0">
                <v-textarea
                  v-model="team.description"
                  label="Descripción"
                  placeholder="Descripción"
                  rows="1"
                  class="expand"
                ></v-textarea>
              </v-col>
              <v-col v-if="!team_selected" cols="12" class="pa-0">
                <v-col cols="12" class="d-flex pt-0">
                  <v-select
                    v-model="agent_to_add"
                    :items="agentsToTeam"
                    :menu-props="{ maxHeight: '400' }"
                    item-value="uid"
                    item-text="full_name"
                    label="Agentes"
                    placeholder="Seleccione y agregue"
                    class="pr-5"
                    return-object
                  ></v-select>
                  <v-btn class="my-auto" color="secondary" rounded outlined @click="addAgentToTeam">
                    Agregar
                  </v-btn>
                </v-col>
                <v-col cols="12" class="pt-0">
                  <h4 v-if="team.agents.length > 0" class="primary--text py-3">Integrantes del equipo</h4>
                  <template v-for="(agent, i) in team.agents">
                    <v-row :key="i" class="d-flex">
                      <v-col class="hidden">
                        <h4>{{ agent.uid }}</h4>
                      </v-col>
                      <v-col cols="12" md="7" class="py-0 my-auto">
                        <h4 class="body-2">{{ agent.full_name }}</h4>
                      </v-col>
                      <v-col cols="10" md="3" class="py-0">
                        <v-select v-model="agent.role" :items="teamRoles" item-value="id" item-text="name"></v-select>
                      </v-col>
                      <v-col cols="2" class="py-0 my-auto text-right">
                        <v-icon small @click="removeAgentToTeam(agent.uid)">fas fa-times</v-icon>
                      </v-col>
                      <v-col v-if="error_form" cols="12">
                        <v-alert dense text type="error">
                          {{ error_form }}
                        </v-alert>
                      </v-col>
                    </v-row>
                  </template>
                </v-col>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="ma-2">
          <v-spacer></v-spacer>
          <v-btn rounded text @click="dialog_enable = false">Cancelar</v-btn>
          <v-btn v-if="team_selected" :loading="loading" color="primary" rounded @click="edit">
            Editar
          </v-btn>
          <v-btn v-else :loading="loading" color="primary" rounded @click="save">
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "Teams",
  data() {
    return {
      toggle: false,
      team: { agents: [] },
      agent_to_add: null,
      team_selected: null,
      state_to_replace: null,
      tickets_states: null,
      error_form: null,
      dialog_enable: false,
      loading: false,
      rules: {
        required: [(v) => !!v || "Campo requerido"],
        norepeat: [
          (v) => !!v || "Campo requerido",
          // eslint-disable-next-line
          (v) =>
            !this.getTeams.find(
              (t) => t.name == this.team.name && (!this.team_selected || t.id !== this.team_selected.id),
            ) || "El nombre del equipo ya existe",
        ],
      },
    };
  },
  computed: {
    ...mapGetters(["getAgent", "getTeams", "getAgentsToArray"]),
    teamRoles() {
      const roles = [
        { id: this.$roles.ADVISOR, name: "Asesor" },
        { id: this.$roles.SUPERVISOR, name: "Supervisor" },
      ];

      if (this.getAgent.system_agent) roles.push({ id: this.$roles.ENTREPRENEUR, name: "Emprendedor" });

      return roles;
    },
    agentsToTeam() {
      let teamAgents = this.team.agents.map((ta) => ta.uid);
      let agents = this.getAgentsToArray.filter(
        (a) =>
          a.uid !== this.getAgent.uid &&
          ((!this.team_selected && !a.team_id) || (this.team_selected && a.team_id === this.team_selected.id)) &&
          !teamAgents.includes(a.uid),
      );
      return agents;
    },
  },
  methods: {
    ...mapActions(["createTeam", "updateTeam", "removeTeam"]),
    openEdit(team_id) {
      this.team_selected = this.getTeams.find((t) => t.id === team_id);
      this.team = Object.assign({}, this.team_selected);
      this.dialog_enable = true;
    },
    async edit() {
      try {
        this.loading = true;
        this.error_form = null;

        if (this.$refs.formTeam.validate()) {
          await this.updateTeam({
            team_id: this.team.id,
            data: { description: this.team.description },
          });

          this.resetFormTeam();
          this.dialog_enable = false;
        }
      } catch (error) {
        console.error("team/edit", error);
        this.error_form = error.message;
      } finally {
        this.loading = false;
      }
    },
    async save() {
      try {
        this.loading = true;
        this.error_form = null;

        if (this.$refs.formTeam.validate()) {
          await this.createTeam({ team_id: this.team.id, team: this.team });

          this.resetFormTeam();
          this.dialog_enable = false;
        }
      } catch (error) {
        console.error("team/save", error);
        this.error_form = error.message;
      } finally {
        this.loading = false;
      }
    },
    resetFormTeam() {
      this.team_selected = null;
      this.team = { agents: [] };
      if (this.$refs.formTeam) this.$refs.formTeam.resetValidation();
    },
    addAgentToTeam() {
      if (this.team.agents.findIndex((ta) => ta.uid === this.agent_to_add.uid) < 0)
        this.team.agents.push({ ...this.agent_to_add, role: this.$roles.ADVISOR });
    },
    removeAgentToTeam(uid) {
      let ind = this.team.agents.findIndex((ta) => ta.uid === uid);
      if (ind >= 0) this.team.agents.splice(ind, 1);
    },
  },
};
</script>
