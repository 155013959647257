import requestAxios from "@/helpers/request_axios";
import * as MutationsType from "./mutationsType";
import { tagsCollection } from "@/config/firebase";
import { roles } from "@/helpers/get_constants";

export default {
  async getAllTags({ commit, rootState }) {
    const agent = rootState.Agent.agent;
    const tags = await tagsCollection.get();
    commit(MutationsType.SET_TAGS, []);
    if (!tags.empty) {
      tags.forEach((tag) => {
        if (
          agent.role !== roles.ENTREPRENEUR ||
          (agent.role === roles.ENTREPRENEUR && (tag.data().team_id === agent.team_id || tag.data().default))
        )
          commit(MutationsType.ADD_TAG, { id: tag.id, ...tag.data() });
      });
    }
  },
  async createTag({ commit }, { tag }) {
    try {
      let response = await requestAxios({
        url: "/awy/api/v1/tags",
        method: "POST",
        data: tag,
      });
      commit(MutationsType.ADD_TAG, response.data.data);
      return response.data;
    } catch (error) {
      console.error("createTag: ", error.response.data);
      throw error.response.data;
    }
  },
  async updateTag({ commit }, { tag }) {
    try {
      let response = await requestAxios({
        url: `/awy/api/v1/tags/${tag.id}`,
        method: "PUT",
        data: tag,
      });
      commit(MutationsType.UPDATE_TAG, response.data.data);
      return response.data;
    } catch (error) {
      console.error("updateTag: ", error.response.data);
      throw error.response.data;
    }
  },
  async removeTag({ commit }, { tag }) {
    try {
      let response = await requestAxios({
        url: `/awy/api/v1/tags/${tag.id}`,
        method: "DELETE",
        data: tag,
      });
      commit(MutationsType.REMOVE_TAG, response.data.data);
      return response.data;
    } catch (error) {
      console.error("removeTag: ", error.response.data);
      throw error.response.data;
    }
  },
};
