import Vue from "vue";
import * as MutationsType from "./mutationsType";

export default {
  [MutationsType.SET_TICKET_STATES](state, payload) {
    state.ticketStates = payload;
  },
  [MutationsType.ADD_TICKET_STATE](state, payload) {
    state.ticketStates.push(payload);
    state.ticketStatesMap[payload.id] = payload;
  },
  [MutationsType.UPDATE_TICKET_STATE](state, payload) {
    let ind = state.ticketStates.findIndex((t) => {
      return t.id == payload.id;
    });
    if (ind >= 0) Vue.set(state.ticketStates, ind, { ...state.ticketStates[ind], ...payload });
  },
  [MutationsType.REMOVE_TICKET_STATE](state, payload) {
    let ind = state.ticketStates.findIndex((t) => t.id === payload.id);
    Vue.delete(state.ticketStates, ind);
  },
  [MutationsType.SET_ORDER_STATES](state, payload) {
    state.orderStates = payload;
  },
  [MutationsType.SET_ORDER_STATES_MAP](state, payload) {
    state.orderStatesMap = payload;
  },
};
