var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.getOrderStates
    ? _c(
        "v-card",
        { staticClass: "pa-4 pb-0" },
        [
          _c(
            "v-col",
            {
              staticClass: "grey--text text--darken-3 font-weight-bold pa-0",
              attrs: { cols: "12" }
            },
            [
              _vm.allowEdit ? _c("span", [_vm._v("EDITAR")]) : _vm._e(),
              _vm._v("\n    ESTADO\n  ")
            ]
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c(
                "v-form",
                { ref: "formOrderState", attrs: { "lazy-validation": "" } },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c("v-autocomplete", {
                            staticClass: "pt-2",
                            staticStyle: { "font-size": "15px" },
                            attrs: {
                              value: _vm.getOrder.state,
                              items: _vm.getOrderStates,
                              readonly:
                                _vm.getOrder.state ===
                                _vm.$orderStates.TERMINADO,
                              "item-text": "name",
                              "item-value": "id",
                              label: "Estado"
                            },
                            model: {
                              value: _vm.state,
                              callback: function($$v) {
                                _vm.state = $$v
                              },
                              expression: "state"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.allowEdit
                        ? _c(
                            "v-col",
                            {
                              staticClass: "text-center pt-0",
                              attrs: { cols: "12" }
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    loading: _vm.loading,
                                    small: "",
                                    rounded: "",
                                    color: "primary"
                                  },
                                  on: { click: _vm.save }
                                },
                                [_vm._v("Guardar")]
                              ),
                              _vm._v(" "),
                              _c(
                                "v-btn",
                                {
                                  attrs: { small: "", rounded: "" },
                                  on: { click: _vm.cancel }
                                },
                                [_vm._v("Cancelar")]
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }