const SET_AGENT = "SET_AGENT";
const SET_AGENTS = "SET_AGENTS";
const SET_ALL_AGENTS = "SET_ALL_AGENTS";
const SET_ALL_AGENTS_MAP = "SET_ALL_AGENTS_MAP";
const UPDATE_AGENT = "UPDATE_AGENT";
const SET_SELLER_AGENTS = "SET_SELLER_AGENTS";
const SET_AGENT_AUTHENTICATED = "SET_AGENT_AUTHENTICATED";
const SET_AGENT_SELECTED = "SET_AGENT_SELECTED";
const SHOW_AGENT_DETAIL = "SHOW_AGENT_DETAIL";
const SET_MENU_ITEMS = "SET_MENU_ITEMS";

export {
  SET_AGENT,
  SET_AGENTS,
  SET_ALL_AGENTS,
  SET_ALL_AGENTS_MAP,
  UPDATE_AGENT,
  SET_AGENT_AUTHENTICATED,
  SET_SELLER_AGENTS,
  SET_AGENT_SELECTED,
  SHOW_AGENT_DETAIL,
  SET_MENU_ITEMS,
};
