var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    { attrs: { id: "ticket_register" } },
    [
      _vm.getAgent
        ? _c(
            "v-container",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.isLoading,
                  expression: "!isLoading"
                }
              ],
              staticClass: "rounded-lg mb-10"
            },
            [
              _c(
                "v-row",
                { staticClass: "pt-4" },
                [
                  _c(
                    "v-col",
                    {
                      attrs: {
                        cols: "12",
                        sm: "10",
                        md: "8",
                        "offset-sm": "1",
                        "offset-md": "2"
                      }
                    },
                    [
                      _c(
                        "h2",
                        {
                          staticClass:
                            "text-h4 primary--text pb-4 font-weight-black pt-8 pt-md-0 pt-lg-0"
                        },
                        [_vm._v("Nueva oportunidad")]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-card",
                        { staticClass: "px-4 py-6 rounded-lg" },
                        [
                          _c("TicketForm", {
                            attrs: { "is-loading": _vm.isLoading },
                            on: {
                              "update:isLoading": function($event) {
                                _vm.isLoading = $event
                              },
                              "update:is-loading": function($event) {
                                _vm.isLoading = $event
                              }
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isLoading ? _c("v-container", [_c("ShowLoading")], 1) : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }