<template>
  <v-app id="importer">
    <v-container>
      <v-row>
        <v-col cols="12" sm="10" md="8" offset-sm="1" offset-md="2">
          <v-row>
            <v-col cols="12">
              <h2 class="text-h4 primary--text pb-4 font-weight-black pt-8 pt-md-0 pt-lg-0">Importador de contactos</h2>
            </v-col>
          </v-row>
          <v-card class="px-4 py-6 rounded-lg">
            <v-row>
              <v-col cols="12" sm="8">
                <h5 class="font-weight-regular subtitle-2">
                  Primero debes <strong>descargar la plantilla de importación</strong> la cual contiene los datos
                  necesario para importar tus contactos.
                </h5>
              </v-col>
              <v-col cols="12" sm="4" class="text-sm-right">
                <v-btn :loading="loadingTemplate" color="primary" outlined @click="exportTemplate">
                  Descargar plantilla
                </v-btn>
              </v-col>
            </v-row>
            <v-row class="mt-5">
              <v-col cols="12">
                <v-form ref="formImporterContacts" lazy-validation>
                  <v-row>
                    <v-col cols="12" sm="8">
                      <v-file-input
                        v-model="file"
                        chips
                        label="Seleccione archivo a importar"
                        placeholder="solo archivos .xlsx"
                        truncate-length="50"
                        prepend-icon=""
                        append-outer-icon="fa fa-paperclip"
                        accept=".xlsx"
                        :rules="[$rules.REQUIRED]"
                      ></v-file-input>
                    </v-col>
                    <v-col cols="12">
                      <v-btn :loading="loading" color="primary" rounded @click="imports">Importar contactos</v-btn>
                    </v-col>
                    <v-col v-if="responseStatus !== null" cols="12" sm="8">
                      <v-alert text :type="responseStatus">
                        {{ responseMessage }}
                      </v-alert>
                      <v-row v-if="responseData">
                        <template v-for="(r, i) in responseData">
                          <v-col v-if="r.error" :key="i" cols="12" class="py-1">
                            <div class="d-flex">
                              <span class="body-2 font-weight-bold">Fila {{ r.error.row }}:</span>
                              <h5 class="body-2 pl-3">{{ r.message }}</h5>
                            </div>
                            <v-alert dense outlined type="error" class="caption my-1">{{ r.error }}</v-alert>
                          </v-col>
                        </template>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-form>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>
<script>
import { mapActions } from "vuex";
export default {
  name: "Importer",
  data() {
    return {
      loading: false,
      loadingTemplate: false,
      file: null,
      responseStatus: null,
      responseMessage: null,
      responseData: null,
    };
  },
  methods: {
    ...mapActions(["exportContactTemplate", "importContacts"]),
    async exportTemplate() {
      try {
        this.loadingTemplate = true;

        let response = await this.exportContactTemplate();
        let url = window.URL.createObjectURL(new Blob([response]));
        let link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "contacts.xlsx");
        document.body.appendChild(link);
        link.click();
      } catch (error) {
        console.error(error);
      } finally {
        this.loadingTemplate = false;
      }
    },
    async imports() {
      try {
        this.loading = true;
        this.responseStatus = null;
        this.responseData = null;
        if (this.$refs.formImporterContacts.validate()) {
          let response = await this.importContacts({ file: this.file });
          this.responseStatus = response.status;
          this.responseMessage = response.message;
          this.responseData = response.data;
          this.cleanForm();
        }
      } catch (error) {
        this.responseStatus = "error";
        this.responseMessage = error.message;
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    cleanForm() {
      this.file = null;
      this.$refs.formImporterContacts.resetValidation();
    },
  },
};
</script>
