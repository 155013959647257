var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-menu",
    {
      attrs: { bottom: "", left: "", "offset-y": "", "open-on-hover": "" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function(ref) {
            var on = ref.on
            var attrs = ref.attrs
            return [
              _c(
                "v-btn",
                _vm._g(
                  _vm._b(
                    { attrs: { small: "", outlined: "", color: "primary" } },
                    "v-btn",
                    attrs,
                    false
                  ),
                  on
                ),
                [
                  _c("v-icon", { attrs: { small: "" } }, [
                    _vm._v("far fa-calendar-plus")
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "pl-2" }, [
                    _vm._v("Agregar a calendario")
                  ])
                ],
                1
              )
            ]
          }
        }
      ])
    },
    [
      _vm._v(" "),
      _c(
        "v-list",
        [
          _c(
            "v-list-item",
            { on: { click: _vm.generateGoogleCalendarLink } },
            [_c("v-list-item-title", [_vm._v("Google Calendar")])],
            1
          ),
          _vm._v(" "),
          _c(
            "v-list-item",
            { on: { click: _vm.generateICS } },
            [_c("v-list-item-title", [_vm._v("iCal")])],
            1
          ),
          _vm._v(" "),
          _c(
            "v-list-item",
            { on: { click: _vm.generateICS } },
            [_c("v-list-item-title", [_vm._v("Outlook")])],
            1
          ),
          _vm._v(" "),
          _c(
            "v-list-item",
            { on: { click: _vm.generateYahooCalendarLink } },
            [_c("v-list-item-title", [_vm._v("Yahoo Calendar")])],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }