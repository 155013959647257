<template>
  <v-row>
    <v-col cols="12" class="pb-0">
      <h4>Filtros</h4>
    </v-col>
    <v-col v-show="segment.filters.length > 0" cols="12">
      <v-row>
        <template v-for="filter in segment.filters">
          <v-col v-if="filter.label" :key="filter.id" cols="12" class="pt-0">
            {{ modulesName[segment.module] }} que en el campo <v-chip outlined small>{{ filter.label }}</v-chip> sea:
            <template v-if="filter.type === 'date'">
              <v-chip outlined small class="mr-1"> Fecha inicial: {{ filter.values.start }} </v-chip>
              <v-chip outlined small class="mr-1"> Fecha final: {{ filter.values.end }} </v-chip>
            </template>
            <template v-for="value in filter.values" v-else>
              <v-chip :key="value" outlined small :value="value" class="mr-1">
                {{
                  filter.default
                    ? filter.items.find((i) => i[filter.item_value || "id"] === value)[filter.item_text]
                    : value
                }}
              </v-chip>
            </template>
          </v-col>
        </template>
      </v-row>
    </v-col>
    <v-col cols="12" class="pb-0">
      <h4>Columnas</h4>
    </v-col>
    <v-col v-show="segment.columns.length > 0" cols="12">
      <template v-for="column in segment.columns">
        <v-chip :key="column.key" outlined small :value="column.key" class="mb-2 mr-1">
          {{ column.header }}
        </v-chip>
      </template>
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: "SegmentDetail",
  props: {
    segment: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      modulesName: {
        tickets: "Oportunidades",
        leads: "Contactos",
        orders: "Órdenes",
      },
    };
  },
};
</script>
