<template>
  <v-navigation-drawer v-model="localDrawer" temporary app right width="400">
    <v-container>
      <v-col cols="12" class="pa-4 primary--text body-1 font-weight-bold">
        <v-icon color="primary" dark small class="mr-2">far fa-edit</v-icon> Editar
      </v-col>
      <v-col cols="12" class="px-0">
        <v-card class="pa-4">
          <FormCampaignBudget :campaign-budget-selected.sync="campaignBudgetSelected" :action.sync="action" :sm="12" />
        </v-card>
      </v-col>
    </v-container>
  </v-navigation-drawer>
</template>
<script>
import FormCampaignBudget from "@/components/forms/CampaignBudgetForm";

export default {
  name: "CampaignBudgetDrawer",
  components: {
    FormCampaignBudget,
  },
  props: {
    drawer: {
      type: Boolean,
      default: false,
    },
    campaignBudgetSelected: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  data: () => ({
    localDrawer: false,
    action: "update",
  }),
  watch: {
    localDrawer(value) {
      this.$emit("update:drawer", value);
    },
    drawer(value) {
      this.localDrawer = value;
    },
    action(value) {
      if (value === "read") {
        this.localDrawer = false;
        this.action = "update";
      }
    },
  },
  created() {
    this.localDrawer = this.drawer;
  },
};
</script>
