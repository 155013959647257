<template>
  <v-row class="fill-height text-center px-3 pb-2">
    <v-col v-for="(product, i) in products" :key="i" cols="12" class="pa-0">
      <v-row class="px-3 pb-2">
        <v-col cols="12" :sm="formatOneRow ? 3 : 12" class="pb-0">
          <v-autocomplete
            v-model="product.id"
            :items="productsToSelect[i]"
            :value="product.id"
            :label="`Producto ${i + 1}`"
            :readonly="isReadOnly"
            :rules="[$rules.REQUIRED]"
            style="font-size: 15px;"
            item-text="name"
            item-value="id"
            @change="changeProductsToSelect(i, $event)"
          ></v-autocomplete>
        </v-col>
        <v-col cols="4" :sm="formatOneRow ? 2 : 2">
          <v-text-field
            v-model.number="product.quantity"
            :rules="[$rules.NUMBER, $rules.GREATER_THAN_ZERO]"
            :readonly="isReadOnly"
            label="Cantidad"
            placeholder="0"
            @input="updateSubtotal(i)"
          ></v-text-field>
        </v-col>
        <v-col cols="4" :sm="formatOneRow ? 3 : 4">
          <v-text-field
            v-model.number="product.price"
            :rules="[$rules.CURRENCY, $rules.GREATER_THAN_ZERO]"
            :prefix="$currency.symbol"
            :readonly="isReadOnly"
            label="Precio"
            placeholder="0"
            @input="updateSubtotal(i)"
          ></v-text-field>
        </v-col>
        <v-col cols="4" :sm="formatOneRow ? 3 : 4">
          <v-text-field
            :value="$formatCurrency(product.subtotal, false)"
            :prefix="$currency.symbol"
            :readonly="true"
            label="Subtotal"
            placeholder="0"
          ></v-text-field>
        </v-col>
        <v-col v-if="!isReadOnly" cols="4" :sm="formatOneRow ? 1 : 1" class="text-right my-auto">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn icon small v-on="on" @click="removeProduct(i)">
                <v-icon small>far fa-trash-alt</v-icon>
              </v-btn>
            </template>
            <span>Eliminar producto</span>
          </v-tooltip>
        </v-col>
        <v-col cols="12" class="mt-2"><v-divider></v-divider></v-col>
      </v-row>
    </v-col>
    <v-col v-if="products && products.length" cols="12" class="pa-0 pb-2">
      <v-row class="px-3">
        <v-col cols="4" :sm="formatOneRow ? 8 : 6" class="py-0 text-right">
          <p class="my-auto pt-5 font-weight-bold grey--text text--darken-2">Total:</p>
        </v-col>
        <v-col cols="4" :sm="formatOneRow ? 3 : 4" class="py-0">
          <v-text-field
            :value="$formatCurrency(total, false)"
            :readonly="true"
            :prefix="$currency.symbol"
            placeholder="0"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-col>
    <v-col v-else cols="12">
      <h5 class="font-weight-thin text--secondary">No hay productos asociados</h5>
    </v-col>
  </v-row>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "Products",
  props: {
    products: {
      type: Array,
      required: true,
    },
    action: {
      type: String,
      default: "read",
    },
    formatOneRow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      total: 0,
      productsToSelect: [],
      resetProducts: false,
      editProducts: false,
      loadingSave: false,
    };
  },
  computed: {
    ...mapGetters(["getActiveProducts", "getProductsMap"]),
    isReadOnly() {
      return this.action === "read";
    },
  },
  watch: {
    products() {
      this.changeProductsToSelect();
    },
  },
  created() {
    this.changeProductsToSelect();
  },
  methods: {
    updateTotal() {
      let total = 0;

      for (let i = 0; i < this.products.length; i++) total += this.products[i].quantity * this.products[i].price;

      this.total = total;
    },
    updateSubtotal(ind) {
      this.products[ind].subtotal = this.products[ind].price * this.products[ind].quantity;

      this.updateTotal();
    },
    changeProductsToSelect(ind, productId) {
      if (ind >= 0) {
        this.products[ind].name = this.getProductsMap[productId].name;
        this.products[ind].quantity = 1;
        this.products[ind].price = this.getProductsMap[productId].price;
        this.products[ind].subtotal = this.getProductsMap[productId].price;
      }

      const productsAvailable = this.getActiveProducts.filter((p) => !this.products.find((pp) => p.id === pp.id));

      let list;
      this.total = 0;

      for (let i = 0; i < this.products.length; i++) {
        list = [...productsAvailable];
        if (this.products[i].id) list.push(this.getProductsMap[this.products[i].id]);
        this.productsToSelect[i] = list;
        this.total += this.products[i].quantity * this.products[i].price;
      }
    },
    removeProduct(ind) {
      this.products.splice(ind, 1);
      this.productsToSelect.splice(ind, 1);
      this.changeProductsToSelect();
    },
    async saveTicketProducts() {
      try {
        this.loadingSave = true;

        if (this.$refs.formProducts.validate()) {
          await this.setTicketProducts({ id: this.ticketId, products: this.products });
          this.editProducts = false;
        }
      } catch (error) {
        console.error("saveTicketProducts: ", error);
      } finally {
        this.loadingSave = false;
      }
    },
  },
};
</script>
