var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    { attrs: { id: "tickets-dashboard" } },
    [
      _vm.getAgent && _vm.getAgents && _vm.groups && _vm.getTickets
        ? _c(
            "div",
            [
              _c(
                "v-col",
                { staticClass: "pa-0", attrs: { cols: "12" } },
                [
                  _c("TicketHeader", {
                    attrs: {
                      query: _vm.query,
                      "show-filters": _vm.showFilters,
                      "custom-filters": _vm.customFilters
                    },
                    on: {
                      "update:query": function($event) {
                        _vm.query = $event
                      },
                      "update:showFilters": function($event) {
                        _vm.showFilters = $event
                      },
                      "update:show-filters": function($event) {
                        _vm.showFilters = $event
                      },
                      "update:customFilters": function($event) {
                        _vm.customFilters = $event
                      },
                      "update:custom-filters": function($event) {
                        _vm.customFilters = $event
                      },
                      loadTickets: _vm.loadTickets,
                      applyFilter: _vm.applyFilter
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                { staticClass: "pa-0", attrs: { cols: "12" } },
                [
                  _c(
                    "v-card",
                    { staticClass: "mb-5 pb-5 rounded-lg" },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c("v-progress-linear", {
                            attrs: {
                              active: _vm.loadingTickets,
                              indeterminate: true,
                              absolute: "",
                              top: "",
                              color: "secondary accent-4"
                            }
                          }),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "d-flex flex-nowrap pa-0",
                              staticStyle: { "overflow-x": "scroll" }
                            },
                            [
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "drag-and-drop",
                                      rawName: "v-drag-and-drop:options",
                                      value: _vm.options,
                                      expression: "options",
                                      arg: "options"
                                    }
                                  ],
                                  staticClass: "col-12 pa-0"
                                },
                                [
                                  _c(
                                    "ul",
                                    {
                                      staticClass:
                                        "drag-list col-12 pa-0 pb-4 flex-nowrap fill-height"
                                    },
                                    _vm._l(_vm.groups, function(group) {
                                      return _c(
                                        "li",
                                        {
                                          key: group.id,
                                          staticClass:
                                            "drag-column col-4 fill-height",
                                          style: {
                                            background:
                                              _vm.$vuetify.theme.themes[
                                                _vm.theme
                                              ].background
                                          }
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "drag-column-header"
                                            },
                                            [
                                              _c(
                                                "h2",
                                                {
                                                  staticClass:
                                                    "font-weight-black"
                                                },
                                                [_vm._v(_vm._s(group.name))]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "v-chip",
                                                {
                                                  staticClass: "black--text",
                                                  attrs: {
                                                    small: "",
                                                    color: "secondary"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                      " +
                                                      _vm._s(
                                                        group.totalResults +
                                                          group.items.length
                                                      ) +
                                                      "\n                    "
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "vue-draggable-group",
                                            {
                                              attrs: {
                                                groups: _vm.groups,
                                                "data-id": group.id
                                              },
                                              model: {
                                                value: group.items,
                                                callback: function($$v) {
                                                  _vm.$set(group, "items", $$v)
                                                },
                                                expression: "group.items"
                                              }
                                            },
                                            [
                                              _c(
                                                "ul",
                                                {
                                                  staticClass:
                                                    "drag-inner-list col-12 px-0 pt-0 fill-height",
                                                  attrs: {
                                                    id: group.id,
                                                    "data-id": group.id
                                                  },
                                                  on: { added: _vm.added }
                                                },
                                                [
                                                  _vm._l(group.items, function(
                                                    item
                                                  ) {
                                                    return _c(
                                                      "li",
                                                      {
                                                        key: item.id,
                                                        staticClass:
                                                          "drag-item",
                                                        attrs: {
                                                          id: item.ticket_id,
                                                          "data-id": item.id
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "v-card",
                                                          {
                                                            staticClass:
                                                              "mx-auto pa-0 pb-2 mb-4 elevation-0 rounded-lg",
                                                            attrs: {
                                                              "max-width": "400"
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.showTicketDetail(
                                                                  {
                                                                    ticketId:
                                                                      item.ticket_id
                                                                  }
                                                                )
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "d-flex pa-4 pb-0"
                                                              },
                                                              [
                                                                _c("v-icon", {
                                                                  staticClass:
                                                                    "far fa-clock mr-1",
                                                                  attrs: {
                                                                    size: "12"
                                                                  }
                                                                }),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "caption font-weight-light w100"
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.$formatTimestamp(
                                                                          item.created_at
                                                                        )
                                                                      )
                                                                    )
                                                                  ]
                                                                )
                                                              ],
                                                              1
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "v-card-title",
                                                              {
                                                                staticClass:
                                                                  "py-0 mb-4"
                                                              },
                                                              [
                                                                _c(
                                                                  "v-row",
                                                                  {
                                                                    staticClass:
                                                                      "w-full"
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-col",
                                                                      {
                                                                        staticClass:
                                                                          "pb-0 d-flex align-center",
                                                                        attrs: {
                                                                          cols:
                                                                            "10"
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "h5",
                                                                          {
                                                                            staticClass:
                                                                              "subtitle-1 font-weight-bold w100"
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                item.name
                                                                              ) +
                                                                                " " +
                                                                                _vm._s(
                                                                                  item.last_name
                                                                                )
                                                                            )
                                                                          ]
                                                                        )
                                                                      ]
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "v-col",
                                                                      {
                                                                        staticClass:
                                                                          "text-right pb-0",
                                                                        attrs: {
                                                                          cols:
                                                                            "2"
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-tooltip",
                                                                          {
                                                                            attrs: {
                                                                              bottom:
                                                                                ""
                                                                            },
                                                                            scopedSlots: _vm._u(
                                                                              [
                                                                                {
                                                                                  key:
                                                                                    "activator",
                                                                                  fn: function(
                                                                                    ref
                                                                                  ) {
                                                                                    var on =
                                                                                      ref.on
                                                                                    return [
                                                                                      _c(
                                                                                        "v-btn",
                                                                                        _vm._g(
                                                                                          {
                                                                                            attrs: {
                                                                                              text:
                                                                                                "",
                                                                                              icon:
                                                                                                "",
                                                                                              to: {
                                                                                                name:
                                                                                                  "ticket_detail",
                                                                                                params: {
                                                                                                  id:
                                                                                                    item.ticket_id
                                                                                                }
                                                                                              },
                                                                                              target:
                                                                                                "_blank"
                                                                                            },
                                                                                            on: {
                                                                                              click: function(
                                                                                                $event
                                                                                              ) {
                                                                                                $event.stopPropagation()
                                                                                              }
                                                                                            }
                                                                                          },
                                                                                          on
                                                                                        ),
                                                                                        [
                                                                                          _c(
                                                                                            "v-icon",
                                                                                            {
                                                                                              attrs: {
                                                                                                size:
                                                                                                  "13"
                                                                                              }
                                                                                            },
                                                                                            [
                                                                                              _vm._v(
                                                                                                "fas fa-external-link-alt"
                                                                                              )
                                                                                            ]
                                                                                          )
                                                                                        ],
                                                                                        1
                                                                                      )
                                                                                    ]
                                                                                  }
                                                                                }
                                                                              ],
                                                                              null,
                                                                              true
                                                                            )
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " "
                                                                            ),
                                                                            _c(
                                                                              "span",
                                                                              [
                                                                                _vm._v(
                                                                                  "Ver oportunidad"
                                                                                )
                                                                              ]
                                                                            )
                                                                          ]
                                                                        )
                                                                      ],
                                                                      1
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              ],
                                                              1
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "v-card-text",
                                                              [
                                                                _c(
                                                                  "v-row",
                                                                  {
                                                                    staticClass:
                                                                      "px-3 pb-2"
                                                                  },
                                                                  _vm._l(
                                                                    _vm.getActiveTags,
                                                                    function(
                                                                      l
                                                                    ) {
                                                                      return _c(
                                                                        "div",
                                                                        {
                                                                          key:
                                                                            l.name
                                                                        },
                                                                        [
                                                                          item.tags &&
                                                                          item.tags.includes(
                                                                            l.id
                                                                          )
                                                                            ? _c(
                                                                                "v-chip",
                                                                                {
                                                                                  staticClass:
                                                                                    "px-3 mr-1",
                                                                                  staticStyle: {
                                                                                    height:
                                                                                      "7px"
                                                                                  },
                                                                                  attrs: {
                                                                                    color:
                                                                                      l.color
                                                                                  }
                                                                                }
                                                                              )
                                                                            : _vm._e()
                                                                        ],
                                                                        1
                                                                      )
                                                                    }
                                                                  ),
                                                                  0
                                                                ),
                                                                _vm._v(" "),
                                                                _c("span", {
                                                                  domProps: {
                                                                    innerHTML: _vm._s(
                                                                      item.last_comment
                                                                    )
                                                                  }
                                                                }),
                                                                _c("br"),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "caption font-weight-light"
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.$formatTimestamp(
                                                                          item.last_activity
                                                                        )
                                                                      )
                                                                    )
                                                                  ]
                                                                )
                                                              ],
                                                              1
                                                            ),
                                                            _vm._v(" "),
                                                            _c("v-divider", {
                                                              staticClass:
                                                                "mt-2 mx-2"
                                                            }),
                                                            _vm._v(" "),
                                                            item.agent_id &&
                                                            _vm.getAllAgentsMap[
                                                              item.agent_id
                                                            ]
                                                              ? _c(
                                                                  "v-card-actions",
                                                                  {
                                                                    staticClass:
                                                                      "pb-1 pr-2"
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-spacer"
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "v-tooltip",
                                                                      {
                                                                        attrs: {
                                                                          bottom:
                                                                            ""
                                                                        },
                                                                        scopedSlots: _vm._u(
                                                                          [
                                                                            {
                                                                              key:
                                                                                "activator",
                                                                              fn: function(
                                                                                ref
                                                                              ) {
                                                                                var on =
                                                                                  ref.on
                                                                                return [
                                                                                  _c(
                                                                                    "v-avatar",
                                                                                    _vm._g(
                                                                                      {
                                                                                        staticClass:
                                                                                          "mr-2",
                                                                                        attrs: {
                                                                                          size:
                                                                                            "25",
                                                                                          color:
                                                                                            "accent"
                                                                                        }
                                                                                      },
                                                                                      on
                                                                                    ),
                                                                                    [
                                                                                      _vm
                                                                                        .getAllAgentsMap[
                                                                                        item
                                                                                          .agent_id
                                                                                      ]
                                                                                        .photo
                                                                                        ? _c(
                                                                                            "img",
                                                                                            {
                                                                                              attrs: {
                                                                                                src:
                                                                                                  _vm
                                                                                                    .getAllAgentsMap[
                                                                                                    item
                                                                                                      .agent_id
                                                                                                  ]
                                                                                                    .photo,
                                                                                                alt:
                                                                                                  "Avatar"
                                                                                              }
                                                                                            }
                                                                                          )
                                                                                        : _c(
                                                                                            "span",
                                                                                            {
                                                                                              staticClass:
                                                                                                "white--text"
                                                                                            },
                                                                                            [
                                                                                              _vm._v(
                                                                                                "\n                                    " +
                                                                                                  _vm._s(
                                                                                                    _vm.getAllAgentsMap[
                                                                                                      item
                                                                                                        .agent_id
                                                                                                    ].full_name.substring(
                                                                                                      0,
                                                                                                      1
                                                                                                    )
                                                                                                  ) +
                                                                                                  "\n                                  "
                                                                                              )
                                                                                            ]
                                                                                          )
                                                                                    ]
                                                                                  )
                                                                                ]
                                                                              }
                                                                            }
                                                                          ],
                                                                          null,
                                                                          true
                                                                        )
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        _c(
                                                                          "span",
                                                                          [
                                                                            _vm._v(
                                                                              "Asignado a " +
                                                                                _vm._s(
                                                                                  _vm
                                                                                    .getAllAgentsMap[
                                                                                    item
                                                                                      .agent_id
                                                                                  ]
                                                                                    .full_name
                                                                                )
                                                                            )
                                                                          ]
                                                                        )
                                                                      ]
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              : _vm._e()
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  }),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "py-3 text-center"
                                                    },
                                                    [
                                                      group.totalResults > 0
                                                        ? _c(
                                                            "v-btn",
                                                            {
                                                              staticClass:
                                                                "mb-5",
                                                              attrs: {
                                                                float: "",
                                                                text: "",
                                                                color: "primary"
                                                              },
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.loadMoreTickets(
                                                                    group.id
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "Ver más resultados"
                                                              )
                                                            ]
                                                          )
                                                        : _vm._e()
                                                    ],
                                                    1
                                                  )
                                                ],
                                                2
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    }),
                                    0
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "v-snackbar",
                                {
                                  attrs: {
                                    bottom: true,
                                    "multi-line": true,
                                    right: true,
                                    timeout: 10000,
                                    dark: ""
                                  },
                                  model: {
                                    value: _vm.snackbar,
                                    callback: function($$v) {
                                      _vm.snackbar = $$v
                                    },
                                    expression: "snackbar"
                                  }
                                },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        icon: "",
                                        color: "white",
                                        loading: true
                                      }
                                    },
                                    [
                                      _c("v-icon", { attrs: { size: "16" } }, [
                                        _vm._v("fa-plus")
                                      ])
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("span", { staticClass: "text--white" }, [
                                    _vm._v(_vm._s(_vm.snackbarText))
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _c("v-container", [_c("ShowLoading")], 1)
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }