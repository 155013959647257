<template>
  <v-navigation-drawer v-model="localDrawer" temporary app right width="400">
    <div>
      <v-card class="elevation-0 pa-4 primary--text text-h6 mb-0 font-weight-black">
        <v-icon color="primary" dark small class="mr-2">fas fa-user-edit</v-icon> Editar
      </v-card>
      <v-container v-if="getAgent && getAgentSelected && getAgentRolesToArray && getAgentId" class="pa-2">
        <v-expansion-panels v-model="tabs" multiple>
          <!-- Información -->
          <v-expansion-panel>
            <v-expansion-panel-header class="text--darken-2 font-weight-bold">
              INFORMACIÓN
              <div
                v-if="getAgent.role === $roles.SUPER_ADMIN && actionInformation === 'read' && getAgentSelected.active"
                class="text-right"
              >
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn size="16" icon small class="mr-4" v-on="on">
                      <v-icon size="16" @click.stop.prevent="actionInformation = 'update'">fas fa-pencil-alt</v-icon>
                    </v-btn>
                  </template>
                  <span>Editar información</span>
                </v-tooltip>
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <AgentForm :action.sync="actionInformation" :agent-select="getAgentSelected" :sm="12" />
            </v-expansion-panel-content>
          </v-expansion-panel>
          <!-- CONTROL DE ACCESO -->
          <v-expansion-panel v-if="getAgent.role === $roles.SUPER_ADMIN">
            <v-expansion-panel-header class="text--darken-2 font-weight-bold">
              CONTROL DE ACCESO
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <h5 class="font-weight-thin mb-3">
                <span v-if="getAgentSelected.active"
                  >Este usuario actualmente tiene acceso al CRM. Si desactiva su cuenta, el usuario ya no va a poder
                  ingresar a la plataforma.</span
                >
                <span v-else
                  >Actualmente este usuario no puede ingresar a la plataforma. Si desea que vuelva a ingresar tiene que
                  activar la cuenta.</span
                >
              </h5>
              <v-btn color="accent" rounded outlined small @click="dialogHandleAccount = true">
                {{ getAgentSelected.active ? "Desactivar" : "Activar" }} cuenta
              </v-btn>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>

        <!-- modals -->
        <v-dialog v-model="dialogHandleAccount" persistent max-width="400">
          <v-card class="py-3">
            <v-card-text>
              <v-form ref="formHandleAccount">
                <v-row>
                  <v-col cols="12">
                    <h3 class="font-weight-thin">
                      ¿Está seguro que
                      <span class="font-weight-bold"
                        >desea {{ getAgentSelected.active ? "desactivar" : "activar" }} la cuenta del usuario
                        {{ getAgentSelected.full_name }} ({{ getAgentSelected.email }}) </span
                      >?.
                    </h3>
                  </v-col>
                  <v-col v-if="errorFormHandleAccount" cols="12">
                    <v-alert dense text type="error">
                      {{ errorFormHandleAccount }}
                    </v-alert>
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
            <v-card-actions class="mr-2">
              <v-spacer></v-spacer>
              <v-btn rounded text @click="dialogHandleAccount = false">Cancelar</v-btn>
              <v-btn
                :loading="loading"
                :color="getAgentSelected.active ? 'red' : 'primary'"
                rounded
                dark
                @click="onHandleAccount"
              >
                {{ getAgentSelected.active ? "Desactivar" : "Activar" }} cuenta
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-container>
      <v-container v-else>
        <ShowLoading />
      </v-container>
    </div>
  </v-navigation-drawer>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import AgentForm from "@/components/forms/AgentForm";

export default {
  name: "AgentDetailDrawer",
  components: {
    AgentForm,
  },
  data() {
    return {
      tabs: [0, 1],
      actionInformation: "read",
      loading: false,
      dialogHandleAccount: false,
      errorFormHandleAccount: null,
    };
  },
  computed: {
    ...mapGetters(["getAgent", "getAgentId", "showAgentDetailDrawer", "getAgentSelected", "getAgentRolesToArray"]),
    localDrawer: {
      get() {
        return this.showAgentDetailDrawer;
      },
      set(v) {
        if (!v) this.showAgentDetail({ agentId: null });
      },
    },
  },
  watch: {
    showAgentDetailDrawer(value) {
      if (value) {
        this.getAgentById({ agent: { uid: this.getAgentId }, is_selected: true });
      }
    },
  },
  methods: {
    ...mapActions(["showAgentDetail", "getAgentById", "handleAgentAccount"]),
    async onHandleAccount() {
      try {
        this.loading = true;
        this.errorFormHandleAccount = null;

        await this.handleAgentAccount(this.getAgentId);

        this.dialogHandleAccount = false;
      } catch (error) {
        this.errorFormHandleAccount = error.message || "Ocurrió un error al modificar la cuenta";
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
