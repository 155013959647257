var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-app", [
    _c(
      "div",
      { staticClass: "pt-12 pt-md-0 pt-lg-0" },
      [_c("MenuOptions"), _vm._v(" "), _c("SettingsOptions")],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }