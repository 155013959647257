var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "d-flex pa-0", attrs: { cols: "12", sm: "6" } },
            [
              _c(
                "div",
                { staticClass: "my-auto px-2" },
                [
                  _c("v-img", {
                    staticClass: "mx-auto",
                    attrs: {
                      src: require("@/assets/social_icons/" + _vm.id + ".png"),
                      width: "35"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "h5",
                { staticClass: "font-weight-thin text--overflow my-auto" },
                [_vm._v(_vm._s(_vm.name))]
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "v-col",
            {
              staticClass: "text-right d-flex",
              attrs: { cols: "12", sm: "6" }
            },
            [
              _c(
                "h5",
                {
                  staticClass:
                    "font-weight-thin grey--text text--darken-2 my-auto"
                },
                [
                  _c(
                    "v-icon",
                    {
                      staticClass: "mr-2",
                      attrs: {
                        "x-small": "",
                        color: _vm.active ? "success" : "warning"
                      }
                    },
                    [_vm._v("fas fa-circle")]
                  ),
                  _vm._v(
                    _vm._s(_vm.active ? "Activado" : "Desactivado") + "\n      "
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("v-spacer"),
              _vm._v(" "),
              !_vm.showDetail
                ? _c(
                    "v-btn",
                    {
                      attrs: { text: "", small: "", color: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.$emit("update:showDetail", true)
                        }
                      }
                    },
                    [_vm._v("Ver detalle")]
                  )
                : _c(
                    "v-btn",
                    {
                      attrs: { text: "", small: "", color: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.$emit("update:showDetail", false)
                        }
                      }
                    },
                    [_vm._v("Ocultar")]
                  ),
              _vm._v(" "),
              _c(
                "v-menu",
                {
                  attrs: { "offset-y": "", bottom: "", left: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function(ref) {
                        var on = ref.on
                        var attrs = ref.attrs
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              _vm._b(
                                {
                                  staticClass: "my-auto",
                                  attrs: { "x-small": "", icon: "" }
                                },
                                "v-btn",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [
                              _c("v-icon", { attrs: { "x-small": "" } }, [
                                _vm._v("fas fa-ellipsis-v")
                              ])
                            ],
                            1
                          )
                        ]
                      }
                    }
                  ])
                },
                [
                  _vm._v(" "),
                  _c(
                    "v-list",
                    [
                      _vm.ableEdit
                        ? _c(
                            "v-list-item",
                            {
                              on: {
                                click: function($event) {
                                  return _vm.$emit("openEdit")
                                }
                              }
                            },
                            [_c("v-list-item-title", [_vm._v("Editar")])],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.ableEnable
                        ? _c(
                            "v-list-item",
                            {
                              on: {
                                click: function($event) {
                                  return _vm.$emit("openEnable")
                                }
                              }
                            },
                            [
                              _c("v-list-item-title", [
                                _vm._v(
                                  _vm._s(_vm.active ? "Desactivar" : "Activar")
                                )
                              ])
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.ableRemove
                        ? _c(
                            "v-list-item",
                            {
                              on: {
                                click: function($event) {
                                  return _vm.$emit("openRemove")
                                }
                              }
                            },
                            [_c("v-list-item-title", [_vm._v("Eliminar")])],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }