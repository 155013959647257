import requestAxios from "@/helpers/request_axios";
import * as MutationsType from "./mutationsType";
import { projectsCollection } from "@/config/firebase";
import { roles } from "@/helpers/get_constants";

export default {
  async getAllProjects({ commit, rootState }) {
    const agent = rootState.Agent.agent;
    let reference = projectsCollection;

    if (agent.role === roles.ENTREPRENEUR) reference = reference.where("team_id", "==", agent.team_id);

    const snap = await reference.get();
    let projects = [];

    if (!snap.empty) {
      snap.forEach((doc) => {
        projects.push({ id: doc.id, ...doc.data() });
      });
    }

    commit(MutationsType.SET_PROJECTS, projects);
  },
  async updatePortionsPerAgent({ commit }, { id, data }) {
    try {
      let response = await requestAxios({
        url: `/awy/api/v1/project/${id}/portions-per-agent`,
        method: "PATCH",
        data: data,
      });
      commit(MutationsType.UPDATE_PROJECT, response.data.data);
      return response.data;
    } catch (error) {
      console.error("updatePortionsPerAgent", error);
      throw error.response.data;
    }
  },
};
