import Vue from "vue";
import * as MutationsType from "./mutationsType";

/* eslint-disable no-param-reassign */
export default {
  [MutationsType.SET_CONTACT](state, payload) {
    state.contact = payload;
  },
  [MutationsType.SET_CONTACTS](state, payload) {
    state.contacts = payload;
  },
  [MutationsType.SET_NEW_CONTACTS](state, payload) {
    state.newContacts = payload;
  },
  [MutationsType.ADD_CONTACT](state, payload) {
    state.contacts.push(payload);
  },
  [MutationsType.UPDATE_CONTACT](state, payload) {
    let ind = (state.contacts || []).findIndex((t) => t.id === payload.id);
    if (ind >= 0) Vue.set(state.contacts, ind, payload.value);
  },
  [MutationsType.SET_CONTACT_STATE](state, payload) {
    state.contact.state = payload;
  },
};
