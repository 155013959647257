import * as MutationsType from "./mutationsType";
import requestAxios from "@/helpers/request_axios";
import { devicesCollection } from "@/config/firebase";
import { notificationTypes } from "@/helpers/get_constants";

export default {
  async getCurrentDevice({ commit }, payload) {
    let allowNotificationTypes = [];
    let doc = await devicesCollection.doc(payload.agent_id).get();
    if (doc.exists) {
      let notificationType;

      for (let type in notificationTypes) {
        notificationType = notificationTypes[type];
        if ((doc.data()[notificationType] || []).find((token) => token === payload.token))
          allowNotificationTypes.push(notificationType);
      }
    }

    commit(MutationsType.SET_DEVICE, { token: payload.token, notification_types: allowNotificationTypes });
  },
  // eslint-disable-next-line
  async handleDeviceNotifications({ commit }, payload) {
    try {
      let response = await requestAxios({
        url: "/awy/api/v1/device/notifications",
        method: "POST",
        data: payload,
      });
      let data = response.data.data;
      commit(MutationsType.SET_DEVICE, {
        token: data.token,
        notification_types: data.notification_types,
        allow: data.allow,
      });
      return response.data;
    } catch (error) {
      console.error("device/handleDeviceNotifications: ", error);
      throw error.response.data;
    }
  },
};
