import * as MutationsType from "./mutationsType";
import requestAxios from "@/helpers/request_axios";
import { campaignsBudgetsCollection } from "@/config/firebase";
import { roles } from "@/helpers/get_constants";

export default {
  async getAllCampaignsBudgets({ commit, rootState }) {
    const agent = rootState.Agent.agent;
    let reference = campaignsBudgetsCollection;

    if (agent.role === roles.ENTREPRENEUR) reference = reference.where("team_id", "==", agent.team_id);

    let campaignsBudgets = [];
    let snap = await reference.orderBy("created_at", "desc").get();

    if (!snap.empty)
      snap.forEach((doc) => {
        campaignsBudgets.push({ id: doc.id, ...doc.data() });
      });

    commit(MutationsType.SET_CAMPAIGNS_BUDGETS, campaignsBudgets);
  },
  // eslint-disable-next-line
  async createCampaignBudget({ commit }, payload) {
    try {
      let response = await requestAxios({
        url: "/awy/api/v1/campaign-budget",
        method: "POST",
        data: payload,
      });
      return response.data;
    } catch (error) {
      console.error("createCampaignBudget: ", error);
      return error.response.data;
    }
  },
  async updateCampaignBudget({ commit }, payload) {
    try {
      let response = await requestAxios({
        url: `/awy/api/v1/campaign-budget/${payload.id}`,
        method: "PUT",
        data: payload,
      });
      commit(MutationsType.UPDATE_CAMPAIGN_BUDGET, response.data.data);
      return response.data;
    } catch (error) {
      console.error("updateCampaignBudget: ", error);
      throw error.response.data;
    }
  },
  // eslint-disable-next-line
  async removeCampaignBudget({ commit }, payload) {
    try {
      let response = await requestAxios({
        url: `/awy/api/v1/campaign-budget/${payload.id}`,
        method: "DELETE",
      });
      commit(MutationsType.REMOVE_CAMPAIGN_BUDGET, payload.id);
      return response.data;
    } catch (error) {
      console.error("removeCampaignBudget: ", error);
      throw error.response.data;
    }
  },
};
