var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-list",
    { staticClass: "py-0", attrs: { dense: "" } },
    _vm._l(_vm.getMenuOptions, function(item) {
      return _c(
        "v-list-item",
        {
          key: item.title,
          staticClass: "my-1",
          attrs: { link: "", to: item.path }
        },
        [
          _c(
            "v-list-item-icon",
            [
              _c("v-icon", { attrs: { small: "" } }, [
                _vm._v(_vm._s(item.icon))
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-list-item-content",
            [_c("v-list-item-title", [_vm._v(_vm._s(item.title))])],
            1
          )
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }