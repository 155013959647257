<template>
  <v-dialog v-model="dialog" persistent scrollable max-width="600">
    <v-card class="py-2">
      <v-card-title class="headline d-flex">
        <span class="ml-0">Mensaje masivo por WhatsApp</span>
        <v-spacer></v-spacer>
        <v-btn icon @click="$emit('update:dialog', false)">
          <v-icon small>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-form ref="formMassiveMessage" lazy-validation>
          <v-row>
            <v-col cols="12" sm="8">
              <v-text-field label="Segmento" :value="segment.name" readonly></v-text-field>
            </v-col>
            <v-col cols="12" sm="4">
              <v-text-field label="Plantilla" value="-" disabled readonly></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-textarea
                v-model="message"
                :rules="[$rules.REQUIRED]"
                label="Mensaje"
                placeholder="Escribe tu mensaje aquí"
                auto-grow
                rows="3"
                class="expand"
              ></v-textarea>
            </v-col>
            <v-col v-if="formErrorMessage" cols="12">
              <v-alert dense text type="error">
                {{ formErrorMessage }}
              </v-alert>
            </v-col>
            <v-col cols="12" class="text-right pb-0">
              <v-btn rounded text @click="$emit('update:dialog', false)">Cancelar</v-btn>
              <v-btn rounded color="primary" class="my-auto ml-5" :loading="loading" @click="onSendMessage"
                >Enviar mensaje masivo</v-btn
              >
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "SendWhatsAppMessageModal",
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    segment: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      message: "",
      formErrorMessage: null,
      loading: false,
    };
  },
  methods: {
    ...mapActions(["sendWhatsAppMessageToSegmentResults"]),
    async onSendMessage() {
      try {
        this.loading = true;
        this.formErrorMessage = null;

        if (this.$refs.formMassiveMessage.validate()) {
          await this.sendWhatsAppMessageToSegmentResults({
            id: this.segment.id,
            message: this.message,
          });

          this.$router.push({ name: "segment_automations" });
        }
      } catch (error) {
        this.formErrorMessage = error.message || "Hubo un error al enviar el mensaje masivo por WhatsApp";
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
