<template>
  <v-app id="ticket_register">
    <v-container v-if="getAgent" v-show="!isLoading" class="rounded-lg mb-10">
      <v-row class="pt-4">
        <v-col cols="12" sm="10" md="8" offset-sm="1" offset-md="2">
          <h2 class="text-h4 primary--text pb-4 font-weight-black pt-8 pt-md-0 pt-lg-0">Nueva oportunidad</h2>
          <v-card class="px-4 py-6 rounded-lg">
            <TicketForm :is-loading.sync="isLoading" />
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-container v-if="isLoading">
      <ShowLoading />
    </v-container>
  </v-app>
</template>

<script>
import TicketForm from "@/components/forms/TicketForm";
import { mapGetters } from "vuex";

export default {
  name: "TicketRegister",
  components: {
    TicketForm,
  },
  data() {
    return {
      isLoading: true,
    };
  },
  computed: { ...mapGetters(["getAgent"]) },
};
</script>
