var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-menu",
    {
      attrs: { bottom: "", left: "", "offset-y": "", "open-on-hover": "" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function(ref) {
            var on = ref.on
            var attrs = ref.attrs
            return [
              _c(
                "v-btn",
                _vm._g(
                  _vm._b(
                    {
                      attrs: { text: "", icon: "" },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                        }
                      }
                    },
                    "v-btn",
                    attrs,
                    false
                  ),
                  on
                ),
                [
                  _c("v-icon", { attrs: { dark: "", small: "" } }, [
                    _vm._v("far fa-envelope")
                  ])
                ],
                1
              )
            ]
          }
        }
      ])
    },
    [
      _vm._v(" "),
      _c(
        "v-list",
        [
          _c(
            "v-list-item",
            {
              attrs: {
                href:
                  "https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=" +
                  _vm.email,
                target: "_blank"
              }
            },
            [_c("v-list-item-title", [_vm._v("GMail")])],
            1
          ),
          _vm._v(" "),
          _c(
            "v-list-item",
            {
              attrs: {
                href:
                  "https://outlook.live.com/?path=/mail/action/compose&to=" +
                  _vm.email,
                target: "_blank"
              }
            },
            [_c("v-list-item-title", [_vm._v("Outlook")])],
            1
          ),
          _vm._v(" "),
          _c(
            "v-list-item",
            {
              attrs: {
                href: "https://compose.mail.yahoo.com/?to=" + _vm.email,
                target: "_blank"
              }
            },
            [_c("v-list-item-title", [_vm._v("Yahoo")])],
            1
          ),
          _vm._v(" "),
          _c(
            "v-list-item",
            { attrs: { href: "mailto:" + _vm.email } },
            [_c("v-list-item-title", [_vm._v("Mailto")])],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }