function formatTimestamp(date, type = "large") {
  try {
    if (!date) return "";
    if (type === "date_z") return this.$moment(date).utc().format("DD/MM/YYYY");

    let date_formated = new Date(date);
    let to_date = date_formated.getTime() ? date_formated : date.toDate();

    if (type === "only_date") return this.$moment(to_date).format("YYYY-MM-DD");

    let days_before = this.$moment().diff(to_date, "days");

    if (days_before < 1) return this.$moment(to_date).fromNow();
    else {
      if (type === "large") return this.$moment(to_date).format("ddd DD MMM YYYY hh:mm a");
      if (type == "short") return this.$moment(to_date).format("ddd DD MMM");
      return this.$moment(to_date).format("DD/MM/YYYY hh:mm a");
    }
  } catch (error) {
    return "-";
  }
}

export default formatTimestamp;
