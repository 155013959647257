import { notificationTypes } from "@/helpers/get_constants";

function formatNotification(data) {
  let notification = {};

  switch (data.notification_type) {
    case notificationTypes.ASSIGN_TICKETS:
      if (data.importer_by) {
        notification.text = `Se te han asignado a ${data.cant_tickets} oportunidades :)`;
        notification.link = location.pathname === "/opportunities" ? "" : "/opportunities";
      } else {
        notification.text = "Has sido asignado a una oportunidad nueva :)";
        notification.link =
          location.pathname === `/opportunities/${data.ticket_id}` ? "" : `/opportunities/${data.ticket_id}`;
      }
      break;

    case notificationTypes.START_EVENTS:
      notification.text = `Recordatorio: ${data.name}`;
      notification.link = "";
      if (data.ticket_id)
        notification.link =
          location.pathname === `/opportunities/${data.ticket_id}` ? "" : `/opportunities/${data.ticket_id}`;
      else if (data.contact_id)
        notification.link = location.pathname === `/contacts/${data.contact_id}` ? "" : `/contacts/${data.contact_id}`;
      break;
  }

  return notification;
}

export default formatNotification;
