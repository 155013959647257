var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-list",
    { staticClass: "py-0", attrs: { dense: "" } },
    [
      _c("v-divider"),
      _vm._v(" "),
      _vm.getAgent
        ? _c(
            "v-list-item",
            { staticClass: "px-3", attrs: { "two-line": "" } },
            [
              _c(
                "v-list-item-avatar",
                { attrs: { color: "primary", size: "26" } },
                [
                  _vm.getAgent.photo
                    ? _c(
                        "v-avatar",
                        [_c("v-img", { attrs: { src: _vm.getAgent.photo } })],
                        1
                      )
                    : _c("v-avatar", { attrs: { color: "primary" } }, [
                        _c("span", { staticClass: "white--text" }, [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.getAgent.full_name
                                  ? _vm.getAgent.full_name.substring(0, 1)
                                  : ""
                              ) +
                              "\n        "
                          )
                        ])
                      ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-list-item-content",
                [
                  _c("v-list-item-title", [
                    _vm._v(_vm._s(_vm.getAgent.full_name))
                  ]),
                  _vm._v(" "),
                  _c("v-list-item-subtitle", [
                    _vm._v(_vm._s(_vm.getAgent.email))
                  ])
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "v-list-item",
        { attrs: { link: "", to: "/settings" } },
        [
          _c(
            "v-list-item-icon",
            { staticClass: "my-auto" },
            [_c("v-icon", { attrs: { small: "" } }, [_vm._v("fas fa-cog")])],
            1
          ),
          _vm._v(" "),
          _c(
            "v-list-item-content",
            [_c("v-list-item-title", [_vm._v("Configuraciones")])],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-list-item",
        {
          attrs: { link: "" },
          on: {
            click: function($event) {
              _vm.dialogModalTips = !_vm.dialogModalTips
            }
          }
        },
        [
          _c(
            "v-list-item-icon",
            { staticClass: "my-auto" },
            [
              _c("v-icon", { attrs: { small: "" } }, [
                _vm._v("far fa-lightbulb")
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-list-item-content",
            [_c("v-list-item-title", [_vm._v("Tips")])],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.dialogModalTips
        ? _c("ModalTips", {
            attrs: { "open-dialog": _vm.dialogModalTips },
            on: {
              "update:openDialog": function($event) {
                _vm.dialogModalTips = $event
              },
              "update:open-dialog": function($event) {
                _vm.dialogModalTips = $event
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }