var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", "max-width": "400" },
      model: {
        value: _vm.dialogToEnable,
        callback: function($$v) {
          _vm.dialogToEnable = $$v
        },
        expression: "dialogToEnable"
      }
    },
    [
      _c(
        "v-card",
        { staticClass: "py-3" },
        [
          _c(
            "v-card-text",
            [
              _c(
                "v-row",
                [
                  _c("v-col", { attrs: { cols: "12" } }, [
                    _c("h3", { staticClass: "font-weight-thin" }, [
                      _vm._v("\n            ¿Desea\n            "),
                      _c("span", { staticClass: "font-weight-bold" }, [
                        _vm._v(_vm._s(_vm.active ? "desactivar" : "activar"))
                      ]),
                      _vm._v(
                        "\n            la integración con " +
                          _vm._s(_vm.name) +
                          "?\n          "
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _vm.error
                    ? _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c(
                            "v-alert",
                            { attrs: { dense: "", text: "", type: "error" } },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.error) +
                                  "\n          "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card-actions",
            { staticClass: "mr-2" },
            [
              _c("v-spacer"),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  attrs: { rounded: "", text: "" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("update:dialogToEnable", false)
                    }
                  }
                },
                [_vm._v("Cancelar")]
              ),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  attrs: {
                    loading: _vm.loading,
                    color: "primary",
                    rounded: ""
                  },
                  on: {
                    click: function($event) {
                      return _vm.$emit("enable")
                    }
                  }
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.active ? "Desactivar" : "Activar") +
                      "\n      "
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }