import { activityTypes } from "../../../helpers/get_constants";

export default {
  timeline: [],
  timelineIds: [],
  contactTimeline: [],
  contactTlineIds: [],
  commentTypes: {
    [activityTypes.COMMENT]: {
      id: activityTypes.COMMENT,
      icon: "fas fa-comment",
      color: "pink",
      allow_user: true,
    },
    [activityTypes.CALL]: {
      id: activityTypes.CALL,
      icon: "fas fa-headset",
      color: "primary",
      allow_user: true,
    },
    [activityTypes.MESSAGE]: {
      id: activityTypes.MESSAGE,
      icon: "fas fa-envelope",
      color: "cyan",
      allow_user: true,
    },
    [activityTypes.EVENT]: {
      id: activityTypes.EVENT,
      icon: "far fa-calendar-alt",
      color: "green",
      allow_user: true,
    },
    [activityTypes.CONTACT]: {
      id: activityTypes.CONTACT,
      icon: "fas fa-user",
      color: "teal",
    },
    "5": {
      id: 5,
      icon: "fab fa-whatsapp",
      color: "#00bb2d",
    },
  },
};
