<template>
  <v-form ref="formSegment" lazy-validation>
    <v-row>
      <v-col cols="12" sm="7">
        <v-text-field v-model="segment.name" :rules="[$rules.REQUIRED]" placeholder=" " label="Nombre"></v-text-field>
      </v-col>
      <v-col cols="12" sm="5">
        <v-select
          v-model="segment.module"
          :items="[
            { id: 'tickets', name: 'Oportunidades' },
            { id: 'leads', name: 'Contactos' },
            { id: 'orders', name: 'Órdenes' },
          ]"
          :rules="[$rules.REQUIRED]"
          :readonly="false"
          item-value="id"
          item-text="name"
          placeholder="Seleccione"
          label="Módulo"
        ></v-select>
      </v-col>
    </v-row>
    <v-row v-if="loadingDataToSegment">
      <v-col cols="12" class="text-center py-15">
        <v-progress-circular indeterminate color="primary"></v-progress-circular>
        <br />
        <span class="caption primary--text">Cargando filtros y columnas</span>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col cols="12">
        <h4>Filtros</h4>
      </v-col>
      <v-col v-show="segment.filters.length > 0" cols="12" sm="7">
        <v-row>
          <template v-for="filter in segment.filters">
            <v-col :key="filter.id" cols="12" class="pt-0">
              <v-card class="rounded-lg">
                <v-card-title class="justify-right pb-2">
                  <v-spacer></v-spacer>
                  <v-btn icon small @click="removeFilterSelected(filter.id)">
                    <v-icon small>fas fa-times</v-icon>
                  </v-btn>
                </v-card-title>
                <v-card-text class="pb-0">
                  {{ modulesName[segment.module] }} que en el campo <v-chip outlined>{{ filter.label }}</v-chip>
                  sea:
                  <template v-if="filter.type === 'date'">
                    <v-row class="pb-2">
                      <v-col cols="12" sm="6">
                        <v-menu
                          v-model="filter.ui.menuStart"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="filter.values.start"
                              :rules="[$rules.REQUIRED]"
                              label="Fecha inicial"
                              prepend-icon="far fa-calendar-alt"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="filter.values.start"
                            :max="today"
                            @input="filter.ui.menuStart = false"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col cols="12" sm="6">
                        <v-menu
                          v-model="filter.ui.menuEnd"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="filter.values.end"
                              :rules="[$rules.REQUIRED, (v) => v >= filter.values.start || 'Fecha inválida']"
                              label="Fecha final"
                              prepend-icon="far fa-calendar-alt"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="filter.values.end"
                            :max="today"
                            @input="filter.ui.menuEnd = false"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                    </v-row>
                  </template>
                  <template v-else>
                    <v-autocomplete
                      v-model="filter.values"
                      :items="filter.items"
                      :rules="[$rules.REQUIRED, (v) => v.length > 0 || 'Debe seleccionar aunque sea uno']"
                      :item-value="filter.item_text ? (filter.item_value ? filter.item_value : 'id') : ''"
                      :item-text="filter.item_text ? filter.item_text : ''"
                      chips
                      multiple
                      rounded
                      filled
                      dense
                      class="mt-2"
                    ></v-autocomplete>
                  </template>
                </v-card-text>
              </v-card>
            </v-col>
          </template>
        </v-row>
      </v-col>
      <v-col cols="12" :sm="segment.filters.length > 0 ? 5 : 12">
        <span class="text--secondary body">
          Seleccione los filtro que desea aplicar:
        </span>
        <v-chip-group v-model="filtersSelected" column multiple>
          <template v-for="filter in filtersAvailable">
            <v-chip :key="filter.id" filter outlined :value="filter.id">
              {{ filter.label }}
            </v-chip>
          </template>
        </v-chip-group>
      </v-col>
      <v-col cols="12">
        <h4>Columnas</h4>
      </v-col>
      <v-col cols="12">
        <span class="text--secondary body">
          Seleccione la información que desea obtener (4 máx):
        </span>
        <v-chip-group v-model="columnsSelected" column multiple max="4">
          <template v-for="column in columnsAvailable">
            <v-chip :key="column.key" filter outlined :value="column.key">
              {{ column.header }}
            </v-chip>
          </template>
        </v-chip-group>
        <span v-if="columnsSelected.length === 0" class="red--text caption">Seleccione al menos uno</span>
      </v-col>
      <v-col v-if="errorForm" cols="12">
        <v-alert text type="error">
          {{ errorForm }}
        </v-alert>
      </v-col>
      <v-col cols="12" :class="action === 'create' ? 'text-left' : 'text-right'">
        <v-btn color="primary" rounded :loading="loadingToSave" @click="onSave">Guardar segmento</v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import {
  ticketFormSchema,
  ticketExportData,
  contactFormSchema,
  contactExportData,
  saleExportData,
} from "Config/config";
import { getCountryList } from "@/helpers";

export default {
  name: "SegmentForm",
  props: {
    segmentSelected: {
      type: Object,
      default: () => null,
    },
    action: {
      type: String,
      default: "create",
    },
  },
  data() {
    return {
      segment: {
        name: "",
        module: "tickets",
        filters: [],
        columns: [],
      },
      filtersSelected: [],
      columnsSelected: [],
      filtersAvailable: [],
      columnsAvailable: [],
      modulesName: {
        tickets: "Oportunidades",
        leads: "Contactos",
        orders: "Órdenes",
      },
      loadingDataToSegment: false,
      loadingToSave: false,
      errorForm: null,
      today: this.$moment().format("YYYY-MM-DD"),
      menuStart: false,
      menuEnd: false,
    };
  },
  computed: {
    ...mapGetters([
      "getAgent",
      "getTicketStates",
      "getOrderStates",
      "getActiveTags",
      "getProjects",
      "getAgentsToArray",
      "getOrigins",
      "getSchemaTicketForm",
      "getSchemaTicketExport",
      "getSchemaContactForm",
      "getSchemaContactExport",
    ]),
  },
  watch: {
    segmentSelected: {
      deep: true,
      handler(value) {
        if (value) {
          this.segment = { ...value };
          this.columnsSelected = value.columns.map((c) => c.key);
          this.filtersSelected = value.filters.map((f) => f.id);
        }
      },
    },
    filtersSelected(value, oldValue) {
      if (value.length > oldValue.length) {
        let field = value[value.length - 1];

        if (!oldValue.includes(field) && !this.segment.filters.find((f) => f.id === field)) {
          let filter = this.filtersAvailable.find((f) => f.id === field);
          this.segment.filters.push({
            ...filter,
            id: field,
            values: filter.type === "date" ? {} : [],
          });
        }
      } else {
        let fieldDeleted = oldValue.find((o) => !value.includes(o));
        this.removeFilterSelected(fieldDeleted);
      }
    },
    "segment.module"() {
      this.loadDataToSegment(true);
    },
  },
  created() {
    if (this.segmentSelected) this.segment = { ...this.segmentSelected };

    this.loadDataToSegment();
  },
  methods: {
    ...mapActions([
      "getAllTicketStates",
      "getAllOrderStates",
      "getAllTags",
      "getAllProjects",
      "getAllAgents",
      "getAllOrigins",
      "getTeamSchema",
      "createSegment",
      "updateSegment",
    ]),
    async loadDataToSegment(reset = false) {
      try {
        this.loadingDataToSegment = true;

        if (reset) {
          this.columnsSelected = [];
          this.filtersSelected = [];
        }

        let data = await this.loadDataByModule(this.segment.module);

        let { filters, columns } = await this.getFiltersAndColumnsByModule(this.segment.module, data);

        this.filtersAvailable = filters;
        this.columnsAvailable = columns;

        if (this.action === "create" && this.segment.module === "tickets")
          this.columnsSelected = ["name", "email", "phone"];

        if (this.action === "update" && this.segment.module === this.segmentSelected.module) {
          this.columnsSelected = this.segmentSelected.columns.map((c) => c.key);
          this.filtersSelected = [...this.segmentSelected.filters.map((f) => f.id)];

          for (let filter of this.filtersSelected) {
            let indFilter = this.segmentSelected.filters.findIndex((f) => f.id === filter);
            this.segment.filters[indFilter].items = filters.find((f) => f.id === filter).items;
          }
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.loadingDataToSegment = false;
      }
    },
    async loadDataByModule(module) {
      let data = {};
      let loadCountries = module !== "orders";
      let loadStates = module !== "leads";
      let loadTags = module !== "orders";
      let loadProjects = module == "tickets";

      if (loadCountries) {
        let countries = await getCountryList();
        data.country = {
          id: "country",
          label: "País",
          items: countries,
          item_value: "name",
          item_text: "name",
          default: true,
        };
      }

      if (loadStates) {
        let states;

        if (module === "tickets") {
          if (!this.getTicketStates) await this.getAllTicketStates();

          states = this.getTicketStates;
        }

        if (module === "orders") {
          if (!this.getOrderStates) await this.getAllOrderStates();

          states = this.getOrderStates;
        }

        data.state = {
          id: "state",
          label: "Estado",
          items: states,
          item_text: "name",
          default: true,
        };
      }

      if (loadTags) {
        if (!this.getActiveTags.length) await this.getAllTags();
        data.tags = {
          id: "tags",
          label: "Etiquetas",
          items: this.getActiveTags,
          item_text: "name",
          default: true,
        };
      }

      if (loadProjects) {
        if (!this.getProjects) await this.getAllProjects();
        data.project = { id: "project", label: "Proyecto", items: this.getProjects, item_text: "id", default: true };
      }

      if (!this.getAgentsToArray) await this.getAllAgents();
      data.agent = {
        id: "agent_id",
        label: "Encargado",
        items: this.getAgentsToArray,
        item_text: "full_name",
        default: true,
      };

      if (!this.getOrigins) await this.getAllOrigins();
      data.origin = {
        id: "origin",
        label: "Origen",
        items: this.getOrigins,
        item_text: "name",
        item_value: "name",
        default: true,
      };

      data.created_at = {
        id: "created_at",
        label: "Fecha de registro",
        type: "date",
        ui: { menuStart: false, menuEnd: false },
        default: true,
      };

      if (module === "tickets")
        data.last_activity = {
          id: "last_activity",
          label: "Fecha de última actividad",
          type: "date",
          ui: { menuStart: false, menuEnd: false },
          default: true,
        };

      return data;
    },
    async getFiltersAndColumnsByModule(module, data) {
      let filters = [];
      let columns = [];
      let schema;

      if (module === "tickets") {
        schema = ticketFormSchema;
        columns = ticketExportData;

        if (this.getAgent.role === this.$roles.ENTREPRENEUR) {
          if (!this.getSchemaTicketForm)
            await this.getTeamSchema({ id: this.getAgent.team_id, schemaIds: ["ticket_form"] });
          schema = this.getSchemaTicketForm;

          if (!this.getSchemaTicketExport)
            await this.getTeamSchema({ id: this.getAgent.team_id, schemaIds: ["ticket_export"] });
          columns = this.getSchemaTicketExport;
        }
      }

      if (module === "leads") {
        schema = contactFormSchema;
        columns = contactExportData;

        if (this.getAgent.role === this.$roles.ENTREPRENEUR) {
          if (!this.getSchemaContactForm)
            await this.getTeamSchema({ id: this.getAgent.team_id, schemaIds: ["contact_form"] });
          schema = this.getSchemaContactForm;

          if (!this.getSchemaContactExport)
            await this.getTeamSchema({ id: this.getAgent.team_id, schemaIds: ["contact_export"] });
          columns = this.getSchemaContactExport;
        }
      }

      if (module === "orders") {
        schema = {};
        columns = saleExportData.filter((s) => s.type !== "products");
      }

      schema = { ...schema, ...data };

      for (let field in schema) {
        if (
          schema[field].default ||
          (["autocomplete", "combobox", "select"].includes(schema[field].type) && field !== "city")
        )
          filters.push({ ...schema[field], id: field });
      }

      return { filters, columns };
    },
    removeFilterSelected(id) {
      let indToDelete = this.filtersSelected.findIndex((f) => f === id);
      if (indToDelete >= 0) this.filtersSelected.splice(indToDelete, 1);

      let indToDeleteFromFilters = this.segment.filters.findIndex((f) => f.id === id);
      if (indToDeleteFromFilters >= 0) this.segment.filters.splice(indToDeleteFromFilters, 1);
    },
    async onSave() {
      try {
        this.loadingToSave = true;

        if (this.$refs.formSegment.validate()) {
          let segment = {
            ...this.segment,
            columns: this.columnsAvailable.filter((c) => this.columnsSelected.includes(c.key)),
          };

          if (this.action === "create") {
            let response = await this.createSegment(segment);
            if (response.data) this.$router.replace({ name: "segments" });
          } else {
            await this.updateSegment(segment);
            this.$emit("afterEdit");
          }
        }
      } catch (error) {
        console.error(error);
        this.errorForm = error.message || `Error al ${this.action === "create" ? "crear" : "actualizar"} el segmento`;
      } finally {
        this.loadingToSave = false;
      }
    },
  },
};
</script>

<style>
.v-chip--active.v-chip--clickable > .v-chip__content > i.fa-check {
  font-size: 14px;
  color: green;
}
</style>
