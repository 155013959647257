<template>
  <div>
    <v-row>
      <v-col cols="12" sm="6" class="d-flex pa-0">
        <div class="my-auto px-2">
          <v-img :src="require(`@/assets/social_icons/${id}.png`)" width="35" class="mx-auto"></v-img>
        </div>
        <h5 class="font-weight-thin text--overflow my-auto">{{ name }}</h5>
      </v-col>
      <v-col cols="12" sm="6" class="text-right d-flex">
        <h5 class="font-weight-thin grey--text text--darken-2 my-auto">
          <v-icon x-small class="mr-2" :color="active ? 'success' : 'warning'">fas fa-circle</v-icon
          >{{ active ? "Activado" : "Desactivado" }}
        </h5>
        <v-spacer></v-spacer>
        <v-btn v-if="!showDetail" text small color="primary" @click="$emit('update:showDetail', true)"
          >Ver detalle</v-btn
        >
        <v-btn v-else text small color="primary" @click="$emit('update:showDetail', false)">Ocultar</v-btn>
        <v-menu offset-y bottom left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn x-small icon class="my-auto" v-bind="attrs" v-on="on">
              <v-icon x-small>fas fa-ellipsis-v</v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item v-if="ableEdit" @click="$emit('openEdit')">
              <v-list-item-title>Editar</v-list-item-title>
            </v-list-item>
            <v-list-item v-if="ableEnable" @click="$emit('openEnable')">
              <v-list-item-title>{{ active ? "Desactivar" : "Activar" }}</v-list-item-title>
            </v-list-item>
            <v-list-item v-if="ableRemove" @click="$emit('openRemove')">
              <v-list-item-title>Eliminar</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  name: "IntegrationDetail",
  props: {
    id: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      default: "canal",
    },
    active: {
      type: Boolean,
      default: false,
    },
    showDetail: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
      default: "",
    },
    ableEdit: {
      type: Boolean,
      default: false,
    },
    ableEnable: {
      type: Boolean,
      default: true,
    },
    ableRemove: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
