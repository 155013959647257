<template>
  <v-app class="pt-16 pt-md-0 pt-lg-0 mb-10 pb-10">
    <div v-if="getAgent">
      <h2 class="text-h4 primary--text py-8 font-weight-black hidden-sm-and-down">Configuraciones</h2>
      <v-card class="rounded-lg">
        <Notifications />
        <DarkMode />
      </v-card>
      <v-col cols="12" class="py-5 mt-5 px-0">
        <h4>Configuraciones avanzadas</h4>
        <v-card class="rounded-lg mt-4">
          <Tickets v-if="[$roles.SUPER_ADMIN, $roles.ENTREPRENEUR].includes(getAgent.role)" />
          <TeamsAgents v-if="getAgent.role === $roles.SUPER_ADMIN" />
          <Integrations />
        </v-card>
      </v-col>
      <v-col cols="12" class="pt-5 mt-5 px-0">
        <v-btn
          color="primary"
          text
          href="https://apolocrm.atlassian.net/servicedesk/customer/portal/1/group/1/create/1"
          target="_blank"
        >
          <v-icon class="mr-2" small>far fa-question-circle</v-icon> Soporte
        </v-btn>
      </v-col>
      <v-col cols="12" class="pt-3 mb-5 px-0">
        <v-btn color="accent" text @click="signout">
          <v-icon class="mr-2" small>fas fa-sign-out-alt</v-icon> Cerrar sesión
        </v-btn>
      </v-col>
    </div>
  </v-app>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { messaging } from "@/config/firebase";
import { notificationTypes } from "@/helpers/get_constants";
import Notifications from "@/views/settings/options/Notifications";
import DarkMode from "@/views/settings/options/DarkMode";
import Integrations from "@/views/settings/options/Integrations";
import Tickets from "@/views/settings/options/Tickets";
import TeamsAgents from "@/views/settings/options/TeamsAgents";

export default {
  name: "Settings",
  components: {
    Notifications,
    DarkMode,
    Integrations,
    Tickets,
    TeamsAgents,
  },
  data() {
    return {
      toggle_webhooks: false,
    };
  },
  computed: {
    ...mapGetters(["getAgent"]),
  },
  created() {},
  methods: {
    ...mapActions(["logout", "handleDeviceNotifications"]),
    async signout() {
      try {
        if (
          process.env.NODE_ENV === "production" &&
          messaging &&
          Notification &&
          Notification.permission === "granted"
        ) {
          const token = await messaging.getToken();
          if (token) await messaging.deleteToken(token);

          localStorage.removeItem("sync_notifications");

          await this.handleDeviceNotifications({
            notification_types: [notificationTypes.ASSIGN_TICKETS, notificationTypes.START_EVENTS],
            allow: false,
            token: token,
            only_token: true,
          });
        }

        await this.logout();
        this.$router.replace("/login");
      } catch (error) {
        console.error("signout", error);
      }
    },
  },
};
</script>
