export default {
  getTicketStates(state) {
    return state.ticketStates;
  },
  getTicketStatesMap(state) {
    return state.ticketStatesMap;
  },
  getActiveTicketStates(state) {
    return state.ticketStates?.filter((ts) => ts.active);
  },
  getOrderStates(state) {
    return state.orderStates;
  },
  getOrderStatesMap(state) {
    return state.orderStatesMap;
  },
};
