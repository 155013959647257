<template>
  <v-dialog v-model="dialog" scrollable persistent max-width="800">
    <v-card class="py-3">
      <v-card-title class="headline">
        <div>
          <v-img :src="require(`@/assets/social_icons/${id}.png`)" width="20px" class="mr-2"></v-img>
        </div>
        <span class="ml-0">WhatsApp</span>
        <v-spacer></v-spacer>
        <v-btn icon @click="closeDialog">
          <v-icon small>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <h4 class="font-weight-thin">
              La integración con WhatsApp le permitirá ingresar oportunidades así como gestionarlas.
            </h4>
          </v-col>
          <v-col cols="12" class="pt-2">
            <v-alert v-if="!getIntegrationWhatsApp" dense text type="info" class="caption">
              Antes de empezar, deberá tener la aplicación de WhatsApp en su dispositivo móvil con la cuenta que desea
              configurar con ApoloCRM.
            </v-alert>
            <v-alert v-if="getIntegrationWhatsApp && getIntegrationWhatsApp.active" dense text type="success">
              Se ha conectado con WhatsApp éxitosamente.
            </v-alert>
          </v-col>
          <v-col v-if="!getIntegrationWhatsApp || !getIntegrationWhatsApp.active" cols="12" class="d-flex">
            <span class="circle primary white--text mr-2">1</span>
            <v-row class="pr-5">
              <v-col cols="12" sm="6" class="pt-0">
                <h4 class="font-weight-thin">
                  Deberá escanear el siguiente <span class="font-weight-bold">QR</span> desde
                  <span class="font-weight-bold">WhatsApp</span>.
                </h4>
                <v-col v-if="generateQRError" cols="12">
                  <v-alert text type="error">
                    {{ generateQRError }}
                  </v-alert>
                </v-col>
                <v-col cols="12">
                  <v-alert dense text type="info" class="caption">
                    Luego que hayas escaneado el código QR con éxito, podrás enviar el hashtag "#" para crear
                    automaticamente una oportunidad en Apolo CRM. Puedes gestionarla con diferentes acciones como:
                    cambiar estado, añadir etiquetas, establecer recordatorios (ejm: #r1m) todas estás desde la
                    conversación que tengas con tu cliente desde WhatsApp. Sí quieres más información de los beneficios
                    de está integración, comunícate con un asesor de Apolo CRM.
                  </v-alert>
                </v-col>
              </v-col>
              <v-col cols="12" sm="6" class="py-0 text-center">
                <img v-if="qr" :src="qr" alt="QR" />
                <div
                  v-if="loadingQR"
                  :style="{
                    height: '300px',
                  }"
                  class="d-flex align-center justify-center"
                >
                  <v-progress-circular indeterminate color="primary"></v-progress-circular>
                </div>
                <v-btn color="primary" rounded @click="pingServer">Generar código QR</v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import QRious from "qrious";
import VueSocketIO from "vue-socket.io";
import SocketIO from "socket.io-client";
import Vue from "vue";
import { mapGetters } from "vuex";
import { app, whatsappServerURL } from "Config/config";

Vue.use(
  new VueSocketIO({
    debug: true,
    connection: SocketIO(whatsappServerURL, { withCredentials: true }),
  }),
);

export default {
  name: "WhatsAppModal",
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      id: "whatsapp",
      qr: null,
      loadingQR: false,
      generateQRError: null,
      isConnected: false,
      socketMessage: "",
    };
  },
  computed: {
    ...mapGetters(["getAgent", "getIntegrationWhatsApp"]),
  },
  sockets: {
    message(msg) {
      if (msg.status === "qr") this.generateQR(msg.qrcode);
    },
  },
  async created() {
    if (!this.getIntegrationWhatsApp || !this.getIntegrationWhatsApp.active) this.pingServer();
  },
  methods: {
    pingServer() {
      this.loadingQR = true;

      this.$socket.emit("whatsapp", {
        event: "getqr",
        room: this.getAgent.uid,
        projectId: app.projectId,
      });
    },
    async generateQR(paramQr) {
      try {
        let qr = new QRious({
          value: paramQr,
          size: 300,
        });

        this.qr = qr.toDataURL();
      } catch (error) {
        this.generateQRError = error.message || "Ocurrió un error al generar WhatsApp QR";
      } finally {
        this.loadingQR = false;
      }
    },
    closeDialog() {
      this.$emit("update:dialog", false);
    },
  },
};
</script>
