var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { scrollable: "", "max-width": "800" },
      on: {
        "click:outside": _vm.closeDialog,
        keydown: function($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          ) {
            return null
          }
          return _vm.closeDialog($event)
        }
      },
      model: {
        value: _vm.dialog,
        callback: function($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _c(
        "v-card",
        { staticClass: "py-3" },
        [
          _c(
            "v-card-title",
            { staticClass: "subheadline" },
            [
              _c("v-icon", { attrs: { small: "" } }, [
                _vm._v("far fa-lightbulb")
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "ml-2" }, [_vm._v("Tips")]),
              _vm._v(" "),
              _c("v-spacer"),
              _vm._v(" "),
              _c(
                "v-btn",
                { attrs: { icon: "" }, on: { click: _vm.closeDialog } },
                [
                  _c("v-icon", { attrs: { small: "" } }, [
                    _vm._v("fas fa-times")
                  ])
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card-text",
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { staticClass: "d-flex", attrs: { cols: "12" } },
                    [
                      _c(
                        "span",
                        { staticClass: "circle primary white--text mr-2" },
                        [_vm._v("1")]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-row",
                        { staticClass: "pr-5" },
                        [
                          _c(
                            "v-col",
                            { staticClass: "pt-0", attrs: { cols: "12" } },
                            [
                              _c("h4", { staticClass: "font-weight-thin" }, [
                                _vm._v(
                                  "\n                Al escribir los siguientes "
                                ),
                                _c(
                                  "span",
                                  { staticClass: "font-weight-bold" },
                                  [_vm._v("hashtags")]
                                ),
                                _vm._v(
                                  " podrás realizar las\n                siguientes acciones para\n                "
                                ),
                                _c(
                                  "span",
                                  { staticClass: "font-weight-bold" },
                                  [
                                    _vm._v(
                                      "\n                  gestionar tus oportunidades\n                  "
                                    ),
                                    _c("span", { staticClass: "black--text" }, [
                                      _vm._v(
                                        "\n                    (cambiar estado, añadir etiquetas y establecer recordatorios)\n                  "
                                      )
                                    ])
                                  ]
                                ),
                                _vm._v(
                                  "\n                desde la caja de comentario o desde tus conversaciones en WhatsApp (previa integración).\n              "
                                )
                              ])
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            { staticClass: "pt-2", attrs: { cols: "12" } },
                            [
                              _c("v-simple-table", {
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function() {
                                      return [
                                        _c("thead", [
                                          _c("tr", [
                                            _c(
                                              "th",
                                              { staticClass: "text-left" },
                                              [
                                                _vm._v(
                                                  "\n                        Acción\n                      "
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "th",
                                              { staticClass: "text-center" },
                                              [
                                                _vm._v(
                                                  "\n                        Hashtag\n                      "
                                                )
                                              ]
                                            )
                                          ])
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "tbody",
                                          [
                                            _c("tr", [
                                              _c("td", [
                                                _vm._v(
                                                  "\n                        Crear una nueva oportunidad desde una conversación en WhatsApp\n                      "
                                                )
                                              ]),
                                              _vm._v(" "),
                                              _c("td", [_vm._v("#n")])
                                            ]),
                                            _vm._v(" "),
                                            _vm._l(
                                              _vm.getActiveTicketStates,
                                              function(t) {
                                                return [
                                                  t.hashtag
                                                    ? _c("tr", { key: t.id }, [
                                                        _c("td", [
                                                          _vm._v(
                                                            "\n                          Actualizar estado a "
                                                          ),
                                                          _c("strong", [
                                                            _vm._v(
                                                              _vm._s(t.name)
                                                            )
                                                          ])
                                                        ]),
                                                        _vm._v(" "),
                                                        _c("td", [
                                                          _vm._v(
                                                            _vm._s(t.hashtag)
                                                          )
                                                        ])
                                                      ])
                                                    : _vm._e()
                                                ]
                                              }
                                            ),
                                            _vm._v(" "),
                                            _vm._l(_vm.getActiveTags, function(
                                              t
                                            ) {
                                              return [
                                                t.hashtag
                                                  ? _c("tr", { key: t.id }, [
                                                      _c(
                                                        "td",
                                                        [
                                                          _vm._v(
                                                            "\n                          Agregar etiqueta "
                                                          ),
                                                          _c(
                                                            "v-chip",
                                                            {
                                                              attrs: {
                                                                small: "",
                                                                color: t.color
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(t.name)
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      ),
                                                      _vm._v(" "),
                                                      _c("td", [
                                                        _vm._v(
                                                          _vm._s(t.hashtag)
                                                        )
                                                      ])
                                                    ])
                                                  : _vm._e()
                                              ]
                                            }),
                                            _vm._v(" "),
                                            _vm._l(_vm.reminderParams, function(
                                              t
                                            ) {
                                              return [
                                                t.hashtag
                                                  ? _c("tr", { key: t.id }, [
                                                      _c("td", [
                                                        _vm._v(
                                                          "Establecer recordatorio en "
                                                        ),
                                                        _c("strong", [
                                                          _vm._v("N")
                                                        ]),
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(t.name) +
                                                            "(s)"
                                                        )
                                                      ]),
                                                      _vm._v(" "),
                                                      _c("td", [
                                                        _vm._v(
                                                          "Ejm: En " +
                                                            _vm._s(t.amount) +
                                                            " " +
                                                            _vm._s(t.name) +
                                                            "(s) => " +
                                                            _vm._s(t.hashtag)
                                                        )
                                                      ])
                                                    ])
                                                  : _vm._e()
                                              ]
                                            })
                                          ],
                                          2
                                        )
                                      ]
                                    },
                                    proxy: true
                                  }
                                ])
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }