export default {
  getSegments(state) {
    return state.segments;
  },
  getSegmentsDownloads(state) {
    return state.segmentsDownloads;
  },
  getSegmentsAutomations(state) {
    return state.segmentsAutomations;
  },
};
