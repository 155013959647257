import * as MutationsType from "./mutationsType";

export default {
  [MutationsType.SET_INTEGRATION_CONFIG](state, payload) {
    state.integration = payload;
  },
  [MutationsType.SET_INTEGRATION_ZOOM_WEBINARS](state, payload) {
    state.integrationZoomWebinars = payload;
  },
  [MutationsType.SET_INTEGRATION_SENDGRID](state, payload) {
    state.integrationSendGrid = payload;
  },
  [MutationsType.SET_INTEGRATION_WHATSAPP](state, payload) {
    state.integrationWhatsApp = payload;
  },
};
