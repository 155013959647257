<template>
  <v-list-item key="darkmode" @click.stop="toggle_dark_mode_m">
    <v-list-item-avatar>
      <v-icon :class="toggle_dark_mode ? 'fas fa-moon' : 'far fa-moon'" text="Modo oscuro"></v-icon>
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title>Modo oscuro</v-list-item-title>
      <v-list-item-subtitle>(Beta)</v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-action>
      <v-switch v-model="toggle_dark_mode" color="primary" @click="toggle_dark_mode_m"></v-switch>
    </v-list-item-action>
  </v-list-item>
</template>
<script>
export default {
  name: "DarkMode",
  data() {
    return {
      darkMode: null,
      allow_receive_notification: false,
      assign_tickets: false,
      suscribed_assign_tickets: null,
      load: {
        assign_tickets: false,
      },
    };
  },
  computed: {
    toggle_dark_mode: {
      get() {
        return this.$vuetify.theme.dark;
      },
      set() {
        this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
        localStorage.setItem("dark_theme", this.$vuetify.theme.dark.toString());
      },
    },
  },

  // watch: {},
  methods: {
    toggle_dark_mode_m: function () {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      localStorage.setItem("dark_theme", this.$vuetify.theme.dark.toString());
    },
  },
};
</script>
