var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", "max-width": "400" },
      model: {
        value: _vm.dialog,
        callback: function($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _c(
        "v-card",
        { staticClass: "py-3" },
        [
          _c(
            "v-card-title",
            { staticClass: "subheadline" },
            [
              _c(
                "div",
                [
                  _c("v-img", {
                    staticClass: "mr-2",
                    attrs: {
                      src: require("@/assets/social_icons/" + _vm.id + ".png"),
                      width: "20px"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c("span", { staticClass: "ml-0" }, [_vm._v(_vm._s(_vm.name))]),
              _vm._v(" "),
              _c("v-spacer"),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  attrs: { icon: "" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("update:dialog", false)
                    }
                  }
                },
                [
                  _c("v-icon", { attrs: { small: "" } }, [
                    _vm._v("fas fa-times")
                  ])
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card-text",
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { staticClass: "text-center", attrs: { cols: "12" } },
                    [
                      _c("h3", { staticClass: "font-weight-thin" }, [
                        _vm._v(
                          "\n            Esta integración está deshabilitada para tu plan actual. Para más información puedes contactarte con un\n            asesor de Apolo CRM.\n          "
                        )
                      ])
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }