export default {
  getIntegration(state) {
    return state.integration;
  },
  getIntegrationZoomWebinars(state) {
    return state.integrationZoomWebinars;
  },
  getIntegrationSendGrid(state) {
    return state.integrationSendGrid;
  },
  getIntegrationWhatsApp(state) {
    return state.integrationWhatsApp;
  },
};
