var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    [
      _c(
        "v-container",
        { staticClass: "rounded-lg" },
        [
          _c(
            "v-row",
            { staticClass: "py-4" },
            [
              _c(
                "v-col",
                {
                  attrs: {
                    cols: "12",
                    sm: "10",
                    md: "8",
                    "offset-sm": "1",
                    "offset-md": "2"
                  }
                },
                [
                  _c(
                    "h2",
                    {
                      staticClass:
                        "text-h4 primary--text pb-4 font-weight-black pt-8 pt-md-0 pt-lg-0"
                    },
                    [
                      _vm._v(
                        "\n          Nuevo presupuesto de campaña\n        "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-card",
                    { staticClass: "px-4 py-6 rounded-lg" },
                    [
                      _c("h5", { staticClass: "font-weight-regular mb-5" }, [
                        _vm._v(
                          "\n            Cada vez que se registre un nuevo presupuesto de campaña, podremos enlazar con el reporte de canales en la\n            sección de estadísticas.\n          "
                        )
                      ]),
                      _vm._v(" "),
                      _c("CampaignBudgetForm", { attrs: { action: "create" } })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }