const roles = {
  ADVISOR: "advisor",
  SUPERVISOR: "supervisor",
  ADMIN: "admin",
  SUPER_ADMIN: "super_admin",
  ENTREPRENEUR: "entrepreneur",
};

const notificationTypes = {
  ASSIGN_TICKETS: "assign_tickets",
  START_EVENTS: "start_events",
};

const rules = {
  REQUIRED: (v) => !!v || "Campo requerido",
  EMAIL: (v) =>
    !v ||
    (!!v &&
      // eslint-disable-next-line
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        v,
      )) ||
    "Correo electrónico no válido",
  NUMBER: (v) => /^\d(\d)*$/.test(v) || "Cantidad no válida",
  STRING_LOWERCASE: (v) => /^[a-z]([a-z])*$/.test(v) || "Sólo letras mínusculas",
  GREATER_THAN_ZERO: (v) => v > 0 || "Debe ser mayor a cero",
  CURRENCY: (v) => /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/.test(v) || "Monto no válido",
};

const ticketStates = {
  NUEVO: "nuevo",
  ORDEN_CREADA: "orden-creada",
};

const orderStates = {
  EN_ESPERA: "en-espera",
  TERMINADO: "terminado",
};

const activityTypes = {
  COMMENT: 0,
  CALL: 1,
  MESSAGE: 2,
  CONTACT: 3,
  EVENT: 4,
};

export { roles, rules, notificationTypes, ticketStates, orderStates, activityTypes };
