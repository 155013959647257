var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", "max-width": "600" },
      model: {
        value: _vm.dialog,
        callback: function($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _c(
        "v-card",
        { staticClass: "py-3" },
        [
          _c(
            "v-card-title",
            { staticClass: "subheadline" },
            [
              _c("div", { staticClass: "ml-0 mt-2" }, [
                _c("span", [_vm._v("Asignación por Porcentaje")]),
                _vm._v(" "),
                _c("br"),
                _vm._v(" "),
                _c("h6", { staticClass: "text-secondary caption" }, [
                  _vm._v(
                    "\n          Está funcionalidad es válida para el registro de oportunidades de forma automática\n        "
                  )
                ])
              ]),
              _vm._v(" "),
              _c("v-spacer"),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  attrs: { icon: "" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("update:dialog", false)
                    }
                  }
                },
                [
                  _c("v-icon", { attrs: { small: "" } }, [
                    _vm._v("fas fa-times")
                  ])
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card-text",
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c("v-select", {
                        staticClass: "px-1",
                        attrs: {
                          value: "default",
                          items: _vm.getProjects,
                          label: "Proyecto",
                          "item-text": "id",
                          "item-value": "id"
                        },
                        model: {
                          value: _vm.projectId,
                          callback: function($$v) {
                            _vm.projectId = $$v
                          },
                          expression: "projectId"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("v-col", { staticClass: "pb-0", attrs: { cols: "12" } }, [
                    _c("h4", { staticClass: "px-1" }, [
                      _vm._v(
                        'Agentes a cargo del proyecto "' +
                          _vm._s(_vm.project.name || _vm.project.id) +
                          '"'
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c(
                        "v-form",
                        {
                          ref: "formAllocationByPortion",
                          attrs: { "lazy-validation": "" }
                        },
                        [
                          _vm._l(_vm.portionsPerAgents, function(p, i) {
                            return [
                              _c(
                                "v-row",
                                { key: i, staticClass: "px-2" },
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "8" } },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          value:
                                            _vm.getAllAgentsMap[_vm.agents[i]]
                                              .full_name,
                                          label: "Agente",
                                          readonly: ""
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "4" } },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          label: "Porcentaje de oportunidades",
                                          suffix: "%",
                                          rules: [
                                            _vm.$rules.REQUIRED,
                                            _vm.$rules.GREATER_THAN_ZERO
                                          ]
                                        },
                                        on: {
                                          input: function($event) {
                                            return _vm.onChangePorcent(i)
                                          }
                                        },
                                        model: {
                                          value:
                                            _vm.portionsPerAgents[i].porcent,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.portionsPerAgents[i],
                                              "porcent",
                                              _vm._n($$v)
                                            )
                                          },
                                          expression:
                                            "portionsPerAgents[i].porcent"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ]
                          })
                        ],
                        2
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.responseMessage
                    ? _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c(
                            "v-alert",
                            {
                              attrs: {
                                dense: "",
                                text: "",
                                type: _vm.responseStatus
                              }
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.responseMessage) +
                                  "\n          "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card-actions",
            { staticClass: "mr-2" },
            [
              _c("v-spacer"),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  attrs: { rounded: "", text: "" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("update:dialog", false)
                    }
                  }
                },
                [_vm._v("Cancelar")]
              ),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  attrs: {
                    loading: _vm.loading,
                    color: "primary",
                    rounded: ""
                  },
                  on: { click: _vm.onSave }
                },
                [_vm._v("\n        Guardar\n      ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }