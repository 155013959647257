import Vue from "vue";
import * as MutationsType from "./mutationsType";

export default {
  [MutationsType.SET_PROJECTS](state, payload) {
    state.projects = payload;
  },
  [MutationsType.UPDATE_PROJECT](state, payload) {
    let ind = state.projects.findIndex((p) => {
      return p.id == payload.id;
    });
    if (ind >= 0) Vue.set(state.projects, ind, { ...state.projects[ind], ...payload });
  },
};
