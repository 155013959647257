var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { staticClass: "fill-height text-center px-3 pb-2" },
    [
      _vm._l(_vm.products, function(product, i) {
        return _c(
          "v-col",
          { key: i, staticClass: "pa-0", attrs: { cols: "12" } },
          [
            _c(
              "v-row",
              { staticClass: "px-3 pb-2" },
              [
                _c(
                  "v-col",
                  {
                    staticClass: "pb-0",
                    attrs: { cols: "12", sm: _vm.formatOneRow ? 3 : 12 }
                  },
                  [
                    _c("v-autocomplete", {
                      staticStyle: { "font-size": "15px" },
                      attrs: {
                        items: _vm.productsToSelect[i],
                        value: product.id,
                        label: "Producto " + (i + 1),
                        readonly: _vm.isReadOnly,
                        rules: [_vm.$rules.REQUIRED],
                        "item-text": "name",
                        "item-value": "id"
                      },
                      on: {
                        change: function($event) {
                          return _vm.changeProductsToSelect(i, $event)
                        }
                      },
                      model: {
                        value: product.id,
                        callback: function($$v) {
                          _vm.$set(product, "id", $$v)
                        },
                        expression: "product.id"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "v-col",
                  { attrs: { cols: "4", sm: _vm.formatOneRow ? 2 : 2 } },
                  [
                    _c("v-text-field", {
                      attrs: {
                        rules: [
                          _vm.$rules.NUMBER,
                          _vm.$rules.GREATER_THAN_ZERO
                        ],
                        readonly: _vm.isReadOnly,
                        label: "Cantidad",
                        placeholder: "0"
                      },
                      on: {
                        input: function($event) {
                          return _vm.updateSubtotal(i)
                        }
                      },
                      model: {
                        value: product.quantity,
                        callback: function($$v) {
                          _vm.$set(product, "quantity", _vm._n($$v))
                        },
                        expression: "product.quantity"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "v-col",
                  { attrs: { cols: "4", sm: _vm.formatOneRow ? 3 : 4 } },
                  [
                    _c("v-text-field", {
                      attrs: {
                        rules: [
                          _vm.$rules.CURRENCY,
                          _vm.$rules.GREATER_THAN_ZERO
                        ],
                        prefix: _vm.$currency.symbol,
                        readonly: _vm.isReadOnly,
                        label: "Precio",
                        placeholder: "0"
                      },
                      on: {
                        input: function($event) {
                          return _vm.updateSubtotal(i)
                        }
                      },
                      model: {
                        value: product.price,
                        callback: function($$v) {
                          _vm.$set(product, "price", _vm._n($$v))
                        },
                        expression: "product.price"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "v-col",
                  { attrs: { cols: "4", sm: _vm.formatOneRow ? 3 : 4 } },
                  [
                    _c("v-text-field", {
                      attrs: {
                        value: _vm.$formatCurrency(product.subtotal, false),
                        prefix: _vm.$currency.symbol,
                        readonly: true,
                        label: "Subtotal",
                        placeholder: "0"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                !_vm.isReadOnly
                  ? _c(
                      "v-col",
                      {
                        staticClass: "text-right my-auto",
                        attrs: { cols: "4", sm: _vm.formatOneRow ? 1 : 1 }
                      },
                      [
                        _c(
                          "v-tooltip",
                          {
                            attrs: { bottom: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function(ref) {
                                    var on = ref.on
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          {
                                            attrs: { icon: "", small: "" },
                                            on: {
                                              click: function($event) {
                                                return _vm.removeProduct(i)
                                              }
                                            }
                                          },
                                          on
                                        ),
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { small: "" } },
                                            [_vm._v("far fa-trash-alt")]
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          },
                          [
                            _vm._v(" "),
                            _c("span", [_vm._v("Eliminar producto")])
                          ]
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "v-col",
                  { staticClass: "mt-2", attrs: { cols: "12" } },
                  [_c("v-divider")],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      }),
      _vm._v(" "),
      _vm.products && _vm.products.length
        ? _c(
            "v-col",
            { staticClass: "pa-0 pb-2", attrs: { cols: "12" } },
            [
              _c(
                "v-row",
                { staticClass: "px-3" },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "py-0 text-right",
                      attrs: { cols: "4", sm: _vm.formatOneRow ? 8 : 6 }
                    },
                    [
                      _c(
                        "p",
                        {
                          staticClass:
                            "my-auto pt-5 font-weight-bold grey--text text--darken-2"
                        },
                        [_vm._v("Total:")]
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    {
                      staticClass: "py-0",
                      attrs: { cols: "4", sm: _vm.formatOneRow ? 3 : 4 }
                    },
                    [
                      _c("v-text-field", {
                        attrs: {
                          value: _vm.$formatCurrency(_vm.total, false),
                          readonly: true,
                          prefix: _vm.$currency.symbol,
                          placeholder: "0"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _c("v-col", { attrs: { cols: "12" } }, [
            _c("h5", { staticClass: "font-weight-thin text--secondary" }, [
              _vm._v("No hay productos asociados")
            ])
          ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }