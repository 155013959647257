var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    { attrs: { id: "segments" } },
    [
      _vm.getAgent && _vm.getAgents && _vm.getSegments
        ? _c(
            "v-container",
            { staticClass: "pt-4 pt-sm-10" },
            [
              _c(
                "v-row",
                { staticClass: "d-flex align-center" },
                [
                  _c("v-col", { attrs: { cols: "12", sm: "6", md: "9" } }, [
                    _c(
                      "p",
                      {
                        staticClass:
                          "text-h4 primary--text mb-0 font-weight-black hidden-sm-and-down"
                      },
                      [_vm._v("Segmentos")]
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    {
                      staticClass: "text-right",
                      attrs: { cols: "12", sm: "6", md: "3" }
                    },
                    [
                      _c(
                        "v-menu",
                        {
                          attrs: { "offset-y": "", bottom: "", left: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        { attrs: { outlined: "", icon: "" } },
                                        on
                                      ),
                                      [
                                        _c(
                                          "v-icon",
                                          { attrs: { size: "16" } },
                                          [_vm._v("fa fa-ellipsis-v")]
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            2365923669
                          )
                        },
                        [
                          _vm._v(" "),
                          _c(
                            "v-list",
                            [
                              _c(
                                "v-list-item",
                                {
                                  attrs: { to: { name: "segment_downloads" } }
                                },
                                [
                                  _c("v-list-item-title", [_vm._v("Descargas")])
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-list-item",
                                {
                                  attrs: { to: { name: "segment_automations" } }
                                },
                                [
                                  _c("v-list-item-title", [
                                    _vm._v("Automatizaciones")
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "px-4 hidden-md-and-up",
                      attrs: { cols: "12" }
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            to: { name: "segment_register" },
                            color: "primary",
                            "elevation-12": "",
                            block: ""
                          }
                        },
                        [
                          _c(
                            "span",
                            [
                              _c(
                                "v-icon",
                                { staticClass: "mr-2", attrs: { size: "16" } },
                                [_vm._v("fa-plus")]
                              ),
                              _vm._v(" Nuevo segmento ")
                            ],
                            1
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c(
                        "v-card",
                        { staticClass: "pa-4 mb-5 rounded-lg" },
                        [
                          _c("v-data-table", {
                            attrs: {
                              headers: _vm.headers,
                              items: _vm.getSegments,
                              "show-expand": "",
                              "sort-by": "",
                              dense: "",
                              "items-per-page": 40,
                              "hide-default-footer": ""
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "item.module",
                                  fn: function(ref) {
                                    var item = ref.item
                                    return [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(_vm.modulesName[item.module]) +
                                          "\n            "
                                      )
                                    ]
                                  }
                                },
                                {
                                  key: "item.filters",
                                  fn: function(ref) {
                                    var item = ref.item
                                    return [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(
                                            item.filters
                                              .map(function(a) {
                                                return a.label
                                              })
                                              .join(", ")
                                          ) +
                                          "\n            "
                                      )
                                    ]
                                  }
                                },
                                {
                                  key: "item.columns",
                                  fn: function(ref) {
                                    var item = ref.item
                                    return [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(
                                            item.columns
                                              .map(function(a) {
                                                return a.header
                                              })
                                              .join(", ")
                                          ) +
                                          "\n            "
                                      )
                                    ]
                                  }
                                },
                                {
                                  key: "item.operations",
                                  fn: function(ref) {
                                    var item = ref.item
                                    return [
                                      _c(
                                        "div",
                                        { style: { width: "100px" } },
                                        [
                                          _c(
                                            "v-tooltip",
                                            {
                                              attrs: { bottom: "" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function(ref) {
                                                      var on = ref.on
                                                      return [
                                                        _c(
                                                          "v-btn",
                                                          _vm._g(
                                                            {
                                                              attrs: {
                                                                text: "",
                                                                icon: ""
                                                              },
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.editSegment(
                                                                    item.id
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            on
                                                          ),
                                                          [
                                                            _c(
                                                              "v-icon",
                                                              {
                                                                attrs: {
                                                                  color:
                                                                    "primary",
                                                                  dark: "",
                                                                  small: ""
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "far fa-edit"
                                                                )
                                                              ]
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            },
                                            [
                                              _vm._v(" "),
                                              _c("span", [
                                                _vm._v("Editar segmento")
                                              ])
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-menu",
                                            {
                                              attrs: {
                                                "offset-y": "",
                                                bottom: "",
                                                left: ""
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function(ref) {
                                                      var on = ref.on
                                                      return [
                                                        _c(
                                                          "v-btn",
                                                          _vm._g(
                                                            {
                                                              attrs: {
                                                                small: "",
                                                                icon: ""
                                                              }
                                                            },
                                                            on
                                                          ),
                                                          [
                                                            _c(
                                                              "v-icon",
                                                              {
                                                                attrs: {
                                                                  small: ""
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "fa fa-ellipsis-v"
                                                                )
                                                              ]
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            },
                                            [
                                              _vm._v(" "),
                                              _c(
                                                "v-list",
                                                [
                                                  _c(
                                                    "v-list-item",
                                                    {
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.onDownload(
                                                            item.id
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _c("v-list-item-title", [
                                                        _vm._v("Descargar")
                                                      ])
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-list-item",
                                                    {
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.deleteSegment(
                                                            item.id
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _c("v-list-item-title", [
                                                        _vm._v("Eliminar")
                                                      ])
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                },
                                {
                                  key: "expanded-item",
                                  fn: function(ref) {
                                    var item = ref.item
                                    return [
                                      _c(
                                        "td",
                                        {
                                          staticClass: "px-8 py-5",
                                          attrs: {
                                            colspan: _vm.headers.length + 1
                                          }
                                        },
                                        [
                                          _c(
                                            "v-row",
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  attrs: {
                                                    cols: "12",
                                                    sm: "8",
                                                    md: "9"
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "v-row",
                                                    [
                                                      _c(
                                                        "v-col",
                                                        {
                                                          attrs: {
                                                            cols: "12",
                                                            sm: "6"
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "grey--text text--darken-2"
                                                            },
                                                            [
                                                              _vm._v(
                                                                "ID Segmento:"
                                                              )
                                                            ]
                                                          ),
                                                          _vm._v(
                                                            "\n                        " +
                                                              _vm._s(item.id) +
                                                              "\n                      "
                                                          )
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "v-col",
                                                        {
                                                          attrs: {
                                                            cols: "12",
                                                            sm: "6"
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "grey--text text--darken-2"
                                                            },
                                                            [
                                                              _vm._v(
                                                                "Generado por:"
                                                              )
                                                            ]
                                                          ),
                                                          _vm._v(
                                                            "\n                        " +
                                                              _vm._s(
                                                                item.created_uid
                                                                  ? _vm
                                                                      .getAllAgentsMap[
                                                                      item
                                                                        .created_uid
                                                                    ].full_name
                                                                  : "-"
                                                              ) +
                                                              "\n                      "
                                                          )
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "v-col",
                                                        {
                                                          attrs: { cols: "12" }
                                                        },
                                                        [
                                                          _c(
                                                            "h5",
                                                            {
                                                              staticClass:
                                                                "body-1 my-2"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  item.name
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "v-col",
                                                        {
                                                          staticClass: "py-0",
                                                          attrs: {
                                                            cols: "12",
                                                            sm: "8"
                                                          }
                                                        },
                                                        [
                                                          _c("SegmentDetail", {
                                                            attrs: {
                                                              segment: item
                                                            }
                                                          })
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "v-col",
                                                {
                                                  attrs: {
                                                    cols: "12",
                                                    sm: "4",
                                                    md: "3"
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "v-row",
                                                    [
                                                      _c(
                                                        "v-col",
                                                        {
                                                          attrs: { cols: "12" }
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "grey--text text--darken-2"
                                                            },
                                                            [
                                                              _vm._v(
                                                                "Última actualización:"
                                                              )
                                                            ]
                                                          ),
                                                          _vm._v(
                                                            "\n                        " +
                                                              _vm._s(
                                                                item.updated_at
                                                                  ? _vm.$formatTimestamp(
                                                                      item.updated_at
                                                                    )
                                                                  : "Aún no se ha ejecutado"
                                                              ) +
                                                              "\n                      "
                                                          )
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "v-col",
                                                        {
                                                          staticClass: "d-flex",
                                                          attrs: { cols: "12" }
                                                        },
                                                        [
                                                          _c(
                                                            "h5",
                                                            {
                                                              staticClass:
                                                                "body-1 mr-2"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  item.counter ||
                                                                    0
                                                                )
                                                              )
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "h6",
                                                            {
                                                              staticClass:
                                                                "caption my-auto"
                                                            },
                                                            [
                                                              _vm._v(
                                                                "registros"
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "v-col",
                                                        {
                                                          attrs: { cols: "12" }
                                                        },
                                                        [
                                                          _c(
                                                            "v-btn",
                                                            {
                                                              attrs: {
                                                                loading:
                                                                  _vm.loadingToSync &&
                                                                  _vm.segmentSelected &&
                                                                  _vm
                                                                    .segmentSelected
                                                                    .id ===
                                                                    item.id,
                                                                color:
                                                                  "primary",
                                                                rounded: "",
                                                                small: "",
                                                                block: ""
                                                              },
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.onSync(
                                                                    item.id
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "v-icon",
                                                                {
                                                                  staticClass:
                                                                    "mr-2",
                                                                  attrs: {
                                                                    dark: "",
                                                                    "x-small":
                                                                      ""
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "fas fa-sync-alt"
                                                                  )
                                                                ]
                                                              ),
                                                              _vm._v(
                                                                " Actualizar segmento\n                        "
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "v-col",
                                                        {
                                                          staticClass: "mt-5",
                                                          attrs: { cols: "12" }
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "grey--text text--darken-2"
                                                            },
                                                            [
                                                              _vm._v(
                                                                "Acciones disponibles:"
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "v-col",
                                                        {
                                                          attrs: { cols: "12" }
                                                        },
                                                        [
                                                          _c(
                                                            "v-btn",
                                                            {
                                                              attrs: {
                                                                loading:
                                                                  _vm.loadingToDownload &&
                                                                  _vm.segmentSelected &&
                                                                  _vm
                                                                    .segmentSelected
                                                                    .id ===
                                                                    item.id,
                                                                color: "accent",
                                                                rounded: "",
                                                                small: "",
                                                                block: ""
                                                              },
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.onDownload(
                                                                    item.id
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "v-icon",
                                                                {
                                                                  staticClass:
                                                                    "mr-2",
                                                                  attrs: {
                                                                    dark: "",
                                                                    "x-small":
                                                                      ""
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "fas fa-download"
                                                                  )
                                                                ]
                                                              ),
                                                              _vm._v(
                                                                " Descargar resultados\n                        "
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _vm._v(" "),
                                                          item.columns.find(
                                                            function(c) {
                                                              return (
                                                                c.key ===
                                                                "phone"
                                                              )
                                                            }
                                                          )
                                                            ? _c(
                                                                "v-btn",
                                                                {
                                                                  staticClass:
                                                                    "mt-3",
                                                                  attrs: {
                                                                    loading:
                                                                      _vm.loadingToSendWhatsApp &&
                                                                      _vm.segmentSelected &&
                                                                      _vm
                                                                        .segmentSelected
                                                                        .id ===
                                                                        item.id,
                                                                    color:
                                                                      "#25d366",
                                                                    rounded: "",
                                                                    small: "",
                                                                    dark: "",
                                                                    block: ""
                                                                  },
                                                                  on: {
                                                                    click: function(
                                                                      $event
                                                                    ) {
                                                                      return _vm.sendMessageOnWhatsApp(
                                                                        item.id
                                                                      )
                                                                    }
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-icon",
                                                                    {
                                                                      staticClass:
                                                                        "mr-2",
                                                                      attrs: {
                                                                        dark:
                                                                          "",
                                                                        "x-small":
                                                                          ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "fab fa-whatsapp"
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _vm._v(
                                                                    " Enviar mensaje masivo\n                        "
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            : _vm._e()
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              2497972019
                            )
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  staticClass: "hidden-sm-and-down",
                  attrs: {
                    to: { name: "segment_register" },
                    fixed: "",
                    bottom: "",
                    right: "",
                    "x-large": "",
                    color: "primary",
                    "elevation-12": "",
                    rounded: ""
                  }
                },
                [
                  _c(
                    "span",
                    [
                      _c(
                        "v-icon",
                        { staticClass: "mr-2", attrs: { size: "16" } },
                        [_vm._v("fa-plus")]
                      ),
                      _vm._v(" Nuevo segmento ")
                    ],
                    1
                  )
                ]
              ),
              _vm._v(" "),
              _vm.dialogEditSegment && _vm.segmentSelected
                ? _c("EditSegmentModal", {
                    attrs: {
                      dialog: _vm.dialogEditSegment,
                      segment: _vm.segmentSelected
                    },
                    on: {
                      "update:dialog": function($event) {
                        _vm.dialogEditSegment = $event
                      },
                      "update:segment": function($event) {
                        _vm.segmentSelected = $event
                      }
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.dialogSendWhatsAppMessage && _vm.segmentSelected
                ? _c("SendWhatsAppMessageModal", {
                    attrs: {
                      dialog: _vm.dialogSendWhatsAppMessage,
                      segment: _vm.segmentSelected
                    },
                    on: {
                      "update:dialog": function($event) {
                        _vm.dialogSendWhatsAppMessage = $event
                      },
                      "update:segment": function($event) {
                        _vm.segmentSelected = $event
                      }
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.segmentSelected
                ? _c(
                    "v-dialog",
                    {
                      attrs: { persistent: "", "max-width": "400" },
                      model: {
                        value: _vm.dialogDeleteSegment,
                        callback: function($$v) {
                          _vm.dialogDeleteSegment = $$v
                        },
                        expression: "dialogDeleteSegment"
                      }
                    },
                    [
                      _c(
                        "v-card",
                        { staticClass: "py-3" },
                        [
                          _c(
                            "v-card-text",
                            [
                              _c(
                                "v-form",
                                { ref: "formDeleteSegment" },
                                [
                                  _c(
                                    "v-row",
                                    [
                                      _c("v-col", { attrs: { cols: "12" } }, [
                                        _c(
                                          "h3",
                                          { staticClass: "font-weight-thin" },
                                          [
                                            _vm._v(
                                              "\n                  ¿Está seguro que\n                  "
                                            ),
                                            _c(
                                              "span",
                                              {
                                                staticClass: "font-weight-bold"
                                              },
                                              [
                                                _vm._v(
                                                  " desea eliminar el segmento '" +
                                                    _vm._s(
                                                      _vm.segmentSelected.name
                                                    ) +
                                                    "'"
                                                )
                                              ]
                                            ),
                                            _vm._v(
                                              "\n                  ?\n                "
                                            )
                                          ]
                                        )
                                      ]),
                                      _vm._v(" "),
                                      _vm.errorFormDeleteSegment
                                        ? _c(
                                            "v-col",
                                            { attrs: { cols: "12" } },
                                            [
                                              _c(
                                                "v-alert",
                                                {
                                                  attrs: {
                                                    dense: "",
                                                    text: "",
                                                    type: "error"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                  " +
                                                      _vm._s(
                                                        _vm.errorFormDeleteSegment
                                                      ) +
                                                      "\n                "
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-card-actions",
                            { staticClass: "mr-2" },
                            [
                              _c("v-spacer"),
                              _vm._v(" "),
                              _c(
                                "v-btn",
                                {
                                  attrs: { rounded: "", text: "" },
                                  on: {
                                    click: function($event) {
                                      _vm.dialogDeleteSegment = false
                                    }
                                  }
                                },
                                [_vm._v("Cancelar")]
                              ),
                              _vm._v(" "),
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    loading: _vm.loadingToDelete,
                                    color: "red",
                                    rounded: "",
                                    dark: ""
                                  },
                                  on: { click: _vm.onDelete }
                                },
                                [_vm._v("\n            Eliminar\n          ")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        : _c("v-container", [_c("ShowLoading")], 1)
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }