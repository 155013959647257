var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-row",
        { staticClass: "justify-end mr-0 pb-1" },
        [
          _c(
            "v-btn",
            {
              attrs: { rounded: "", color: "primary" },
              on: {
                click: function($event) {
                  _vm.resetFormState()
                  _vm.dialogEnable = true
                }
              }
            },
            [
              _c("v-icon", { attrs: { size: "16" } }, [_vm._v("fa-plus")]),
              _vm._v(" "),
              _c("span", { staticClass: "hidden-sm-and-down ml-2" }, [
                _vm._v("Agregar estado")
              ])
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "pt-0", attrs: { cols: "12" } },
            [
              _c("v-simple-table", {
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function() {
                      return [
                        _c("thead", [
                          _c("tr", [
                            _c("th", { staticClass: "text-left" }, [
                              _vm._v("\n                #\n              ")
                            ]),
                            _vm._v(" "),
                            _c("th", { staticClass: "text-left" }, [
                              _vm._v("\n                Nombre\n              ")
                            ]),
                            _vm._v(" "),
                            _c("th", { staticClass: "text-left" }, [
                              _vm._v(
                                "\n                Descripción\n              "
                              )
                            ]),
                            _vm._v(" "),
                            _c("th", { staticClass: "text-left" }, [
                              _vm._v(
                                "\n                Abreviación\n              "
                              )
                            ]),
                            _vm._v(" "),
                            _c("th", { staticClass: "text-center" }, [
                              _vm._v(
                                "\n                Operaciones\n              "
                              )
                            ])
                          ])
                        ]),
                        _vm._v(" "),
                        _c(
                          "tbody",
                          _vm._l(_vm.getActiveTicketStates, function(
                            ticketState,
                            i
                          ) {
                            return _c("tr", { key: i }, [
                              _c("td", [_vm._v(_vm._s(i + 1))]),
                              _vm._v(" "),
                              _c("td", [_vm._v(_vm._s(ticketState.name))]),
                              _vm._v(" "),
                              _c("td", [
                                _vm._v(_vm._s(ticketState.description))
                              ]),
                              _vm._v(" "),
                              _c("td", { staticClass: "text-center" }, [
                                _vm._v(_vm._s(ticketState.hashtag))
                              ]),
                              _vm._v(" "),
                              _c("td", { staticClass: "text-right" }, [
                                !ticketState.default
                                  ? _c(
                                      "div",
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              text: "",
                                              small: "",
                                              color: "primary"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.openEdit(
                                                  ticketState.id
                                                )
                                              }
                                            }
                                          },
                                          [_vm._v("Editar ")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "v-menu",
                                          {
                                            attrs: { bottom: "", left: "" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "activator",
                                                  fn: function(ref) {
                                                    var on = ref.on
                                                    var attrs = ref.attrs
                                                    return [
                                                      _c(
                                                        "v-btn",
                                                        _vm._g(
                                                          _vm._b(
                                                            {
                                                              staticClass:
                                                                "my-auto mr-5",
                                                              attrs: {
                                                                "x-small": "",
                                                                icon: ""
                                                              }
                                                            },
                                                            "v-btn",
                                                            attrs,
                                                            false
                                                          ),
                                                          on
                                                        ),
                                                        [
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              attrs: {
                                                                "x-small": ""
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "fas fa-ellipsis-v"
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          },
                                          [
                                            _vm._v(" "),
                                            _c(
                                              "v-list",
                                              [
                                                _c(
                                                  "v-list-item",
                                                  {
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.openDelete(
                                                          ticketState.id
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _c("v-list-item-title", [
                                                      _vm._v("Eliminar")
                                                    ])
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ])
                            ])
                          }),
                          0
                        )
                      ]
                    },
                    proxy: true
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "400" },
          model: {
            value: _vm.dialogEnable,
            callback: function($$v) {
              _vm.dialogEnable = $$v
            },
            expression: "dialogEnable"
          }
        },
        [
          _c(
            "v-card",
            { staticClass: "py-3" },
            [
              _c(
                "v-card-text",
                [
                  _c(
                    "v-form",
                    { ref: "formState" },
                    [
                      _c(
                        "v-row",
                        [
                          _c("v-col", { attrs: { cols: "12" } }, [
                            _c("h3", { staticClass: "font-weight-thin" }, [
                              _vm._v(
                                "\n                " +
                                  _vm._s(
                                    _vm.stateSelected
                                      ? 'Editar estado "' +
                                          _vm.stateSelected.name +
                                          '"'
                                      : "Nuevo estado"
                                  ) +
                                  "\n              "
                              )
                            ])
                          ]),
                          _vm._v(" "),
                          !_vm.stateSelected
                            ? _c(
                                "v-col",
                                { attrs: { cols: "12" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      label: "Nombre",
                                      placeholder: " ",
                                      rules: _vm.rules.noRepeatStateName
                                    },
                                    model: {
                                      value: _vm.state.name,
                                      callback: function($$v) {
                                        _vm.$set(_vm.state, "name", $$v)
                                      },
                                      expression: "state.name"
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c("v-textarea", {
                                attrs: {
                                  label: "Descripción",
                                  rows: "2",
                                  placeholder: " ",
                                  rules: _vm.rules.required
                                },
                                model: {
                                  value: _vm.state.description,
                                  callback: function($$v) {
                                    _vm.$set(_vm.state, "description", $$v)
                                  },
                                  expression: "state.description"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            {
                              staticClass: "d-flex mt-4",
                              attrs: { cols: "12" }
                            },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  label: "Abreviación",
                                  hint: "Para acciones automáticas",
                                  placeholder: " ",
                                  rules: _vm.rules.noRepeatStateHashtag.concat([
                                    _vm.$rules.STRING_LOWERCASE
                                  ])
                                },
                                model: {
                                  value: _vm.state.hashtag,
                                  callback: function($$v) {
                                    _vm.$set(_vm.state, "hashtag", $$v)
                                  },
                                  expression: "state.hashtag"
                                }
                              }),
                              _vm._v(" "),
                              _c(
                                "v-alert",
                                {
                                  staticClass: "my-auto ml-3",
                                  attrs: {
                                    dense: "",
                                    text: "",
                                    color: "primary"
                                  }
                                },
                                [
                                  _vm._v(
                                    " #e" + _vm._s(_vm.state.hashtag) + " "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card-actions",
                { staticClass: "ma-2" },
                [
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: { rounded: "", text: "" },
                      on: {
                        click: function($event) {
                          _vm.dialogEnable = false
                        }
                      }
                    },
                    [_vm._v("Cancelar")]
                  ),
                  _vm._v(" "),
                  _vm.stateSelected
                    ? _c(
                        "v-btn",
                        {
                          attrs: {
                            loading: _vm.loading,
                            color: "primary",
                            rounded: ""
                          },
                          on: { click: _vm.edit }
                        },
                        [_vm._v("\n          Editar\n        ")]
                      )
                    : _c(
                        "v-btn",
                        {
                          attrs: {
                            loading: _vm.loading,
                            color: "primary",
                            rounded: ""
                          },
                          on: { click: _vm.save }
                        },
                        [_vm._v("\n          Guardar\n        ")]
                      )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.stateSelected
        ? _c(
            "v-dialog",
            {
              attrs: { persistent: "", "max-width": "400" },
              model: {
                value: _vm.dialogDelete,
                callback: function($$v) {
                  _vm.dialogDelete = $$v
                },
                expression: "dialogDelete"
              }
            },
            [
              _c(
                "v-card",
                { staticClass: "py-3" },
                [
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "v-form",
                        { ref: "formDeleteState" },
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12" } },
                                [
                                  _c(
                                    "h3",
                                    { staticClass: "font-weight-thin" },
                                    [
                                      _vm._v(
                                        "\n                ¿Está seguro que\n                "
                                      ),
                                      _c(
                                        "span",
                                        { staticClass: "font-weight-bold" },
                                        [
                                          _vm._v(
                                            "desea eliminar el estado con " +
                                              _vm._s(_vm.stateSelected.name)
                                          )
                                        ]
                                      ),
                                      _vm._v("?.\n              ")
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "h3",
                                    { staticClass: "font-weight-thin py-3" },
                                    [
                                      _vm._v(
                                        "\n                Para completar está acción debe seleccionar el nuevo estado que reemplazará al estado que va a\n                eliminar\n              "
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("v-select", {
                                    attrs: {
                                      label: "Reemplazar por",
                                      items: _vm.ticketStates,
                                      "item-text": "name",
                                      "item-value": "id",
                                      rules: _vm.rules.required,
                                      "return-object": ""
                                    },
                                    model: {
                                      value: _vm.stateToReplace,
                                      callback: function($$v) {
                                        _vm.stateToReplace = $$v
                                      },
                                      expression: "stateToReplace"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _vm.errorFormDelete
                                ? _c(
                                    "v-col",
                                    { attrs: { cols: "12" } },
                                    [
                                      _c(
                                        "v-alert",
                                        {
                                          attrs: {
                                            dense: "",
                                            text: "",
                                            type: "error"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(_vm.errorFormDelete) +
                                              "\n              "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-card-actions",
                    { staticClass: "mr-2" },
                    [
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          attrs: { rounded: "", text: "" },
                          on: {
                            click: function($event) {
                              _vm.dialogDelete = false
                            }
                          }
                        },
                        [_vm._v("Cancelar")]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            loading: _vm.loading,
                            color: "red",
                            rounded: "",
                            dark: ""
                          },
                          on: {
                            click: function($event) {
                              return _vm.remove(_vm.stateSelected)
                            }
                          }
                        },
                        [_vm._v("Eliminar")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }