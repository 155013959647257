<template>
  <div>
    <Card id="whatsapp" name="WhatsApp" @open="open" />

    <IntegrationDisabled
      v-if="dialogIntegrationDisabled"
      id="whatsapp"
      :dialog.sync="dialogIntegrationDisabled"
      name="WhatsApp"
    />
  </div>
</template>
<script>
import IntegrationDisabled from "@/views/settings/modals/IntegrationDisabled";
import Card from "@/components/integrations/Card";

export default {
  name: "WhatsAppCard",
  components: {
    IntegrationDisabled,
    Card,
  },
  props: {
    dialogWhatsapp: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialogIntegrationDisabled: false,
    };
  },
  methods: {
    open() {
      if (this.isDisabled) this.dialogIntegrationDisabled = true;
      else this.$emit("update:dialogWhatsapp", true);
    },
  },
};
</script>
