<template>
  <v-app id="tickets-downloads">
    <v-container v-if="getTicketDownloads && getAgents" class="mb-10 pb-10">
      <v-row class="pt-4">
        <v-col cols="12">
          <h2 class="text-h4 primary--text pb-4 font-weight-black pt-8 pt-md-0 pt-lg-0">
            Descargas de Oportunidades
          </h2>
        </v-col>
        <v-col cols="12 hidden-md-and-up">
          <v-btn :to="{ name: 'ticket_downloads_register' }" color="primary" elevation-12 block>
            <span>
              <v-icon size="16" class="mr-2">fas fa-file-download</v-icon>
              Nueva descarga
            </span>
          </v-btn>
        </v-col>
        <v-col cols="12">
          <v-card class="pa-4 rounded-lg fill-height">
            <v-row class="justify-center">
              <v-col v-if="getTicketDownloads.length" cols="12" class="py-0">
                <v-data-table
                  :headers="headers"
                  :items="getTicketDownloads"
                  :items-per-page="50"
                  dense
                  sort-by
                  :mobile-breakpoint="0"
                  hide-default-footer
                  show-expand
                  class="text-headers-colors"
                >
                  <template v-slot:item.agent="{ item }">
                    {{ item.filters.agent_id ? getAllAgentsMap[item.filters.agent_id].full_name : "todos" }}
                  </template>
                  <template v-slot:item.counter="{ item }">
                    {{ item.counter || 0 }}
                  </template>
                  <template v-slot:item.operations="{ item }">
                    <v-tooltip v-if="item.state === 'completado' && item.counter" bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn text icon @click.stop.prevent="download(item.url)" v-on="on">
                          <v-icon color="primary" dark small>fas fa-download</v-icon>
                        </v-btn>
                      </template>
                      <span>Descargar archivo</span>
                    </v-tooltip>
                    <div v-if="['descargando', 'procesando', 'convirtiendo'].includes(item.state)" class="text-center">
                      <v-progress-circular :size="20" color="primary" indeterminate></v-progress-circular>
                    </div>
                  </template>
                  <template v-slot:expanded-item="{ item }">
                    <td :colspan="headers.length" class="pa-8">
                      <v-row>
                        <v-col cols="12" sm="4" class="pt-0">
                          <span class="grey--text text--darken-2">ID Descarga:</span>
                          <v-chip color="accent" :ripple="false" small>{{ item.id }}</v-chip>
                        </v-col>
                        <v-col cols="12" sm="4" class="pt-0">
                          <span class="grey--text text--darken-2">Generado por:</span>
                          {{ item.created_uid ? getAllAgentsMap[item.created_uid].full_name : "-" }}
                        </v-col>
                        <v-col cols="12" sm="4" class="pt-0">
                          <span class="grey--text text--darken-2">Fecha de registro:</span>
                          {{ $formatTimestamp(item.created_at, false, true) }}
                        </v-col>
                        <v-col cols="12">
                          <h5 class="body-2">{{ item.name }}</h5>
                        </v-col>
                        <v-col cols="12" sm="4">
                          <span class="grey--text text--darken-4">Filtros</span>
                          <br />
                          <span class="grey--text text--darken-2">Fecha inicio:</span>
                          {{ item.filters.date_start || "-" }}
                          <br />
                          <span class="grey--text text--darken-2">Fecha final:</span>
                          {{ item.filters.date_end || "-" }}
                          <br />
                          <span class="grey--text text--darken-2">Agente:</span>
                          {{ item.filters.agent_id ? getAllAgentsMap[item.filters.agent_id].full_name : "todos" }}
                        </v-col>
                        <v-col cols="12" sm="4">
                          <span class="grey--text text--darken-2">Estado:</span>
                          {{ item.state }}
                          <br />
                          <span v-if="item.state === 'error'">{{ item.error }}</span>
                        </v-col>
                        <v-col v-if="item.counter" cols="12" sm="4">
                          <span class="grey--text text--darken-2">Registros descargados:</span>
                          {{ item.counter || 0 }}
                        </v-col>
                      </v-row>
                    </td>
                  </template>
                </v-data-table>
              </v-col>
              <v-col v-else cols="12" class="text-center text--secondary pt-10">
                <i class="fas fa-file-download"></i>
                <h6 class="caption">Aún no hay descargas disponibles</h6>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>

      <v-btn
        :to="{ name: 'ticket_downloads_register' }"
        fixed
        bottom
        right
        x-large
        color="primary"
        elevation-12
        rounded
        class="hidden-sm-and-down"
      >
        <span>
          <v-icon size="16" class="mr-2">fas fa-file-download</v-icon>
          Nueva descarga
        </span>
      </v-btn>
    </v-container>
    <v-container v-else>
      <ShowLoading />
    </v-container>
  </v-app>
</template>
<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "TicketDownloads",
  data() {
    return {
      headers: [
        {
          text: "Nombre de descarga",
          align: "center",
          sortable: false,
          value: "name",
        },
        {
          text: "Fecha inicial (filtro)",
          align: "center",
          sortable: false,
          value: "filters.date_start",
        },
        {
          text: "Fecha final (filtro)",
          align: "center",
          sortable: false,
          value: "filters.date_end",
        },
        {
          text: "Encargado (filtro)",
          align: "center",
          sortable: false,
          value: "agent",
        },
        {
          text: "Estado",
          align: "center",
          sortable: false,
          value: "state",
        },
        {
          text: "Nº registros",
          align: "center",
          sortable: false,
          value: "counter",
        },
        {
          text: "Operación",
          align: "center",
          sortable: false,
          value: "operations",
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["getTicketDownloads", "getAgents", "getAllAgentsMap"]),
  },
  async created() {
    await this.getAllAgents();
    await this.getAllTicketDownloads();
  },
  methods: {
    ...mapActions(["getAllAgents", "getAllTicketDownloads"]),
    download(fileUrl) {
      let link = document.createElement("a");
      link.href = fileUrl;
      link.setAttribute("download", `apolocrm-orders-${this.$moment().format("DD-MM-YYYY-hh-mm")}.xlsx`);
      document.body.appendChild(link);
      link.click();
    },
  },
};
</script>
